import {
  REQUEST_EXTERNAL_LINKS,
  RECEIVE_EXTERNAL_LINKS,
} from '../actions/ExternalLinksActions'

export default (
  state = {
    externalLinks: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_EXTERNAL_LINKS:
      return Object.assign({}, state, {
        externalLinks: action.links,
      })
    case REQUEST_EXTERNAL_LINKS:
    default:
      return state
  }
}
