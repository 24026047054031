import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
  faExclamationTriangle,
  faQuestionCircle,
  faCheckSquare,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { compose } from 'redux'
import {
  Box, Button, Typography, withStyles, withTheme,
} from '@material-ui/core'
import Select from 'react-windowed-select'
import { fetchDevicesDetails } from '../actions/SitesActions'
import NeatTable from '../components/NeatTable'
import MonitoredDevicesProptype from '../proptypes/DeviceDetailsProptype'
import NeatPermissions from '../helpers/NeatPermissions'
import { Camelize, Titleize } from '../helpers/StringHelper'
import PingDeviceModal from '../components/PingDeviceModal'
import SlideUpModal from '../components/SlideUpModal'
import MonitoredDeviceGraph from '../components/MonitoredDeviceGraph'


const styles = theme => ({
  select: {
    fontFamily: theme.typography.fontFamily,
  },
  priorityText: {
    marginLeft: theme.spacing(1),
  },
})

/*
  MonitoredDevicesContainer
  Container for MonitoredDevices
 */
class MonitoredDevicesContainer extends React.Component {
  constructor(props) {
    super(props)
    const { deviceGroup } = props // default prop is 'allDevices'
    const selectedGroup = this.options().filter(option => option.id === deviceGroup)[0] || {}
    this.state = {
      selectedGroup,
      options: [],
      modalOpen: false,
    }
  }

  componentDidMount() {
    const { dispatch, siteId } = this.props
    dispatch(fetchDevicesDetails(siteId))
  }

  componentDidUpdate(prevProps) {
    const { devicesDetails: prevDeviceDetails } = prevProps
    const { devicesDetails: currentDeviceDetails } = this.props
    const { selectedGroup } = this.state
    let group
    const state = {}
    if (currentDeviceDetails !== prevDeviceDetails) {
      const options = this.filteredOptions(currentDeviceDetails)
      if (options.some(option => option.id === selectedGroup.id)) {
        group = selectedGroup
      } else {
        [group] = options.filter(option => option.id === 'allDevices')
      }
      state.options = options
      state.selectedGroup = group

      // eslint will complain about using setState in componentDidUpdate,
      // because it can cause an infinite loop if not used properly. Since
      // it is being used inside of a conditional that checks if the
      // props differ from the current props, it's fine to use this here.
      // so im gonna disable this warning

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(state)
    }
  }

  toggleModal = (deviceId) => {
    const { modalOpen } = this.state
    const stateObj = { modalOpen: !modalOpen }
    if (deviceId) {
      stateObj.deviceId = deviceId
    }

    this.setState(stateObj)
  }

  formUrl = (host) => {
    const reg = new RegExp(/^https?:\/\//i)
    // if it's already an https:// or http:// url, just return that
    if (reg.test(host)) {
      return host
    }
    // otherwise, we need to add the basic http:// to the front of the bare IP address
    return `http://${host}`
  }

  getPriorityIcon = (priority, size = '2x') => {
    const { alertStateMapping } = this.props
    const { color } = alertStateMapping[priority]
    switch (Number(priority)) {
      case 0:
        return <FontAwesomeIcon icon={faCheckSquare} style={{ color }} size={size} />
      case 1:
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color }} size={size} />
      case 2:
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color }} size={size} />
      case 3:
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color }} size={size} />
      case 4:
        return <FontAwesomeIcon icon={faWrench} style={{ color }} size={size} />
      default:
        return <FontAwesomeIcon icon={faQuestionCircle} size={size} />
    }
  }

  filteredOptions = (devicesDetails) => {
    const filteredDevices = {
      allDevices: devicesDetails.allDevices,
      networks: devicesDetails.networks,
      controllers: devicesDetails.controllers,
      units: devicesDetails.units,
      accessPoints: devicesDetails.accessPoints,
      unknownDevices: devicesDetails.unknownDevices,
    }
    const filtered = Object.keys(filteredDevices)
      .filter(key => devicesDetails[key].length).map(key => ({
        id: Camelize(key),
        value: Titleize(key),
        label: Titleize(key),
      }))
    return filtered
  }

  renderAlertStateLabel = (value) => {
    const { alertStateMapping } = this.props
    return (
      <Typography style={{ marginLeft: '1rem' }} variant="body1">{Object.keys(alertStateMapping[value]).length > 0 ? Titleize(alertStateMapping[value].alert_state) : '[Blank]' }</Typography>
    )
  }

  // headers for NeatTable
  headers = () => {
    const { user, dispatch } = this.props
    return [
      {
        id: 'name',
        label: 'Device Name',
        filterable: true,
        filterType: 'text',
      },
      {
        id: 'alertStateRank',
        label: 'Alert State',
        filterable: true,
        filterLabel: 'Device Status',
        filterOptionFormat: val => (
          <Box display="flex" flexDirection="row">
            { this.getPriorityIcon(val, 'lg') }
            { this.renderAlertStateLabel(val) }
          </Box>
        ),
        format: val => (
          <Box display="flex" flexDirection="row">
            { this.getPriorityIcon(val, '2x') }
          </Box>
        ),
      },
      {
        id: 'deviceStatus',
        label: 'Device Status',
        format: val => (
          <Box display="flex" flexDirection="row">
            { this.renderAlertStateLabel(val) }
          </Box>
        ),
      },
      {
        id: 'deviceType',
        filterable: true,
        filterType: 'text',
        label: 'Device Group',
      },
      {
        id: 'gatewayDeviceLink',
        label: 'Parent Device',
        filterable: true,
        filterLabel: 'Parent Device',
        format: (val) => {
          const regX = new RegExp(/^http/ig)
          if (user.hasPermission(NeatPermissions.devices.redirect)) {
            return (
              <a href={regX.test(val) ? val : this.formUrl(val)} rel="noopener noreferrer" target="_blank">
                <Typography variant="body1">{val}</Typography>
              </a>
            )
          }
          return val
        },
      },
      {
        id: 'ping',
        label: 'Ping',
        filterable: false,
        format: (val) => {
          if (user.hasPermission(NeatPermissions.sites.pdsh_ping_device)) {
            return (
              <PingDeviceModal dispatch={dispatch} siteId={val.site_id} deviceId={val.id} />
            )
          }
          return false
        },
      },
      {
        id: 'graph',
        label: 'Graph',
        filterable: false,
        format: (deviceId) => {
          if (user.hasPermission(NeatPermissions.devices.graph_data)) {
            return (
              <Button variant="outlined" color="primary" onClick={() => this.toggleModal(deviceId)}>View Graph</Button>
            )
          }
          return false
        },
      },
      {
        id: 'ip',
        label: 'External IP',
        filterable: true,
        filterType: 'text',
        format: (val) => {
          if (user.hasPermission(NeatPermissions.devices.redirect)) {
            return (
              <a href={this.formUrl(val)} rel="noopener noreferrer" target="_blank">
                <Typography variant="body1">{val}</Typography>
              </a>
            )
          }
          return val
        },
        tooltip: { // wraps the header in a tooltip that pops up on hover
          title: 'Port Forward for Wattbox/PDU is :40001',
          placement: 'top-start',
        },
        icon: faQuestionCircle, // renders an icon to the right of the header text
      },
      {
        id: 'location',
        label: 'Location',
        filterable: true,
        filterType: 'text',
      },
    ]
  }

  graphContainer = deviceId => (
    <MonitoredDeviceGraph deviceId={deviceId} />
  )

  // rows for NeatTable
  rows = () => {
    const { devicesDetails } = this.props
    const { selectedGroup } = this.state
    let rows
    if (selectedGroup) {
      const selectedData = devicesDetails[selectedGroup.id]
      rows = selectedData.map(device => this.createRow(device))
    } else {
      rows = []
    }
    return rows
  }

  // options for device type select
  options = () => {
    const optionsArray = ['allDevices', 'accessPoints', 'units', 'controllers', 'networks', 'unknownDevices']
    const optionsObject = optionsArray.map(value => ({
      id: value,
      value: Titleize(value),
      label: Titleize(value),
    }))
    return optionsObject
  }

  createRow = device => (
    {
      name: device.name,
      alertStateRank: device.device_statuses ? device.device_statuses[0].alert_state_rank : '',
      // device status, but give it the alert_state_rank, instead of alert_state
      // so we can get the devices in SDT to show up properly using the mapping hash
      deviceStatus: device.device_statuses ? device.device_statuses[0].alert_state_rank : '',
      deviceType: device.group_name,
      gatewayDeviceLink: device.gateway_device.link || device.gateway_device.host,
      ping: device,
      graph: device.id,
      ip: device.ip,
      location: device.location,
    }
  )

  handleChange = (selectedGroup) => {
    this.setState({ selectedGroup })
  }

  render() {
    const {
      classes, theme, devicesDetails,
    } = this.props
    const { alertStateMapping } = devicesDetails
    const { selectedGroup, deviceId, modalOpen } = this.state
    const { options } = this.state
    return (
      <Box p={1}>
        <Box mt={1}>
          <Select
            className={classes.select}
            value={selectedGroup}
            options={options}
            placeholder="Device Group"
            onChange={this.handleChange}
            menuPortalTarget={document.body}
            styles={{
              option: base => ({
                ...base,
                fontFamily: theme.typography.fontFamily,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
          />
          <NeatTable
            headers={this.headers()}
            rows={this.rows()}
            onRowClicked={this.handleRowClick}
            legendMapping={alertStateMapping}
            defaultSortColumn="alertStateRank"
            defaultSortDirection="desc"
            getIcon={this.getPriorityIcon}
          />
        </Box>
        <SlideUpModal
          open={modalOpen}
          onClose={() => this.toggleModal()}
          childComponent={this.graphContainer(deviceId)}
          title="Monitored Device Graph"
          maxWidth="xl"
        />
      </Box>
    )
  }
}

MonitoredDevicesContainer.propTypes = {
  alertStateMapping: PropTypes.instanceOf(Object),
  classes: PropTypes.instanceOf(Object).isRequired,
  devicesDetails: MonitoredDevicesProptype.isRequired,
  deviceGroup: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  siteId: PropTypes.string,
  theme: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

MonitoredDevicesContainer.defaultProps = {
  alertStateMapping: {},
  deviceGroup: 'allDevices',
  siteId: '',
}

function mapStateToProps(state) {
  const { user } = state.global
  const { sitesReducer } = state
  const { location } = state.router
  const {
    devicesDetails,
  } = sitesReducer
  const { alertStateMapping } = devicesDetails
  return {
    alertStateMapping,
    devicesDetails,
    location,
    user,
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps),
)(MonitoredDevicesContainer)
