import {
  REQUEST_ROLES,
  RECEIVE_ROLES,
} from '../actions/RolesActions'

export default (
  state = {
    roles: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_ROLES:
      return Object.assign({}, state, {
        roles: action.roles,
      })
    case REQUEST_ROLES:
    default:
      return state
  }
}
