import HandleTimeout from '../helpers/SessionHelper'

// ------------------- INDEX ACTIONS --------------------
export const REQUEST_EXTERNAL_LINKS = 'REQUEST_EXTERNAL_LINKS'
function requestExternalLinks() {
  return {
    type: REQUEST_EXTERNAL_LINKS,
  }
}

export const RECEIVE_EXTERNAL_LINKS = 'RECEIVE_EXTERNAL_LINKS'
function receiveExternalLinks(links) {
  return {
    type: RECEIVE_EXTERNAL_LINKS,
    links,
  }
}

export const FAILURE_EXTERNAL_LINKS = 'FAILURE_EXTERNAL_LINKS'
function failureExternalLinks(error) {
  return {
    type: FAILURE_EXTERNAL_LINKS,
    error,
  }
}

// ------------------- CREATE ACTIONS --------------------
export const REQUEST_CREATE_EXTERNAL_LINK = 'REQUEST_CREATE_EXTERNAL_LINK'
function requestCreateExternalLink() {
  return {
    type: REQUEST_CREATE_EXTERNAL_LINK,
  }
}

export const RECEIVE_CREATE_EXTERNAL_LINK = 'RECEIVE_CREATE_EXTERNAL_LINK'
function receiveCreateExternalLink(link) {
  return {
    type: RECEIVE_CREATE_EXTERNAL_LINK,
    link,
  }
}

export const FAILURE_CREATE_EXTERNAL_LINK = 'FAILURE_CREATE_EXTERNAL_LINK'
function failureCreateExternalLink(error) {
  return {
    type: FAILURE_CREATE_EXTERNAL_LINK,
    error,
  }
}

// ------------------- DELETE ACTIONS --------------------
export const REQUEST_DELETE_EXTERNAL_LINK = 'REQUEST_DELETE_EXTERNAL_LINK'
function requestDeleteExternalLink() {
  return {
    type: REQUEST_DELETE_EXTERNAL_LINK,
  }
}

export const RECEIVE_DELETE_EXTERNAL_LINK = 'RECEIVE_DELETE_EXTERNAL_LINK'
function receiveDeleteExternalLink(link) {
  return {
    type: RECEIVE_DELETE_EXTERNAL_LINK,
    link,
  }
}

export const FAILURE_DELETE_EXTERNAL_LINK = 'FAILURE_DELETE_EXTERNAL_LINK'
function failureDeleteExternalLink(error) {
  return {
    type: FAILURE_DELETE_EXTERNAL_LINK,
    error,
  }
}

// ------------------- UPDATE ACTIONS --------------------
export const REQUEST_UPDATE_EXTERNAL_LINK = 'REQUEST_UPDATE_EXTERNAL_LINK'
function requestUpdateExternalLink() {
  return {
    type: REQUEST_UPDATE_EXTERNAL_LINK,
  }
}

export const RECEIVE_UPDATE_EXTERNAL_LINK = 'RECEIVE_UPDATE_EXTERNAL_LINK'
function receiveUpdateExternalLink(link) {
  return {
    type: RECEIVE_UPDATE_EXTERNAL_LINK,
    link,
  }
}

export const FAILURE_UPDATE_EXTERNAL_LINK = 'FAILURE_UPDATE_EXTERNAL_LINK'
function failureUpdateExternalLink(error) {
  return {
    type: FAILURE_UPDATE_EXTERNAL_LINK,
    error,
  }
}


export function fetchExternalLinks() {
  return (dispatch) => {
    dispatch(requestExternalLinks())
    return fetch('/api/external_links')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveExternalLinks(json)))
      .catch(error => dispatch(failureExternalLinks(error)))
  }
}

export function fetchCreateExternalLink(link) {
  return (dispatch) => {
    dispatch(requestCreateExternalLink(link))
    return fetch('/api/external_links/create', {
      method: 'POST',
      body: link,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchExternalLinks())
          dispatch(receiveCreateExternalLink(link))
          return json
        }
        dispatch(failureCreateExternalLink(json))
        throw json
      })
  }
}


export function fetchDeleteExternalLink(link) {
  return (dispatch) => {
    dispatch(requestDeleteExternalLink(link))
    return fetch('/api/external_links/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ link }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchExternalLinks())
          dispatch(receiveDeleteExternalLink(link))
          return json
        }
        dispatch(failureDeleteExternalLink(json))
        throw json
      })
  }
}

export function fetchUpdateExternalLink(link) {
  return (dispatch) => {
    dispatch(requestUpdateExternalLink(link))
    return fetch('/api/external_links/update', {
      method: 'POST',
      body: link,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchExternalLinks())
          dispatch(receiveUpdateExternalLink(link))
          return json
        }
        dispatch(failureUpdateExternalLink(json))
        throw json
      })
  }
}
