// Currently this is only going to work for client side tables where all of the
// data is already on hand. There might be potential for refactor to work with
// server side rendered collections. The neat table currently does something
// similar to this, but it accepts an additional prop that is a function that
// will run if the current implementation of the neat table is 'server side'.
// I'm just rambling at this point.
import React from 'react'
import PropTypes from 'prop-types'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import { CSVLink } from 'react-csv'

export default function CsvDownloadButton(props) {
  const {
    data, csvLink, tooltipTitle, exportFileName,
  } = props
  return (
    <Box textAlign="end">
      <Tooltip title={tooltipTitle}>
        <IconButton
          aria-label="download"
          onClick={() => {
            csvLink.current.link.click()
          }}
        >
          <FontAwesomeIcon size="xs" icon={faDownload} />
        </IconButton>
      </Tooltip>
      <CSVLink
        filename={exportFileName}
        data={data}
        ref={csvLink}
        className="hidden"
      />
    </Box>
  )
}

CsvDownloadButton.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  csvLink: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  tooltipTitle: PropTypes.string,
  exportFileName: PropTypes.string,
}

CsvDownloadButton.defaultProps = {
  tooltipTitle: 'Download CSV',
  exportFileName: 'export.csv',
}
