import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  codeBlock: {
    backgroundColor: '#eee',
    border: '1px solid #999',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
}))

export default function CodeBlock(props) {
  const { lines } = props
  const classes = useStyles()
  return (
    <pre>
      <code className={classes.codeBlock}>
        { lines.map(line => (
          line !== '' && (
            <p key={line}>{line}</p>
          )
        ))}
      </code>
    </pre>
  )
}

CodeBlock.propTypes = {
  lines: PropTypes.instanceOf(Array).isRequired,
}
