import {
  REQUEST_PERMISSIONS,
  RECEIVE_PERMISSIONS,
} from '../actions/PermissionsActions'

export default (
  state = {
    permissions: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_PERMISSIONS:
      return Object.assign({}, state, {
        permissions: action.permissions,
      })
    case REQUEST_PERMISSIONS:
    default:
      return state
  }
}
