import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  withStyles,
  Button,
  Box,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  IconButton,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import OutageAlertManager from './OutageAlertManager'
import NeatTable from '../NeatTable'
import {
  fetchOutageAlerts,
  fetchCreateOutageAlert,
  fetchDeleteOutageAlert,
  fetchUpdateOutageAlert,
  fetchToggleOutageAlertActive,
} from '../../actions/OutageAlertsActions'
import NeatPermissions from '../../helpers/NeatPermissions'
import { headers, rows } from './OutageAlertsTableHelper'

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  deleteButton: {
    color: 'red',
  },
  deactivateButton: {
    color: 'red',
  },
  activateButton: {
    color: theme.palette.primary.main,
  },
})

class OutageAlertsContainer extends React.Component {
  constructor(props) {
    super(props)
    const { location } = props
    const { pathname } = location
    const siteId = pathname.split('/')[2]
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
      siteId,
    }
  }

  componentDidMount() {
    const { dispatch, outageAlerts } = this.props
    const { siteId } = this.state
    if (!outageAlerts.length > 0) {
      // fail safe if the alerts weren't given as props from the locations dashboard
      dispatch(fetchOutageAlerts(siteId))
    }
  }

  setModalOpen = (open, type, object = {}) => () => {
    this.setState({
      modalOpen: open,
      modalType: type,
      modalObject: object,
    })
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  clearErrors = () => this.setState({ errors: [] })

  toggleActive = (alert) => {
    const { dispatch } = this.props
    const { siteId } = this.state
    dispatch(fetchToggleOutageAlertActive(siteId, alert))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  updateOrCreate = (alert) => {
    const { dispatch } = this.props
    const { modalType, siteId } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateOutageAlert
    } else if (modalType === 'delete') {
      request = fetchDeleteOutageAlert
    } else {
      request = fetchUpdateOutageAlert
    }
    dispatch(request(siteId, alert))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  renderErrorContent(errors) {
    const { classes } = this.props
    return (
      <SnackbarContent
        className={classes.error}
        message={(
          <Box display="flex" flexDirection="row">
            <ErrorIcon />
            <Box mr={1} />
            <Typography>
              { errors.map(error => (
                <React.Fragment key={error}>
                  {error}
                  <br />
                </React.Fragment>
              )) }
            </Typography>
          </Box>
        )}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  renderErrors() {
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={10000}
        onClose={this.clearErrors}
      >
        { this.renderErrorContent(errors) }
      </Snackbar>
    )
  }

  renderOutageAlertsManager() {
    const { dispatch } = this.props
    const {
      modalOpen,
      modalType,
      modalObject,
      siteId,
    } = this.state
    return (
      <Modal open={modalOpen} onClose={this.setModalOpen(false)}>
        <React.Fragment>
          <OutageAlertManager
            type={modalType}
            dispatch={dispatch}
            outageAlert={modalObject}
            onSubmit={this.updateOrCreate}
            onClose={this.setModalOpen(false)}
            siteId={siteId}
          />
        </React.Fragment>
      </Modal>
    )
  }

  render() {
    const { user } = this.props
    const rowProps = {
      setModalOpen: this.setModalOpen,
      toggleActive: this.toggleActive,
      ...this.props,
    }
    return (
      <Box width="100%" p={1}>
        <Box display="flex" justifyContent="flex-end" m={1}>
          {user.hasPermission(NeatPermissions.outage_alerts.create) ? (
            <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
          ) : ''}
        </Box>
        <NeatTable
          headers={headers()}
          rows={rows(rowProps)}
        />
        { this.renderOutageAlertsManager() }
      </Box>
    )
  }
}

OutageAlertsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  outageAlerts: PropTypes.arrayOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { outageAlertsReducer } = state
  const { outageAlerts } = outageAlertsReducer
  const { user } = state.global
  return {
    outageAlerts,
    user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(OutageAlertsContainer)
