/* eslint-disable no-underscore-dangle */
import React from 'react'
import { withStyles, withTheme } from '@material-ui/styles'
import { Typography, Container } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import ErrorBoundaryStyles from '../styles/ErrorBoundaryStyles'

const styles = () => (ErrorBoundaryStyles)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  // TODO : update this email to support@neatapp.net. feedback email is being used for the beta

  renderStack = () => {
    const { info } = this.state
    const { classes } = this.props
    const formatted = info.componentStack.split(' in')
    return (
      <React.Fragment>
        <Typography style={{ fontWeight: 'lighter' }} variant="h6">Stack Trace:</Typography>
        <Typography variant="caption">(Only visible in development.)</Typography>
        <div key={Math.random()} className={classes.stackContainer}>
          {formatted.map(str => (
            <Typography className={classes.errorText} key={str} variant="body1">
              {str}
            </Typography>
          ))}
        </div>
      </React.Fragment>
    )
  }

  renderErrorBody() {
    const { classes } = this.props
    return (
      <Typography className={classes.errorBody} variant="body1">
        Try reloading your browser window. Or&nbsp;
        <a href="mailto:feedback@neatapp.net?Subject=NEAT%20Error%20Encountered">
          contact support
        </a>
        &nbsp;if the issue persists.
      </Typography>
    )
  }

  renderFullSizeError() {
    const { error } = this.state
    const { classes, componentName } = this.props
    return (
      <Container className={classes.root}>
        <Typography className={classes.errorHeading} variant="h4">
          {`Something went wrong in the ${componentName} component.`}
        </Typography>
        { this.renderErrorBody() }
        <hr />
        <Typography variant="h5">Error Message:</Typography>
        <Typography className={classes.errorText} variant="body1">{`${error}`}</Typography>
        { window._env !== 'production' && this.renderStack() }
      </Container>
    )
  }

  renderSmallError() {
    const { error } = this.state
    const { classes, componentName } = this.props
    return (
      <Container className={classes.root}>
        <Typography className={classes.errorHeading} variant="h6">
          {`Something went wrong in the ${componentName} component.`}
        </Typography>
        { this.renderErrorBody() }
        <hr />
        <Typography style={{ fontWeight: 'lighter' }} variant="h6">Error Message:</Typography>
        <Typography className={classes.errorText} variant="body1">{`${error}`}</Typography>
        { window._env !== 'production' && this.renderStack() }
      </Container>
    )
  }

  render() {
    const { hasError } = this.state
    const { children, small } = this.props
    if (hasError) {
      return (
        small ? this.renderSmallError() : this.renderFullSizeError()
      )
    }
    return children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  componentName: PropTypes.string,
  small: PropTypes.bool,
}

ErrorBoundary.defaultProps = {
  componentName: '',
  small: false,
}

export default compose(withStyles(styles))(withTheme(ErrorBoundary))
