import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: '2.5rem',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    borderRadius: 10,
  },

  font: {
    fontFamily: 'sans-serif',
    color: theme.palette.secondary.main,
  },

  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
}))

export default function UniqueConnectedDevices(props) {
  const classes = useStyles()
  const { uniqConnectedDevices } = props
  return (
    <div>
      <h4 className={classes.header}>Unique Connected Devices</h4>
      <Paper className={classes.root}>
        <h4 className={classes.font}>{uniqConnectedDevices || 'N/A'}</h4>
      </Paper>
    </div>
  )
}

UniqueConnectedDevices.propTypes = {
  uniqConnectedDevices: PropTypes.number,
}

UniqueConnectedDevices.defaultProps = {
  uniqConnectedDevices: undefined,
}
