import {
  REQUEST_GATEWAYS_THROUGHPUT,
  REQUEST_GATEWAYS_CLIENT_COUNT,
  RECEIVE_GATEWAYS_THROUGHPUT,
  RECEIVE_GATEWAYS_CLIENT_COUNT,
} from '../actions/GatewaysActions'

export default (
  state = {
    throughputData: [],
    clientCountData: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_GATEWAYS_THROUGHPUT:
      return Object.assign({}, state, {
        throughputData: action.throughputData,
      })
    case RECEIVE_GATEWAYS_CLIENT_COUNT:
      return Object.assign({}, state, {
        clientCountData: action.clientCountData,
      })
    case REQUEST_GATEWAYS_THROUGHPUT:
    case REQUEST_GATEWAYS_CLIENT_COUNT:
    default:
      return state
  }
}
