import React from 'react'
import { connect } from 'react-redux'
import {
  Card, CardContent, Typography, Grid, Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWifi, faHome, faLaptop, faHdd, faCheckCircle, faQuestionCircle, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import DeviceDetailsProptype from '../proptypes/DeviceDetailsProptype'
import MonitoredDevicesContainer from '../containers/MonitoredDevicesContainer'
import FullScreenModal from './FullScreenModal'

const styles = theme => ({
  card: {
    height: '100%',
    background: theme.color,
  },
  error: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 8,
  },
  warning: {
    backgroundColor: 'orange',
    borderRadius: 8,
  },
  allOk: {
    color: 'green',
    borderRadius: 8,
  },
  box: {
    border: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    height: '100%',
  },
  alertText: {
    variant: 'h2',
  },
  container: {
    padding: theme.spacing(2),
    alignItems: 'center',
    justify: 'space-evenly',
  },
  textItems: {
    paddingLeft: theme.spacing(1),
  },
  button: {
    textTransform: 'none',
  },
  notFoundMessage: {
    marginTop: '4.5rem',
    textDecoration: 'underline',
  },
  notFoundIcon: {
    color: '#f5ca03',
    marginRight: theme.spacing(1),
  },
})

/*
  This component controls a modal that displays the MonitoredDevicesContainer.
  Clicking the individual device group icons will render the modal for the
  device group that was clicked.
*/
class DeviceDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  // takes a device group argument, renders devices in that group
  toggleModal = (group) => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen, group })
  }

  // use this as the child component for the modal
  monitoredDevicesContainer = () => {
    const { siteId, user } = this.props
    // make sure the container renders devices for the group the user clicked on
    const { group } = this.state
    return (
      <MonitoredDevicesContainer siteId={siteId} user={user} deviceGroup={group} />
    )
  }

  warningClass = (issues, deviceCount) => {
    const { classes } = this.props
    return (issues.length / deviceCount > 0.10) ? classes.error : classes.warning
  }

  issuesFragment = issues => (
    <Grid item>
      <Typography align="center" variant="h2">{issues.length}</Typography>
      <Typography align="center">Alerts</Typography>
    </Grid>
  )

  detailsFragment = (group) => {
    const { classes } = this.props
    const {
      title,
      alerts,
      deviceDetails,
      icon,
    } = group
    return (
      <Grid container direction="column">
        <Grid container item>
          <Grid item>
            <FontAwesomeIcon icon={icon} size="2x" />
          </Grid>
          <Grid item className={classes.textItem}>
            <Typography>{title}</Typography>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <Typography>Devices: {deviceDetails.length}</Typography>
          </Grid>
        </Grid>
        {alerts.length ? this.issuesFragment(alerts) : this.greenCheckFragment() }
      </Grid>
    )
  }

  greenCheckFragment = () => (
    <Grid item container flex-direction="column" justify="center">
      <Typography align="center"><FontAwesomeIcon align="center" icon={faCheckCircle} size="3x" /></Typography>
      <Typography align="center">100%</Typography>
    </Grid>
  )

  uniqueUnits = units => [...new Set(units.map(unit => unit.group_name))]

  getGroupDetails = () => {
    const { details } = this.props
    const {
      units, accessPoints, controllers, networks, unknownDevices, accessPointAlerts,
      unitAlerts, networkAlerts, controllerAlerts, unknownDeviceAlerts,
    } = details
    return {
      accessPoints: {
        title: 'Access Points',
        deviceDetails: accessPoints,
        alerts: accessPointAlerts,
        icon: faWifi,
      },
      units: {
        title: `${this.uniqueUnits(units).length} Units`,
        deviceDetails: units,
        alerts: unitAlerts,
        icon: faHome,
      },
      controllers: {
        title: 'Controllers',
        deviceDetails: controllers,
        alerts: controllerAlerts,
        icon: faLaptop,
      },
      networks: {
        title: 'Networks',
        deviceDetails: networks,
        alerts: networkAlerts,
        icon: faHdd,
      },
      unknownDevices: {
        title: 'Unknown',
        deviceDetails: unknownDevices,
        alerts: unknownDeviceAlerts,
        icon: faQuestionCircle,
      },
    }
  }

  renderDeviceDetails = () => {
    const { classes } = this.props
    const groupDetails = this.getGroupDetails()
    return Object.keys(groupDetails).map((group) => {
      const currentGroup = groupDetails[group]
      const { alerts, deviceDetails } = currentGroup

      return deviceDetails.length > 0 && (
        <Grid item key={currentGroup.title}>
          <Button
            disabled={!deviceDetails.length}
            onClick={() => this.toggleModal(group)}
            className={alerts.length
              ? [this.warningClass(alerts, deviceDetails.length), classes.container, classes.button].join(' ')
              : [classes.allOk, classes.container, classes.button].join(' ')}
          >
            {this.detailsFragment(currentGroup)}
          </Button>
        </Grid>
      )
    })
  }

  render() {
    const { classes } = this.props
    const { modalOpen } = this.state
    const groupDetails = this.getGroupDetails()
    // check if any of the groups (access_points, controllers, networks etc..) have actual details
    const renderDetails = Object.values(groupDetails).some(group => group.deviceDetails.length > 0)

    return (
      <React.Fragment>
        <Card style={{ overflowY: 'scroll' }} className={classes.card}>
          <CardContent>
            <Grid container flex-direction="row" alignItems="center" justify="center">
              { renderDetails ? (
                this.renderDeviceDetails()
              ) : (
                <React.Fragment>
                  <Typography className={classes.notFoundMessage} variant="h5">
                    <FontAwesomeIcon icon={faExclamationTriangle} className={classes.notFoundIcon} />
                    No devices found for the selected site.
                  </Typography>
                </React.Fragment>
              )}
            </Grid>
          </CardContent>
        </Card>

        <FullScreenModal
          open={modalOpen}
          onClose={() => this.toggleModal()} // omit the group argument because we're just closing
          childComponent={this.monitoredDevicesContainer()}
          title="Monitored Devices"
        />
      </React.Fragment>
    )
  }
}

DeviceDetails.propTypes = {
  details: DeviceDetailsProptype.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  siteId: PropTypes.string,
  user: PropTypes.instanceOf(Object).isRequired,
}

DeviceDetails.defaultProps = {
  siteId: '',
}


export default withStyles(styles)(connect()(DeviceDetails))
