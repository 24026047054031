import {
  REQUEST_FEATURE_REQUEST_FORMS,
  RECEIVE_FEATURE_REQUEST_FORMS,
  FAILURE_FEATURE_REQUEST_FORMS,
  REQUEST_CREATE_FEATURE_REQUEST,
  RECEIVE_CREATE_FEATURE_REQUEST,
  FAILURE_CREATE_FEATURE_REQUEST,
  REQUEST_DELETE_FEATURE_REQUEST,
  RECEIVE_DELETE_FEATURE_REQUEST,
  FAILURE_DELETE_FEATURE_REQUEST,
} from '../actions/FeatureRequestFormsActions'

export default (
  state = {
    featureRequestForms: [],
    featureRequestErrors: [],
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_FEATURE_REQUEST_FORMS:
    case RECEIVE_FEATURE_REQUEST_FORMS:
      return Object.assign({}, state, {
        featureRequestForms: action.featureRequestForms,
      })
    case FAILURE_FEATURE_REQUEST_FORMS:
      return Object.assign({}, state, {
        featureRequestErrors: action.error,
      })

    case REQUEST_CREATE_FEATURE_REQUEST:
    case RECEIVE_CREATE_FEATURE_REQUEST:
    case FAILURE_CREATE_FEATURE_REQUEST:
      return Object.assign({}, state, {
        featureRequestErrors: action.error,
      })

    case REQUEST_DELETE_FEATURE_REQUEST:
    case RECEIVE_DELETE_FEATURE_REQUEST:
    case FAILURE_DELETE_FEATURE_REQUEST:
      return Object.assign({}, state, {
        featureRequestErrors: action.error,
      })
    default:
      return state
  }
}