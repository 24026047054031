import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import CircularProgressSpinner from './CircularProgressSpinner'
import NeatGraph from './NeatGraph'
import { fetchGatewaysClientCount } from '../actions/GatewaysActions'
import DateFilters from './DateFilters'
import {
  formatData, graphHeight, xAxisFormat, xAxisLabel, graphMargins,
} from '../helpers/GatewayHelper'

export default class ClientCountGraph extends React.Component {
  // fetch data for the default timeframe when the component first renders
  componentDidMount() {
    this.fetchClientCountData()
  }

  // fetches new throughput data for the site and given timeframe
  fetchClientCountData = (startDate, endDate) => {
    const { dispatch, siteId } = this.props
    dispatch(fetchGatewaysClientCount(siteId, { startDate, endDate }))
  }

  render() {
    const { data, expanded, toggleExpanded } = this.props
    return (
      <React.Fragment>
        <Grid container>
          { expanded && <DateFilters onFilter={this.fetchClientCountData} xs={2} /> }
          <Grid item xs={expanded ? 10 : 12}>
            { data.length >= 2 ? (
              <NeatGraph
                title="Client Count"
                expanded={expanded}
                toggleExpanded={() => toggleExpanded()}
                color={[data[0].color]}
                label={[data[0].legend, data[1].legend]}
                type={['area', 'line']}
                data={formatData(data, 'clientCount')}
                height={graphHeight(expanded)}
                xAxisLabel={xAxisLabel(expanded)}
                xFormat={val => (xAxisFormat(val, expanded))}
                margin={graphMargins(expanded)}
                showExpandButton
                tickLabelAngle={-45}
              />
            ) : (
              <React.Fragment>
                <CircularProgressSpinner />
                <Typography variant="body1">Loading...</Typography>
              </React.Fragment>
            )}

          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

ClientCountGraph.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  dispatch: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
}
