import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, withTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { replace } from 'connected-react-router'
import ComboBox from './ComboBox'
import RouterLocationPrototype from '../proptypes/RouterLocationPrototype'
import NeatPermissions from '../helpers/NeatPermissions'

import {
  fetchGatewayDetails,
  fetchSite,
  fetchSites,
  fetchTickets,
  fetchDevicesDetails,
  fetchDocuments,
  fetchOpenClosedTicketCounts,
} from '../actions/SitesActions'
import { fetchGatewaysClientCount, fetchGatewaysThroughput } from '../actions/GatewaysActions'
import { fetchOutageAlerts } from '../actions/OutageAlertsActions'
import { getRoute } from '../helpers/RouteHelper'

const styles = theme => ({
  select: {
    fontFamily: theme.typography.fontFamily,
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
})

class PropertyDropdown extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    const { dispatch, siteId, sites } = this.props
    let site = {}
    if (sites.length === 0) {
      dispatch(fetchSites())
    } else if (siteId) {
      site = this.options().filter(s => s.id === siteId)[0] || {}
      this.fetchData(site)
    } else {
      [site] = this.options()
      this.fetchData(site)
    }

    this.state = {
      selectedOption: site,
    }
  }

  // This typically runs after the initial load where the component fetches the sites
  componentDidUpdate(prevProps) {
    const { siteId, sites } = this.props
    const { selectedOption } = this.state
    const options = this.options()
    // "Choose" first site once sites have been loaded and if there isn't one selected
    // eslint-disable-next-line max-len
    if (prevProps.sites.length === 0 && sites.length > 0 && Object.keys(selectedOption).length === 0) {
      if (siteId) {
        const site = this.options()
          .filter(s => s.id === siteId)[0] || {}
        this.handleChange(null, site)
      } else {
        this.handleChange(null, options[0])
      }
    }
  }

  options = () => {
    const { sites } = this.props
    return sites.map((site) => {
      const address = (site.address || '').replace(/\r?\n|\r/g, ' ')
      return ({
        title: `${site.name} - ${address}`,
        id: site.id,
      })
    })
  }

  // the unused _event argument is so the auto complete component
  // onChange functionality works properly, do not remove.

  handleChange = (_event, selectedOption) => {
    this.setState({ selectedOption })
    this.fetchData(selectedOption)
  }

  fetchData = (selectedOption) => {
    const {
      location, dispatch, ticketFilters, user,
    } = this.props

    const route = getRoute(location.pathname)

    if (Object.keys(selectedOption).length) {
      const siteId = selectedOption.id
      dispatch(replace(`${route.path}/${siteId}`))
      dispatch(fetchSite(siteId))
      dispatch(fetchGatewayDetails(siteId))
      dispatch(fetchTickets(siteId, ticketFilters))
      dispatch(fetchDevicesDetails(siteId))
      dispatch(fetchGatewaysThroughput(siteId))
      dispatch(fetchGatewaysClientCount(siteId))
      dispatch(fetchDocuments(siteId))
      dispatch(fetchOpenClosedTicketCounts(siteId))

      if (user.hasPermission(NeatPermissions.outage_alerts.index)) {
        dispatch(fetchOutageAlerts(siteId))
      }
    } else {
      // if the provided site id wasn't found default it back to the /locations route
      window.location.href = '/locations'
    }
  }

  render() {
    const { selectedOption } = this.state

    return (
      <ComboBox
        handleChange={this.handleChange}
        label="Search for a property..."
        options={this.options()}
        selectedOption={selectedOption}
        size="small"
      />
    )
  }
}

PropertyDropdown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: RouterLocationPrototype.isRequired,
  siteId: PropTypes.string,
  sites: PropTypes.arrayOf(Object).isRequired,
  ticketFilters: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
}

PropertyDropdown.defaultProps = {
  siteId: '',
  ticketFilters: {},
}

function mapStateToProps(state) {
  const { location } = state.router
  return {
    location,
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps),
)(PropertyDropdown)
