import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
} from '@material-ui/core'

import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

import AdminStyles from '../styles/AdminStyles'

import NeatTable from '../components/NeatTable'
import {
  fetchCustomerSettings,
  fetchCreateCustomerSetting,
  fetchUpdateCustomerSetting,
  fetchDeleteCustomerSetting,
} from '../actions/CustomerSettingsActions'
import { fetchCustomers } from '../actions/CustomersActions'
import CustomerSettingsManager from '../components/CustomerSettingsManager'
import NeatPermissions from '../helpers/NeatPermissions'
// import CustomerSettingsManager from '../components/CustomerSettingsManager'

class CustomerSettingsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchCustomerSettings())
    // only admin will be able to change the customer
    // customer input will be disabled if it is already created...
    if (this.userIsAdmin()) {
      dispatch(fetchCustomers())
    }
  }

  setModalOpen = (open, type, object = {}) => () => {
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  clearErrors = () => this.setState({ errors: [] })

  headers = () => ([
    {
      id: 'customer', label: 'Customer',
    },
    {
      id: 'timeout', label: 'Timeout',
    },
    {
      id: 'displayedNearestWarehouses', label: 'Displayed Nearest Warehouses',
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ])

  rows = () => {
    const { customerSettings } = this.props
    const rows = []
    customerSettings.forEach((setting) => {
      rows.push(this.createRow(setting))
    })
    return rows
  }

  createRow = (customerSetting) => {
    const { classes } = this.props
    return (
      {
        customer: customerSetting.customer ? customerSetting.customer.name : '',
        timeout: customerSetting.timeout,
        displayedNearestWarehouses: customerSetting.displayed_nearest_warehouses,
        edit: <Button color="primary" onClick={this.setModalOpen(true, 'edit', customerSetting)}>Edit</Button>, // anyone can edit
        delete: this.userIsAdmin() && <Button className={classes.deleteButton} onClick={this.setModalOpen(true, 'delete', customerSetting)}>Delete</Button>, // only admins can create/delete
      }
    )
  }

  updateOrCreateCustomerSetting = (setting) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateCustomerSetting
    } else if (modalType === 'delete') {
      request = fetchDeleteCustomerSetting
    } else {
      request = fetchUpdateCustomerSetting
    }

    dispatch(request(setting))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  userIsAdmin() {
    const { user } = this.props
    return user.hasPermission(NeatPermissions.admin)
  }

  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  renderModal = (open, type, object) => {
    const { dispatch, customers } = this.props

    return (
      <Modal open={open} onClose={this.setModalOpen(false)}>
        <React.Fragment>
          <CustomerSettingsManager
            type={type}
            dispatch={dispatch}
            customerSetting={object}
            availableCustomers={customers}
            onSubmit={this.updateOrCreateCustomerSetting}
            onClose={this.setModalOpen(false)}
          />
        </React.Fragment>
      </Modal>
    )
  }

  render() {
    const { modalOpen, modalType, modalObject } = this.state

    return (
      <Box width="100%" p={1}>
        {/* only admin users can create */}
        { this.userIsAdmin() && (
          <Box display="flex" justifyContent="flex-end" mr={2}>
            <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
          </Box>
        )}

        <NeatTable
          headers={this.headers()}
          rows={this.rows()}
        />

        { this.renderModal(modalOpen, modalType, modalObject) }
        { this.renderErrors() }
      </Box>
    )
  }
}

CustomerSettingsContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  customerSettings: PropTypes.arrayOf(Object).isRequired,
  customers: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { customerSettingsReducer, customersReducer, global } = state
  const { customerSettings } = customerSettingsReducer
  const { customers } = customersReducer
  const { user } = global
  return { customerSettings, customers, user }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(CustomerSettingsContainer)
