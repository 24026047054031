import HandleTimeout from '../helpers/SessionHelper'
import NeatPermissions from '../helpers/NeatPermissions'

const querystring = require('querystring')

export const REQUEST_CENTRALIZED_OUTAGES = 'REQUEST_CENTRALIZED_OUTAGES'
function requestCentralizedOutages() {
  return {
    type: REQUEST_CENTRALIZED_OUTAGES,
    requiredPermission: NeatPermissions.outages.centralized,
  }
}

export const RECEIVE_CENTRALIZED_OUTAGES = 'RECEIVE_CENTRALIZED_OUTAGES'
function receiveCentralizedOutages(centralizedOutages) {
  return {
    type: RECEIVE_CENTRALIZED_OUTAGES,
    centralizedOutages,
  }
}

export const FAILURE_CENTRALIZED_OUTAGES = 'FAILURE_CENTRALIZED_OUTAGES'
function failureCentralizedOutages(error) {
  return {
    type: FAILURE_CENTRALIZED_OUTAGES,
    error,
  }
}

export const REQUEST_DISTRIBUTED_OUTAGES = 'REQUEST_DISTRIBUTED_OUTAGES'
function requestDistributedOutages() {
  return {
    type: REQUEST_DISTRIBUTED_OUTAGES,
    requiredPermission: NeatPermissions.outages.distributed,
  }
}

export const RECEIVE_DISTRIBUTED_OUTAGES = 'RECEIVE_DISTRIBUTED_OUTAGES'
function receiveDistributedOutages(distributedOutages) {
  return {
    type: RECEIVE_DISTRIBUTED_OUTAGES,
    distributedOutages,
  }
}

export const FAILURE_DISTRIBUTED_OUTAGES = 'FAILURE_DISTRIBUTED_OUTAGES'
function failureDistributedOutages(error) {
  return {
    type: FAILURE_DISTRIBUTED_OUTAGES,
    error,
  }
}

export const REQUEST_OUTAGE_DASHBOARD = 'REQUEST_OUTAGE_DASHBOARD'
function requestOutageDashboard(filters) {
  return {
    type: REQUEST_OUTAGE_DASHBOARD,
    requiredPermission: NeatPermissions.outages.dashboard,
    filters,
  }
}
export const RECEIVE_OUTAGE_DASHBOARD = 'RECEIVE_OUTAGE_DASHBOARD'
function receiveOutageDashboard(json) {
  return {
    type: RECEIVE_OUTAGE_DASHBOARD,
    tickets: json,
  }
}
export const FAILURE_OUTAGE_DASHBOARD = 'FAILURE_OUTAGE_DASHBOARD'
function failureOutageDashboard(error) {
  return {
    type: FAILURE_OUTAGE_DASHBOARD,
    error,
  }
}

export function fetchCentralizedOutages() {
  return (dispatch) => {
    if (dispatch(requestCentralizedOutages())) {
      return fetch('/api/outages/centralized')
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveCentralizedOutages(json)))
        .catch(error => dispatch(failureCentralizedOutages(error)))
    }
    return false
  }
}

export function fetchDistributedOutages() {
  return (dispatch) => {
    if (dispatch(requestDistributedOutages())) {
      return fetch('/api/outages/distributed')
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveDistributedOutages(json)))
        .catch(error => dispatch(failureDistributedOutages(error)))
    }
    return false
  }
}

export function fetchOutageDashboard(filters = {}) {
  return (dispatch) => {
    if (dispatch(requestOutageDashboard(filters))) {
      const params = {
        ticket_group_id: filters.ticket_group_id,
      }
      return fetch(`/api/outages/dashboard?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveOutageDashboard(json)))
        .catch(error => dispatch(failureOutageDashboard(error)))
    }
    return false
  }
}
