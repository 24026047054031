export const REQUEST_WELCOME_TEXTS = 'REQUEST_WELCOME_TEXTS'
export const RECEIVE_WELCOME_TEXTS = 'RECEIVE_WELCOME_TEXTS'
export const FAILURE_WELCOME_TEXTS = 'FAILURE_WELCOME_TEXTS'
export const REQUEST_CREATE_WELCOME_TEXT = 'REQUEST_CREATE_WELCOME_TEXT'
export const RECEIVE_CREATE_WELCOME_TEXT = 'RECEIVE_CREATE_WELCOME_TEXT'
export const FAILURE_CREATE_WELCOME_TEXT = 'FAILURE_CREATE_WELCOME_TEXT'
export const REQUEST_UPDATE_WELCOME_TEXT = 'REQUEST_UPDATE_WELCOME_TEXT'
export const RECEIVE_UPDATE_WELCOME_TEXT = 'RECEIVE_UPDATE_WELCOME_TEXT'
export const FAILURE_UPDATE_WELCOME_TEXT = 'FAILURE_UPDATE_WELCOME_TEXT'
export const REQUEST_DELETE_WELCOME_TEXT = 'REQUEST_DELETE_WELCOME_TEXT'
export const RECEIVE_DELETE_WELCOME_TEXT = 'RECEIVE_DELETE_WELCOME_TEXT'
export const FAILURE_DELETE_WELCOME_TEXT = 'FAILURE_DELETE_WELCOME_TEXT'
export const REQUEST_TOGGLE_WELCOME_TEXT_ACTIVE = 'REQUEST_TOGGLE_WELCOME_TEXT_ACTIVE'
export const RECEIVE_TOGGLE_WELCOME_TEXT_ACTIVE = 'RECEIVE_TOGGLE_WELCOME_TEXT_ACTIVE'
export const FAILURE_TOGGLE_WELCOME_TEXT_ACTIVE = 'FAILURE_TOGGLE_WELCOME_TEXT_ACTIVE'

export function requestWelcomeTexts() {
  return {
    type: REQUEST_WELCOME_TEXTS,
  }
}

export function receiveWelcomeTexts(welcomeTexts) {
  return {
    type: RECEIVE_WELCOME_TEXTS,
    welcomeTexts,
  }
}

export function failureWelcomeTexts(error) {
  return {
    type: FAILURE_WELCOME_TEXTS,
    error,
  }
}

export function requestCreateWelcomeText() {
  return {
    type: REQUEST_CREATE_WELCOME_TEXT,
  }
}

export function receiveCreateWelcomeText(welcomeTexts) {
  return {
    type: RECEIVE_CREATE_WELCOME_TEXT,
    welcomeTexts,
  }
}

export function failureCreateWelcomeText(error) {
  return {
    type: FAILURE_CREATE_WELCOME_TEXT,
    error,
  }
}

export function requestUpdateWelcomeText() {
  return {
    type: REQUEST_UPDATE_WELCOME_TEXT,
  }
}

export function receiveUpdateWelcomeText(welcomeTexts) {
  return {
    type: RECEIVE_UPDATE_WELCOME_TEXT,
    welcomeTexts,
  }
}

export function failureUpdateWelcomeText(error) {
  return {
    type: FAILURE_UPDATE_WELCOME_TEXT,
    error,
  }
}

export function requestDeleteWelcomeText() {
  return {
    type: REQUEST_DELETE_WELCOME_TEXT,
  }
}

export function receiveDeleteWelcomeText(welcomeTexts) {
  return {
    type: RECEIVE_DELETE_WELCOME_TEXT,
    welcomeTexts,
  }
}

export function failureDeleteWelcomeText(error) {
  return {
    type: FAILURE_DELETE_WELCOME_TEXT,
    error,
  }
}

export function requestToggleWelcomeTextActive() {
  return {
    type: REQUEST_TOGGLE_WELCOME_TEXT_ACTIVE,
  }
}

export function receiveToggleWelcomeTextActive(welcomeText) {
  return {
    type: RECEIVE_TOGGLE_WELCOME_TEXT_ACTIVE,
    welcomeText,
  }
}

export function failureToggleWelcomeTextActive(error) {
  return {
    type: FAILURE_TOGGLE_WELCOME_TEXT_ACTIVE,
    error,
  }
}
