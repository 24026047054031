/*
  I made this file to help reduce some complexity in the graphing components
  related to gateways (ClientCountGraph.js & ThroughputGraph.js). The behavior
  between the two components is very similar so hopefully this will consolidate
  some shared logic between the two components.
*/

import moment from 'moment'

// set the graph height based on whether or not it is expanded
export function graphHeight(expanded) {
  return expanded ? 800 : 235
}

/*
  set the x axis label based on whether or not it is expanded
  when expanded, we're allowing filtering to multiple days, so it should
  display as a date, not a time. But when it's collapsed, it's
  always only a 24 hour range.
*/
export function xAxisLabel(expanded) {
  return expanded ? 'Date' : 'Time'
}

/*
  the x axis is formatted with months and days when the graph is expanded
  but only with hours and minutes when it's not expanded
*/
export function xAxisFormat(value, expanded) {
  return (
    expanded
      ? moment(value).format('MM/DD hh:mm a')
      : moment(value).format('hh:mm a')
  )
}

function formatClientCountData(data) {
  return Object.entries(data.data).map(d => ({
    x: moment(d[0], 'yyyy-MM-DD HH:mm:ss Z').valueOf(),
    y: d[1],
  }))
}
function formatThroughputData(data) {
  return Object.entries(data.data).map(d => ({
    x: moment(d[0], 'yyyy-MM-DD HH:mm:ss Z').valueOf(),
    y: d[1] / 1.0e6,
  }))
}

export function formatData(data, type) {
  return data.map((datum) => {
    if (type === 'clientCount') {
      return formatClientCountData(datum)
    }
    if (type === 'throughput') {
      return formatThroughputData(datum)
    }
    return false
  })
}

export function graphMargins(expanded) {
  return expanded
    ? { left: 75, bottom: 75 }
    : { left: 65, bottom: 50, right: 20 }
}
