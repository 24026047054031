/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core'

import ManagerModalStyles from '../styles/ManagerModalStyles'
import DeleteConfirmation from './DeleteConfirmation'

class CustomerSettingsManager extends React.Component {
  constructor(props) {
    super(props)
    const { customerSetting } = props
    const { customer, timeout, displayed_nearest_warehouses } = customerSetting
    this.state = {
      customer: customer || {},
      timeout: timeout || 60,
      displayed_nearest_warehouses: displayed_nearest_warehouses != null ? displayed_nearest_warehouses : 3,
      // This is a ternary because 0 is not truthy. So if it was 0 or 3, they both would return 3
    }
  }

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  saveCustomerSetting() {
    const { customer, timeout, displayed_nearest_warehouses } = this.state
    const { onSubmit, customerSetting } = this.props
    const { id } = customerSetting

    if (customer && timeout) {
      const settingData = new FormData()
      if (id) {
        settingData.append('id', id)
      }
      settingData.append('customer_id', customer.id)
      settingData.append('timeout', timeout)
      settingData.append('displayed_nearest_warehouses', displayed_nearest_warehouses)
      onSubmit(settingData)
    } else this.setState({ error: true })
  }

  renderFormFields() {
    const { classes, availableCustomers, type } = this.props
    const { customer, timeout, displayed_nearest_warehouses } = this.state
    const timeoutValues = [15, 30, 45, 60]
    const displayedNearestWarehousesValues = [...Array(11).keys()]
    return (
      <React.Fragment>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel>Customer</InputLabel>
            <Select
              name="customer"
              value={customer}
              onChange={this.handleSelectChange}
              renderValue={c => c.name}
              disabled={type === 'edit'}
            >
              { availableCustomers.map(c => (
                <MenuItem key={c.id} value={c}>{c.name}</MenuItem>
              )) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel>Timeout</InputLabel>
            <Select
              name="timeout"
              value={timeout}
              onChange={this.handleSelectChange}
              renderValue={val => `${val} minutes`}
            >
              { timeoutValues.map(timeoutValue => (
                <MenuItem key={timeoutValue} value={timeoutValue}>{`${timeoutValue} minutes`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel>Displayed Warehouses</InputLabel>
            <Select
              name="displayed_nearest_warehouses"
              value={displayed_nearest_warehouses}
              onChange={this.handleSelectChange}
            >
              {displayedNearestWarehousesValues.map(whv =>
                <MenuItem key={whv} value={whv}>{`${whv}`}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </React.Fragment>
    )
  }

  renderForm() {
    const { type } = this.props
    const { error } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {type === 'create' ? 'Create' : 'Edit'} Customer Setting
          </Typography>
        </Grid>
        { this.renderFormFields() }
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => this.saveCustomerSetting()}>
            <Typography>Save Customer Setting</Typography>
          </Button>
          {error && <Typography color="error">All fields are required</Typography>}
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {
      classes, onClose, customerSetting, onSubmit, type,
    } = this.props
    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
        <Grid container direction="column" spacing={2} className={classes.container}>
          {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={customerSetting}
                title="Customer Setting"
              />
            )
          }
          {(type === 'create' || type === 'edit') && this.renderForm()}
        </Grid>
      </Box>
    )
  }
}

CustomerSettingsManager.propTypes = {
  customer: PropTypes.instanceOf(Object),
  timeout: PropTypes.number,
  customerSetting: PropTypes.instanceOf(Object),
  availableCustomers: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

CustomerSettingsManager.defaultProps = {
  customer: {},
  timeout: 60,
  customerSetting: {},
  type: '',
}

export default withStyles(ManagerModalStyles)(CustomerSettingsManager)
