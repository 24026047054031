/* eslint-disable camelcase */
import HandleTimeout from '../helpers/SessionHelper'

const querystring = require('querystring')

export const REQUEST_USERS = 'REQUEST_USERS'
function requestUsers() {
  return {
    type: REQUEST_USERS,
  }
}

export const RECEIVE_USERS = 'RECEIVE_USERS'
function receiveUsers(response) {
  return {
    type: RECEIVE_USERS,
    response,
  }
}

export const FAILURE_USERS = 'FAILURE_USERS'
function failureUsers(error) {
  return {
    type: FAILURE_USERS,
    error,
  }
}

export const REQUEST_USERS_CSV = 'REQUEST_USERS_CSV'
function requestUsersCsv() {
  return {
    type: REQUEST_USERS_CSV,
  }
}

export const RECEIVE_USERS_CSV = 'RECEIVE_USERS_CSV'
function receiveUsersCsv(usersCsv) {
  return {
    type: RECEIVE_USERS_CSV,
    usersCsv,
  }
}

export const FAILURE_USERS_CSV = 'FAILURE_USERS_CSV'
function failureUsersCsv(error) {
  return {
    type: FAILURE_USERS_CSV,
    error,
  }
}

export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER'
function createUser(user) {
  return {
    type: REQUEST_CREATE_USER,
    user,
  }
}

export const RECEIVE_CREATE_USER = 'RECEIVE_CREATE_USER'
function receiveCreateUser(user) {
  return {
    type: RECEIVE_CREATE_USER,
    user,
  }
}

export const FAILURE_CREATE_USER = 'FAILURE_CREATE_USER'
function failureCreateUser(error) {
  return {
    type: FAILURE_CREATE_USER,
    error,
  }
}

export const REQUEST_EDIT_USER = 'REQUEST_EDIT_USER'
function editUser(user) {
  return {
    type: REQUEST_EDIT_USER,
    user,
  }
}

export const RECEIVE_EDIT_USER = 'RECEIVE_EDIT_USER'
function receiveEditUser(user) {
  return {
    type: RECEIVE_EDIT_USER,
    user,
  }
}

export const FAILURE_EDIT_USER = 'FAILURE_EDIT_USER'
function failureEditUser(error) {
  return {
    type: FAILURE_EDIT_USER,
    error,
  }
}

export const REQUEST_CHANGE_TIMEZONE = 'REQUEST_CHANGE_TIMEZONE'
function editTimezone(timeZone) {
  return {
    type: REQUEST_CHANGE_TIMEZONE,
    timeZone,
  }
}

export const RECEIVE_CHANGE_TIMEZONE = 'RECEIVE_CHANGE_TIMEZONE'
function receiveEditTimezone(timeZone) {
  return {
    type: RECEIVE_CHANGE_TIMEZONE,
    timeZone,
  }
}

export const FAILURE_CHANGE_TIMEZONE = 'FAILURE_CHANGE_TIMEZONE'
function failureEditTimezone(error) {
  return {
    type: FAILURE_CHANGE_TIMEZONE,
    error,
  }
}

export const USER_LOGOUT = 'USER_LOGOUT'
function userLogout() {
  return {
    type: USER_LOGOUT,
  }
}

export const REQUEST_USER_PERMISSIONS = 'REQUEST_USER_PERMISSIONS'
function requestUserPermissions(user) {
  return {
    type: REQUEST_USER_PERMISSIONS,
    user,
  }
}

export const RECEIVE_USER_PERMISSIONS = 'RECEIVE_USER_PERMISSIONS'
function receiveUserPermissions(permissions) {
  return {
    type: RECEIVE_USER_PERMISSIONS,
    permissions,
  }
}

export const FAILURE_USER_PERMISSIONS = 'FAILURE_USER_PERMISSIONS'
function failureUserPermissions(error) {
  return {
    type: FAILURE_USER_PERMISSIONS,
    error,
  }
}

export const REQUEST_USER_LOGINS = 'REQUEST_USER_LOGINS'
function requestUserLogins(userId) {
  return {
    type: REQUEST_USER_LOGINS,
    userId,
  }
}

export const RECEIVE_USER_LOGINS = 'RECEIVE_USER_LOGINS'
function receiveUserLogins(userLogins) {
  return {
    type: RECEIVE_USER_LOGINS,
    userLogins,
  }
}

export const FAILURE_USER_LOGINS = 'FAILURE_USER_LOGINS'
function failureUserLogins(error) {
  return {
    type: FAILURE_USER_LOGINS,
    error,
  }
}

export const REQUEST_PASSWORD_RESET_EMAIL = 'REQUEST_PASSWORD_RESET_EMAIL'
function requestPasswordResetEmail(user) {
  return {
    type: REQUEST_PASSWORD_RESET_EMAIL,
    user,
  }
}

// the name of this constant doesn't really make sense
// we're not actually receiving the email here, but a json response
// indicating that the mailer successfully sent. It's called RECEIVE_
// because the loading reducer uses the name of these constants to display
// the loading indicator.
export const RECEIVE_PASSWORD_RESET_EMAIL = 'RECEIVE_PASSWORD_RESET_EMAIL'
function receivePasswordResetEmail(json) {
  return {
    type: RECEIVE_PASSWORD_RESET_EMAIL,
    json,
  }
}

export const FAILURE_PASSWORD_RESET_EMAIL = 'FAILURE_PASSWORD_RESET_EMAIL'
function failurePasswordResetEmail(error) {
  return {
    type: FAILURE_PASSWORD_RESET_EMAIL,
    error,
  }
}

export const REQUEST_PROFILE_UPDATE = 'REQUEST_PROFILE_UPDATE'
function requestProfileUpdate(user) {
  return {
    type: REQUEST_PROFILE_UPDATE,
    user,
  }
}

export const RECEIVE_PROFILE_UPDATE = 'RECEIVE_PROFILE_UPDATE'
function receiveProfileUpdate(json) {
  return {
    type: RECEIVE_PROFILE_UPDATE,
    json,
  }
}

export const FAILURE_PROFILE_UPDATE = 'FAILURE_PROFILE_UPDATE'
function failureProfileUpdate(errors) {
  return {
    type: FAILURE_PROFILE_UPDATE,
    errors,
  }
}

// set a default page of 1
export function fetchUsers(filters = {}) {
  return (dispatch) => {
    if (dispatch(requestUsers(filters))) {
      const {
        createdAtStart: created_at_start,
        createdAtEnd: created_at_end,
        customer,
        email,
        order,
        phoneNumber: phone_number,
        sort_by,
        username,
        page,
        internally_managed,
      } = filters
      const params = {
        created_at_start,
        created_at_end,
        customer,
        email,
        order,
        phone_number,
        sort_by,
        username,
        internally_managed,
        page: (page || 0) + 1,
      }
      return fetch(`/api/users?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveUsers(json)))
        .catch(error => dispatch(failureUsers(error)))
    }
    return false
  }
}

export function fetchUsersCsv(filters = {}, callback) {
  return (dispatch) => {
    if (dispatch(requestUsersCsv(filters))) {
      const {
        createdAtStart: created_at_start,
        createdAtEnd: created_at_end,
        customer,
        email,
        order,
        phoneNumber: phone_number,
        sort_by,
        username,
        internally_managed,
      } = filters
      const params = {
        created_at_start,
        created_at_end,
        customer,
        email,
        order,
        phone_number,
        sort_by,
        username,
        internally_managed,
        all: true,
      }
      return fetch(`/api/users?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then((json) => {
          dispatch(receiveUsersCsv(json))
          callback()
        })
        .catch(error => dispatch(failureUsersCsv(error)))
    }
    return false
  }
}

export function fetchCreateUser(user, filters = {}) {
  return (dispatch) => {
    dispatch(createUser(user))
    return fetch('/api/users/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchUsers(filters))
          dispatch(receiveCreateUser(user))
          return json
        }
        dispatch(failureCreateUser(json))
        throw json
      })
  }
}

export function fetchEditUser(user, filters = {}) {
  return (dispatch) => {
    dispatch(editUser(user))
    return fetch('/api/users/edit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchUsers(filters))
          dispatch(receiveEditUser(user))
          return json
        }
        dispatch(failureEditUser(json))
        throw json
      })
  }
}

// eslint-disable-next-line camelcase
export function fetchEditTimezone(time_zone) {
  return (dispatch) => {
    dispatch(editTimezone(time_zone))
    return fetch('/api/users/timezone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ time_zone }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(receiveEditTimezone(time_zone))
          return json
        }
        dispatch(failureEditTimezone(json))
        throw json
      })
  }
}

export function fetchUserLogout() {
  return (dispatch) => {
    dispatch(userLogout())
    return fetch('/users/sign_out', {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        window.location = '/logged-out'
      }
    })
  }
}

export function fetchUserPermissions(user) {
  return (dispatch) => {
    // can't fetch permissions for a user if there is no user... :rollsafe:
    if (user.id !== undefined) {
      dispatch(requestUserPermissions())
      return fetch(`/api/users/${user.id}/permissions`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveUserPermissions(json)))
        .catch(error => dispatch(failureUserPermissions(error)))
    }
    return false
  }
}

export function fetchUserLogins(userId) {
  return (dispatch) => {
    if (userId !== undefined) {
      dispatch(requestUserLogins())
      return fetch(`/api/users/${userId}/user_logins`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveUserLogins(json)))
        .catch(error => dispatch(failureUserLogins(error)))
    }
    return false
  }
}

export function sendPasswordResetEmail(user) {
  return (dispatch) => {
    // just to be safe, make sure the user is actually present
    // this should only be accessable from the 'edit' user modal though
    if (user.id !== undefined) {
      dispatch(requestPasswordResetEmail(user))
      return fetch(`/api/users/${user.id}/send_password_reset_email`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receivePasswordResetEmail(json))
          } else {
            dispatch(failurePasswordResetEmail(json))
          }
        })
    }
    return false
  }
}

export function profileUpdate(user) {
  return (dispatch) => {
    dispatch(requestProfileUpdate(user))
    return fetch('/api/profiles/profile_update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(receiveProfileUpdate(user))
          return json
        }
        dispatch(failureProfileUpdate(json))
        throw json
      })
  }
}
