import PropTypes from 'prop-types'

export default (
  PropTypes.shape({
    host: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    formatted_uptime: PropTypes.string,
  })
)
