import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import UpdateUser from '../components/admin/UpdateUser'
import {
  fetchCustomerRegions,
  fetchCustomers,
  fetchCustomerSites,
  fetchCustomerTags,
} from '../actions/CustomersActions'
import { fetchThemes } from '../actions/ThemesActions'
import { fetchRegions } from '../actions/RegionsActions'
import { fetchSites } from '../actions/SitesActions'
import {
  fetchCreateUser, fetchEditUser, fetchUsers, sendPasswordResetEmail, fetchUsersCsv,
} from '../actions/UsersActions'
import { fetchPermissions } from '../actions/PermissionsActions'
import { fetchRoles } from '../actions/RolesActions'
import NeatTable from '../components/NeatTable'
import AdminStyles from '../styles/AdminStyles'
import SlideUpModal from '../components/SlideUpModal'
import UsersLoginsContainer from './UsersLoginsContainer'
import NeatPermissions from '../helpers/NeatPermissions'
import ActionMenu from '../components/admin/ActionMenu'
import DisabledUserForm from '../components/admin/DisabledUserForm'

class UsersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {},
      userLoginModalOpen: false,
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
      userId: '',
    }
    this.setUserLoginModalOpen = this.setUserLoginModalOpen.bind(this)
    this.setModalOpen = this.setModalOpen.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchCustomers())
    dispatch(fetchPermissions())
    dispatch(fetchRegions())
    dispatch(fetchRoles())
    dispatch(fetchSites())
    dispatch(fetchThemes())
    dispatch(fetchUsers())
  }

  onChooseCustomer = (customer) => {
    const { dispatch } = this.props
    dispatch(fetchCustomerRegions(customer.id))
    dispatch(fetchCustomerSites(customer.id))
    dispatch(fetchCustomerTags(customer.id))
  }

  handleRequest = (filters) => {
    const { dispatch } = this.props
    dispatch(fetchUsers(filters))
    this.setState({ filters })
  }

  setModalOpen = (open, type, object = {}) => () => {
    if (object.customer) {
      this.onChooseCustomer(object.customer)
    }
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  setUserLoginModalOpen = (open, userId) => () => {
    this.setState({ userLoginModalOpen: open, userId })
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  clearErrors = () => this.setState({ errors: [] })

  updateOrCreateUser = (u) => {
    const { dispatch } = this.props
    const { modalType, filters } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateUser
    } else {
      request = fetchEditUser
    }
    dispatch(request(u, filters))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  headers = () => ([
    {
      id: 'id', label: 'User ID',
    },
    {
      id: 'internally_managed', label: 'Internally Managed?', filterable: true, mapping: { true: 'True', false: 'False', null: 'Unknown' },
    },
    {
      id: 'username', label: 'Verified Email', filterable: true, filterType: 'text',
    },
    {
      id: 'email', label: 'Email', filterable: true, filterType: 'text',
    },
    {
      id: 'phoneNumber', label: 'Phone', filterable: true, filterType: 'text',
    },
    {
      id: 'customer', label: 'Customer', filterable: true, filterType: 'text',
    },
    {
      id: 'roles', label: 'Roles',
    },
    {
      id: 'createdAt', format: val => Moment(val).format('MM/DD/YYYY'), label: 'Created', filterable: true, type: 'createdAt', filterType: 'date',
    },
    {
      id: 'action_menu', label: '',
    },
    {
      id: 'toggle_active', label: '',
    },
  ])

  createCsvRows = (users) => {
    const rows = []
    users.forEach((user) => {
      rows.push(this.createCsvRow(user))
    })
    return rows
  }

  // We don't need some of the more UI centric stuff for the CSV export
  createCsvRow = user => ({
    id: user.id,
    internallyManaged: user.internally_managed,
    username: user.username,
    emapl: user.email,
    phoneNumber: user.phone_number,
    customer: user.customer ? user.customer.name : '',
    roles: user.roles.map(role => role.name).join(', '),
    createdAt: Moment(user.created_at).format('MM/DD/YYYY'),
  })

  rows = () => {
    const { users } = this.props
    const rows = []
    if (users) {
      users.forEach((user) => {
        rows.push(this.createRow(user))
      })
    }
    return rows
  }

  handleInternallyManagedIcon = (user) => {
    if (user.internally_managed === true) return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: 'green' }} />
    if (user.internally_managed === false) return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: 'red' }} />
    return <FontAwesomeIcon icon={faQuestionCircle} size="lg" style={{ color: 'black' }} />
  }

  createRow = (user) => {
    const { classes, dispatch } = this.props
    const { user: globalUser } = this.props
    return {
      id: user.id,
      internally_managed: this.handleInternallyManagedIcon(user),
      username: user.username,
      email: user.email,
      phoneNumber: user.phone_number,
      customer: user.customer ? user.customer.name : '',
      roles: user.roles.map(role => role.name).join(', '),
      createdAt: Moment(user.created_at).format('MM/DD/YYYY'),
      action_menu: (
        <ActionMenu
          userLoginFuction={this.setUserLoginModalOpen(true, user.id)}
          permissionFunction={this.setModalOpen(true, 'permissions', user)}
          editFunction={this.setModalOpen(true, user.internally_managed ? 'edit' : 'editDisabled', user)}
          emailFunction={sendPasswordResetEmail}
          user={user}
          dispatch={dispatch}
          globalUser={globalUser}
        />
      ),
      toggle_active: user.admin ? null : (
        <Button
          className={user.active ? classes.deactivateButton : classes.activateButton}
          onClick={this.setModalOpen(true, user.active ? 'deactivate' : 'activate', user)}
        >
          {user.active ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    }
  }

  usersLoginsContainer = () => {
    const { userId } = this.state
    return (
      <UsersLoginsContainer userId={userId} />
    )
  }

  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  renderModal = (open, type, object) => {
    const {
      classes,
      customers,
      dispatch,
      permissions,
      regions,
      roles,
      sites,
      tags,
      themes,
    } = this.props
    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={this.setModalOpen(false)}
      >
        <React.Fragment>
          { type === 'editDisabled' ? (
            <DisabledUserForm
              user={object}
              onClose={this.setModalOpen}
            />
          ) : (
            <UpdateUser
              availablePermissions={permissions}
              type={type}
              dispatch={dispatch}
              availableRoles={roles}
              availableCustomers={customers}
              availableRegions={regions}
              availableThemes={themes}
              availableSites={sites}
              availableTags={tags}
              user={object}
              onChooseCustomer={this.onChooseCustomer}
              onSubmit={this.updateOrCreateUser}
              onClose={this.setModalOpen}
            />

          )}
        </React.Fragment>
      </Modal>
    )
  }

  renderUsersLoginModal = open => (
    <SlideUpModal
      title="User Logins"
      open={open}
      onClose={this.setUserLoginModalOpen(false)}
      childComponent={this.usersLoginsContainer()}
    />
  )

  render() {
    const {
      modalOpen, modalType, modalObject, userLoginModalOpen, filters,
    } = this.state
    const {
      user, totalCount, dispatch, usersCsv,
    } = this.props
    return (
      <Box width="100%" p={1}>
        {user.hasPermission(NeatPermissions.admin) && (
          <Box width="100%" p={1}>
            <Box display="flex" justifyContent="flex-end" m={1}>
              <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
            </Box>
          </Box>
        )}

        <NeatTable
          serverSide
          rowsForCsv={this.createCsvRows(usersCsv)}
          csvRequest={callback => dispatch(fetchUsersCsv(filters, callback))}
          onHandleRequest={this.handleRequest}
          setFilterValues={filters}
          totalCount={totalCount}
          headers={this.headers()}
          rows={this.rows()}
        />

        { this.renderUsersLoginModal(userLoginModalOpen)}
        { this.renderModal(modalOpen, modalType, modalObject) }
        { this.renderErrors() }
      </Box>
    )
  }
}

UsersContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  customers: PropTypes.arrayOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(Object).isRequired,
  regions: PropTypes.arrayOf(Object).isRequired,
  roles: PropTypes.arrayOf(Object).isRequired,
  sites: PropTypes.arrayOf(Object).isRequired,
  tags: PropTypes.arrayOf(Object).isRequired,
  themes: PropTypes.arrayOf(Object).isRequired,
  users: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  usersCsv: PropTypes.arrayOf(Object).isRequired,
  totalCount: PropTypes.number,
}

UsersContainer.defaultProps = {
  totalCount: 0,
}

function mapStateToProps(state) {
  const {
    customersReducer,
    permissionsReducer,
    rolesReducer,
    themesReducer,
    usersReducer,
  } = state
  const { users, totalCount, usersCsv } = usersReducer
  const { roles } = rolesReducer
  const { permissions } = permissionsReducer
  const { themes } = themesReducer
  const {
    customers,
    regions,
    sites,
    tags,
    userLogins,
  } = customersReducer
  const { user } = state.global
  return {
    customers,
    permissions,
    regions,
    roles,
    sites,
    tags,
    themes,
    userLogins,
    users,
    user,
    usersCsv,
    totalCount,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(UsersContainer)
