import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, Box, Typography } from '@material-ui/core'
import ticketStatusLegend from '../constants/TicketStatusLegend'
import { Titleize } from './StringHelper'

export function getStatusIcon(status, size = '2x') {
  // handle statuses that aren't in the legend mapping
  const color = ticketStatusLegend[status]
    ? ticketStatusLegend[status].color
    : '#828282'

  switch (status) {
    case 'new':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    case 'open':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    case 'pending':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    case 'hold':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    case 'solved':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    case 'closed':
      return <FontAwesomeIcon data-testid="square-icon" icon={faSquare} style={{ color }} size={size} />
    default:
      return <FontAwesomeIcon data-testid="unknown-icon" icon={faQuestionCircle} style={{ color }} size={size} />
  }
}

// if the ticket does not have a status, use the unknown status
export function statusFormat(status = 'unknown') {
  const icon = getStatusIcon(status)
  // handle statuses that aren't in the legend mapping
  const title = ticketStatusLegend[status]
    ? ticketStatusLegend[status].description
    : ''

  return (
    <Tooltip title={title} placement="top">
      <Box
        display="flex"
        alignItems="center"
        data-testid="status-container"
      >
        {icon}
        <Typography>
          &nbsp;
          {Titleize(status)}
        </Typography>
      </Box>
    </Tooltip>
  )
}
