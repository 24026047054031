import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import {
  Box, Paper, TextField, Typography, Button,
  withStyles,
} from '@material-ui/core'
import PermissionTree from './PermissionTree'
import DeleteConfirmation from '../DeleteConfirmation'

const styles = theme => ({
  create: {
    minWidth: '35%',
    maxWidth: '35%',
    maxHeight: '75%',
    overflowY: 'scroll',
  },
  edit: {
    minWidth: '35%',
    maxWidth: '35%',
    maxHeight: '75%',
    overflowY: 'scroll',
  },
  delete: {
    minWidth: '35%',
    maxWidth: '35%',
    maxHeight: '75%',
    overflowY: 'scroll',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  list: {
    height: 300,
    overflow: 'auto',
  },
  deleteButton: {
    color: 'red',
  },
})

class UpdateRole extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { type, role, availablePermissions } = props
    const title = `${type.charAt(0).toUpperCase()}${type.slice(1)} Role`
    let permissions = []
    if (role.role_permissions) {
      permissions = role.role_permissions.map((r) => {
        const permission = availablePermissions.filter(p => p.resource === r.resource)[0]
        return ({
          id: permission.resource,
          value: permission.resource,
          label: permission.title,
        })
      })
    }
    this.state = {
      roleName: (role.name || ''),
      permissions,
      title,
    }
  }

  handleSelectPermissions = (permissions) => {
    this.setState({ permissions })
  }

  handleChange = prop => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  submit = () => {
    const { role, onSubmit } = this.props
    const {
      roleName, permissions,
    } = this.state
    const r = {}
    if (role.id) {
      r.id = role.id
    }
    if (roleName) {
      r.name = roleName
    }
    r.permissions = permissions.map(p => p.value)
    onSubmit(r)
  }

  renderCreateRole() {
    const { classes } = this.props

    return (
      <Box mt={1}>
        <TextField
          id="roleName"
          label="Role"
          className={classes.formControl}
          onChange={this.handleChange('roleName')}
        />
      </Box>
    )
  }

  renderEditRole() {
    const {
      classes, role,
    } = this.props
    return (
      <Box mt={1}>
        <TextField
          InputProps={{
            readOnly: true,
          }}
          className={classes.formControl}
          label="Role"
          defaultValue={role.name}
        />
      </Box>
    )
  }

  renderEditOrCreateSection() {
    const {
      classes, onClose, availablePermissions,
    } = this.props
    const {
      permissions,
    } = this.state

    return (
      <React.Fragment>
        <Box className={classes.formControl}>
          <Typography variant="h6">
            Permissions
          </Typography>
          <PermissionTree
            availablePermissions={availablePermissions}
            selectedPermissions={permissions}
            handleSelectPermissions={this.handleSelectPermissions}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="text" color="primary">
            Cancel
          </Button>
          <Button onClick={this.submit} variant="text" color="primary">
            Submit
          </Button>
        </Box>
      </React.Fragment>
    )
  }

  render() {
    const {
      classes, type, onClose, onSubmit, role,
    } = this.props
    const {
      title,
    } = this.state

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes[type]}>
        <Typography variant="h5">
          { type !== 'delete' && title }
        </Typography>
        { type === 'create' && this.renderCreateRole() }
        { type === 'edit' && this.renderEditRole() }
        {/* { type === 'delete' && this.renderDeleteRole() } */}
        {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={role}
                title="Role"
              />
            )
          }
        { (type === 'create' || type === 'edit') && this.renderEditOrCreateSection() }
      </Box>
    )
  }
}

UpdateRole.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.oneOf(['create', 'edit', 'delete']).isRequired,
  role: PropTypes.instanceOf(Object).isRequired,
  availablePermissions: PropTypes.arrayOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
)(UpdateRole)
