/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import RGL, { WidthProvider } from 'react-grid-layout'
import {
  faChartBar,
  faExclamationTriangle,
  faFileAlt,
  faLock,
  faUnlock,
  faRetweet,
} from '@fortawesome/free-solid-svg-icons'
import {
  Grid, Box, Button, Popover, List, Typography,
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import OpenClosedGraph from '../components/LocationDashboardOpenClosedGraph'
import TicketsDetailsProptype from '../proptypes/TicketsDetailsProptype'
import { reorderDashboardLayout, resetDashboardLayout, toggleDashboardLock } from '../actions/DashboardActions'
import SiteDetailsProptype from '../proptypes/SiteDetailsProptype'
import GatewayDetailsProptype from '../proptypes/GatewayDetailsProptype'
import DeviceDetailsProptype from '../proptypes/DeviceDetailsProptype'
import DropDownButtonGroup from '../components/DropDownButtonGroup'

import PropertyDropdown from '../components/PropertyDropdown'
import ServiceAlert from '../components/ServiceAlert'
import LocationDetails from '../components/LocationDetails'
import GatewayDetails from '../components/GatewayDetails'
import TicketsDetails from '../components/TicketWidget'
import DeviceDetails from '../components/DeviceDetails'
import MappingDetails from '../components/MappingDetails'
import NeatPermissions from '../helpers/NeatPermissions'

import OutageAlert from '../components/OutageAlerts/OutageAlert'
import OutageAlertsContainer from '../components/OutageAlerts/OutageAlertsContainer'

import NeatRoutes from '../helpers/NeatRoutes'
import ClientReportingContainer from './ClientReportingContainer'
import ErrorBoundary from '../components/ErrorBoundary'
import ThroughputGraph from '../components/ThroughputGraph'
import ClientCountGraph from '../components/ClientCountGraph'

const ReactGridLayout = WidthProvider(RGL)
class LocationsDashboardContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this._isMounted = false
    this.state = {
      anchorEl: null,
      clientCountExpanded: false,
      deviceThroughputExpanded: false,
      doRedirect: false,
      isDragging: false,
      mapExpanded: false,
      open: false,
      openClosedTicketsExpanded: false,
      redirectToOutageAlerts: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onClose = () => {
    this.setState({ open: false, anchorEl: null })
  }

  onActivationFormClicked = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget })
  }

  onClientReportingClicked = () => {
    this.setState({ doRedirect: true })
  }

  onOutageAlertsClicked = () => {
    this.setState({ redirectToOutageAlerts: true })
  }

  onDrag = () => {
    const { isLocked } = this.props
    // dont update the state if the dashboard is locked
    if (!isLocked) {
      this.setState({ isDragging: true })
    }
  }

  onDragStop = () => {
    const { isLocked } = this.props
    // dont update the state if the dashboard is locked
    if (!isLocked) {
      // add some delay otherwise a click event is sent
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isDragging: false })
        }
      }, 200, this)
    }
  }

  onClickCapture = (event) => {
    const { isDragging } = this.state
    if (isDragging) {
      event.stopPropagation()
    }
  }

  toggleDashboardLock = () => {
    const { dispatch } = this.props
    // this.setState({ isLocked: !locked })
    dispatch(toggleDashboardLock())
  }

  toggleThroughputExpanded = () => {
    const { deviceThroughputExpanded } = this.state
    this.setState({ deviceThroughputExpanded: !deviceThroughputExpanded })
  }

  toggleClientCountExpanded = () => {
    const { clientCountExpanded } = this.state
    this.setState({ clientCountExpanded: !clientCountExpanded })
  }

  reorderLayout(layout) {
    const { dispatch } = this.props
    dispatch(reorderDashboardLayout(layout))
  }

  resetLayout() {
    const { dispatch } = this.props
    dispatch(resetDashboardLayout())
  }

  propertyButtonOptions() {
    const { user, site } = this.props
    return [
      {
        label: 'Outage Alerts',
        onClick: this.onOutageAlertsClicked,
        icon: faExclamationTriangle,
        disabled: !user.hasPermission(NeatPermissions.outage_alerts.index),
      },
      {
        label: 'Property Forms',
        onClick: this.onActivationFormClicked,
        icon: faFileAlt,
        disabled: !user.hasPermission(NeatPermissions.sites.documents),
      },
      {
        label: 'Client Reporting',
        onClick: this.onClientReportingClicked,
        icon: faChartBar,
        disabled: !(user.hasPermission(NeatPermissions.sites.client_reporting)
        && site.site_external_identifiers.client_tracking_fk_id),
      },
    ]
  }

  dashboardControlButtonOptions() {
    const { isLocked } = this.props
    return [
      {
        label: isLocked ? 'Reset Layout (locked)' : 'Reset Layout',
        // this should be disabled when the dashboard is locked
        disabled: isLocked,
        icon: faRetweet,
        onClick: () => this.resetLayout(),
      },
      {
        label: isLocked ? 'Unlock Dashboard' : 'Lock Dashboard',
        disabled: false,
        icon: isLocked ? faUnlock : faLock,
        onClick: () => this.toggleDashboardLock(),
      },
    ]
  }

  renderMappingDetails() {
    const { mapExpanded } = this.state
    const { site } = this.props
    return (
      <ErrorBoundary small componentName="Map">
        <MappingDetails toggleMapExpanded={() => this.setState({ mapExpanded: !mapExpanded })} expanded={mapExpanded} site={site} height="800px" width="100%" />
      </ErrorBoundary>
    )
  }

  renderClientCountGraph() {
    const { clientCountExpanded } = this.state
    const { dispatch, siteId, clientCountData } = this.props
    return (
      <ErrorBoundary small componentName="Client Count Graph">
        { siteId ? (
          <ClientCountGraph
            data={clientCountData}
            dispatch={dispatch}
            expanded={clientCountExpanded}
            siteId={siteId}
            toggleExpanded={this.toggleClientCountExpanded}
          />
        ) : (
          <Typography variant="body1">No Site Selected</Typography>
        )}
      </ErrorBoundary>
    )
  }

  renderOpenClosedTicketsGraph() {
    const { openClosedTicketsExpanded } = this.state
    const { openClosedTicketCounts, useDarkMode } = this.props

    return (
      <ErrorBoundary small componentName="Open vs. Closed Tickets Graph">
        <OpenClosedGraph
          useDarkMode={useDarkMode}
          data={openClosedTicketCounts}
          expanded={openClosedTicketsExpanded}
          toggleExpanded={
            () => this.setState({ openClosedTicketsExpanded: !openClosedTicketsExpanded })
          }
        />
      </ErrorBoundary>
    )
  }

  renderThroughputGraph() {
    const { deviceThroughputExpanded } = this.state
    const { dispatch, siteId, throughputData } = this.props
    return (
      <ErrorBoundary small componentName="Throughput Graph">
        { siteId ? (
          <ThroughputGraph
            data={throughputData}
            dispatch={dispatch}
            expanded={deviceThroughputExpanded}
            siteId={siteId}
            toggleExpanded={this.toggleThroughputExpanded}
          />
        ) : (
          <Typography variant="body1">No Site Selected</Typography>
        )}
      </ErrorBoundary>
    )
  }

  renderPopover() {
    const { documents } = this.props
    const { open, anchorEl } = this.state

    return (
      <Popover
        open={open}
        onClose={this.onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List style={{ display: 'flex', flexDirection: 'column' }}>
          { Object.entries(documents).map(([name, document]) => (
            <Button key={name} target="_blank" href={document.url} onClick={this.onClose} color={document.type.toLowerCase() === 'naf' ? 'primary' : 'inherit'}>
              {name}
            </Button>
          )) }
        </List>
      </Popover>
    )
  }

  renderGridItems() {
    const {
      user, site, gatewayDetails, tickets, devicesDetails,
      openClosedTicketCounts, isLocked, siteId,
    } = this.props
    const { mapExpanded } = this.state
    const gridItems = [
      [NeatPermissions.sites.show,
        (
          <div key="locationDetails" className={isLocked ? '' : 'rgl-drag-handle'}>
            <ErrorBoundary small componentName="Location Details">
              <LocationDetails
                details={site}
                user={user}
              />
            </ErrorBoundary>
          </div>
        ),
      ],
      gatewayDetails.length > 0 ? [NeatPermissions.sites.gateways,
        (
          <div key="gatewayDetails" className={isLocked ? '' : 'rgl-drag-handle'} onClickCapture={this.onClickCapture}>
            <ErrorBoundary small componentName="Gateway Details">
              <GatewayDetails
                details={gatewayDetails}
              />
            </ErrorBoundary>
          </div>
        ),
      ] : false,
      // eslint-disable-next-line max-len
      [NeatPermissions.sites.devices,
        (
          <div key="devicesDetails" className={isLocked ? '' : 'rgl-drag-handle'} onClickCapture={this.onClickCapture}>
            <ErrorBoundary small componentName="Device Details">
              <DeviceDetails
                details={devicesDetails}
                siteId={site.id}
                user={user}
              />
            </ErrorBoundary>
          </div>
        ),
      ],
      [NeatPermissions.sites.tickets,
        (
          <div key="tickets" className={isLocked ? '' : 'rgl-drag-handle'} onClickCapture={this.onClickCapture}>
            <ErrorBoundary small componentName="Ticket Details">
              <TicketsDetails
                siteId={site.id || siteId}
                details={tickets}
              />
            </ErrorBoundary>
          </div>
        ),
      ],
      [NeatPermissions.sites.show,
        (
          <div key="mappingWidget">
            <ErrorBoundary small componentName="Map">
              <MappingDetails
                dashboardLocked={isLocked}
                toggleMapExpanded={() => this.setState({ mapExpanded: !mapExpanded })}
                site={site}
              />
            </ErrorBoundary>
          </div>
        ),
      ],
      [NeatPermissions.gateways.throughput,
        (
          <div key="deviceThroughputGraph" className={isLocked ? '' : 'rgl-drag-handle'}>
            {this.renderThroughputGraph()}
          </div>
        ),
      ],
      [NeatPermissions.gateways.client_count,
        (
          <div key="deviceClientCountGraph" className={isLocked ? '' : 'rgl-drag-handle'}>
            {this.renderClientCountGraph()}
          </div>
        ),
      ],
      Object.values(openClosedTicketCounts).length >= 1
        ? [NeatPermissions.tickets.open_closed_tickets_graph,
          (
            <div key="openClosedTicketCountGraph" className={isLocked ? '' : 'rgl-drag-handle'}>
              {this.renderOpenClosedTicketsGraph()}
            </div>
          ),
        ]
        : false,
    ]

    const allowedItems = []
    gridItems.forEach((item) => {
      if (item && user.hasPermission(item[0])) {
        allowedItems.push(item[1])
      }
    })

    return allowedItems
  }

  render() {
    const {
      user, siteId, sites,
      site, gridLayout, openClosedTicketCounts,
      outageAlerts,
    } = this.props
    const {
      clientCountExpanded,
      deviceThroughputExpanded,
      doRedirect,
      mapExpanded,
      openClosedTicketsExpanded,
      redirectToOutageAlerts,
    } = this.state

    if (doRedirect) {
      return <Redirect to={`${NeatRoutes.client_reporting.path}/${siteId}`} component={ClientReportingContainer} />
    }

    if (redirectToOutageAlerts) {
      return <Redirect to={`${NeatRoutes.outage_alerts.path}/${siteId}`} render={props => <OutageAlertsContainer {...props} />} />
    }
    const propertyDropdownWidth = 9

    return (
      <React.Fragment>
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item xs={propertyDropdownWidth}>
              <ErrorBoundary small componentName="Property Dropdown">
                <PropertyDropdown
                  siteId={siteId || site.id}
                  sites={sites}
                  user={user}
                />
              </ErrorBoundary>
            </Grid>
            <Grid item xs>
              <DropDownButtonGroup label="Layout Controls" options={this.dashboardControlButtonOptions()} />
            </Grid>
            <Grid item xs>
              <DropDownButtonGroup label="Property Options" options={this.propertyButtonOptions()} />
            </Grid>
            { site.service_alert && (
            <Grid item xs={12}>
              <ErrorBoundary small>
                <ServiceAlert
                  alert={site.service_alert}
                />
              </ErrorBoundary>
            </Grid>
            )}
            {
              user.hasPermission(NeatPermissions.outage_alerts.index)
              && outageAlerts
              && outageAlerts.filter(outageAlert => outageAlert.active)
                .map(outageAlert => (
                  <Grid key={outageAlert} item xs={12}>
                    <ErrorBoundary small componentName="Outage Alerts">
                      <OutageAlert outageAlert={outageAlert} />
                    </ErrorBoundary>
                  </Grid>
                ))
            }
          </Grid>
        </Box>
        {mapExpanded && NeatPermissions.sites.show && this.renderMappingDetails() }
        {
          clientCountExpanded
          && NeatPermissions.sites.gateways
          && this.renderClientCountGraph()
        }
        {
          deviceThroughputExpanded
          && NeatPermissions.sites.gateways
          && this.renderThroughputGraph()
        }
        {
          openClosedTicketsExpanded
          && Object.values(openClosedTicketCounts).length >= 1
          && NeatPermissions.tickets.open_closed_tickets_graph
          && this.renderOpenClosedTicketsGraph()
        }
        {
          !mapExpanded
            && !clientCountExpanded
            && !deviceThroughputExpanded
            && !openClosedTicketsExpanded
            && (
            <ReactGridLayout
              draggableHandle=".rgl-drag-handle"
              onDrag={this.onDrag}
              onDragStop={this.onDragStop}
              onLayoutChange={(layout) => { this.reorderLayout(layout) }}
              {...gridLayout}
            >
              { this.renderGridItems() }
            </ReactGridLayout>
            )}
        { this.renderPopover() }
      </React.Fragment>
    )
  }
}

LocationsDashboardContainer.propTypes = {
  clientCountData: PropTypes.arrayOf(Object).isRequired,
  devicesDetails: DeviceDetailsProptype.isRequired,
  dispatch: PropTypes.func.isRequired,
  documents: PropTypes.instanceOf(Object).isRequired,
  gatewayDetails: PropTypes.arrayOf(GatewayDetailsProptype).isRequired,
  gridLayout: PropTypes.instanceOf(Object).isRequired,
  isLocked: PropTypes.bool,
  openClosedTicketCounts: PropTypes.instanceOf(Array).isRequired,
  outageAlerts: PropTypes.arrayOf(Object),
  site: SiteDetailsProptype.isRequired,
  siteId: PropTypes.string,
  sites: PropTypes.arrayOf(Object).isRequired,
  throughputData: PropTypes.arrayOf(Object).isRequired,
  tickets: TicketsDetailsProptype.isRequired,
  useDarkMode: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
}

LocationsDashboardContainer.defaultProps = {
  outageAlerts: [],
  siteId: '',
  isLocked: false,
  useDarkMode: false,
}

function mapStateToProps(state, ownProps) {
  const {
    global, dashboard, sitesReducer, gatewaysReducer, outageAlertsReducer, darkModeReducer,
  } = state
  const { useDarkMode } = darkModeReducer
  const { user } = global
  const { siteId } = ownProps.match.params
  const { gridLayout, isLocked } = dashboard
  const {
    sites, site, gatewayDetails, tickets, devicesDetails, documents, openClosedTicketCounts,
  } = sitesReducer
  const {
    outageAlerts,
  } = outageAlertsReducer
  const {
    clientCountData,
    throughputData,
  } = gatewaysReducer
  return {
    clientCountData,
    devicesDetails,
    documents,
    gatewayDetails,
    gridLayout,
    isLocked,
    openClosedTicketCounts,
    outageAlerts,
    site,
    siteId,
    sites,
    throughputData,
    tickets,
    useDarkMode,
    user,
  }
}

export default connect(mapStateToProps)(LocationsDashboardContainer)
