import React from 'react'
import {
  withStyles, Paper,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = theme => ({
  root: {
    background: theme.palette.primary.main,
    borderRadius: 10,
    color: 'white',
    padding: 10,
    maxHeight: 450,
    overflowY: 'scroll',
  },
  dt: {
    color: theme.palette.secondary.main,
  },
  dl: {
    color: theme.palette.secondary.main,
    display: 'flex',
    fontFamily: 'sans-serif',
  },
  dd: {
    color: theme.palette.secondary.main,
    marginLeft: 'auto',
  },
  font: {
    fontSize: 18,
  },
  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
})

// This is the problem component
class ClientDeviceTypeDetails extends React.Component {
  renderDeviceDetails() {
    const rows = []
    const { clientDeviceTypePercentages, classes } = this.props
    const devices = Object.values(clientDeviceTypePercentages)
    const devicesSortedByPercent = devices.sort((a, b) => ((a.pct > b.pct) ? -1 : 1))
    devicesSortedByPercent.forEach((hash) => {
      rows.push(
        (
          <dl key={hash.device_and_platform} className={classes.dl}>
            <dt className={classes.dt}>
              {hash.device_and_platform}
              :
            </dt>
            <dd className={classes.dd}>
              {hash.pct}
              %
            </dd>
          </dl>
        ),
      )
    })
    return rows
  }

  render() {
    const { classes, header } = this.props
    return (
      <div style={{ marginTop: '-2rem' }}>
        <h4 className={classes.header}>{header}</h4>
        <Paper className={classes.root} xs={3}>
          { this.renderDeviceDetails() }
        </Paper>
      </div>
    )
  }
}

ClientDeviceTypeDetails.propTypes = {
  header: PropTypes.string.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  clientDeviceTypePercentages: PropTypes.instanceOf(Object),
}

ClientDeviceTypeDetails.defaultProps = {
  clientDeviceTypePercentages: {},
}

function mapStateToProps(state) {
  const {
    sitesReducer,
  } = state
  const {
    siteId, clientDeviceTypePercentages, classes,
  } = sitesReducer
  return {
    siteId,
    clientDeviceTypePercentages,
    classes,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ClientDeviceTypeDetails)
