const NeatPermissions = {
  admin: 'admin',
  customer_admin: 'customer_admin',
  support_admin: 'support_admin',
  any: 'any',
  devices: {
    redirect: 'devices:redirect',
    graph_data: 'devices:graph_data',
  },
  gateways: {
    client_count: 'gateways:client_count',
    throughput: 'gateways:throughput',
  },
  outage_alerts: {
    index: 'outage_alerts:index',
    create: 'outage_alerts:create',
    update: 'outage_alerts:update',
    destroy: 'outage_alerts:destroy',
  },
  outages: {
    centralized: 'outages:centralized',
    distributed: 'outages:distributed',
    dashboard: 'outages:dashboard',
  },
  sites: {
    client_reporting: 'sites:client_reporting',
    devices: 'sites:devices',
    documents: 'sites:documents',
    end_user_authentication_method: 'sites:end_user_authentication_method',
    gateways: 'sites:gateways',
    index: 'sites:index',
    ninety_day_activations: 'sites:90_day_activations',
    nomadix_utilization: 'sites:nomadix_utilization',
    pdsh_ping_device: 'sites:pdsh_ping_device',
    ping_device: 'sites:ping_device',
    property_authentication_portal: 'sites:property_authentication_portal',
    resident_portal: 'sites:resident_portal',
    admin_connectivity_network: 'sites:admin_connectivity_network',
    show: 'sites:show',
    tickets: 'tickets:ticketing_access',
    warehouses: 'sites:warehouses',
    mgmt_market_area: 'sites:mgmt_market_area',
    region: 'sites:region',
    sparing_account: 'sites:sparing_account',
    read_all_customers: 'sites:read_all_customers',
  },
  theme: {
    show: 'theme:show',
    update: 'theme:update',
  },
  tickets: {
    index: 'tickets:ticketing_access',
    show: 'tickets:ticketing_access',
    open_closed_tickets_graph: 'tickets:aggregate_open_and_closed',
  },
  forms: {
    feature_request_form: 'forms:feature_request_form',
  },
}

export default NeatPermissions
