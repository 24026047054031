import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles, withTheme } from '@material-ui/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import {
  fetchSite,
  fetchClientUsageMetrics,
  fetchReturningClientMetrics,
  fetchClientDeviceTypePercentages,
  fetchClientSessionDetails,
} from '../actions/SitesActions'
import SiteDetailsProptype from '../proptypes/SiteDetailsProptype'
import DateRangePicker from '../components/DateRangePicker'
import ClientDeviceTypeDetails from '../components/client_reporting/ClientDeviceTypeDetails'
import NewVsReturningClients from '../components/client_reporting/NewVsReturningClients'
import ClientReportingSessionInfo from '../components/client_reporting/ClientReportingSessionInfo'
import TotalData from '../components/client_reporting/TotalData'
import AverageDataUsage from '../components/client_reporting/AverageDataUsage'
import AverageSessionTime from '../components/client_reporting/AverageSessionTime'
import UniqueConnectedDevices from '../components/client_reporting/UniqueConnectedDevices'
import NeatRoutes from '../helpers/NeatRoutes'

const styles = theme => ({
  root: {
    padding: '1rem',
    flexGrow: 1,
    fontFamily: theme.typography.fontFamily,
  },
  margin: {
    marginLeft: '1.5rem',
  },
  fullScreen: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  backButton: {
    marginTop: '1.5rem',
    background: theme.palette.primary.main,
    color: '#b2c2e1',
    marginLeft: 'auto',
    marginRight: '1.5rem',
  },
  siteName: {
    marginTop: '1.25rem',
    marginLeft: '1.5rem',
    width: 'fit-content',
  },
})

class ClientReportingContainer extends React.Component {
  constructor(props, context) {
    super(props, context)
    const {
      dispatch, siteId,
    } = this.props
    const startDate = new Date()
    const pastDate = startDate.getDate() - 7

    this.state = {
      startdate: new Date(startDate.setDate(pastDate)),
      endDate: new Date(),
    }
    this.keyTitles = {
      total_pretty: 'Total Data',
      avg_session_bytes_pretty: 'Average Data Usage Per Guest',
      avg_session_minutes: 'Average Session Time',
      uniq_clients_in_range: 'Unique Connected Devices',
      pct_returning: 'Guest Type',
    }
    const { startdate, endDate } = this.state
    const dateFilter = { start_date: startdate, end_date: endDate }
    dispatch(fetchSite(siteId))
    dispatch(fetchClientUsageMetrics(siteId, dateFilter))
    dispatch(fetchReturningClientMetrics(siteId, dateFilter))
    dispatch(fetchClientDeviceTypePercentages(siteId, dateFilter))
    dispatch(fetchClientSessionDetails(siteId, dateFilter))
  }

  getTitleFromKey = key => this.keyTitles[key]

  goBack = (siteId) => { document.location.href = `${NeatRoutes.locations.path}/${siteId}` }

  render() {
    const {
      clientUsageMetrics,
      returningClientMetrics,
      clientSessionDetails,
      clientDeviceTypePercentages,
      siteId,
      classes,
      theme,
      site,
    } = this.props
    const { name } = site
    const show = (Object.keys(clientUsageMetrics).length > 0
      || Object.keys(returningClientMetrics).length > 0
      || Object.keys(clientSessionDetails).length > 0
      || Object.keys(clientDeviceTypePercentages).length > 0
    )

    return (
      <div className={classes.fullScreen}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <DateRangePicker siteid={siteId} />
          { name !== undefined && (
            <Typography className={classes.siteName} variant={name.length > 50 ? 'h5' : 'h4'}>{name}</Typography>
          )}
          <Button
            className={classes.backButton}
            variant="contained"
            color="secondary"
            onClick={() => { this.goBack(siteId) }}
          >
            Back
          </Button>
        </div>
        {show ? (
          <div>
            <Grid className={classes.root} container spacing={4}>
              <Grid item key="NewVsReturningClients" xs={3}>
                <NewVsReturningClients
                  theme={theme}
                  header={this.getTitleFromKey('pct_returning')}
                  details={returningClientMetrics.pct_returning}
                />
              </Grid>
              <Grid item key="TotalData" xs={3}>
                <TotalData
                  theme={theme}
                  totalUp={clientUsageMetrics.total_up_pretty}
                  totalDown={clientUsageMetrics.total_down_pretty}
                  totalData={clientUsageMetrics.total_pretty}
                />
              </Grid>

              <Grid item key="AverageDataUsage" xs={3}>
                <AverageDataUsage
                  theme={theme}
                  avgSessionUp={clientUsageMetrics.avg_session_up_bytes_pretty}
                  avgSessionDown={clientUsageMetrics.avg_session_down_bytes_pretty}
                  avgSessionTotal={clientUsageMetrics.avg_session_bytes_pretty}
                />
              </Grid>

              <Grid item key="AverageSessionTime" xs={3}>
                <AverageSessionTime
                  theme={theme}
                  avgSessionTime={clientUsageMetrics.avg_session_minutes}
                />
                <UniqueConnectedDevices
                  theme={theme}
                  uniqConnectedDevices={returningClientMetrics.uniq_clients_in_range}
                />
              </Grid>
            </Grid>

            <Grid className={classes.root} container spacing={4}>
              <Grid item xs={9}>
                <ClientReportingSessionInfo siteid={siteId} />
              </Grid>

              <Grid item xs={3}>
                <ClientDeviceTypeDetails theme={theme} header="Device Type" />
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid className={classes.margin} item xs={12}>
            <Typography variant="h5">No data available for selected dates.</Typography>
            <Typography variant="body1">Please select a different date range.</Typography>
          </Grid>
        )}
      </div>
    )
  }
}

ClientReportingContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  siteId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.instanceOf(Object).isRequired,
  clientUsageMetrics: PropTypes.instanceOf(Object).isRequired,
  returningClientMetrics: PropTypes.instanceOf(Object).isRequired,
  clientSessionDetails: PropTypes.instanceOf(Object).isRequired,
  clientDeviceTypePercentages: PropTypes.instanceOf(Object).isRequired,
  site: SiteDetailsProptype.isRequired,
}

function mapStateToProps(state, ownProps) {
  const {
    sitesReducer,
  } = state
  const {
    clientUsageMetrics,
    returningClientMetrics,
    clientDeviceTypePercentages,
    clientSessionDetails,
    site,
  } = sitesReducer
  const { siteId } = ownProps.match.params
  return {
    siteId,
    site,
    clientUsageMetrics,
    returningClientMetrics,
    clientDeviceTypePercentages,
    clientSessionDetails,
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(withTheme(ClientReportingContainer))
