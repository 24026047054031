export default (
  {
    root: {
      width: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(50%, 50%)',
    },
    container: {
      padding: 16,
    },
    formControl: {
      marginTop: 8,
      marginBottom: 8,
      minWidth: 300,
    },
    deleteButton: {
      color: '#f00',
    },
  }
)
