import PropTypes from 'prop-types'

export default (
  PropTypes.shape({
    download_kbps: PropTypes.string,
    end_user_authentication_method: PropTypes.string,
    end_user_speed: PropTypes.string,
    ethernet: PropTypes.bool,
    guest_support: PropTypes.string,
    hdtv: PropTypes.bool,
    location: PropTypes.string,
    nearest_warehouses: PropTypes.array,
    pan_private_area_network: PropTypes.bool,
    phone_number: PropTypes.string,
    property_authentication_portal: PropTypes.string,
    resident_portal: PropTypes.string,
    room_count: PropTypes.number,
    segment: PropTypes.string,
    service_alert: PropTypes.string,
    service_provider: PropTypes.string,
    staff_support: PropTypes.string,
    voip: PropTypes.bool,
    wifi: PropTypes.bool,
    wired_port_end_user_speed: PropTypes.string,
  })
)
