import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import NeatTable from '../../NeatTable'
import ActionsButton from '../../ActionsButton'
import NeatPermissions from '../../../helpers/NeatPermissions'
import { Titleize } from '../../../helpers/StringHelper'

export default function FeatureRequestsTable(props) {
  const { user, forms } = props

  const headers = () => ([
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'title',
      label: 'Title',
    },
    {
      id: 'developmentType',
      label: 'Development Type',
      format: val => Titleize(val),
    },
    {
      id: 'priority',
      label: 'Priority',
      format: val => Titleize(val),
    },
    {
      id: 'application',
      label: 'Application',
      format: val => Titleize(val),
    },
    {
      id: 'requestDescription',
      label: 'Description',
    },
    {
      id: 'reasonDescription',
      label: 'Reason',
    },
    {
      id: 'customerId',
      label: 'Customer',
    },
    {
      id: 'requestedCompleteBy',
      label: 'Tentative Completed By',
      format: val => (val ? moment(val).format('MM/DD/YYYY') : null),
    },
    {
      id: 'createdAt',
      label: 'Created At',
      format: val => moment(val).format('MM/DD/YYYY'),
    },
    {
      id: 'actions',
      label: 'Actions',
      format: val => user.hasPermission(NeatPermissions.admin) && (
        <ActionsButton object={val} />
      ),
    },
  ])

  const createRow = form => ({
    id: form.id,
    title: form.title,
    developmentType: form.development_type,
    priority: form.priority,
    application: form.application,
    requestDescription: form.request_description,
    reasonDescription: form.reason_description,
    customerId: form.customer ? form.customer.name : '',
    requestedCompleteBy: form.requested_complete_by,
    createdAt: form.created_at,
    actions: form,
  })

  const rows = () => forms.map(form => createRow(form))

  return (
    <React.Fragment>
      <NeatTable
        headers={headers()}
        rows={rows()}
      />
    </React.Fragment>
  )
}

FeatureRequestsTable.propTypes = {
  forms: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}
