/*
  Reducer for GlobalState
 */

import NeatPermissions from '../helpers/NeatPermissions'

export default (
  state = {},
) => {
  // Add hasPermission function to user
  const { user } = state
  if (user && !user.hasPermission) {
    // eslint-disable-next-line max-len
    user.hasPermission = permission => permission === NeatPermissions.any || user.permissions.includes(permission)
  }
  return state
}
