import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import Moment, { now } from 'moment'

class TicketComments extends React.PureComponent {
  renderTicketComment = (comment, showDivider) => (
    <Box key={comment.id} display="flex" flexDirection="column" mt={1}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" alignItems="center" mr={1}>
          <Person fontSize="large" />
        </Box>
        <Box>
          { comment.author !== undefined && (
            <Typography variant="subtitle1">
              {comment.author.name}
            </Typography>
          )}
          <Typography variant="subtitle2">
            {`${Moment.duration((now() - Moment(comment.comment_at))).humanize()} ago`}
          </Typography>
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: comment.html_body }} />
      </Box>
      { showDivider && <Divider />}
    </Box>
  )

  render() {
    const { comments } = this.props

    return (
      <React.Fragment>
        {
          comments.map((comment, index) => (
            this.renderTicketComment(comment, index !== comments.length - 1)))
        }
      </React.Fragment>
    )
  }
}

TicketComments.propTypes = {
  comments: PropTypes.arrayOf(Object).isRequired,
}

export default TicketComments
