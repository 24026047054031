import React from 'react'
import PropTypes from 'prop-types'
import {
  Box, FormControlLabel, Checkbox, Tooltip,
} from '@material-ui/core'

export default function TreeNode(props) {
  const {
    children, id, label, description, checked, handleChange, disableCheckboxes,
  } = props

  return (
    <Box>
      <Tooltip key={id} title={description} placement="right">
        <FormControlLabel
          disabled={disableCheckboxes}
          key={id}
          control={(
            <Checkbox
              key={id}
              style={{ padding: 2 }}
              checked={checked}
              onChange={handleChange}
              value={id}
            />
          )}
          label={label}
        />
      </Tooltip>
      <Box pl={3}>
        {children}
      </Box>
    </Box>
  )
}

TreeNode.propTypes = {
  children: PropTypes.node,
  disableCheckboxes: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
}

TreeNode.defaultProps = {
  children: [],
  disableCheckboxes: false,
  description: '',
  handleChange: null,
}
