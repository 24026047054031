import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {
  Box, Tooltip, InputLabel, Select, MenuItem, Typography, withStyles,
} from '@material-ui/core'
import { getComparator, stableSort } from '../helpers/TableHelper'
import { ClientSideTableHead } from './ClientSideTable'
import TicketCommentsContainer from '../containers/TicketCommentsContainer'
import MonitoredDevicesContainer from '../containers/MonitoredDevicesContainer'
import FullScreenModal from './FullScreenModal'
import SlideUpModal from './SlideUpModal'
import OutageTableStyles from '../styles/OutageTableStyles'
import CsvDownloadButton from './CsvDownloadButton'

function OutageDashboardTable(props) {
  const {
    rows, headers, user, availableTicketGroups, requestOutages, classes,
  } = props
  const headerKeys = headers.map(header => header.id)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState(headers[0].id)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(30)
  const [ticketDetailsModalOpen, setTicketDetailsModalOpen] = React.useState(false)
  const [ticketId, setTicketId] = React.useState('')
  const [ticketSourcePrefix, setTicketSourcePrefix] = React.useState('')
  const [devicesModalOpen, setDevicesModalOpen] = React.useState(false)
  const [siteId, setSiteId] = React.useState('')
  const csvLink = React.createRef()

  const defaultTicketGroupId = 'zd360011309693_12839365367'

  const [filters, setFilters] = React.useState({
    ticket_group_id: defaultTicketGroupId,
  })

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDownPercentClicked = (ticket) => {
    setSiteId(ticket.siteId)
    setDevicesModalOpen(true)
  }

  const handleTicketIdClicked = (ticket) => {
    setTicketId(ticket.ticketId)
    setTicketSourcePrefix(ticket.source_prefix)
    setTicketDetailsModalOpen(true)
  }

  const determineClass = (ticket) => {
    switch (ticket.rowColor) {
      case 'green':
        return classes.greenRow
      case 'yellow':
        return classes.yellowRow
      case 'orange':
        return classes.orangeRow
      case 'red':
        return classes.redRow
      default:
        return null
    }
  }

  // returns a formatted user-friendly tooltip title based on the column received.
  // currently only used for the cells that contain a button.
  const determineTooltip = (column) => {
    switch (column) {
      case 'downPercent':
        return 'Click to view monitored device details for this ticket\'s property'
      case 'ticketId':
        return 'Click to view this ticket\'s details and comments'
      // should never hit the default
      default:
        return ''
    }
  }

  const renderButtonCell = (ticket, column, onClick) => (
    <TableCell onClick={() => onClick(ticket)}>
      <Tooltip disableFocusListener disableTouchListener placement="top" arrow title={determineTooltip(column)}>
        <Button variant="contained" color="primary">
          {ticket[column]}
        </Button>
      </Tooltip>
    </TableCell>
  )
  const renderStandardCell = (ticket, column) => <TableCell>{ticket[column]}</TableCell>

  // The 'ticketId' and 'downPercent' columns need cells that pop open
  // the TicketCommentsContainer and the MonitoredDevicesContainer respectively
  const renderTableCell = (ticket, column) => {
    let cell
    // the cells that would be buttons have separate click handler functions
    if (column === 'downPercent') {
      // don't let them open a modal if there's no devices for the property
      if (ticket[column] === 'No Devices') {
        cell = renderStandardCell(ticket, column)
      } else {
        cell = renderButtonCell(ticket, column, handleDownPercentClicked)
      }
    } else if (column === 'ticketId') {
      cell = renderButtonCell(ticket, column, handleTicketIdClicked)
    } else if (column === 'source_prefix') {
      cell = null
    } else {
      cell = renderStandardCell(ticket, column)
    }

    return cell
  }

  // used for the modals child components
  // JTP: Nolan, I don't know how to go about getting at the ticket.source_prefix (set in OutageDashboardContainer)
  // const ticketCommentsContainer = <TicketCommentsContainer ticketId={`${ticket.source_prefix}${ticketId}`} />
  const ticketCommentsContainer = <TicketCommentsContainer ticketId={`${ticketSourcePrefix}${ticketId}`} />
  const monitoredDevicesContainer = <MonitoredDevicesContainer siteId={siteId} user={user} />

  const handleFilterChange = (event) => {
    const newFilters = { [event.target.name]: event.target.value }
    setFilters(newFilters)
    requestOutages(newFilters)
  }

  const renderTicketGroupFilter = () => (
    <Box m={2} display="flex" flexDirection="column">
      <InputLabel>Ticket Group</InputLabel>
      <Select
        className={classes.formControl}
        name="ticket_group_id"
        value={filters.ticket_group_id}
        onChange={e => handleFilterChange(e)}
      >
        { availableTicketGroups.map(ticketGroup => (
          <MenuItem value={ticketGroup.id}>
            { ticketGroup.name }
          </MenuItem>
        ))}
      </Select>
    </Box>
  )

  const renderDownloadButton = () => (
    <CsvDownloadButton
      data={rows}
      csvLink={csvLink}
      tooltipTitle="Download CSV"
      exportFileName="outage_dashboard_export.csv"
    />
  )

  return (
    <React.Fragment>
      <Paper>
        <TableContainer>
          { renderTicketGroupFilter() }
          { renderDownloadButton() }
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="neat table"
          >
            <ClientSideTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow
                      className={determineClass(row)}
                      tabIndex={-1}
                    >
                      {headerKeys.map(key => (
                        renderTableCell(row, key)
                      ))}
                    </TableRow>
                  ))
              ) : (
                <Box p={1} display="flex">
                  <Typography variant="h6">
                    No Outages For Selected Ticket Group.
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <SlideUpModal
        open={ticketDetailsModalOpen}
        onClose={setTicketDetailsModalOpen}
        childComponent={ticketCommentsContainer}
        title="Ticket Details"
      />

      <FullScreenModal
        open={devicesModalOpen}
        onClose={setDevicesModalOpen}
        childComponent={monitoredDevicesContainer}
        title="Monitored Devices"
      />
    </React.Fragment>
  )
}

OutageDashboardTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rows: PropTypes.arrayOf(Object).isRequired,
  headers: PropTypes.arrayOf(Object).isRequired,
  availableTicketGroups: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  requestOutages: PropTypes.func.isRequired,
}

export default compose(
  withStyles(OutageTableStyles),
)(OutageDashboardTable)
