import {
  RECEIVE_ALL_TICKETS,
  RECEIVE_TICKET,
  RECEIVE_ALL_TICKETS_CSV,
} from '../actions/TicketsActions'

export default (
  state = {
    tickets: {
      tickets: [],
      counts: {},
      page: 0,
      pages: 0,
      page_size: 0,
    },
    ticketsCsv: [],
    ticket: {
      ticket_id: '',
      comments: [],
      submitter: {},
      assignee: {},
    },
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_ALL_TICKETS:
      return Object.assign({}, state, {
        tickets: action.tickets,
      })
    case RECEIVE_ALL_TICKETS_CSV:
      return Object.assign({}, state, {
        ticketsCsv: action.ticketsCsv,
      })
    case RECEIVE_TICKET:
      return Object.assign({}, state, {
        ticket: action.ticket,
      })
    default:
      return state
  }
}
