// These functions are intended to help with modifying strings.
// Use them by importing them in whatever file you need to use them in. E.g:
// import { Titleize, Camelize } from '../helpers/StringHelper'

// will only work for spinal/kebab and snake cased strings
export function Titleize(string) {
  if (!string) {
    return ''
  }
  if (string.includes(' - ')) {
    return string
  }

  if (/[A-Z]/.test(string)) {
    // it's camelcase
    // eslint-disable-next-line no-param-reassign
    string = string.replace(/([a-z0-9])([A-Z])/g, '$1_$2')
  }
  return string
    .split(/[-_]/ig)
    .map(str => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ')
}

// will only work for space separated, spinal/kebab, and snake cased strings
export function Camelize(string) {
  return string.replace(/([ \-_][a-z])/ig, $1 => $1.slice(1).toUpperCase())
}

export function HasLeadingWhitespace(string) {
  return /^\s+\w/.test(string)
}

export function HasTrailingWhitespace(string) {
  return /\w\s+$/.test(string)
}

export function HasLeadingOrTrailingWhitespace(string) {
  return HasLeadingWhitespace(string) || HasTrailingWhitespace(string)
}

export function CamelToSnake(string) {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}
