import {
  SET_ERROR,
  HIDE_ERROR,
} from '../actions/ErrorActions'

// Reducers are just condition checks. Just because one reducer matches
// on an action, doesn't mean that it is the ONLY reducer that could match on that action.
// Redux continues to pass on the action through ALL available reducers to check for
// any other possible matches.

// This error reducer checks for the existance of any non null error field in the payload
// of a the given action. This way, there is no longer a need to check specific action types.
export default (
  state = {
    errors: [],
    isOpen: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        errors: [...state.errors, action.error],
        isOpen: true,
      }
    case HIDE_ERROR:
      return {
        error: null,
        isOpen: false,
      }
    default:
      return state
  }
}
