export default (
  {
    root: {
      border: '1px solid red',
      borderRadius: 8,
      backgroundColor: '#ffcccb',
      zIndex: 99,
      height: 'fit-content',
      maxHeight: '85%',
      paddingTop: 12,
      paddingBottom: 12,
      overflowY: 'auto',
    },
    errorText: {
      color: '#FE5252',
      fontWeight: 'bold',
      fontFamily: 'monospace',
      marginLeft: '1.5rem',
    },
    errorHeading: {
      textAlign: 'center',
      fontWeight: 'lighter',
      width: 'fit-content',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    errorBody: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    stackContainer: {
      border: '1px solid red',
      borderRadius: 8,
      backgroundColor: 'floralwhite',
      padding: '.5rem',
    },
    reloadButton: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  }
)
