import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Moment from 'moment'

const useStyles = makeStyles(theme => ({
  betaUserRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  betaUserDiv: {
    width: '100%',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: theme.spacing(3),
  },
}))

export default function Welcome(props) {
  const classes = useStyles()
  const { user } = props
  const { welcomeTexts } = user
  return (
    <div className={classes.betaUserRoot}>
      <div className={classes.betaUserDiv}>
        <Typography variant="h4">
          {`Welcome ${user.first_name}!`}
        </Typography>
        <hr />
        { welcomeTexts.length > 0 && welcomeTexts.map(welcomeText => (
          <React.Fragment key={welcomeText.created_at}>
            <Typography variant="body1">{Moment(welcomeText.created_at).format('LLL')}</Typography>
            <ReactMarkdown className={classes.text} source={welcomeText.markdown_text} />
            <hr />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

Welcome.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
}
