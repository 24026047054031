import React from 'react'
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'

const IframeRenderer = (props) => {
  const { iframe } = props
  return (
    <Iframe
      url={iframe}
      width="100%"
      height="100%"
      display="initial"
      position="relative"
      style={{ marginRight: '20%', marginLeft: '20%' }}
    />
  )
}

IframeRenderer.propTypes = {
  iframe: PropTypes.string.isRequired,
}

export default IframeRenderer
