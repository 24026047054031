/*
  This is meant to take some of the complexity away from the NeatTable component.
  This is basic functionality that will work if you pass in rows and headers.
  It will render a table that has pagination and density options as well as sorting.
  For this to be viable, it needs to incorporate some filtering functionality as well.
  I ran out of points to add that filtering functionality so this is gonna stay here.

  The filtering functionality would have a lot of shared code/behavior with NeatTable
  So maybe we could implement some kind of helper module that both of these files could
  use for filtering.
*/
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { getComparator, stableSort } from '../helpers/TableHelper'

export function ClientSideTableHead({
  classes, order, orderBy, onRequestSort, headers,
}) {
  const createSortHandler = property => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        { headers.filter(header => !header.hidden).map((header => (
          <TableCell
            key={header.id}
            sortDirection={orderBy === header.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        )))}
      </TableRow>
    </TableHead>
  )
}

ClientSideTableHead.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(Object).isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export default function ClientSideTable(props) {
  const {
    rows, headers, onRowClicked,
  } = props
  const headerKeys = headers.map(header => header.id)
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState(headers[0].id)
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(30)

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="neat table"
          >
            <ClientSideTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow
                    hover
                    onClick={e => onRowClicked && onRowClicked(rows.indexOf(row), e)}
                    tabIndex={-1}
                    key={row.id}
                  >
                    {headerKeys.filter(key => !(headers.find(header => (header.id == key))).hidden).map(key => (
                      <TableCell>{row[key]}</TableCell>
                    ))}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense Padding"
      />
    </div>
  )
}

ClientSideTable.propTypes = {
  rows: PropTypes.arrayOf(Object).isRequired,
  headers: PropTypes.arrayOf(Object).isRequired,
  onRowClicked: PropTypes.func,
}

ClientSideTable.defaultProps = {
  onRowClicked: null,
}
