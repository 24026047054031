// This file is intended to refactor most of the table setup
// required for the NeatTable component from the OutageAlertsContainer file
// I like this idea, it keeps things modular. Going forward I'll probably do
// this sort of thing for any new functionality requiring NeatTable setup
import React from 'react'
import Moment from 'moment'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import NeatPermissions from '../../helpers/NeatPermissions'

export function headers() {
  return [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'message',
      label: 'Message',
      filterable: true,
      filterType: 'text',
    },
    {
      id: 'serviceAlert',
      label: 'Service Alert?',
    },
    {
      id: 'active',
      label: 'Active?',
    },
    {
      id: 'createdAt',
      format: val => Moment(val).format('MM/DD/YYYY'),
      label: 'Created At',
      filterable: true,
      type: 'createdAt',
      filterType: 'date',
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'toggleActive', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ]
}

const editButton = (props, outageAlert) => {
  const { user, setModalOpen } = props
  return user.hasPermission(NeatPermissions.outage_alerts.update) ? (
    <Button color="primary" onClick={setModalOpen(true, 'edit', outageAlert)}>
      Edit
    </Button>
  ) : ''
}

editButton.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

const toggleActiveButton = (props, outageAlert) => {
  const { user, classes, toggleActive } = props
  return user.hasPermission(NeatPermissions.outage_alerts.update) ? (
    <Button
      className={outageAlert.active ? classes.deactivateButton : classes.activateButton}
      onClick={() => toggleActive(outageAlert)}
    >
      {outageAlert.active ? 'Deactivate' : 'Activate'}
    </Button>
  ) : ''
}

toggleActiveButton.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  toggleActive: PropTypes.func.isRequired,
}

const deleteButton = (props, outageAlert) => {
  const { user, classes, setModalOpen } = props
  return user.hasPermission(NeatPermissions.outage_alerts.destroy) ? (
    <Button
      className={classes.deleteButton}
      onClick={setModalOpen(true, 'delete', outageAlert)}
    >
      Delete
    </Button>
  ) : ''
}

deleteButton.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

function createRow(outageAlert, props = {}) {
  return {
    id: outageAlert.id,
    message: outageAlert.message,
    serviceAlert: String(outageAlert.service_alert),
    active: String(outageAlert.active),
    createdAt: Moment(outageAlert.created_at).format('MM/DD/YYYY'),
    edit: editButton(props, outageAlert),
    toggleActive: toggleActiveButton(props, outageAlert),
    delete: deleteButton(props, outageAlert),
  }
}

export function rows(props = {}) {
  const { outageAlerts } = props
  const rowArray = []
  if (outageAlerts) {
    outageAlerts.forEach((outageAlert) => {
      rowArray.push(createRow(outageAlert, props))
    })
  }
  return rowArray
}
