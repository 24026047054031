import {
  REORDER_DASHBOARD_LAYOUT,
  RESET_DASHBOARD_LAYOUT,
  TOGGLE_DASHBOARD_LOCK,
} from '../actions/DashboardActions'


function defaultGridLayout() {
  return {
    isDraggable: true,
    isResizable: false,
    rowHeight: 235,
    cols: 4,
    // draggableHandle: '.dragHandle'
    layout: [{
      i: 'locationDetails',
      x: 0,
      y: 0,
      w: 1,
      h: 2,
    },
    {
      i: 'gatewayDetails',
      x: 3,
      y: 2,
      w: 1,
      h: 1,
    },
    {
      i: 'devicesDetails',
      x: 0,
      y: 2,
      w: 2,
      h: 1,
    },
    {
      i: 'tickets',
      x: 2,
      y: 2,
      w: 1,
      h: 1,
    },
    {
      i: 'mappingWidget',
      x: 2,
      y: 0,
      w: 2,
      h: 2,
    },
    {
      i: 'deviceThroughputGraph',
      x: 1,
      y: 0,
      w: 1,
      h: 1,
    },
    {
      i: 'deviceClientCountGraph',
      x: 1,
      y: 1,
      w: 1,
      h: 1,
    },
    {
      i: 'openClosedTicketCountGraph',
      x: 0,
      y: 2,
      w: 2,
      h: 1,
    },
    ],
  }
}

function mergeLayout(oldLayout, newLayout) {
  const defaultLayout = defaultGridLayout().layout
  // Add missing items
  oldLayout.forEach((item) => {
    const match = newLayout.filter(o => o.i === item.i)[0]
    if (!match) {
      newLayout.push(item)
    }
  })
  // Preserve dimensions
  newLayout.forEach((item) => {
    const match = defaultLayout.filter(o => o.i === item.i)[0]
    if (match) {
      // eslint-disable-next-line no-param-reassign
      item.w = match.w
      // eslint-disable-next-line no-param-reassign
      item.h = match.h
    }
  })
}


export default (
  state = {
    gridLayout: defaultGridLayout(),
    isLocked: false,
  },
  action,
) => {
  switch (action.type) {
    case REORDER_DASHBOARD_LAYOUT:
      mergeLayout(state.gridLayout.layout, action.layout)
      return Object.assign({}, state, {
        gridLayout: {
          ...defaultGridLayout(),
          layout: action.layout,
        },
      })
    case RESET_DASHBOARD_LAYOUT:
      return Object.assign({}, { gridLayout: defaultGridLayout() })
    case TOGGLE_DASHBOARD_LOCK:
      return Object.assign({}, state, {
        isLocked: !state.isLocked,
      })
    default:
      return state
  }
}
