import HandleTimeout from '../helpers/SessionHelper'

// ------------------- INDEX ACTIONS --------------------
export const REQUEST_CUSTOMER_SETTINGS = 'REQUEST_CUSTOMER_SETTINGS'
function requestCustomerSettings() {
  return {
    type: REQUEST_CUSTOMER_SETTINGS,
  }
}

export const RECEIVE_CUSTOMER_SETTINGS = 'RECEIVE_CUSTOMER_SETTINGS'
function receiveCustomerSettings(customerSettings) {
  return {
    type: RECEIVE_CUSTOMER_SETTINGS,
    customerSettings,
  }
}

export const FAILURE_CUSTOMER_SETTINGS = 'FAILURE_CUSTOMER_SETTINGS'
function failureCustomerSettings(error) {
  return {
    type: FAILURE_CUSTOMER_SETTINGS,
    error,
  }
}

// ------------------- CREATE ACTIONS --------------------
export const REQUEST_CREATE_CUSTOMER_SETTING = 'REQUEST_CREATE_CUSTOMER_SETTING'
function requestCreateCustomerSetting() {
  return {
    type: REQUEST_CREATE_CUSTOMER_SETTING,
  }
}

export const RECEIVE_CREATE_CUSTOMER_SETTING = 'RECEIVE_CREATE_CUSTOMER_SETTING'
function receiveCreateCustomerSetting(customerSetting) {
  return {
    type: RECEIVE_CREATE_CUSTOMER_SETTING,
    customerSetting,
  }
}

export const FAILURE_CREATE_CUSTOMER_SETTING = 'FAILURE_CREATE_CUSTOMER_SETTING'
function failureCreateCustomerSetting(error) {
  return {
    type: FAILURE_CREATE_CUSTOMER_SETTING,
    error,
  }
}

// ------------------- UPDATE ACTIONS --------------------
export const REQUEST_UPDATE_CUSTOMER_SETTING = 'REQUEST_UPDATE_CUSTOMER_SETTING'
function requestUpdateCustomerSetting() {
  return {
    type: REQUEST_UPDATE_CUSTOMER_SETTING,
  }
}

export const RECEIVE_UPDATE_CUSTOMER_SETTING = 'RECEIVE_UPDATE_CUSTOMER_SETTING'
function receiveUpdateCustomerSetting(customerSetting) {
  return {
    type: RECEIVE_UPDATE_CUSTOMER_SETTING,
    customerSetting,
  }
}

export const FAILURE_UPDATE_CUSTOMER_SETTING = 'FAILURE_UPDATE_CUSTOMER_SETTING'
function failureUpdateCustomerSetting(error) {
  return {
    type: FAILURE_UPDATE_CUSTOMER_SETTING,
    error,
  }
}

// ------------------- DELETE ACTIONS --------------------
export const REQUEST_DELETE_CUSTOMER_SETTING = 'REQUEST_DELETE_CUSTOMER_SETTING'
function requestDeleteCustomerSetting() {
  return {
    type: REQUEST_DELETE_CUSTOMER_SETTING,
  }
}

export const RECEIVE_DELETE_CUSTOMER_SETTING = 'RECEIVE_DELETE_CUSTOMER_SETTING'
function receiveDeleteCustomerSetting(customerSetting) {
  return {
    type: RECEIVE_DELETE_CUSTOMER_SETTING,
    customerSetting,
  }
}

export const FAILURE_DELETE_CUSTOMER_SETTING = 'FAILURE_DELETE_CUSTOMER_SETTING'
function failureDeleteCustomerSetting(error) {
  return {
    type: FAILURE_DELETE_CUSTOMER_SETTING,
    error,
  }
}


export function fetchCustomerSettings() {
  return (dispatch) => {
    dispatch(requestCustomerSettings())
    return fetch('/api/customer_settings')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveCustomerSettings(json)))
      .catch(error => dispatch(failureCustomerSettings(error)))
  }
}

export function fetchCreateCustomerSetting(setting) {
  return (dispatch) => {
    dispatch(requestCreateCustomerSetting(setting))
    return fetch('/api/customer_settings/create', {
      method: 'POST',
      body: setting,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchCustomerSettings())
          dispatch(receiveCreateCustomerSetting(setting))
          return json
        }
        dispatch(failureCreateCustomerSetting(json))
        throw json
      })
  }
}

export function fetchUpdateCustomerSetting(setting) {
  return (dispatch) => {
    dispatch(requestUpdateCustomerSetting(setting))
    return fetch('/api/customer_settings/update', {
      method: 'POST',
      body: setting,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchCustomerSettings())
          dispatch(receiveUpdateCustomerSetting(setting))
          return json
        }
        dispatch(failureUpdateCustomerSetting(json))
        throw json
      })
  }
}

export function fetchDeleteCustomerSetting(setting) {
  return (dispatch) => {
    dispatch(requestDeleteCustomerSetting(setting))
    return fetch('/api/customer_settings/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ setting }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchCustomerSettings())
          dispatch(receiveDeleteCustomerSetting(setting))
          return json
        }
        dispatch(failureDeleteCustomerSetting(json))
        throw json
      })
  }
}
