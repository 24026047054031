import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  Box, Button, Paper, TextField,
  Typography, withStyles, withTheme,
  List, ListItem, Tooltip,
} from '@material-ui/core'
import {
  Alert, AlertTitle,
} from '@material-ui/lab'
import { Titleize, HasLeadingOrTrailingWhitespace } from '../helpers/StringHelper'
import { profileUpdate } from '../actions/UsersActions'
import TimezoneInput from './TimezoneInput'

const styles = theme => ({
  form: {
    minWidth: '35%',
    maxWidth: '50%',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    fontFamily: theme.typography.fontFamily,
  },
  list: {
    height: 300,
    overflow: 'auto',
  },
  changePasswordHeader: {
    marginTop: theme.spacing(1),
  },
  errorContainer: {
    border: '1px solid red',
    marginBottom: theme.spacing(1),
  },
  errorsList: {
    paddingTop: 0,
  },
  errorsListItem: {
    paddingTop: 0,
  },
  tooltip: {
    maxWidth: 120,
  },
})

class UserProfile extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { user } = props
    this.state = {
      id: user.id || '',
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      email: user.email || '',
      phoneNumber: user.phone_number || '',
      password: '',
      confirmPassword: '',
      username: user.username || '',
      timeZone: user.time_zone || '',
      customer: user.customer || {},
      passwordError: '',
      internallyManaged: user.internallyManaged, // true, false, or null
    }
  }

  handleChange = prop => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  filterUserKeys = (user) => {
    const blacklist = ['confirmPassword', 'username', 'customer', 'passwordError']
    return Object.keys(user)
      .filter(key => !blacklist.includes(key))
      .reduce((obj, key) => ({
        ...obj,
        [this.camelToSnake(key)]: user[key],
      }), {})
  }

  camelToSnake = key => key.replace(/([A-Z])/g, '_$1').toLowerCase()

  handleSubmit = () => {
    const { dispatch, onClose } = this.props
    const { password, confirmPassword } = this.state
    const user = this.filterUserKeys({ ...this.state })
    if (password === confirmPassword) {
      dispatch(profileUpdate(user))
        .then(() => {
          onClose()
          window.location.reload()
        })
    } else {
      this.setState({ passwordError: 'Passwords Must Match' })
    }
  }

  passwordsMatch = () => {
    const { password, confirmPassword } = this.state

    return password === confirmPassword
  }

  renderErrors = () => {
    const { errors, classes } = this.props
    const { passwordError } = this.state
    const errorKeys = Object.keys(errors)
    return (
      <Alert className={classes.errorContainer} severity="error">
        { errorKeys.map((errorKey) => {
          const errorValues = errors[errorKey]
          return (
            <AlertTitle>
              Errors:
              <List className={classes.errorsList}>
                { errorValues.map(errorValue => (
                  <ListItem className={classes.errorsListItem}>{`${Titleize(errorKey)} ${errorValue}`}</ListItem>
                ))}
              </List>
            </AlertTitle>
          )
        })}
        { passwordError && (
          <AlertTitle>
            {passwordError}
          </AlertTitle>
        )}
      </Alert>
    )
  }

  render() {
    const {
      classes, onClose, errors, user,
    } = this.props
    const {
      firstName, lastName, phoneNumber,
      email, password, confirmPassword,
      username, customer, passwordError, timeZone,
      internallyManaged,
    } = this.state
    const whitespaceWarning = 'Leading and trailing white space will be stripped upon save.'
    const errorKeys = Object.keys(errors)
    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.form}>
        { (Object.keys(errors).length > 0 || passwordError) && this.renderErrors() }
        <Typography variant="h6">
          Profile
        </Typography>
        <Box mt={1} mb={1} display="flex" flexDirection="column">
          <Box>
            <TextField
              disabled={!internallyManaged}
              InputProps={{
                readOnly: true,
              }}
              className={classes.formControl}
              label="Verified Email"
              defaultValue={username}
            />
            {/* only render the customer if they're an admin user */}
            { user.roles.map(role => role.name).includes('Application Admin') && (
              <TextField
                disabled={!internallyManaged}
                InputProps={{
                  readOnly: true,
                }}
                className={classes.formControl}
                label="Customer"
                defaultValue={customer || 'None'}
              />
            )}
            <TimezoneInput disabled={!internallyManaged} timezone={timeZone} onChange={this.handleChange('timeZone')} />
          </Box>
          <Box mb={1}>
            <Tooltip classes={{ tooltip: classes.tooltip }} open placement="top" arrow title={HasLeadingOrTrailingWhitespace(firstName) ? whitespaceWarning : ''}>
              <TextField
                disabled={!internallyManaged}
                className={classes.formControl}
                label="First Name"
                defaultValue={firstName}
                onChange={this.handleChange('firstName')}
              />
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} open placement="top" arrow title={HasLeadingOrTrailingWhitespace(lastName) ? whitespaceWarning : ''}>
              <TextField
                disabled={!internallyManaged}
                className={classes.formControl}
                label="Last Name"
                defaultValue={lastName}
                onChange={this.handleChange('lastName')}
              />
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} open placement="top" arrow title={HasLeadingOrTrailingWhitespace(email) ? whitespaceWarning : ''}>
              <TextField
                disabled={!internallyManaged}
                className={classes.formControl}
                label="Email"
                defaultValue={email}
                onChange={this.handleChange('email')}
                error={errorKeys.includes('email')}
                helperText={username !== email ? 'Pending Verification' : ''}
              />
            </Tooltip>
            <TextField
              disabled={!internallyManaged}
              className={classes.formControl}
              label="Phone Number"
              defaultValue={phoneNumber}
              onChange={this.handleChange('phoneNumber')}
            />
          </Box>
        </Box>
        { internallyManaged && (
          <React.Fragment>
            <Typography className={classes.changePasswordHeader} variant="h6">
              Change Password
            </Typography>
            <Box mb={1}>
              <TextField
                className={classes.formControl}
                type="password"
                label="New Password"
                defaultValue={password}
                onChange={this.handleChange('password')}
                error={!this.passwordsMatch()}
                helperText={!this.passwordsMatch() ? 'Passwords Do Not Match' : ''}
              />
              <TextField
                className={classes.formControl}
                type="password"
                label="Confirm Password"
                defaultValue={confirmPassword}
                onChange={this.handleChange('confirmPassword')}
                error={!this.passwordsMatch()}
                helperText={!this.passwordsMatch() ? 'Passwords Do Not Match' : ''}
              />
            </Box>
          </React.Fragment>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="text" color="primary">
            Cancel
          </Button>
          { internallyManaged && (
            <Button onClick={this.handleSubmit} variant="text" color="primary">
              Submit
            </Button>
          )}
        </Box>
      </Box>
    )
  }
}

UserProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object),
}

UserProfile.defaultProps = {
  errors: {},
}

function mapStateToProps(state) {
  const { usersReducer } = state
  const { errors } = usersReducer
  return {
    errors,
  }
}
export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(withTheme(UserProfile))
