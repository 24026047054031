import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_CREATE_THEME = 'REQUEST_CREATE_THEME'
function createTheme(theme) {
  return {
    type: REQUEST_CREATE_THEME,
    theme,
  }
}

export const RECEIVE_CREATE_THEME = 'RECEIVE_CREATE_THEME'
function receiveCreateTheme(theme) {
  return {
    type: RECEIVE_CREATE_THEME,
    theme,
  }
}

export const FAILURE_CREATE_THEME = 'FAILURE_CREATE_THEME'
function failureCreateTheme(error) {
  return {
    type: FAILURE_CREATE_THEME,
    error,
  }
}

export const FAILURE_EDIT_THEME = 'FAILURE_EDIT_THEME'
function failureEditTheme(error) {
  return {
    type: FAILURE_EDIT_THEME,
    error,
  }
}

export const REQUEST_EDIT_THEME = 'REQUEST_EDIT_THEME'
function editTheme(theme) {
  return {
    type: REQUEST_EDIT_THEME,
    theme,
  }
}

export const RECEIVE_EDIT_THEME = 'RECEIVE_EDIT_THEME'
function receiveEditTheme(theme) {
  return {
    type: RECEIVE_EDIT_THEME,
    theme,
  }
}

export const REQUEST_THEMES = 'REQUEST_THEMES'
export function requestThemes() {
  return {
    type: REQUEST_THEMES,
  }
}

export const RECEIVE_THEMES = 'RECEIVE_THEMES'
export function receiveThemes(themes) {
  return {
    type: RECEIVE_THEMES,
    themes,
  }
}

export const FAILURE_THEMES = 'FAILURE_THEMES'
function failureThemes(error) {
  return {
    type: FAILURE_THEMES,
    error,
  }
}

export const REQUEST_DELETE_THEME = 'REQUEST_DELETE_THEME'
function requestDeleteTheme(theme) {
  return {
    type: REQUEST_DELETE_THEME,
    theme,
  }
}

export const RECEIVE_DELETE_THEME = 'RECEIVE_DELETE_THEME'
function receiveDeleteTheme(theme) {
  return {
    type: RECEIVE_DELETE_THEME,
    theme,
  }
}

export const FAILURE_DELETE_THEME = 'FAILURE_DELETE_THEME'
function failureDeleteTheme(error) {
  return {
    type: FAILURE_DELETE_THEME,
    error,
  }
}

export function fetchThemes() {
  return (dispatch) => {
    dispatch(requestThemes())
    return fetch('/api/themes')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveThemes(json)))
      .catch(error => dispatch(failureThemes(error)))
  }
}

export function fetchCreateTheme(theme) {
  return (dispatch) => {
    dispatch(createTheme(theme))
    return fetch('/api/themes/create', {
      method: 'POST',
      body: theme,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchThemes())
          dispatch(receiveCreateTheme(theme))
          return json
        }
        dispatch(failureCreateTheme(json))
        throw json
      })
  }
}

export function fetchEditTheme(theme) {
  return (dispatch) => {
    dispatch(editTheme(theme))
    return fetch('/api/themes/edit', {
      method: 'POST',
      body: theme,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchThemes())
          dispatch(receiveEditTheme(theme))
          return json
        }
        dispatch(failureEditTheme(json))
        throw json
      })
  }
}

export function fetchDeleteTheme(theme) {
  return (dispatch) => {
    dispatch(requestDeleteTheme(theme))
    return fetch('/api/themes/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ theme }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchThemes())
          dispatch(receiveDeleteTheme(theme))
          return json
        }
        dispatch(failureDeleteTheme(json))
        throw json
      })
  }
}
