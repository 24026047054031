import {
  REQUEST_THEMES,
  RECEIVE_THEMES,
} from '../actions/ThemesActions'

export default (
  state = {
    themes: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_THEMES:
      return Object.assign({}, state, {
        themes: action.themes,
      })
    case REQUEST_THEMES:
    default:
      return state
  }
}
