import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_FEATURE_REQUEST_FORMS = 'REQUEST_FEATURE_REQUEST_FORMS'
function requestFeatureRequestForms() {
  return {
    type: REQUEST_FEATURE_REQUEST_FORMS,
  }
}

export const RECEIVE_FEATURE_REQUEST_FORMS = 'RECEIVE_FEATURE_REQUEST_FORMS'
function receiveFeatureRequestForms(featureRequestForms) {
  return {
    type: RECEIVE_FEATURE_REQUEST_FORMS,
    featureRequestForms,
  }
}

export const FAILURE_FEATURE_REQUEST_FORMS = 'FAILURE_FEATURE_REQUEST_FORMS'
function failureFeatureRequestForms(error) {
  return {
    type: FAILURE_FEATURE_REQUEST_FORMS,
    error,
  }
}

export function fetchFeatureRequestForms() {
  return (dispatch) => {
    if (dispatch(requestFeatureRequestForms())) {
      return fetch('/api/feature_request_forms')
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveFeatureRequestForms(json)))
        .catch(error => dispatch(failureFeatureRequestForms(error)))
    }
    return false
  }
}


export const REQUEST_CREATE_FEATURE_REQUEST = 'REQUEST_CREATE_FEATURE_REQUEST'
function requestCreateFeatureRequest(form) {
  return {
    type: REQUEST_CREATE_FEATURE_REQUEST,
    form,
  }
}

export const RECEIVE_CREATE_FEATURE_REQUEST = 'RECEIVE_CREATE_FEATURE_REQUEST'
function receiveCreateFeatureRequest(form) {
  return {
    type: RECEIVE_CREATE_FEATURE_REQUEST,
    form,
  }
}

export const FAILURE_CREATE_FEATURE_REQUEST = 'FAILURE_CREATE_FEATURE_REQUEST'
function failureCreateFeatureRequest(error) {
  return {
    type: FAILURE_CREATE_FEATURE_REQUEST,
    error,
  }
}

export function fetchCreateFeatureRequest(form) {
  return (dispatch) => {
    dispatch(requestCreateFeatureRequest(form))
    return fetch('/api/feature_request_forms/create', {
      method: 'POST',
      body: form,
    })
    .then(response => HandleTimeout(response))
    .then(response => response.json().then(json => [json, response.ok]))
    .then(([json, ok]) => {
      if (ok) {
        dispatch(fetchFeatureRequestForms())
        dispatch(receiveCreateFeatureRequest(form))
        return json
      }
      dispatch(failureCreateFeatureRequest(json))
      throw json
    })
  }
}

export const REQUEST_UPDATE_FEATURE_REQUEST = 'REQUEST_UPDATE_FEATURE_REQUEST'
function requestUpdateFeatureRequest(form) {
  return {
    type: REQUEST_UPDATE_FEATURE_REQUEST,
    form,
  }
}

export const RECEIVE_UPDATE_FEATURE_REQUEST = 'RECEIVE_UPDATE_FEATURE_REQUEST'
function receiveUpdateFeatureRequest(form) {
  return {
    type: RECEIVE_UPDATE_FEATURE_REQUEST,
    form,
  }
}

export const FAILURE_UPDATE_FEATURE_REQUEST = 'FAILURE_UPDATE_FEATURE_REQUEST'
function failureUpdateFeatureRequest(error) {
  return {
    type: FAILURE_UPDATE_FEATURE_REQUEST,
    error,
  }
}

export function fetchUpdateFeatureRequest(form) {
  return (dispatch) => {
    dispatch(requestUpdateFeatureRequest(form))
    return fetch('/api/feature_request_forms/update', {
      method: 'POST',
      body: form,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchFeatureRequestForms())
          dispatch(receiveUpdateFeatureRequest(form))
          return json
        }
        dispatch(failureUpdateFeatureRequest(json))
        throw json
      })
  }
}

export const REQUEST_DELETE_FEATURE_REQUEST = 'REQUEST_DELETE_FEATURE_REQUEST'
function requestDeleteFeatureRequest(form) {
  return {
    type: REQUEST_DELETE_FEATURE_REQUEST,
    form,
  }
}

export const RECEIVE_DELETE_FEATURE_REQUEST = 'RECEIVE_DELETE_FEATURE_REQUEST'
function receiveDeleteFeatureRequest(form) {
  return {
    type: RECEIVE_DELETE_FEATURE_REQUEST,
    form,
  }
}

export const FAILURE_DELETE_FEATURE_REQUEST = 'FAILURE_DELETE_FEATURE_REQUEST'
function failureDeleteFeatureRequest(error) {
  return {
    type: FAILURE_DELETE_FEATURE_REQUEST,
    error,
  }
}

export function fetchDeleteFeatureRequest(form) {
  return (dispatch) => {
    dispatch(requestDeleteFeatureRequest(form))
    return fetch('/api/feature_request_forms/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ form }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchFeatureRequestForms())
          dispatch(receiveDeleteFeatureRequest(form))
          return json
        }
        dispatch(failureDeleteFeatureRequest(json))
        throw json
      })
  }
}
