import {
  REQUEST_CUSTOMERS,
  RECEIVE_CUSTOMERS,
  REQUEST_CUSTOMER_REGIONS,
  RECEIVE_CUSTOMER_REGIONS,
  REQUEST_CUSTOMER_SITES,
  RECEIVE_CUSTOMER_SITES,
  REQUEST_CUSTOMER_TAGS,
  RECEIVE_CUSTOMER_TAGS,
} from '../actions/CustomersActions'

export default (
  state = {
    customers: [],
    regions: [],
    sites: [],
    tags: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_CUSTOMERS:
      return Object.assign({}, state, {
        customers: action.customers,
      })
    case RECEIVE_CUSTOMER_REGIONS:
      return Object.assign({}, state, {
        regions: action.regions,
      })
    case RECEIVE_CUSTOMER_SITES:
      return Object.assign({}, state, {
        sites: action.sites,
      })
    case RECEIVE_CUSTOMER_TAGS:
      return Object.assign({}, state, {
        tags: action.tags,
      })
    case REQUEST_CUSTOMERS:
    case REQUEST_CUSTOMER_REGIONS:
    case REQUEST_CUSTOMER_SITES:
    case REQUEST_CUSTOMER_TAGS:
    default:
      return state
  }
}
