import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'

import Moment from 'moment'
import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableLegend from './TableLegend'
import { Titleize } from '../helpers/StringHelper'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerIcon: {
    marginLeft: theme.spacing(1),
  },
})

class NeatTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    const {
      defaultFilterValues,
      defaultSortColumn,
      defaultSortDirection,
      headers,
    } = this.props
    this.state = {
      filters: {},
      order: defaultSortDirection || 'desc',
      orderBy: defaultSortColumn || headers[0].id,
      page: 0,
      shouldFilterCreatedAt: headers.some(header => header.type === 'createdAt' && header.filterable),
      shouldFilterDate: headers.some(header => header.type === 'date' && header.filterable),
      values: {
        ...defaultFilterValues,
        createdAtEnd: null,
        createdAtStart: null,
        endDate: null,
        startDate: null,
      },
    }
    this.csvLink = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { headers, rows, setFilterValues } = nextProps
    const filters = []
    const values = {
      ...prevState.values,
      ...setFilterValues,
    }
    headers.forEach((header) => {
      if (header.type !== 'date' && header.filterable) {
        const filterVals = []
        filters[header.id] = {
          filterLabel: header.filterLabel || '',
          filterType: header.filterType || 'select',
          label: header.label || '',
          type: header.type || 'select',
          values: filterVals,
        }
        values[header.id] = values[header.id] || ''
        if (!header.filterType || header.filterType === 'select') {
          if (header.mapping) {
            Object.keys(header.mapping).forEach(
              optionLabel => filterVals.push([optionLabel, header.mapping[optionLabel]]),
            )
          } else {
            rows.forEach((row) => {
              Object.keys(row).forEach((columnHeader) => {
                if (columnHeader === header.id && !filterVals.includes(row[columnHeader])) {
                  filterVals.push(row[columnHeader])
                }
              })
            })
          }
        }
      }
    })
    return ({ filters, values })
  }

  onRequestSort = (property) => {
    const { serverSide, onHandleRequest } = this.props
    const {
      order, orderBy, values, page,
    } = this.state
    const isDesc = orderBy === property && order === 'desc'
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
    })
    if (serverSide) {
      /*
        i can't figure out how to allow sorting the user collection by
        customer when it is a server side table since customer is in
        another database. So for not, just don't request the sort at all
       */
      if (property !== 'customer') {
        onHandleRequest({
          ...values, page, sort_by: property, order: isDesc ? 'asc' : 'desc',
        })
      }
    }
  }

  findHeaderObject = (id) => {
    const { headers } = this.props
    let header = {}
    headers.forEach((h) => {
      if (h.id.indexOf(id) !== -1) {
        header = h
      }
    })
    return header
  }

  filterCreatedAt = (row, filteredRows) => {
    const { values } = this.state
    const { createdAtStart, createdAtEnd } = values
    const rowCreatedDate = new Moment(row.created || row.createdAt).format('MM/DD/YYYY').valueOf()
    const rowCreated = new Date(rowCreatedDate)
    const createdAtStartDate = new Date(createdAtStart)
    const createdAtEndDate = new Date(createdAtEnd)

    if (createdAtStart && createdAtEnd) {
      if ((rowCreated >= createdAtStartDate) && (rowCreated <= createdAtEndDate)) {
        filteredRows.push(row)
      }
    }

    if (createdAtStart && !createdAtEnd) {
      if (rowCreated >= createdAtStartDate) {
        filteredRows.push(row)
      }
    }

    if (createdAtEnd && !createdAtStart) {
      if (rowCreated <= createdAtEndDate) {
        filteredRows.push(row)
      }
    }
  }

  filterRows = (rows) => {
    const { filters, values } = this.state
    const {
      startDate,
      endDate,
    } = values
    const filteredRows = []
    Object.values(rows).forEach((row) => {
      const rowCreatedDate = new Moment(row.created || row.createdAt).format('MM/DD/YYYY').valueOf()
      let flag = true
      // only iterate over the filters that have a value.
      Object.keys(values).filter(key => values[key]).forEach((key) => {
        const { mapping } = this.findHeaderObject(key)
        if (mapping) {
          if (values[key] && filters[key].type === 'range') {
            const range = String(row[key]).replace('%', '')
            const rangeVal = values[key].split('-')
            // when adding a range as a filter, make sure it's formatted like '0-10', with no units
            if (Number(range) < Number(rangeVal[0]) || Number(range) > Number(rangeVal[1])) {
              flag = false
            }
          } else if (values[key] !== '' && !mapping[values[key]].includes(row[key])) {
            flag = false
          }
          // this is a really complicated way to check if two strings are not equal
        } else if (String(row[key]).toLowerCase().indexOf(String(values[key]).toLowerCase()) === -1) {
          flag = false
        }
      })
      if (flag && (
        startDate === null
          || (rowCreatedDate > startDate.valueOf()))
        && (endDate === null
          || (rowCreatedDate < endDate.valueOf()))
      ) {
        filteredRows.push(row)
      }

      this.filterCreatedAt(row, filteredRows)
    })
    return filteredRows
  }

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  getSorting = (order, orderBy) => (order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy))

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getCellFormat = (column, value) => {
    const { headers } = this.props
    const index = headers.findIndex(p => p.id === column)
    const { format } = headers[index]
    if (format) {
      return format(value)
    }
    return value
  }

  getFilterOptionFormat = (column, value) => {
    const { headers } = this.props
    const index = headers.findIndex(p => p.id === column)
    const { filterOptionFormat } = headers[index]
    if (filterOptionFormat) {
      return filterOptionFormat(value)
    }
    return typeof value === 'string' ? Titleize(value) : value
  }

  handleFilterChange = (name, value) => {
    const { serverSide, onHandleRequest } = this.props
    const {
      values, page, orderBy, order,
    } = this.state

    const stateValues = {
      ...values,
    }

    if (name) {
      stateValues[name] = value
    }
    this.setState({
      values: stateValues,
    })

    if (serverSide) {
      onHandleRequest({
        page, ...values, [name]: value, sort_by: orderBy, order,
      })
    }
  }

  handleChangePage = (event, newPage) => {
    const { serverSide, onHandleRequest } = this.props
    const { values, orderBy, order } = this.state
    this.setState({ page: newPage })
    if (serverSide) {
      onHandleRequest({
        ...values, page: newPage, sort_by: orderBy, order,
      })
    }
  }

  renderDateFilters = () => {
    const { values } = this.state
    const { startDate, endDate } = values

    const endDateProps = { }
    if (startDate) endDateProps.minDate = startDate

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          clearable
          margin="normal"
          label="Start Date"
          value={startDate}
          onChange={(moment) => {
            if (moment === null || moment.isValid()) {
              this.handleFilterChange('startDate', moment === null ? null : moment.format('YYYY-MM-DD'))
            }
          }}
          maxDate={endDate || new Date()}
          format="MM/DD/YYYY"
        />
        <Box mr={1} />
        <KeyboardDatePicker
          {...endDateProps}
          clearable
          margin="normal"
          label="End Date"
          value={endDate}
          onChange={(moment) => {
            if (moment === null || moment.isValid()) {
              this.handleFilterChange('endDate', moment === null ? null : moment.format('YYYY-MM-DD'))
            }
          }}
          maxDate={new Date()}
          format="MM/DD/YYYY"
        />
      </MuiPickersUtilsProvider>
    )
  }

  renderCreatedAtFilter = () => {
    const { values } = this.state
    const { createdAtStart, createdAtEnd } = values
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          clearable
          margin="normal"
          label="Created At Start"
          value={createdAtStart || null}
          onChange={(moment) => {
            if (moment === null || moment.isValid()) {
              this.handleFilterChange('createdAtStart', moment === null ? null : moment.format('MM/DD/YYYY'))
            }
          }}
          maxDate={new Date()}
          format="MM/DD/YYYY"
        />
        <KeyboardDatePicker
          clearable
          margin="normal"
          label="Created At End"
          value={createdAtEnd || null}
          onChange={(moment) => {
            if (moment === null || moment.isValid()) {
              this.handleFilterChange('createdAtEnd', moment === null ? null : moment.format('MM/DD/YYYY'))
            }
          }}
          maxDate={new Date()}
          format="MM/DD/YYYY"
        />
      </MuiPickersUtilsProvider>
    )
  }

  renderFreeTextFilter = (key) => {
    const { filters, values } = this.state
    return (
      <TextField
        label={filters[key].label || Titleize(key)}
        defaultValue={values[key]}
        onChange={event => this.handleFilterChange(key, event.target.value)}
      />
    )
  }

  renderSelectFilterOptions = (filter) => {
    const { filters } = this.state
    // desctructure the filter values out for the current filter
    // these are set in the getDerivedStateFromProps function.
    // if there's a mapping on the header, it uses that to set these.
    // if there isn't a mapping, it iterates through the table rows and uses the values
    // for the given column to populate the select options filter 'values'
    const { values } = filters[filter]
    return values.map((filterOption) => {
      // if filterOption is an array here, that means that values was a 2d array
      // and the header's mapping object is being used.
      if (Array.isArray(filterOption)) {
        return (
          <MenuItem key={filterOption[1]} value={filterOption[1]}>
            { /* if it's a range (eg 1-100), use the descriptive key, otherwise, titleize the value
                 this can get tricky when you want the filter
                 option to display something other than the mapping key
                 like when we want monitored devices 'none' alert status to read 'normal' */ }
            {filterOption[1].includes('-') ? filterOption[0] : Titleize(filterOption[1])}
          </MenuItem>
        )
      }
      // if filterOption isn't an array, it's just using the values
      // found in the table to populate the filter's select options
      // a range will never be found in the table
      return (
        <MenuItem key={filterOption} value={filterOption}>
          { this.getFilterOptionFormat(filter, filterOption) }
        </MenuItem>
      )
    })
  }

  renderSelectFilter = (filter) => {
    const { filters, values } = this.state
    return (
      <React.Fragment>
        <InputLabel htmlFor={`${filter}-input`}>{filters[filter].filterLabel || Titleize(filter)}</InputLabel>
        <Select
          value={values[filter]}
          onChange={event => this.handleFilterChange(filter, event.target.value)}
          inputProps={{
            name: filter,
            id: `${filter}-input`,
          }}
        >
          <MenuItem key="default-value" value="">
            <em>No Filter</em>
          </MenuItem>
          { this.renderSelectFilterOptions(filter) }
        </Select>
      </React.Fragment>
    )
  }

  renderFilters = () => {
    const { classes } = this.props
    const { shouldFilterDate, shouldFilterCreatedAt, filters } = this.state
    return (
      <Paper>
        <Box p={1} mb={1} display="flex" flexDirection="row">
          <form className={classes.container} noValidate>
            { Object.keys(filters).map(key => (
              <FormControl key={key} className={classes.formControl}>
                { filters[key].filterType === 'text' && this.renderFreeTextFilter(key) }
                { filters[key].filterType === 'select' && this.renderSelectFilter(key) }
              </FormControl>
            )) }
            { shouldFilterDate && this.renderDateFilters() }
            { shouldFilterCreatedAt && this.renderCreatedAtFilter() }
          </form>
        </Box>
      </Paper>
    )
  }

  renderHeader = (header) => {
    const { classes } = this.props
    const { order, orderBy } = this.state
    // JTP: Nolan, I don't know if this is proper...but it does prevent a column
    // header from being rendered.
    if (!header.hidden) {
      return (
        <TableCell key={header.id} sortDirection={orderBy === header.id ? order : false}>
          <TableSortLabel
            active={orderBy === header.id}
            direction={order}
            onClick={() => this.onRequestSort(header.id)}
          >
            {header.label}
            {header.icon && <FontAwesomeIcon className={classes.headerIcon} icon={header.icon} />}
          </TableSortLabel>
        </TableCell>
      );
    }
  }

  // I tried creating a HOC for this but didn't seem to work
  // TODO : figure out how to turn this into a HOC
  renderTooltipHeader = (header) => {
    const { tooltip: { placement, title } } = header
    return (
      <Tooltip key={header.id} placement={placement} title={title}>
        { this.renderHeader(header) }
      </Tooltip>
    )
  }

  renderTableHead = () => {
    const { headers } = this.props

    return (
      <TableHead>
        <TableRow>
          {headers.map(header => (
            header.tooltip ? this.renderTooltipHeader(header) : this.renderHeader(header)
          ))}
        </TableRow>
      </TableHead>
    )
  }

  getHeaders = () => {
    const { headers } = this.props
    return Object.values(headers).map(h => h.id)
  }

  getRows = (slice = true) => {
    const {
      serverSide, rows, pageSize,
    } = this.props
    const {
      order, orderBy, page, values,
    } = this.state

    let sortedRows
    let filteredRows
    if (serverSide) {
      sortedRows = rows
    } else {
      // check if there are actually any filters being applied
      // before iterating over potentially thousands of records
      if (Object.keys(values).some(key => values[key])) {
        filteredRows = this.filterRows(rows)
      }
      sortedRows = this.stableSort(filteredRows || rows, this.getSorting(order, orderBy))
      if (slice) {
        sortedRows = sortedRows.slice(page * pageSize, page * pageSize + pageSize)
      }
    }
    return sortedRows
  }

  getData = () => {
    const { serverSide, rowsForCsv } = this.props
    const data = serverSide ? rowsForCsv : this.getRows(false)
    return [this.getHeaders(), ...data.map(r => Object.values(r).map(v => v))]
  }

  renderTableBody = () => {
    const {
      rows, onRowClicked, noData, headers
    } = this.props

    const sortedRows = this.getRows()

    return (
      noData ? (
        <TableBody>
          <TableRow>
            <TableCell key="no-data" align="center" colSpan={12}>No Data To Display...</TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {sortedRows.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow hover onClick={e => onRowClicked && onRowClicked(row, rows.indexOf(row), e)} key={`${Object.values(row)[0]}-${index}`}>
              {
                Object.keys(row).filter(key => !(headers.find(header => (header.id == key))).hidden).map(key => (
                  <TableCell key={`${key}-${row.id}`}>{this.getCellFormat(key, row[key])}</TableCell>
                ))
              }
            </TableRow>
          ))}
        </TableBody>
      )
    )
  }

  // in order to use this function, you must give <NeatTable />
  // legendMapping (Object) and getIcon (function) props
  renderTableLegend = () => {
    const { legendMapping, getIcon } = this.props
    return (
      <TableLegend legendMapping={legendMapping} getIcon={getIcon} />
    )
  }

  getRowCount = () => {
    const { totalCount, rows } = this.props
    const { filters } = this.state
    if (totalCount) {
      return totalCount
    }
    if (filters) {
      return this.filterRows(rows).length
    }
    return rows.length
  }

  render() {
    const {
      className, serverSide, pageSize, small, csvRequest, legendMapping,
    } = this.props
    const { shouldFilterDate, filters, page } = this.state
    return (
      <React.Fragment>
        { (shouldFilterDate || Object.keys(filters).length > 0) && this.renderFilters() }
        { Object.keys(legendMapping).length > 0 && this.renderTableLegend() }
        <Paper className={className}>
          <Box textAlign="end">
            <Tooltip title="Download CSV">
              <IconButton
                aria-label="download"
                onClick={() => {
                  if (serverSide) {
                    csvRequest(() => {
                      this.csvLink.current.link.click()
                    })
                  } else {
                    this.csvLink.current.link.click()
                  }
                }}
              >
                <FontAwesomeIcon size="xs" icon={faDownload} />
              </IconButton>
            </Tooltip>
            <CSVLink
              filename="export.csv"
              data={this.getData()}
              ref={this.csvLink}
              className="hidden"
            />
          </Box>
          <Table size={small ? 'small' : 'medium'}>
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageSize]}
            component="div"
            count={this.getRowCount()}
            rowsPerPage={pageSize}
            page={this.getRowCount() <= pageSize ? 0 : page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={this.handleChangePage}
          />
        </Paper>
      </React.Fragment>
    )
  }
}

NeatTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
  csvRequest: PropTypes.func,
  defaultFilterValues: PropTypes.instanceOf(Object),
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.string,
  getIcon: PropTypes.func,
  headers: PropTypes.instanceOf(Array).isRequired,
  legendMapping: PropTypes.instanceOf(Object),
  noData: PropTypes.bool,
  onHandleRequest: PropTypes.func,
  onRowClicked: PropTypes.func,
  pageSize: PropTypes.number,
  rows: PropTypes.instanceOf(Array).isRequired,
  rowsForCsv: PropTypes.instanceOf(Array),
  serverSide: PropTypes.bool,
  setFilterValues: PropTypes.instanceOf(Object),
  small: PropTypes.bool,
  totalCount: PropTypes.number,
}

NeatTable.defaultProps = {
  className: '',
  csvRequest: null,
  defaultFilterValues: {},
  defaultSortColumn: '',
  defaultSortDirection: '',
  getIcon: () => {},
  legendMapping: {},
  noData: false,
  onHandleRequest: null,
  onRowClicked: null,
  pageSize: 30,
  rowsForCsv: [],
  serverSide: false,
  setFilterValues: {},
  small: false,
  totalCount: 0,
}

export default withStyles(styles)(NeatTable)
