const parent = {
  marginRight: '8px',
}

export default (
  {
    active: {
      ...parent,
      backgroundColor: '#000000',
      color: '#FFFFFF',
      marginBottom: 8,
      marginTop: 8,
    },
    non_active: {
      ...parent,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(204, 204, 204, 1)',
      backgroundColor: 'rgba(228, 228, 228, 1)',
      color: '#999999',
      marginBottom: 8,
      marginTop: 8,
    },
  }
)
