import HandleTimeout from '../helpers/SessionHelper'
import { SET_ERROR } from './ErrorActions'

// ------------------- INDEX ACTIONS --------------------
export const REQUEST_OUTAGE_ALERTS = 'REQUEST_OUTAGE_ALERTS'
function requestOutageAlerts() {
  return {
    type: REQUEST_OUTAGE_ALERTS,
  }
}

export const RECEIVE_OUTAGE_ALERTS = 'RECEIVE_OUTAGE_ALERTS'
function receiveOutageAlerts(outageAlerts) {
  return {
    type: RECEIVE_OUTAGE_ALERTS,
    outageAlerts,
  }
}

function failureOutageAlerts(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

// ------------------- CREATE ACTIONS --------------------
export const REQUEST_CREATE_OUTAGE_ALERT = 'REQUEST_CREATE_OUTAGE_ALERT'
function requestCreateOutageAlert() {
  return {
    type: REQUEST_CREATE_OUTAGE_ALERT,
  }
}

export const RECEIVE_CREATE_OUTAGE_ALERT = 'RECEIVE_CREATE_OUTAGE_ALERT'
function receiveCreateOutageAlert(outageAlert) {
  return {
    type: RECEIVE_CREATE_OUTAGE_ALERT,
    outageAlert,
  }
}

export const FAILURE_CREATE_OUTAGE_ALERT = 'FAILURE_CREATE_OUTAGE_ALERT'
function failureCreateOutageAlert(error) {
  return {
    type: FAILURE_CREATE_OUTAGE_ALERT,
    error,
  }
}

// ------------------- DELETE ACTIONS --------------------
export const REQUEST_DELETE_OUTAGE_ALERT = 'REQUEST_DELETE_OUTAGE_ALERT'
function requestDeleteOutageAlert() {
  return {
    type: REQUEST_DELETE_OUTAGE_ALERT,
  }
}

export const RECEIVE_DELETE_OUTAGE_ALERT = 'RECEIVE_DELETE_OUTAGE_ALERT'
function receiveDeleteOutageAlert(outageAlert) {
  return {
    type: RECEIVE_DELETE_OUTAGE_ALERT,
    outageAlert,
  }
}

export const FAILURE_DELETE_OUTAGE_ALERT = 'FAILURE_DELETE_OUTAGE_ALERT'
function failureDeleteOutageAlert(error) {
  return {
    type: FAILURE_DELETE_OUTAGE_ALERT,
    error,
  }
}

// ------------------- UPDATE ACTIONS --------------------
export const REQUEST_UPDATE_OUTAGE_ALERT = 'REQUEST_UPDATE_OUTAGE_ALERT'
function requestUpdateOutageAlert() {
  return {
    type: REQUEST_UPDATE_OUTAGE_ALERT,
  }
}

export const RECEIVE_UPDATE_OUTAGE_ALERT = 'RECEIVE_UPDATE_OUTAGE_ALERT'
function receiveUpdateOutageAlert(outageAlert) {
  return {
    type: RECEIVE_UPDATE_OUTAGE_ALERT,
    outageAlert,
  }
}

export const FAILURE_UPDATE_OUTAGE_ALERT = 'FAILURE_UPDATE_OUTAGE_ALERT'
function failureUpdateOutageAlert(error) {
  return {
    type: FAILURE_UPDATE_OUTAGE_ALERT,
    error,
  }
}

// ------------------- TOGGLE ACTIVE ACTIONS -------------------
export const REQUEST_TOGGLE_OUTAGE_ALERT_ACTIVE = 'REQUEST_TOGGLE_OUTAGE_ALERT_ACTIVE'
function requestToggleOutageAlertActive() {
  return {
    type: REQUEST_TOGGLE_OUTAGE_ALERT_ACTIVE,
  }
}

export const RECEIVE_TOGGLE_OUTAGE_ALERT_ACTIVE = 'RECEIVE_TOGGLE_OUTAGE_ALERT_ACTIVE'
function receiveToggleOutageAlertActive(outageAlert) {
  return {
    type: RECEIVE_TOGGLE_OUTAGE_ALERT_ACTIVE,
    outageAlert,
  }
}

export const FAILURE_TOGGLE_OUTAGE_ALERT_ACTIVE = 'FAILURE_TOGGLE_OUTAGE_ALERT_ACTIVE'
function failureToggleOutageAlertActive(error) {
  return {
    type: FAILURE_TOGGLE_OUTAGE_ALERT_ACTIVE,
    error,
  }
}


export function fetchOutageAlerts(siteId) {
  return (dispatch) => {
    dispatch(requestOutageAlerts())
    return fetch(`/api/sites/${siteId}/outage_alerts`)
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(receiveOutageAlerts(json))
        } else {
          dispatch(failureOutageAlerts(json))
        }
      })
  }
}

export function fetchCreateOutageAlert(siteId, outageAlert) {
  return (dispatch) => {
    dispatch(requestCreateOutageAlert())
    return fetch(`/api/sites/${siteId}/outage_alerts`, {
      method: 'POST',
      body: outageAlert,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchOutageAlerts(siteId))
          dispatch(receiveCreateOutageAlert(outageAlert))
          return json
        }
        dispatch(failureCreateOutageAlert(json))
        throw json
      })
  }
}

export function fetchDeleteOutageAlert(siteId, outageAlert) {
  return (dispatch) => {
    dispatch(requestDeleteOutageAlert(outageAlert))
    return fetch(`/api/sites/${siteId}/outage_alerts/${outageAlert.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ outageAlert }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchOutageAlerts(siteId))
          dispatch(receiveDeleteOutageAlert(outageAlert))
          return json
        }
        dispatch(failureDeleteOutageAlert(json))
        throw json
      })
  }
}

export function fetchUpdateOutageAlert(siteId, outageAlert) {
  return (dispatch) => {
    dispatch(requestUpdateOutageAlert())
    // this uses the .get() method because a FormData object is being submitted
    // the other methods a common object is submitted
    return fetch(`/api/sites/${siteId}/outage_alerts/${outageAlert.get('id')}`, {
      method: 'PUT',
      body: outageAlert,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchOutageAlerts(siteId))
          dispatch(receiveUpdateOutageAlert(outageAlert))
          return json
        }
        dispatch(failureUpdateOutageAlert(json))
        throw json
      })
  }
}

export function fetchToggleOutageAlertActive(siteId, outageAlert) {
  return (dispatch) => {
    dispatch(requestToggleOutageAlertActive())
    return fetch(`/api/sites/${siteId}/outage_alerts/${outageAlert.id}/${outageAlert.active ? 'deactivate' : 'activate'}`, {
      method: 'PUT',
      body: outageAlert,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchOutageAlerts(siteId))
          dispatch(receiveToggleOutageAlertActive(outageAlert))
          return json
        }
        dispatch(failureToggleOutageAlertActive(json))
        throw json
      })
  }
}
