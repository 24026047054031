import {
  REQUEST_OUTAGE_ALERTS,
  RECEIVE_OUTAGE_ALERTS,
} from '../actions/OutageAlertsActions'

export default (
  state = {
    outageAlerts: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_OUTAGE_ALERTS:
      return Object.assign({}, state, {
        outageAlerts: action.outageAlerts,
      })
    case REQUEST_OUTAGE_ALERTS:
    default:
      return state
  }
}
