import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Container,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import ActionsButtonContext from '../../../context/actions-button-context'
import {
  fetchCreateFeatureRequest,
  fetchUpdateFeatureRequest,
  fetchFeatureRequestForms,
  fetchDeleteFeatureRequest,
} from '../../../actions/FeatureRequestFormsActions'
import SlideUpModal from '../../SlideUpModal'

import FeatureRequestFormsTable from './Table'
import Form from './Form'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  newButtonContainer: {
    textAlign: 'right',
  },
})

class PastSubmissions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      selectedForm: {},
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchFeatureRequestForms())
  }

  setModalOpen = (modalOpen = true, modalType = 'new', selectedForm = {}) => { // stubbing the links
    this.setState({ modalOpen, modalType, selectedForm })
  }

  setModalClosed = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  handleDeleteClicked = (form) => {
    const { dispatch } = this.props
    // no time to make a custom popup for this
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this form submission? This action cannot be undone.')) {
      dispatch(fetchDeleteFeatureRequest(form))
    }
  }

  onSubmit = (formData) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'new') {
      request = fetchCreateFeatureRequest
    } else {
      request = fetchUpdateFeatureRequest
    }

    dispatch(request(formData))
    this.setState({ modalOpen: false })
  }

  renderHeader = () => {
    const { classes } = this.props

    return (
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h4">Past Submissions</Typography>
        </Grid>
        <Grid item xs={6} className={classes.newButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.setModalOpen()}
          >
            New
          </Button>
        </Grid>
      </Grid>
    )
  }

  renderTable = () => {
    const { featureRequestForms, user } = this.props
    return (
      <ActionsButtonContext.Provider
        value={{
          onEditClicked: this.setModalOpen,
          onDeleteClicked: this.handleDeleteClicked,
        }}
      >
        <FeatureRequestFormsTable
          forms={featureRequestForms}
          user={user}
        />
      </ActionsButtonContext.Provider>
    )
  }

  renderNewForm = () => {
    const { user, dispatch } = this.props
    return (
      <Form
        dispatch={dispatch}
        onSubmit={this.onSubmit}
        type="new"
        user={user}
      />
    )
  }

  renderEditForm = () => {
    const { user } = this.props
    const { selectedForm } = this.state
    return (
      <Form
        form={selectedForm}
        onSubmit={this.onSubmit}
        type="edit"
        user={user}
      />
    )
  }

  renderForm = () => {
    const { modalType } = this.state
    return modalType === 'new' ? this.renderNewForm() : this.renderEditForm()
  }

  render() {
    const { modalOpen } = this.state
    return (
      <Container maxWidth="xl">
        { this.renderHeader() }
        { this.renderTable() }
        <SlideUpModal
          open={modalOpen}
          onClose={this.setModalClosed}
          childComponent={this.renderForm()}
          title="New Feature Request"
        />
      </Container>
    )
  }
}

PastSubmissions.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  featureRequestForms: PropTypes.arrayOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
}

PastSubmissions.defaultProps = {
  featureRequestForms: [],
}

function mapStateToProps(state) {
  const { user } = state.global
  const { featureRequestFormsReducer } = state
  const { featureRequestForms } = featureRequestFormsReducer
  return {
    user,
    featureRequestForms,
  }
}
export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(PastSubmissions)
