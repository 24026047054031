import {
  REQUEST_CENTRALIZED_OUTAGES,
  REQUEST_DISTRIBUTED_OUTAGES,
  RECEIVE_CENTRALIZED_OUTAGES,
  RECEIVE_DISTRIBUTED_OUTAGES,
  REQUEST_OUTAGE_DASHBOARD,
  RECEIVE_OUTAGE_DASHBOARD,
} from '../actions/OutagesActions'

export default (
  state = {
    centralizedOutages: [],
    distributedOutages: [],
    outageDashboardTickets: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_CENTRALIZED_OUTAGES:
      return Object.assign({}, state, {
        centralizedOutages: action.centralizedOutages,
      })
    case RECEIVE_DISTRIBUTED_OUTAGES:
      return Object.assign({}, state, {
        distributedOutages: action.distributedOutages,
      })
    case RECEIVE_OUTAGE_DASHBOARD:
      return Object.assign({}, state, {
        outageDashboardTickets: action.tickets,
      })
    case REQUEST_CENTRALIZED_OUTAGES:
    case REQUEST_DISTRIBUTED_OUTAGES:
    case REQUEST_OUTAGE_DASHBOARD:
    default:
      return state
  }
}
