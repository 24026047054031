import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { Popover } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  icon: {
    marginLeft: 'auto',
  },
}))

export default function NestedMenu(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {
    id,
    label,
    routes,
    handleChangeRoute,
  } = props

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <React.Fragment>
      <MenuItem style={{ width: '100%' }} aria-describedby={id} variant="contained" onClick={handleClick}>
        {label}
        {/* only show the arrows if there's a sub menu to display */}
        { routes.length > 0 && (anchorEl ? <ArrowDropDownIcon className={classes.icon} /> : <ArrowRightIcon className={classes.icon} />) }
      </MenuItem>
      {/* only render the popover if there is a sub menu to display */}
      { routes.length > 0 && (
        <Popover
          id={id}
          key={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {routes.map(module => (
            <MenuItem
              key={module.path}
              onClick={event => handleChangeRoute(event, module.path)}
            >
              {module.label}
            </MenuItem>
          ))}
        </Popover>
      )}
    </React.Fragment>
  )
}

NestedMenu.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(Object).isRequired,
  handleChangeRoute: PropTypes.func.isRequired,
}
