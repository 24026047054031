import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Paper, ButtonBase, Typography,
} from '@material-ui/core'


class CounterWidget extends React.PureComponent {
  render() {
    const {
      label, value, clickable, handleClick,
    } = this.props

    const widgetProps = {}
    if (clickable) {
      widgetProps.component = ButtonBase
      widgetProps.onClick = handleClick
    }

    return (
      <Box component={Paper} display="flex" flexDirection="column" height="100%">
        <Box {...widgetProps} display="flex" flexDirection="column" alignItems="center" p={1}>
          <Typography>
            { value }
          </Typography>
          <Typography>
            { label }
          </Typography>
        </Box>
      </Box>
    )
  }
}

CounterWidget.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  clickable: PropTypes.bool,
  handleClick: PropTypes.func,
}

CounterWidget.defaultProps = {
  clickable: false,
  handleClick: null,
  value: '',
}

export default CounterWidget
