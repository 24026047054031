import React from 'react'

import Moment, { now } from 'moment'

import { Box, Typography } from '@material-ui/core'
import InfoGrid from './InfoGrid'

import TicketProptype from '../proptypes/TicketProptype'

class TicketDetails extends React.PureComponent {
  timeAgoString = (date) => {
    const duration = Moment.duration((now() - Moment(date))).humanize()
    return `${duration} ago`
  }

  render() {
    const { ticket } = this.props

    // Set defaults for the keys that contain objects
    // so we don't try to access a key on an undefined object in the info array
    const {
      submitter = { name: '' },
      created_at: createdAt,
      last_activity: lastActivity,
      assignee = { name: '' },
      ticket_id: ticketId = '',
      status,
      priority,
    } = ticket

    const formattedCreatedAt = this.timeAgoString(Moment(createdAt))
    const formattedLastActivity = this.timeAgoString(Moment(lastActivity))

    // 3rd array element is just a data-testid string so we can test stuff
    const info = [
      ['Requester', submitter.name, 'ticket-details-requester-name'],
      ['Created', formattedCreatedAt],
      ['Last Activity', formattedLastActivity],
      ['Assigned to', assignee.name, 'ticket-details-assignee-name'],
      ['ID', ticketId.replace(/cwzd|cw|zd/, '')],
      ['Status', status],
      ['Priority', priority],
    ]
    return (
      <React.Fragment>
        <Box component={Typography} variant="h5" pb={2}>
          {ticket.subject}
        </Box>
        <InfoGrid
          info={info}
          spacing={1}
        />
      </React.Fragment>
    )
  }
}

TicketDetails.propTypes = {
  ticket: TicketProptype.isRequired,
}

export default TicketDetails
