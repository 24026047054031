import React from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@material-ui/styles'
import ActionsButtonContext from '../context/actions-button-context'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

/*
  This component is intended to be used as a cell within a table.
  The `onMethodClicked` functions that are provided by the context
  are performed on one of the 'objects' in the table. The object
  must be passed in as a prop when the table rows are rendered.

  Contexts are useful for reducing prop chaining problems.
  For example:
    A container controls the state for hiding/showing an edit modal.
    The container also defines functions to handle the form submission to update the record
    The container renders a table which renders this component.
    This component needs access to the handler functions defined by the container.
    The table that renders this component has no use for those functions
    except to pass them as props down to this one.

  By using context we can remove the unnecessary prop chaining and just get
  access to the functions wherever we need them.
*/
export default function ActionsButton(props) {
  // object is the object that these actions will act upon
  const { object } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const actionsButtonContext = React.useContext(ActionsButtonContext)

  const handleMenuClicked = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClosed = () => {
    setAnchorEl(null)
  }

  const handleEditClicked = () => {
    setAnchorEl(null)
    actionsButtonContext.onEditClicked(true, 'edit', object)
  }

  const handleDeleteClicked = () => {
    setAnchorEl(null)
    actionsButtonContext.onDeleteClicked(object)
  }
  return (
    <React.Fragment>
      <Button
        aria-controls="actions-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleMenuClicked}
      >
        <FontAwesomeIcon style={{ marginRight: 8 }} icon={faCog} size="1x" />
        Actions
      </Button>
      <StyledMenu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClosed}
      >
        { /*
            TODO - update this to be dynamic. Parent should determine which actions
            should be rendered and it should just iterate.
        */}
        <StyledMenuItem onClick={handleEditClicked}>
          <FontAwesomeIcon style={{ marginRight: 8 }} icon={faPencilAlt} />
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteClicked}>
          <FontAwesomeIcon style={{ marginRight: 8 }} icon={faTrash} />
          Delete
        </StyledMenuItem>
      </StyledMenu>
    </React.Fragment>
  )
}

ActionsButton.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
}
