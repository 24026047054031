import {
  REQUEST_SITES,
  RECEIVE_SITES,
  REQUEST_NINETY_DAY_ACTIVATIONS,
  RECEIVE_NINETY_DAY_ACTIVATIONS,
  REQUEST_SITE,
  RECEIVE_SITE,
  RECEIVE_GATEWAY_DETAILS,
  REQUEST_GATEWAY_DETAILS,
  REQUEST_TICKETS,
  RECEIVE_TICKETS,
  RECEIVE_TICKETS_CSV,
  REQUEST_DEVICE_DETAILS,
  RECEIVE_DEVICE_DETAILS,
  RECEIVE_DOCUMENTS,
  REQUEST_DOCUMENTS,
  RECEIVE_NINETY_DAY_TICKETS,
  RECEIVE_NINETY_DAY_TICKETS_CSV,
  REQUEST_CLIENT_USAGE_METRICS,
  RECEIVE_CLIENT_USAGE_METRICS,
  RECEIVE_RETURNING_CLIENT_METRICS,
  REQUEST_RETURNING_CLIENT_METRICS,
  REQUEST_CLIENT_DEVICE_TYPE_PERCENTAGES,
  RECEIVE_CLIENT_DEVICE_TYPE_PERCENTAGES,
  REQUEST_CLIENT_SESSION_DETAILS,
  RECEIVE_CLIENT_SESSION_DETAILS,
  REQUEST_DEVICE_PING,
  RECEIVE_DEVICE_PING,
  REQUEST_DEVICE_PDSH_PING,
  RECEIVE_DEVICE_PDSH_PING,
  REQUEST_OPEN_CLOSED_TICKET_COUNTS,
  RECEIVE_OPEN_CLOSED_TICKET_COUNTS,
} from '../actions/SitesActions'
/*
  Reducer for Sites
 */

export default (
  state = {
    sites: [],
    site: {
      address: '',
      download_kbps: '',
      end_user_authentication_method: '',
      end_user_speed: '',
      ethernet: false,
      gps_lat: 0,
      gps_lng: 0,
      guest_support: '',
      hdtv: false,
      location: '',
      nearest_warehouses: [],
      pan_private_area_network: false,
      phone_number: '',
      property_authentication_portal: '',
      resident_portal: '',
      room_count: 0,
      segment: '',
      service_alert: '',
      site_external_identifiers: { client_tracking_fk_id: null },
      staff_support: '',
      voip: false,
      wifi: false,
      wired_port_end_user_speed: '',
    },
    ninetyDaySites: [],
    ninetyDayTickets: {
      tickets: [],
      counts: {},
      page: 0,
      pages: 0,
      page_size: 0,
    },
    ninetyDayTicketsCsv: [],
    gatewayDetails: [],
    tickets: {
      tickets: [],
      counts: {},
      page: 0,
      pages: 0,
      page_size: 0,
    },
    ticketsCsv: [],
    devicesDetails: {
      accessPointAlerts: [],
      accessPoints: [],
      alertStateMapping: [],
      allDevices: [],
      controllerAlerts: [],
      controllers: [],
      networkAlerts: [],
      networks: [],
      unitAlerts: [],
      units: [],
      unknownDeviceAlerts: [],
      unknownDevices: [],
    },
    pingDetails: {},
    documents: [],
    clientUsageMetrics: [],
    returningClientMetrics: [],
    clientDeviceTypePercentages: {},
    clientSessionDetails: {},
    openClosedTicketCounts: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_SITES:
      return Object.assign({}, state, {
        sites: action.sites,
      })
    case RECEIVE_NINETY_DAY_ACTIVATIONS:
      return Object.assign({}, state, {
        ninetyDaySites: action.ninetyDaySites,
      })
    case RECEIVE_NINETY_DAY_TICKETS:
      return Object.assign({}, state, {
        ninetyDayTickets: action.ninetyDayTickets,
      })
    case RECEIVE_NINETY_DAY_TICKETS_CSV:
      return Object.assign({}, state, {
        ninetyDayTicketsCsv: action.ninetyDayTicketsCsv,
      })
    case RECEIVE_SITE:
      return Object.assign({}, state, {
        site: action.site,
      })
    case RECEIVE_GATEWAY_DETAILS:
      return Object.assign({}, state, {
        gatewayDetails: action.gatewayDetails,
      })
    case RECEIVE_TICKETS:
      return Object.assign({}, state, {
        tickets: action.tickets,
      })
    case RECEIVE_TICKETS_CSV:
      return Object.assign({}, state, {
        ticketsCsv: action.ticketsCsv,
      })
    case RECEIVE_DEVICE_DETAILS:
      return Object.assign({}, state, {
        devicesDetails: action.devicesDetails,
      })
    case RECEIVE_DOCUMENTS:
      return Object.assign({}, state, {
        documents: action.documents,
      })
    case RECEIVE_CLIENT_USAGE_METRICS:
      return Object.assign({}, state, {
        clientUsageMetrics: action.clientUsageMetrics,
      })
    case RECEIVE_RETURNING_CLIENT_METRICS:
      return Object.assign({}, state, {
        returningClientMetrics: action.returningClientMetrics,
      })
    case RECEIVE_CLIENT_DEVICE_TYPE_PERCENTAGES:
      return Object.assign({}, state, {
        clientDeviceTypePercentages: action.clientDeviceTypePercentages,
      })
    case RECEIVE_CLIENT_SESSION_DETAILS:
      return Object.assign({}, state, {
        clientSessionDetails: action.clientSessionDetails,
      })
    case RECEIVE_DEVICE_PING:
      return Object.assign({}, state, {
        pingDetails: action.pingDetails,
      })
    case RECEIVE_DEVICE_PDSH_PING:
      return Object.assign({}, state, {
        pingDetails: action.pingDetails,
      })
    case RECEIVE_OPEN_CLOSED_TICKET_COUNTS:
      return Object.assign({}, state, {
        openClosedTicketCounts: action.openClosedTicketCounts,
      })
    case REQUEST_SITES:
    case REQUEST_NINETY_DAY_ACTIVATIONS:
    case REQUEST_SITE:
    case REQUEST_GATEWAY_DETAILS:
    case REQUEST_TICKETS:
    case REQUEST_DEVICE_DETAILS:
    case REQUEST_DOCUMENTS:
    case REQUEST_CLIENT_USAGE_METRICS:
    case REQUEST_RETURNING_CLIENT_METRICS:
    case REQUEST_CLIENT_DEVICE_TYPE_PERCENTAGES:
    case REQUEST_CLIENT_SESSION_DETAILS:
    case REQUEST_DEVICE_PING:
    case REQUEST_DEVICE_PDSH_PING:
    case REQUEST_OPEN_CLOSED_TICKET_COUNTS:
    default:
      return state
  }
}
