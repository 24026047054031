import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import {
  Box,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Paper,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

const renderExpandButton = (expanded, toggleExpanded) => (
  <Grid item>
    <Button size="small" variant="contained" onClick={toggleExpanded}>
      { expanded
        ? <FontAwesomeIcon icon={faCompressArrowsAlt} />
        : <FontAwesomeIcon icon={faExpandArrowsAlt} />
      }
    </Button>
  </Grid>
)

const renderChartTypeToggle = (onChange, areaChart) => (
  <Grid item>
    <FormControl component="fieldset">
      <FormGroup aria-label="chartType" row>
        <FormControlLabel
          value="Area Chart"
          control={<Switch color="primary" onChange={() => onChange(!areaChart)} />}
          label="Area Chart"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  </Grid>
)

const controls = [
  {
    controlPosition: 'top',
    controlType: 'DateRangeFilter',
    options: {
      filterColumnLabel: 'Date',
      ui: {
        cssClass: 'open-closed-graph-control',
        format: {
          pattern: 'yyyy-MM-dd',
        },
      },
    },
  },
]

const options = useDarkMode => ({
  chartArea: {
    height: '70%',
    width: '75%',
  },
  hAxis: {
    slantedText: false,
    title: 'Date',
  },
  title: 'Open Vs. Closed Tickets',
  tooltip: {
    isHtml: true,
    trigger: 'visible',
  },
  vAxis: {
    format: '0',
    minValue: 0,
  },
  backgroundColor: useDarkMode ? '#424242' : '#ffffff',
})

const renderChart = (props, type) => {
  const {
    data,
    expanded,
    useDarkMode,
  } = props

  return (
    <Chart
      chartPackages={['corechart', 'controls']}
      chartType={type}
      controls={expanded ? controls : []}
      data={data}
      height="100%"
      legendToggle
      loader={<div className="google-visualization-controls-label">Loading Chart</div>}
      options={options(useDarkMode)}
      width="100%"
    />
  )
}

export default function LocationDashboardOpenClosedGraph(props) {
  const {
    expanded,
    toggleExpanded,
  } = props

  const [areaChart, setAreaChart] = React.useState(false || areaChart)

  return (
    <Box
      component={Paper}
      height={expanded ? 800 : 235}
      width="100%"
    >
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        { renderChartTypeToggle(setAreaChart, areaChart) }
        { renderExpandButton(expanded, toggleExpanded) }
      </Grid>
      { renderChart(props, areaChart ? 'AreaChart' : 'LineChart') }
    </Box>
  )
}

LocationDashboardOpenClosedGraph.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
}

renderChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  expanded: PropTypes.bool.isRequired,
  useDarkMode: PropTypes.bool,
}

renderChart.defaultProps = {
  useDarkMode: false,
}
