/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Box, FormControl, Paper, TextField, Typography,
  InputLabel, Input, ListItemText,
  Checkbox, Select, MenuItem, Button,
  withStyles, Tooltip,
} from '@material-ui/core'
import ReactSelect from 'react-windowed-select'
import PermissionTree from './PermissionTree'
import { fetchUserPermissions, sendPasswordResetEmail } from '../../actions/UsersActions'
import UserLoginFields from './UserLoginFields'
import TimezoneInput from '../TimezoneInput'
import { HasLeadingOrTrailingWhitespace } from '../../helpers/StringHelper'

const styles = theme => ({
  root: {
    width: '50%',
    maxHeight: '90vh',
    overflowX: 'scroll',
  },
  toggleActivation: {
    minWidth: '35%',
    width: 'auto',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
  list: {
    height: 300,
    overflow: 'auto',
  },
  activateButton: {
    color: theme.palette.primary.main,
  },
  deactivateButton: {
    color: 'red',
  },
  sendPasswordResetButton: {
    marginTop: theme.spacing(1),
  },
  tooltip: {
    maxWidth: 120,
  },
})

class UpdateUser extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { type, user } = props
    let title
    if (type === 'permissions') {
      title = 'User Permissions Overview'
    } else {
      title = `${type.charAt(0).toUpperCase()}${type.slice(1)} User`
    }
    let roles = []
    if (user.roles) {
      roles = user.roles.map(r => r.id)
    }
    const timezone = user.time_zone
    this.state = {
      showPassword: false,
      username: user.username || '',
      phoneNumber: user.phone_number || '',
      email: user.email || '',
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      elevenUsername: user.eleven_username || '',
      elevenPassword: user.eleven_password || '',
      elevenRealm: user.eleven_realm || '',
      password: '',
      customer: user.customer || { id: '', name: '' },
      sites: (user.granted_sites || []).map(site => ({
        id: site.id || '',
        value: (site.address ? site.address.toLowerCase() : site.address) || '',
        label: site.address || '',
      })),
      regions: (user.granted_regions || []).map(region => ({
        id: region.id || '',
        value: region.region || '',
        label: region.region || '',
      })),
      tags: (user.granted_tags || []).map(tag => ({
        id: tag.id || '',
        value: `${tag.tag_grouping}: ${tag.tag}` || '',
        label: `${tag.tag_grouping}: ${tag.tag}` || '',
      })),
      roles,
      title,
      theme: user.theme || '',
      timezone: timezone || '',
      requiredFieldsError: false,
      customerRoleError: false,
      usernameEmailMismatchError: false,
    }
  }

  // the user prop doesn't include any of the permission info
  // since it's jsut the modalObject from the admin container
  // so we need to grab that user's curernt permissions here
  componentDidMount() {
    const { dispatch, user, type } = this.props
    if (type === 'permissions') {
      dispatch(fetchUserPermissions(user))
    }
  }

  mirrorUsernameAndEmail = (field, event) => {
    // if they're updating the username.. update the email to be the same value
    if (field === 'username') {
      this.setState({ email: event.target.value })
    // if they're updating the email.. update the username to be the same value
    } else if (field === 'email') {
      this.setState({ username: event.target.value })
    }
  }

  handleChange = prop => (event) => {
    // this function keeps username and email in sync with each other for new user records
    this.mirrorUsernameAndEmail(prop, event)
    this.setState({ [prop]: event.target.value })
  }

  handleShowPassword = () => {
    const { showPassword } = this.state
    this.setState({ showPassword: !showPassword })
  }

  handleSelectRole = (event) => {
    const { value } = event.target
    const roles = [...value]
    this.setState({ roles })
  }

  handleSelectCustomer = (event) => {
    const { onChooseCustomer } = this.props
    const { customer } = this.state
    if (customer !== event.target.value) {
      this.setState({
        sites: [],
        regions: [],
        tags: [],
      })
    }
    onChooseCustomer(event.target.value)
    this.setState({ customer: event.target.value })
  }

  handleSelectRegion = (regions) => {
    this.setState({ regions })
  }

  handleSelectSite = (sites) => {
    this.setState({ sites })
  }

  handleSelectTag = (tags) => {
    this.setState({ tags })
  }

  handleSelectTheme = (event) => {
    this.setState({ theme: event.target.value })
  }

  handleTimezoneChanged = (event) => {
    this.setState({ timezone: event.target.value })
  }

  handleSendPasswordResetLink = () => {
    const { dispatch, user } = this.props
    dispatch(sendPasswordResetEmail(user))
  }

  submit = () => {
    const {
      user, onSubmit, type, availableRoles,
    } = this.props
    const {
      customer,
      elevenPassword,
      elevenRealm,
      elevenUsername,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      regions,
      roles,
      sites,
      tags,
      theme,
      timezone,
      username,
    } = this.state
    const u = {}
    if (user.id) u.id = user.id
    if (username) u.username = username
    if (phoneNumber) u.phone_number = phoneNumber
    if (email) u.email = email
    if (firstName) u.first_name = firstName
    if (lastName) u.last_name = lastName
    if (timezone) u.time_zone = timezone
    if (elevenUsername) u.eleven_username = elevenUsername
    if (elevenPassword) u.eleven_password = elevenPassword
    if (elevenRealm) u.eleven_realm = elevenRealm

    u.active = type === 'deactivate' ? false : type === 'activate' || user.active
    u.roles = roles
    u.customer_id = customer.id
    u.sites = (sites || []).map(site => site.id)
    u.regions = (regions || []).map(region => region.id)
    u.theme_id = theme.id
    u.tags = (tags || []).map(tag => tag.id)

    // this is an array of the names of the user's roles when submitted.
    const userRoles = availableRoles.filter(r => u.roles.includes(r.id)).map(r => r.name)

    // required fields
    if (username && email) {
      // if the user isn't an admin, they NEED A CUSTOMER!
      // i dont like this weak association here. using the name to determine if it's an admin role
      // but that's kind of all the information we have available right now.
      if (u.customer_id === undefined && !userRoles.includes('Application Admin')) {
        this.setState({ customerRoleError: true })
      } else if (username !== email) {
        this.setState({ usernameEmailMismatchError: true })
      } else if (password && user.password !== password) {
        if (confirm('Submitting will update the user\'s password. Are you sure you wish to continue?')) {
          u.password = password
          onSubmit(u)
        }
      } else {
        onSubmit(u)
      }
    } else {
      this.setState({ requiredFieldsError: true })
    }
  }

  regionOptions = () => {
    const { availableRegions } = this.props
    return availableRegions.map(region => ({
      id: region.id,
      value: region.region,
      label: region.region,
    }))
  }

  siteOptions = () => {
    const { availableSites } = this.props
    return availableSites.map(site => ({
      id: site.id,
      value: `${site.name} ${site.address}`.toLowerCase(),
      label: `${site.name.split('-')[0]} - ${site.address}`,
    }))
  }

  tagOptions = () => {
    const { availableTags } = this.props
    return availableTags.map(tag => ({
      id: tag.id,
      label: `${tag.tag_grouping}: ${tag.tag}`,
      value: `${tag.tag_grouping}: ${tag.tag}`,
    }))
  }

  // this renders the username and password fields
  // might be worth it to try and refactor all of the fields out
  // from the renderEditOrCreateSection function
  renderLoginFields(type) {
    const { classes } = this.props
    const {
      showPassword, password, username, email,
    } = this.state

    return (
      <UserLoginFields
        type={type}
        classes={classes}
        username={username}
        email={email}
        showPassword={showPassword}
        password={password}
        onFieldChanged={this.handleChange}
        onShowPasswordClicked={this.handleShowPassword}
        handleSendPasswordResetLink={this.handleSendPasswordResetLink}
      />
    )
  }

  // this was made to render only the selects for the assignment fields
  // eg region, sites, tags...
  renderAssignmentSelect = (label, options, onChange, value) => {
    const { classes } = this.props
    return (
      <Box className={classes.formControl}>
        <Typography variant="h6">
          {label}
        </Typography>
        <ReactSelect
          isMulti
          isSearchable
          options={options}
          onChange={onChange}
          value={value}
        />
      </Box>
    )
  }

  // this was made to render the generic text fields
  // eg first_name, last_name, email etc...
  renderFormTextField = (id, label, value) => {
    const { classes } = this.props
    const { username } = this.state
    const whitespaceWarning = 'Leading and trailing white space will be stripped upon save.'
    return (
      <React.Fragment>
        <Tooltip classes={{ tooltip: classes.tooltip }} open placement="top" arrow title={HasLeadingOrTrailingWhitespace(value) ? whitespaceWarning : ''}>
          <TextField
            id={id}
            label={label}
            className={classes.formControl}
            onChange={this.handleChange(id)}
            // can't have a defaultValue and a value. Since this is an uncontrolled input now
            // (changing email changes username & vice versa) it needs to be value. It still works
            // for setting an initial value for controlled components as well.
            value={value}
            error={id === 'email' && value !== username}
            helperText={(id === 'email' && value !== username) ? 'Must match verified email.' : ''}
          />
        </Tooltip>

      </React.Fragment>
    )
  }

  renderEditOrCreateSection() {
    const {
      classes, availableRoles, availableCustomers, onClose,
      availableThemes, passwordResetFailMessage, passwordResetSuccessMessage,
    } = this.props
    const {
      roles, customer, sites, regions,
      theme, tags, requiredFieldsError,
      customerRoleError, usernameEmailMismatchError,
      email, firstName, lastName, phoneNumber, timezone,
      elevenUsername, elevenPassword, elevenRealm,
    } = this.state
    return (
      <React.Fragment>
        <Box mt={1} display="flex" flexDirection="row">
          { this.renderFormTextField('firstName', 'First Name', firstName) }
          { this.renderFormTextField('lastName', 'Last Name', lastName) }
          { this.renderFormTextField('email', 'Email', email) }
          { this.renderFormTextField('phoneNumber', 'Phone Number', phoneNumber) }
        </Box>
        <Box mt={1} display="flex" flexDirection="row">
          { this.renderFormTextField('elevenUsername', 'Eleven Username', elevenUsername)}
          { this.renderFormTextField('elevenPassword', 'Eleven Password', elevenPassword)}
          { this.renderFormTextField('elevenRealm', 'Eleven Realm', elevenRealm)}
        </Box>
        <Box mt={1} display="flex" flexDirection="row">
          <FormControl className={classes.formControl}>
            <InputLabel>Customer</InputLabel>
            <Select
              name="Customer"
              value={availableCustomers.filter(cust => cust.id === customer.id)[0] || ''}
              onChange={this.handleSelectCustomer}
              renderValue={c => c.name}
            >
              { availableCustomers.map(c => (
                <MenuItem key={c.id} value={c}>{c.name}</MenuItem>
              )) }
            </Select>
          </FormControl>
          <Box mr={1} />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
            <Select
              multiple
              name="Roles"
              value={roles}
              onChange={this.handleSelectRole}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected => availableRoles.filter(r => selected.includes(r.id)).map(r => r.name).join(', ')}
            >
              {availableRoles.map(role => (
                <MenuItem key={role.id} value={role.id}>
                  <Checkbox checked={roles.indexOf(role.id) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mr={1} />
          <TimezoneInput timezone={timezone} onChange={this.handleTimezoneChanged} />
        </Box>
        <Box className={classes.formControl}>
          <FormControl className={classes.formControl}>
            <InputLabel>Theme</InputLabel>
            <Select
              name="Theme"
              value={availableThemes.filter(t => t.id === theme.id)[0] || ''}
              onChange={this.handleSelectTheme}
              renderValue={t => t.name}
            >
              { availableThemes.map(t => (
                <MenuItem key={t.id} value={t}>{t.name}</MenuItem>
              )) }
            </Select>
          </FormControl>
        </Box>

        { this.renderAssignmentSelect('Regions', this.regionOptions(), this.handleSelectRegion, regions)}
        { this.renderAssignmentSelect('Sites', this.siteOptions(), this.handleSelectSite, sites)}
        { this.renderAssignmentSelect('Tags', this.tagOptions(), this.handleSelectTag, tags)}
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose(false)} variant="text" color="primary">
            Cancel
          </Button>
          <Button onClick={this.submit} variant="text" color="primary">
            Submit
          </Button>
        </Box>
        {requiredFieldsError && <Typography color="error">Verified Email and Email are required</Typography>}
        {customerRoleError && <Typography color="error">The user must have a customer if they do not have an admin role</Typography>}
        {usernameEmailMismatchError && <Typography color="error">The verified email address and email address must match.</Typography>}
        {passwordResetFailMessage && <Typography color="error">{`Failed to send password reset email: ${passwordResetFailMessage}`}</Typography>}
        {passwordResetSuccessMessage && <Typography color="primary">{passwordResetSuccessMessage}</Typography>}
      </React.Fragment>
    )
  }

  renderToggleActivationUser() {
    const {
      classes, user, type, onClose,
    } = this.props

    return (
      <Box display="flex" flexDirection="column">
        <Box className={classes.formControl} display="flex" justifyContent="flex-start" mt={1}>
          <Typography>
            Are you sure you want to
            {` "${type}" `}
            this user?
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button onClick={onClose(false)} variant="text" color="primary">
            Cancel
          </Button>
          <Button onClick={this.submit} variant="text" className={user.active ? classes.deactivateButton : classes.activateButton}>
            {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
          </Button>
        </Box>
      </Box>
    )
  }

  renderShowPermissions = () => {
    const { availablePermissions, permissions } = this.props
    return (
      <PermissionTree
        availablePermissions={availablePermissions}
        selectedPermissions={permissions}
        handleSelectPermissions={this.handleSelectPermissions}
        disableCheckboxes
      />
    )
  }

  render() {
    const {
      classes, type,
    } = this.props
    const {
      title,
    } = this.state

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={(type === 'activate' || type === 'deactivate') ? classes.toggleActivation : classes.root}>
        <Typography variant="h5">
          { title }
        </Typography>
        <hr />
        { ['edit', 'create'].includes(type) && this.renderLoginFields(type) }
        { (type === 'activate' || type === 'deactivate') && this.renderToggleActivationUser() }
        { (type === 'edit' || type === 'create') && this.renderEditOrCreateSection() }
        { (type === 'permissions') && this.renderShowPermissions() }
      </Box>
    )
  }
}

UpdateUser.propTypes = {
  availableCustomers: PropTypes.arrayOf(Object).isRequired,
  availableRegions: PropTypes.arrayOf(Object).isRequired,
  availableRoles: PropTypes.arrayOf(Object).isRequired,
  availableSites: PropTypes.arrayOf(Object).isRequired,
  availableTags: PropTypes.arrayOf(Object).isRequired,
  availableThemes: PropTypes.arrayOf(Object).isRequired,
  availablePermissions: PropTypes.arrayOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  onChooseCustomer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  passwordResetFailMessage: PropTypes.string,
  passwordResetSuccessMessage: PropTypes.string,
  permissions: PropTypes.arrayOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

UpdateUser.defaultProps = {
  passwordResetFailMessage: '',
  passwordResetSuccessMessage: '',
}

function mapStateToProps(state) {
  const {
    usersReducer,
  } = state
  const {
    permissions, passwordResetSuccessMessage, passwordResetFailMessage,
  } = usersReducer
  return {
    permissions,
    passwordResetSuccessMessage,
    passwordResetFailMessage,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(UpdateUser)
