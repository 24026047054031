import PropTypes from 'prop-types'

export default (
  PropTypes.shape({
    allDevices: PropTypes.arrayOf(Object).isRequired,
    units: PropTypes.arrayOf(Object).isRequired,
    controllers: PropTypes.arrayOf(Object).isRequired,
    networks: PropTypes.arrayOf(Object).isRequired,
    accessPoints: PropTypes.arrayOf(Object).isRequired,
    controllerAlerts: PropTypes.arrayOf(Object).isRequired,
    accessPointAlerts: PropTypes.arrayOf(Object).isRequired,
    networkAlerts: PropTypes.arrayOf(Object).isRequired,
    unitAlerts: PropTypes.arrayOf(Object).isRequired,
    unknownDeviceAlerts: PropTypes.arrayOf(Object).isRequired,
  })
)
