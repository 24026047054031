import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Moment from 'moment'

import Box from '@material-ui/core/Box'

import TicketsDetailsProptype from '../proptypes/TicketsDetailsProptype'
import NeatTable from '../components/NeatTable'
import { fetchTickets, fetchTicketsCsv } from '../actions/SitesActions'
import SlideUpModal from '../components/SlideUpModal'
import TicketCommentsContainer from './TicketCommentsContainer'
import ticketStatusLegend from '../constants/TicketStatusLegend'
import { getStatusIcon, statusFormat } from '../helpers/TicketStatusHelper'
import { priorityFormat } from '../helpers/TicketPriorityHelper'

class TicketsDashboardContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      filters: {},
      modalOpen: false,
      ticketId: '',
    }
  }

  componentDidMount() {
    const {
      dispatch, site, siteId,
    } = this.props
    const { status } = this.props
    const filters = { status }
    this.setState({ filters: { ...filters } })
    if (site.id || siteId) {
      dispatch(fetchTickets(site.id || siteId, filters))
    }
  }

  ticketCommentsContainer = () => {
    const { ticketId } = this.state
    return (
      <TicketCommentsContainer ticketId={ticketId} />
    )
  }

  headers = () => ([
    {
      id: 'ticket_id',
      label: 'Ticket ID',
      filterable: true,
      filterType: 'text',
    },
    {
      id: 'priority',
      label: 'Priority',
      filterable: true,
      format: priorityFormat,
      mapping: {
        low: 'low',
        normal: 'normal',
        high: 'high',
        urgent: 'urgent',
      },
    },
    {
      id: 'subject',
      label: 'Subject',
    },
    {
      id: 'status',
      label: 'Status',
      filterable: true,
      format: statusFormat,
      mapping: {
        open: 'open',
        hold: 'hold',
        pending: 'pending',
        new: 'new',
        closed: 'closed',
        solved: 'solved',
        resolved: 'resolved',
        unresolved: 'unresolved',
      },
    },
    {
      id: 'ticket_state',
      label: 'Stage',
    },
    {
      id: 'category',
      label: 'Category',
    },
    {
      id: 'created_at',
      filterable: true,
      type: 'date',
      label: 'Created',
      format: val => Moment(val).format('LLL'),
    },
    {
      id: 'building_apartment_or_room_number',
      label: 'Building/Room',
      filterable: true,
      filterType: 'text',
      filterLabel: 'Unit / Building',
    },
    {
      id: 'source_prefix',
      label: 'Source Prefix',
      filterable: false,
      hidden: true,
    },
  ])


  createRows = (tickets) => {
    const rows = []
    tickets.forEach((ticket) => {
      rows.push(this.createRow(ticket))
    })
    return rows
  }

  rows = () => {
    const { tickets } = this.props
    return this.createRows(tickets.tickets)
  }

  createRow = ticket => (
    {
      ticket_id: ticket.ticket_id.replace(/cwzd|cw|zd/, ''),
      priority: ticket.priority,
      subject: ticket.subject,
      status: ticket.status,
      ticket_state: ticket.ticket_state,
      category: ticket.category,
      created_at: ticket.created_at,
      building_apartment_or_room_number: ticket.building_apartment_or_room_number,
      source_prefix: ticket.ticket_id.replace(/\d+$/, ''),
    }
  )

  toggleModal = (ticket = {}) => {
    const { modalOpen } = this.state
    const stateObj = { modalOpen: !modalOpen }
    if (Object.keys(ticket).length) {
      const sourcePrefix = ticket.source_prefix
      const ticketId = ticket.ticket_id
      stateObj.ticketId = `${sourcePrefix}${ticketId}`
    }

    this.setState(stateObj)
  }

  handleRequest = (filters) => {
    const { dispatch, site } = this.props
    dispatch(fetchTickets(site.id, filters))
    this.setState({ filters })
  }

  render() {
    const {
      dispatch, tickets, site, ticketsCsv,
    } = this.props
    const { counts } = tickets
    const { filters, modalOpen } = this.state

    return (
      <Box p={1}>
        <Box mt={1}>
          <NeatTable
            serverSide
            totalCount={counts.total}
            pageSize={tickets.page_size}
            headers={this.headers()}
            rows={this.rows()}
            rowsForCsv={this.createRows(ticketsCsv)}
            csvRequest={callback => dispatch(fetchTicketsCsv(site.id, filters, callback))}
            setFilterValues={filters}
            onRowClicked={this.toggleModal}
            onHandleRequest={this.handleRequest}
            legendMapping={ticketStatusLegend}
            getIcon={getStatusIcon}
          />
        </Box>
        <SlideUpModal
          open={modalOpen}
          onClose={() => this.toggleModal()} // omit the ticket argument because we're closing
          childComponent={this.ticketCommentsContainer()}
          title="Ticket Details"
        />
      </Box>
    )
  }
}

TicketsDashboardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  siteId: PropTypes.string,
  site: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string,
  tickets: TicketsDetailsProptype.isRequired,
  ticketsCsv: PropTypes.arrayOf(Object).isRequired,
}

TicketsDashboardContainer.defaultProps = {
  siteId: '',
  status: '',
}

function mapStateToProps(state) {
  const { sitesReducer } = state
  const {
    site,
    sites,
    tickets,
    ticketsCsv,
    ticketFilters,
  } = sitesReducer
  return {
    site,
    sites,
    tickets,
    ticketsCsv,
    ticketFilters,
  }
}

export default connect(mapStateToProps)(TicketsDashboardContainer)
