/* eslint-disable no-use-before-define */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

export default function ComboBox(props) {
  const {
    handleChange,
    label,
    options,
    selectedOption,
    size, // can be 'small' or 'medium'
  } = props
  return (
    <Autocomplete
      id={label}
      autoComplete
      disableClearable
      filterSelectedOptions
      blurOnSelect // why doesn't this work when you press enter?? https://github.com/mui-org/material-ui/pull/20314
      // this is so stupid but that blurOnSelect prop actually doesn't work when you press enter
      // but this accomplishes the things we want
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.target.blur()
        }
      }}
      getOptionLabel={option => option.title || ''}
      noOptionsText="No Sites Available..."
      onChange={handleChange}
      options={options}
      size={size}
      value={selectedOption}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          style={{ width: '100%' }}
          variant="outlined"
        />
      )}
    />
  )
}

ComboBox.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  selectedOption: PropTypes.instanceOf(Object).isRequired,
  size: PropTypes.string,
}

ComboBox.defaultProps = {
  size: 'medium',
}
