/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Box, Paper, Typography, Button, withStyles,
} from '@material-ui/core'

const styles = () => ({
  root: {
    width: '50%',
  },
  headingBox: {
    minWidth: 150,
    textAlign: 'right',
  },
  valueBox: {
    marginLeft: '1rem',
    marginTop: '.25rem',
  },
  box: {
    width: '100%',
  },
})

function DisabledUserForm(props) {
  const { classes, onClose, user } = props
  const {
    roles,
    customer,
    granted_sites: sites,
    granted_regions: regions,
    theme,
    granted_tags: tags,
    username,
    email,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    time_zone: timezone,
  } = user

  const renderField = (label, value) => (
    <Box display="flex" flexDirection="row" className={classes.box}>
      <Box className={classes.headingBox}>
        <Typography variant="h6">
          {label}
            :
        </Typography>
      </Box>

      <Box className={classes.valueBox}>
        {Array.isArray(value) ? (
            value.map(subValue => (
              <Box key={subValue}>
                <Typography variant="body1">
                  {subValue}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body1">
              {value}
            </Typography>
          )}
      </Box>
    </Box>
    )

  const renderEditOrCreateSection = () => (
    <React.Fragment>
      { renderField('Username', username) }
      { renderField('First Name', firstName) }
      { renderField('Last Name', lastName) }
      { renderField('Email', email) }
      { renderField('Phone Number', phoneNumber) }
      { renderField('Customer', (customer || {}).name)}
      { renderField('Roles', roles.map(r => r.name))}
      { renderField('Timezone', timezone)}
      { renderField('Theme', (theme || {}).name)}
      { renderField('Regions', regions.map(region => region.region)) }
      { renderField('Sites', sites.map(site => site.name)) }
      { renderField('Tags', tags.map(tag => `${tag.tag_grouping}:${tag.tag}`)) }
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onClose(false)} variant="text" color="primary">
            Cancel
        </Button>
      </Box>
    </React.Fragment>
    )

  return (
    <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
      <Typography variant="h5">
        View User
      </Typography>
      <Typography variant="caption">
        Note: This user is not managed internally,
        that is why you are unable to edit the fields below.
      </Typography>
      <hr />
      { renderEditOrCreateSection() }
    </Box>
  )
}

DisabledUserForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

export default compose(
  withStyles(styles),
)(DisabledUserForm)
