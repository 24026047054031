import React from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@material-ui/core'
import NeatPermissions from '../../helpers/NeatPermissions'

export default function ActionMenu(props) {
  const {
    dispatch,
    userLoginFuction,
    permissionFunction,
    editFunction,
    emailFunction,
    user,
    globalUser,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLoginClicked = () => {
    userLoginFuction()
    handleClose()
  }

  const handlePermissionClicked = () => {
    permissionFunction()
    handleClose()
  }

  const handleEditClicked = () => {
    editFunction()
    handleClose()
  }

  const handlePasswordClicked = () => {
    dispatch(emailFunction(user))
    handleClose()
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            Open Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {((globalUser.hasPermission(NeatPermissions.admin)
          || globalUser.hasPermission(NeatPermissions.support_admin))
          && user.internally_managed)
          && <MenuItem onClick={handlePasswordClicked}>Send Password Reset Email</MenuItem>}
        {globalUser.hasPermission(NeatPermissions.admin)
          && <MenuItem onClick={handleLoginClicked}>User Logins</MenuItem>}
        {globalUser.hasPermission(NeatPermissions.admin)
          && <MenuItem onClick={handlePermissionClicked}>Permissions</MenuItem>}
        {globalUser.hasPermission(NeatPermissions.admin)
          && <MenuItem onClick={handleEditClicked}>Edit</MenuItem>}
      </Menu>
    </div>
  )
}

ActionMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userLoginFuction: PropTypes.func.isRequired,
  permissionFunction: PropTypes.func.isRequired,
  editFunction: PropTypes.func.isRequired,
  emailFunction: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  globalUser: PropTypes.instanceOf(Object).isRequired,
}
