import React from 'react'
import PropTypes from 'prop-types'
import {
  Box, TextField, FormControl, Button, Tooltip,
} from '@material-ui/core'
import PasswordInput from '../PasswordInput'
import { HasLeadingOrTrailingWhitespace } from '../../helpers/StringHelper'

export default function UserLoginFields(props) {
  const {
    classes, username, email,
    showPassword, password, onFieldChanged,
    onShowPasswordClicked, handleSendPasswordResetLink, type,
  } = props
  const whitespaceWarning = 'Leading white space will be stripped upon save.'
  return (
    <Box mt={1} display="flex" flexDirection="row">
      <Tooltip open placement="top" arrow title={HasLeadingOrTrailingWhitespace(username) ? whitespaceWarning : ''}>
        <TextField
          id="username"
          className={classes.formControl}
          label="Verified Email"
          onChange={onFieldChanged('username')}
          value={username}
          error={username !== email}
          helperText={username !== email ? 'Must match email.' : ''}
        />
      </Tooltip>
      <Box mr={1} />
      <PasswordInput
        show={showPassword}
        value={password}
        onChange={onFieldChanged('password')}
        showClicked={onShowPasswordClicked}
      />
      { type === 'edit' && (
        <FormControl className={classes.formControl}>
          <Button className={classes.sendPasswordResetButton} variant="outlined" color="primary" onClick={handleSendPasswordResetLink} mt={3}>
            Send Password Reset Link
          </Button>
        </FormControl>
      )}
    </Box>
  )
}

UserLoginFields.propTypes = {
  classes: PropTypes.instanceOf(Object),
  email: PropTypes.string,
  handleSendPasswordResetLink: PropTypes.func,
  onFieldChanged: PropTypes.func.isRequired,
  onShowPasswordClicked: PropTypes.func.isRequired,
  password: PropTypes.string,
  showPassword: PropTypes.bool,
  type: PropTypes.string.isRequired,
  username: PropTypes.string,
}

UserLoginFields.defaultProps = {
  classes: {},
  email: '',
  handleSendPasswordResetLink: null,
  password: '',
  showPassword: false,
  username: '',
}
