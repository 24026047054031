/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover, List, ListItem, ListItemText, Grid,
} from '@material-ui/core'
import { push } from 'connected-react-router'
import NeatGraph from '../components/NeatGraph'
import { Titleize } from './StringHelper'
import NeatRoutes from './NeatRoutes'

export function OutagePopover({
  open, onClose, x, y, showStats, showOpenTickets, showLocationDb,
}) {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: y, left: x }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
        <ListItem button onClick={showStats}>
          <ListItemText primary="View Statistics" />
        </ListItem>
        <ListItem button onClick={showOpenTickets}>
          <ListItemText primary="View Open Tickets" />
        </ListItem>
        <ListItem button onClick={showLocationDb}>
          <ListItemText primary="Go To Locations Dashboard" />
        </ListItem>
      </List>
    </Popover>
  )
}

OutagePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  showStats: PropTypes.func.isRequired,
  showOpenTickets: PropTypes.func.isRequired,
  showLocationDb: PropTypes.func.isRequired,
}

export function OutageCharts({ data, chartWidth, type }) {
  const {
    gateway, network, ap, controller, mtk, pdu,
  } = data
  const centralizedData = {
    gateway, network, ap, controller,
  }
  const distributedData = { mtk, ap, pdu }

  const renderOutageChart = (id, chartData) => (
    <Grid item xs={chartWidth} key={id}>
      <NeatGraph
        title={Titleize(id)}
        margin={{ bottom: 10 }}
        height={250}
        type={['bar', 'bar']}
        data={chartData}
        color={['#EF9892', '#B7F6A4']}
        xDomainMargin={[-1, 1]}
        xFormat={() => ''}
        yFormat={val => (Math.round(val) === val ? val : '')}
        stackBy="y"
      />
    </Grid>
  )

  return (
    <Grid container spacing={1} style={{ height: 250 }}>
      { type === 'centralized' ? (
        Object.keys(centralizedData).map(key => renderOutageChart(key, centralizedData[key]))
      ) : (
        Object.keys(distributedData).map(key => renderOutageChart(key, distributedData[key]))
      )}
    </Grid>
  )
}

OutageCharts.propTypes = {
  chartWidth: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.oneOf(['centralized', 'distributed']).isRequired,
}

export function showOpenTickets(dispatch, outages, index) {
  const outage = outages[index]

  dispatch(push(`${NeatRoutes.ticketing.path}/${outage.site_id}`, { status: 'open' }))
}

export function showLocationsDashboard(dispatch, outages, index) {
  const outage = outages[index]

  dispatch(push(`${NeatRoutes.locations.path}/${outage.site_id}`))
}

function formatCentralizedData(outage) {
  return {
    gateway: [[{ x: 0, y: outage.gw_down }], [{ x: 0, y: (outage.gw_total - outage.gw_down) }]],
    network: [[{ x: 0, y: outage.nwk_down }], [{ x: 0, y: (outage.nwk_total - outage.nwk_down) }]],
    ap: [[{ x: 0, y: outage.ap_down }], [{ x: 0, y: (outage.ap_total - outage.ap_down) }]],
    controller: [[{ x: 0, y: outage.con_down }], [{ x: 0, y: (outage.con_total - outage.con_down) }]],
  }
}

function formatDistributedData(outage) {
  return {
    mtk: [[{ x: 0, y: outage.mtk_down }], [{ x: 0, y: (outage.mtk_total - outage.mtk_down) }]],
    pdu: [[{ x: 0, y: outage.pdu_down }], [{ x: 0, y: (outage.pdu_total - outage.pdu_down) }]],
    ap: [[{ x: 0, y: outage.ap_down }], [{ x: 0, y: (outage.ap_total - outage.ap_down) }]],
  }
}
export function formatStatistics(outage, type) {
  if (type === 'centralized') return formatCentralizedData(outage)
  if (type === 'distributed') return formatDistributedData(outage)
  return false
}
