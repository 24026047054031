import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import ColorPicker from 'material-ui-color-picker'
import {
  Avatar, Typography, Grid, withStyles, TextField, Button, Box, Paper,
} from '@material-ui/core'
import DeleteConfirmation from './DeleteConfirmation'


const styles = theme => ({
  root: {
    width: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(50%, 50%)',
  },
  container: {
    padding: theme.spacing(2),
  },
  dropZoneContainer: {
    padding: '5px',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
})

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

function Previews(props) {
  // eslint-disable-next-line react/prop-types
  const { removeIcon, onDroppedFiles, showImageSizeError } = props
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        const image = new Image()
        image.onload = (event) => {
          // reject files bigger than 128x128
          if (event.target.height > 128 || event.target.width > 128) {
            showImageSizeError()
            return
          }
          onDroppedFiles(file)
          setFiles([Object.assign(file, {
            preview: image.src,
          })])
        }
        image.src = URL.createObjectURL(file)
        return []
      })
    },
  })

  const thumbs = files.map((file) => {
    if (file && file.name) {
      return (
        <React.Fragment key={file.preview}>
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img
                alt="theme icon"
                src={file.preview}
                style={img}
              />
            </div>
          </div>
          <Button onClick={() => {
            URL.revokeObjectURL(file.preview)
            setFiles([{}])
            removeIcon()
          }}
          >
            Remove
          </Button>
        </React.Fragment>
      )
    }
    return (<React.Fragment />)
  })

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => (file.preview ? URL.revokeObjectURL(file.preview) : null))
  }, [files])

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography>
          Drag an image here for the theme icon, image size limited to 128x128
        </Typography>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  )
}

class ThemeManager extends React.Component {
  static defaultProps = {
    theme: {
      name: '',
      primaryColor: '#000000',
      secondaryColor: '#000000',
      icon: '',
    },
  }

  constructor(props) {
    super(props)
    const { theme } = props
    this.state = {
      name: theme ? theme.name : '',
      primaryColor: theme ? theme.primary_color : '#000000',
      secondaryColor: theme ? theme.secondary_color : '#000000',
      icon: theme ? theme.icon_url : '',
      file: null,
      error: false,
      imageError: false,
    }
    this.dropzoneRef = createRef()
  }

  onDroppedFiles(acceptedFile) {
    this.setState({ file: acceptedFile })
  }

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value })
  }

  showImageSizeError() {
    this.setState({ imageError: true })
  }

  removeIcon() {
    this.setState({ file: null })
  }

  saveTheme() {
    const {
      name, primaryColor, secondaryColor, file,
    } = this.state
    const { onSubmit, theme } = this.props

    if (name && primaryColor && secondaryColor) {
      const themeData = new FormData()
      if (theme && theme.id) {
        themeData.append('id', theme.id)
      }
      themeData.append('name', name)
      themeData.append('primary_color', primaryColor)
      themeData.append('secondary_color', secondaryColor)
      if (file) {
        themeData.append('icon', file)
      }
      onSubmit(themeData)
    } else this.setState({ error: true })
  }

  render() {
    const {
      classes, type, onClose, onSubmit, theme,
    } = this.props
    const {
      name, primaryColor, secondaryColor, error, icon, imageError,
    } = this.state

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
        <Grid container direction="column" spacing={2} className={classes.container}>
          {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={theme}
                title="Theme"
              />
            )
          }
          {(type === 'create' || type === 'edit') && (
          <React.Fragment>
            <Grid item>
              <Typography variant="h4">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {type === 'create' ? 'Create' : 'Edit'} Theme
              </Typography>
            </Grid>
            <Grid item>
              <TextField required label="Name" name="name" value={name} onChange={this.handleChange('name')} />
            </Grid>
            <Grid item>
              <ColorPicker
                name="primaryColor"
                label="Primary Color"
                defaultValue="#000000"
                TextFieldProps={{ disabled: true }}
                value={primaryColor}
                onChange={color => this.setState({ primaryColor: color })}
              />
            </Grid>
            <Grid item>
              <ColorPicker
                name="secondaryColor"
                label="Secondary Color"
                defaultValue="#000000"
                TextFieldProps={{ disabled: true }}
                value={secondaryColor}
                onChange={color => this.setState({ secondaryColor: color })}
              />
            </Grid>
            <Grid item>
              <Paper className={classes.dropZoneContainer}>
                <Previews
                  onDroppedFiles={files => this.onDroppedFiles(files)}
                  removeIcon={() => this.removeIcon()}
                  showImageSizeError={() => this.showImageSizeError()}
                />
              </Paper>
            </Grid>
            {type === 'edit' && (
              <Grid item>
                <Avatar src={icon} />
              </Grid>
            )}

            <Grid item>
              <Button variant="contained" color="primary" onClick={() => this.saveTheme()}>
                <Typography>Save Theme</Typography>
              </Button>
              {error && <Typography color="error">All name and color fields are required</Typography>}
              {imageError && <Typography color="error">Icon Image must be 128 x 128 dimension or smaller</Typography>}
            </Grid>
          </React.Fragment>
          )}
        </Grid>
      </Box>
    )
  }
}

ThemeManager.propTypes = {
  theme: PropTypes.instanceOf(Object),
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
}

export default withStyles(styles)(ThemeManager)
