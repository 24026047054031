import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import customersReducer from './CustomersReducer'
import dashboardReducer from './DashboardReducer'
import externalLinksReducer from './ExternalLinksReducer'
import gatewaysReducer from './GatewaysReducer'
import globalStateReducer from './GlobalStateReducer'
import loadingReducer from './LoadingReducer'
import outageAlertsReducer from './OutageAlertsReducer'
import outagesReducer from './OutagesReducer'
import permissionsReducer from './PermissionsReducer'
import regionsReducer from './RegionsReducer'
import rolesReducer from './RolesReducer'
import sitesReducer from './SitesReducer'
import themesReducer from './ThemesReducer'
import ticketsReducer from './TicketsReducer'
import usersReducer from './UsersReducer'
import welcomeTextsReducer from './WelcomeTextsReducer'
import errorsReducer from './ErrorsReducer'
import customerSettingsReducer from './CustomerSettingsReducer'
import featureRequestFormsReducer from './FeatureRequestFormsReducer'
import darkModeReducer from './DarkModeReducer'
import monitoredDevicesReducer from './MonitoredDevicesReducer'

export default history => combineReducers({
  global: globalStateReducer,
  router: connectRouter(history),
  dashboard: dashboardReducer,
  customersReducer,
  externalLinksReducer,
  gatewaysReducer,
  loadingReducer,
  outageAlertsReducer,
  outagesReducer,
  permissionsReducer,
  regionsReducer,
  rolesReducer,
  sitesReducer,
  themesReducer,
  ticketsReducer,
  usersReducer,
  welcomeTextsReducer,
  errorsReducer,
  customerSettingsReducer,
  featureRequestFormsReducer,
  darkModeReducer,
  monitoredDevicesReducer,
})
