import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Typography,
  Box,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { fetchDevicePdshPing } from '../actions/SitesActions'
import CodeBlock from './CodeBlock'
import { isFetchingRequests } from '../reducers/LoadingReducer'
import CircularProgressSpinner from './CircularProgressSpinner'

function modalStyles() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  box: {
    display: 'flex',
  },
  centered: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  hosticon: {
    borderRadius: '50%',
    backgroundColor: 'gray',
    width: 10,
    height: 10,
    marginTop: '.25rem',
    marginLeft: '.5rem',
  },
}))

function PingDeviceModal(props) {
  const {
    dispatch, siteId, deviceId, pingDetails, isFetching,
  } = props
  const classes = useStyles()
  const [modalStyle] = React.useState(modalStyles)
  const [open, setOpen] = React.useState(false)

  const pingDevice = () => {
    dispatch(fetchDevicePdshPing(siteId, deviceId))
  }

  const handleOpen = () => {
    setOpen(true)
    pingDevice()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderPingDetails = () => Object.keys(pingDetails).map((descriptor => (
    // there'll be 3 ip addresses / hosts per ping
    Object.keys(pingDetails[descriptor]).map((ipAddress, index) => (
      <Box key={ipAddress} flexDirection="row" flex={1}>
        { /* display an arbitrary 'HOST #n' with a colored circle indicating the ssh status */ }
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div><Typography variant="body2">{`Host ${index + 1}`}</Typography></div>
          <div style={{ backgroundColor: pingDetails.exit_code === 0 ? '#00ff00' : '#ff0000' }} className={classes.hosticon} />
        </div>
        { /* render a code block detailing the ping response */ }
        <CodeBlock lines={pingDetails[descriptor][ipAddress]} />
      </Box>
    ))
  )))


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>Ping</Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Box style={{ flexDirection: 'column' }} className={classes.box}>
            <Typography variant="h6">Ping Results:</Typography>
            { /* the descriptor will be one of: 'stdout', 'stderr', 'exit_code' */ }
            { isFetching ? (
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <CircularProgressSpinner />
              </div>
            ) : (
              renderPingDetails()
            )}
          </Box>
        </div>
      </Modal>
    </div>
  )
}

PingDeviceModal.propTypes = {
  siteId: PropTypes.string.isRequired,
  deviceId: PropTypes.instanceOf(Array).isRequired,
  dispatch: PropTypes.func.isRequired,
  pingDetails: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool.isRequired,
}

PingDeviceModal.defaultProps = {
  pingDetails: {
    stderr: [],
    stdout: [],
    exitCode: undefined,
  },
}

function mapStateToProps(state) {
  const isFetching = isFetchingRequests(state)
  const {
    sitesReducer,
  } = state
  const { pingDetails } = sitesReducer
  return {
    pingDetails,
    isFetching,
  }
}

export default connect(mapStateToProps)(PingDeviceModal)
