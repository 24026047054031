import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Box, Typography } from '@material-ui/core'

const styles = theme => ({
  title: {
    color: 'white',
    width: '100%',
    maxHeight: theme.typography.subtitle1.lineHeight * 16 * 2,
    overflow: 'auto',
  },
})


class ServiceAlert extends React.PureComponent {
  render() {
    const { classes, alert } = this.props

    return (
      <Box key="service-alert" className="service-alert-pulse" display="flex" p={1} bgcolor="#e88783" border={1} borderColor="#cd0001" borderRadius="borderRadius">
        <Typography
          className={classes.title}
          variant="subtitle1"
        >
          {/* eslint-disable-next-line react/no-array-index-key */}
          {alert.split('\n').map((i, key) => <Box width="fit-content" margin="auto" key={key}>{i}</Box>)}
        </Typography>
      </Box>
    )
  }
}

ServiceAlert.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.string.isRequired,
}

export default withStyles(styles)(ServiceAlert)
