// The error reducer created in src/actions/errorsReducer.js allows us
// to manually trigger errors as well. EG: form submission in the admin panel.
// Instead of each component managing error state, it will be much more efficient
// to handle error state globally. For that, we need to be able to dispatch error actions manually.

export const SET_ERROR = 'SET_ERROR'
export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

// We also need an action to clear out errors from the application state.
export const HIDE_ERROR = 'HIDE_ERROR'
export function hideError() {
  return {
    type: HIDE_ERROR,
  }
}
