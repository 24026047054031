import React from 'react'
import PropTypes from 'prop-types'

// import redux helpers
import { compose } from 'redux'
import { connect } from 'react-redux'

// import MUI components
import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
} from '@material-ui/core'

// import icons
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

// import custom admin page styles
import AdminStyles from '../styles/AdminStyles'

import ExternalLinkManager from '../components/ExternalLinkManager'

// import fetching actions
import {
  fetchExternalLinks,
  fetchCreateExternalLink,
  fetchDeleteExternalLink,
  fetchUpdateExternalLink,
} from '../actions/ExternalLinksActions'
import { fetchPermissions } from '../actions/PermissionsActions'

import NeatTable from '../components/NeatTable'

class ExternalLinksContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchExternalLinks())
    dispatch(fetchPermissions())
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  setModalOpen = (open, type, object = {}) => () => {
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  headers = () => ([
    {
      id: 'display_name', label: 'Display Name',
    },
    {
      id: 'url', label: 'URL',
    },
    {
      id: 'resource', label: 'Resource',
    },
    {
      id: 'tooltip_message', label: 'Tooltip Message',
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ])

  rows = () => {
    const { externalLinks } = this.props
    const rows = []
    externalLinks.forEach((link) => {
      rows.push(this.createRow(link))
    })
    return rows
  }

  createRow = (link) => {
    const { classes } = this.props
    return (
      {
        display_name: link.display_name,
        url: link.url,
        resource: link.resource,
        tooltip_message: link.tooltip_message,
        edit: <Button color="primary" onClick={this.setModalOpen(true, 'edit', link)}>Edit</Button>,
        delete: (
          <Button
            className={classes.deleteButton}
            onClick={this.setModalOpen(true, 'delete', link)}
          >
            Delete
          </Button>
        ),
      }
    )
  }

  updateOrCreateExternalLink = (link) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateExternalLink
    } else if (modalType === 'delete') {
      request = fetchDeleteExternalLink
    } else {
      request = fetchUpdateExternalLink
    }
    dispatch(request(link))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  renderModal = (open, type, object) => {
    const { dispatch, permissions } = this.props
    return (
      <Modal open={open} onClose={this.setModalOpen(false)}>
        <ExternalLinkManager
          type={type}
          dispatch={dispatch}
          availablePermissions={permissions}
          link={object}
          onSubmit={this.updateOrCreateExternalLink}
          onClose={this.setModalOpen(false)}
        />
      </Modal>
    )
  }

  clearErrors = () => {
    this.setState({ errors: [] })
  }

  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  render() {
    const { modalOpen, modalType, modalObject } = this.state

    return (
      <Box width="100%" p={1}>
        <Box display="flex" justifyContent="flex-end" m={1}>
          <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
        </Box>

        <NeatTable
          headers={this.headers()}
          rows={this.rows()}
        />

        { this.renderModal(modalOpen, modalType, modalObject)}
        { this.renderErrors() }
      </Box>
    )
  }
}

ExternalLinksContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  externalLinks: PropTypes.arrayOf(Object).isRequired,
  permissions: PropTypes.arrayOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { permissionsReducer, externalLinksReducer } = state
  const { permissions } = permissionsReducer
  const { externalLinks } = externalLinksReducer
  return { externalLinks, permissions }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(ExternalLinksContainer)
