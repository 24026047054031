import {
  REQUEST_CUSTOMER_SETTINGS,
  RECEIVE_CUSTOMER_SETTINGS,
  FAILURE_CUSTOMER_SETTINGS,
} from '../actions/CustomerSettingsActions'

export default (
  state = {
    customerSettings: [],
    customerSettingsErrors: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_CUSTOMER_SETTINGS:
      return Object.assign({}, state, {
        customerSettings: action.customerSettings,
      })
    case FAILURE_CUSTOMER_SETTINGS:
      return Object.assign({}, state, {
        customerSettingsErrors: action.error,
      })
    case REQUEST_CUSTOMER_SETTINGS:
    default:
      return state
  }
}
