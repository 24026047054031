import {
  REQUEST_REGIONS,
  RECEIVE_REGIONS,
} from '../actions/RegionsActions'

export default (
  state = {
    regions: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_REGIONS:
      return Object.assign({}, state, {
        regions: action.regions,
      })
    case REQUEST_REGIONS:
    default:
      return state
  }
}
