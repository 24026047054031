import React from 'react'
import {
  Box, ButtonBase,
} from '@material-ui/core'
import MappingDetails from './MappingDetails'
import SiteDetailsProptype from '../proptypes/SiteDetailsProptype'

class MappingWidget extends React.Component {
  render() {
    const { siteDetails } = this.props
    return (
      <Box height="100%" width="100%" component={ButtonBase} boxShadow={1} borderRadius="borderRadius" bgcolor="background.paper">
        <MappingDetails siteDetails={siteDetails} width="100%" height="470px" />
      </Box>
    )
  }
}

MappingWidget.propTypes = {
  siteDetails: SiteDetailsProptype.isRequired,
}

export default MappingWidget
