import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'

// Eventually need to clean up these styles and incorporate theme somehow into compnent text color
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    borderRadius: 10,
  },

  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },

  graphicContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  graphic: {
    flexGrow: 1,
    marginTop: '0.5rem',
  },

  numbersContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  returning: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: '0.5rem',
  },

  new: {
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: '0.5rem',
  },

  newFont: {
    fontFamily: 'sans-serif',
    color: '#58dba4',
  },

  returningFont: {
    fontFamily: 'sans-serif',
    color: '#84b3d1',
  },

}))

export default function NewVsReturningClients(props) {
  const classes = useStyles()
  const { details, header } = props
  return (
    <div>
      <h4 className={classes.header}>{header}</h4>
      <Paper className={classes.root}>
        <div className={classes.graphicContainer}>
          { Array(10).fill().map((_, i) => i).map((elem) => {
            const filled = ((elem + 1) * 10) > 100 * (1 - details)
            const color = filled ? '#84b3d1' : '#58dba4'
            return <PersonIcon key={elem} style={{ color }} className={classes.graphic} fontSize="large" />
          }) }
        </div>
        <div className={classes.numbersContainer}>
          <div className={classes.new}>
            <h4 className={classes.newFont}>New Guest:</h4>
            <h4 className={classes.newFont}>
              { details !== '' ? `${100 - Math.round(100 * details)}%` : 'N/A'}
            </h4>
          </div>

          <div className={classes.returning}>
            <h4 className={classes.returningFont}>Returning Guest:</h4>
            <h4 className={classes.returningFont}>
              { details !== '' ? `${Math.round(100 * details)}%` : 'N/A'}
            </h4>
          </div>


        </div>
      </Paper>
    </div>
  )
}

NewVsReturningClients.propTypes = {
  details: PropTypes.string,
  header: PropTypes.string,
}

NewVsReturningClients.defaultProps = {
  header: '',
  details: '',
}
