// we could make this more dynamic and provide tooltips
// if we defined a legend similar to the one in
// constants/TicketStatusLegend.js.
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Typography, Box } from '@material-ui/core'
import { Titleize } from './StringHelper'

export function getPriorityIcon(priority) {
  switch (priority) {
    case ('low'):
      return <FontAwesomeIcon data-testid="check-circle-icon" icon={faCheckCircle} style={{ color: 'green' }} />
    case ('normal'):
      return <FontAwesomeIcon data-testid="check-circle-icon" icon={faCheckCircle} style={{ color: 'green' }} />
    case ('high'):
      return <FontAwesomeIcon data-testid="exclamation-triangle-icon" icon={faExclamationTriangle} style={{ color: 'orange' }} />
    case ('urgent'):
      return <FontAwesomeIcon data-testid="exclamation-triangle-icon" icon={faExclamationTriangle} style={{ color: 'red' }} />
    default:
      return <FontAwesomeIcon data-testid="question-circle-icon" icon={faQuestionCircle} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
  }
}

export function priorityFormat(val) {
  const icon = getPriorityIcon(val)
  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="priority-container"
    >
      {icon}
      <Typography>
        &nbsp;
        {val && Titleize(val)}
      </Typography>
    </Box>
  )
}
