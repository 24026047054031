export default (
  {
    formControl: {
      width: 180,
    },
    greenRow: {
      background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(94,221,84,1) 0%, rgba(147,255,141,1) 100%)',
    },
    yellowRow: {
      background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(221,215,84,1) 0%, rgba(255,253,180,1) 100%)',
    },
    orangeRow: {
      background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(221,148,84,1) 0%, rgba(255,202,115,1) 100%)',
    },
    redRow: {
      background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(221,84,84,1) 0%, rgba(255,157,157,1) 100%)',
    },
    greenButton: {
      backgroundColor: 'rgba(94,221,84,1)',
    },
    yellowButton: {
      backgroundColor: 'rgba(221,215,84,1)',
    },
    orangeButton: {
      backgroundColor: 'rgba(221,148,84,1)',
    },
    redButton: {
      backgroundColor: 'rgba(221,84,84,1)',
    },
  }
)
