import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

class InfoGrid extends React.PureComponent {
  render() {
    const { info, spacing } = this.props
    const informationViews = []
    info.forEach((i) => {
      informationViews.push(
        <Grid item xs={1} key={`label_${i[0]}`}>
          <Typography variant="body1">{`${i[0]}:`}</Typography>
        </Grid>,
        <Grid item xs={11} key={`value_${i[0]}_${i[1]}`}>
          <Typography data-testid={i[2]} variant="body1">{i[1]}</Typography>
        </Grid>,
      )
    })

    return (
      <Grid container spacing={spacing} item xs={12}>
        {informationViews}
      </Grid>
    )
  }
}

InfoGrid.propTypes = {
  info: PropTypes.arrayOf(Object).isRequired,
  spacing: PropTypes.number,
}

InfoGrid.defaultProps = {
  spacing: 0,
}

export default InfoGrid
