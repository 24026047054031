import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import Button from '@material-ui/core/Button'

export function welcomeTextHeaders() {
  return [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'markdownText',
      label: 'Markdown Text',
    },
    {
      id: 'active',
      label: 'Active',
    },
    {
      id: 'createdAt',
      format: val => Moment(val).format('MM/DD/YYYY'),
      label: 'Created At',
      type: 'createdAt',
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'toggleActive', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ]
}

const editButton = (welcomeText, props) => {
  const { setModalOpen } = props
  return (
    <Button color="primary" onClick={setModalOpen(true, 'edit', welcomeText)}>
      Edit
    </Button>
  )
}

editButton.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
}

const deleteButton = (welcomeText, props) => {
  const { classes, setModalOpen } = props
  return (
    <Button
      className={classes.deleteButton}
      onClick={setModalOpen(true, 'delete', welcomeText)}
    >
      Delete
    </Button>
  )
}

deleteButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

const toggleActiveButton = (welcomeText, props) => {
  const { classes, toggleActive } = props
  return (
    <Button
      className={welcomeText.active ? classes.deactivateButton : classes.activateButton}
      onClick={() => toggleActive(welcomeText)}
    >
      {welcomeText.active ? 'Deactivate' : 'Activate'}
    </Button>
  )
}

toggleActiveButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  toggleActive: PropTypes.func.isRequired,
}

function createRow(welcomeText, props = {}) {
  return {
    id: welcomeText.id,
    markdownText: welcomeText.markdown_text,
    active: String(welcomeText.active),
    createdAt: Moment(welcomeText.created_at).format('MM/DD/YYYY'),
    edit: editButton(welcomeText, props),
    toggleActive: toggleActiveButton(welcomeText, props),
    delete: deleteButton(welcomeText, props),
  }
}

export function welcomeTextRows(props = {}) {
  const { welcomeTexts } = props
  const rowArray = []
  if (welcomeTexts) {
    welcomeTexts.forEach((welcomeText) => {
      rowArray.push(createRow(welcomeText, props))
    })
  }
  return rowArray
}
