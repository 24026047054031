/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Grid, withStyles, Button, Box, Paper,
} from '@material-ui/core'
import WelcomeTextEditor from './WelcomeTextEditor'

import ManagerModalStyles from '../styles/ManagerModalStyles'
import DeleteConfirmation from './DeleteConfirmation'

class WelcomeTextManager extends React.Component {
  constructor(props) {
    super(props)
    const { welcomeText } = props
    const {
      markdown_text,
      active,
    } = welcomeText
    // the ors are so that if the initial value is undefined,
    // we don't get an uncontrolled input error
    this.state = {
      markdownText: markdown_text || '',
      active: active || false,
    }
  }

  handleActiveChanged(active) {
    this.setState({ active })
  }

  handleMarkdownTextChanged(markdownText) {
    this.setState({ markdownText })
  }

  saveWelcomeText() {
    const { markdownText, active } = this.state
    const { onSubmit, welcomeText } = this.props
    const { id } = welcomeText

    if (markdownText) {
      const welcomeTextData = new FormData()
      if (id) {
        welcomeTextData.append('id', id)
      }
      welcomeTextData.append('markdown_text', markdownText)
      welcomeTextData.append('active', active)

      onSubmit(welcomeTextData)
    } else this.setState({ error: true })
  }

  renderFormFields() {
    const { active, markdownText } = this.state
    return (
      <React.Fragment>
        <WelcomeTextEditor
          active={active}
          handleActiveChanged={activeBool => this.handleActiveChanged(activeBool)}
          markdownText={markdownText}
          handleMarkdownTextChanged={changedText => this.handleMarkdownTextChanged(changedText)}
        />
      </React.Fragment>
    )
  }

  renderForm() {
    const { type } = this.props
    const { error } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {type === 'create' ? 'Create' : 'Edit'} Welcome Text
          </Typography>
        </Grid>
        { this.renderFormFields() }
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => this.saveWelcomeText()}>
            <Typography>Save Welcome Text</Typography>
          </Button>
          {error && <Typography color="error">All fields are required</Typography>}
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {
      classes, type, onClose, onSubmit, welcomeText,
    } = this.props

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
        <Grid container direction="column" spacing={2} className={classes.container}>
          {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={welcomeText}
                title="Welcome Text"
              />
            )
          }
          {(type === 'create' || type === 'edit') && this.renderForm()}
        </Grid>
      </Box>
    )
  }
}

WelcomeTextManager.propTypes = {
  welcomeText: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

WelcomeTextManager.defaultProps = {
  welcomeText: {},
  type: '',
}


export default withStyles(ManagerModalStyles)(WelcomeTextManager)
