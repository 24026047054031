// THIS COMPONENT IS DEPRECATED - DO NOT USE
// we have started a pattern of graphs getting their own separate compnenents.
import React from 'react'
import PropTypes from 'prop-types'

import {
  FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines,
  VerticalBarSeries, LineSeries, AreaSeries, DiscreteColorLegend, Crosshair,
} from 'react-vis'
import {
  Box, Button, Paper, Typography, withStyles, Grid,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
  title: {
    position: 'relative',
    left: '200px',
  },
  graph: {
    fontFamily: theme.typography.fontFamily,
  },
  colorLegend: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    position: 'absolute',
    right: 0,
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    background: 'white',
    top: '3px',
    right: '5px',
    zIndex: 100,
  },
})

class NeatGraph extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      crosshairValues: [],
    }
  }

  /**
   * A callback to format the crosshair items.
   * @param {Object} values Array of values.
   * @returns {Array<Object>} Array of objects with titles and values.
   * @private
   */
  formatCrosshairItems = (values) => {
    const { label, yFormat } = this.props
    return values.map((v, i) => ({
      title: label[i],
      value: yFormat ? yFormat(v.y) : v.y,
    }))
  }

  /**
   * Format the title line of the crosshair.
   * @param {Array} values Array of values.
   * @returns {Object} The caption and the value of the title.
   * @private
   */
  formatCrosshairTitle = (values) => {
    const { xFormat, xAxisLabel } = this.props
    return ({
      title: xAxisLabel,
      value: xFormat ? xFormat(values[0].x) : values[0].x,
    })
  }

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  mouseLeaveHandler = () => {
    this.setState({ crosshairValues: [] })
  }

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {number} index Index of the series.
   * @private
   */

  // This handler fires when the user moves their mouse somewhere on the plot.
  // The handler fires a function that takes two arguments:
  //   the datapoint with the x value closest to the cursor or touch point
  //   a second object containing:
  //     the innerX value (x coordinates of the cursor relative to the left of the plot),
  //     index (position of this datapoint in the dataset)
  //     the actual event as event.

  nearestXHandler = (value, { index }) => {
    const { data } = this.props
    this.setState({
      crosshairValues: data.map(s => s[index]),
    })
  }

  getYDomain = () => {
    const { data, yDomainMargin, stackBy } = this.props

    let yMin = 0
    let yMax = 0
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      // eslint-disable-next-line no-loop-func
      data[i].forEach(({ y }) => {
        if (y < yMin) {
          yMin = y
        } else if (stackBy !== 'y') {
          if (y > yMax) {
            yMax = y
          }
        } else {
          yMax += y
        }
      })
    }
    yMin -= yDomainMargin[0]
    if (yMin === 0 && yMax === 0) {
      yMax = 10
    } else {
      yMax += yDomainMargin[1]
    }

    return [yMin, yMax]
  }

  isHex = (h) => {
    const a = parseInt(h, 16)
    return h && (a.toString(16) === h.toLowerCase())
  }

  renderSeries() {
    const { data, color, type } = this.props
    const series = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      let c = color[i]
      if (this.isHex(c)) {
        c = `#${c}`
      }
      if (type[i] === 'area') {
        series.push(
          <AreaSeries
            key={i}
            color={c}
            data={data[i]}
            onNearestX={this.nearestXHandler}
          />,
        )
      } else if (type[i] === 'line') {
        series.push(
          <LineSeries
            key={i}
            color={c}
            data={data[i]}
            onNearestX={this.nearestXHandler}
          />,
        )
      } else if (type[i] === 'bar') {
        series.push(
          <VerticalBarSeries
            key={i}
            color={c}
            data={data[i]}
          />,
        )
      }
    }

    return series
  }

  render() {
    const {
      classes,
      expanded, toggleExpanded,
      color, label,
      title, margin, width, height, tickLabelAngle,
      xDomainMargin, xFormat, yFormat, xTickValues, yTickValues, stackBy, showExpandButton,
    } = this.props
    const { crosshairValues } = this.state

    const plotProps = {}
    if (xDomainMargin[0] !== 0 && xDomainMargin[1] !== 0) {
      plotProps.xDomain = xDomainMargin
    }
    if (stackBy) {
      plotProps.stackBy = stackBy
    }
    if (width > 0) {
      plotProps.width = width - 16
    }
    if (height > 0) {
      plotProps.height = height - 23
    }

    const xAxisProps = {}
    const yAxisProps = {}
    if (xFormat) {
      xAxisProps.tickFormat = xFormat
    }
    if (yFormat) {
      yAxisProps.tickFormat = yFormat
    }
    if (xTickValues) {
      xAxisProps.tickValues = xTickValues
    }
    if (yTickValues) {
      yAxisProps.tickValues = yTickValues
    }

    const series = label.map((d, index) => ({ color: `#${color[index]}`, title: label[index] }))

    return (
      <Box component={Paper} width="100%" height={height > 0 ? height : '100%'}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography className={classes.title}>
              {title}
            </Typography>
          </Grid>
          {showExpandButton && (
          <Grid item>
            <Button size="small" variant="contained" className={classes.button} onClick={() => toggleExpanded()}>
              { expanded
                ? <FontAwesomeIcon icon={faCompressArrowsAlt} />
                : <FontAwesomeIcon icon={faExpandArrowsAlt} /> }
            </Button>
          </Grid>
          )
        }
        </Grid>
        <FlexibleXYPlot
          className={classes.graph}
          margin={{ top: 25, ...margin }}
          yDomain={this.getYDomain()}
          onMouseLeave={this.mouseLeaveHandler}
          {...plotProps}
        >
          <HorizontalGridLines />
          <XAxis {...xAxisProps} tickLabelAngle={tickLabelAngle} />
          <YAxis {...yAxisProps} />
          {this.renderSeries()}
          <DiscreteColorLegend
            className={classes.colorLegend}
            items={series}
            style={{ top: 25, position: 'absolute' }}
          />
          <Crosshair
            itemsFormat={this.formatCrosshairItems}
            titleFormat={this.formatCrosshairTitle}
            values={crosshairValues}
          />
        </FlexibleXYPlot>
      </Box>
    )
  }
}

NeatGraph.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.arrayOf(String).isRequired,
  label: PropTypes.arrayOf(String),
  type: PropTypes.arrayOf(PropTypes.oneOf(['area', 'line', 'bar'])).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  xAxisLabel: PropTypes.string,
  margin: PropTypes.instanceOf(Object),
  width: PropTypes.number,
  height: PropTypes.number,
  xFormat: PropTypes.func,
  yFormat: PropTypes.func,
  xDomainMargin: PropTypes.arrayOf(Number),
  yDomainMargin: PropTypes.arrayOf(Number),
  xTickValues: PropTypes.arrayOf(Number),
  yTickValues: PropTypes.arrayOf(Number),
  stackBy: PropTypes.string,
  toggleExpanded: PropTypes.func,
  expanded: PropTypes.bool,
  showExpandButton: PropTypes.bool,
  tickLabelAngle: PropTypes.number,
}

NeatGraph.defaultProps = {
  label: [],
  xAxisLabel: 'X',
  margin: {},
  width: 0,
  height: 0,
  xFormat: null,
  yFormat: null,
  xDomainMargin: [0, 0],
  yDomainMargin: [0, 0],
  xTickValues: null,
  yTickValues: null,
  stackBy: '',
  toggleExpanded: null,
  expanded: false,
  showExpandButton: false,
  tickLabelAngle: -90,
}

export default withStyles(styles)(NeatGraph)
