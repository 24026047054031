import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Moment from 'moment'
import PropTypes from 'prop-types'
import NeatTable from '../components/NeatTable'
import { fetchUserLogins } from '../actions/UsersActions'

class UserLoginsContainer extends React.Component {
  componentDidMount() {
    const { dispatch, userId } = this.props
    dispatch(fetchUserLogins(userId))
  }

  headers = () => ([
    {
      id: 'loginDate', format: val => Moment(val).format('LLL'), filterable: false, label: 'User Logins',
    },
  ])

  rows = () => {
    const { userLogins } = this.props
    return userLogins.map(login => this.createRow(login))
  }

  createRow = login => (
    {
      loginDate: login,
    }
  )

  render() {
    const { userLogins } = this.props
    return (
      <React.Fragment>
        <Paper>
          {userLogins.length > 0
          ? (
            <NeatTable
              headers={this.headers()}
              rows={this.rows()}
            />
            )
            : <p>This User does not have any login history.</p>
          }
        </Paper>
      </React.Fragment>
    )
  }
}

UserLoginsContainer.propTypes = {
  userId: PropTypes.number,
  userLogins: PropTypes.arrayOf(String),
  dispatch: PropTypes.func.isRequired,
}

UserLoginsContainer.defaultProps = {
  userId: -1,
  userLogins: [],
}

function mapStateToProps(state) {
  const {
    usersReducer,
  } = state
  const {
    userLogins,
  } = usersReducer
  return {
    userLogins,
  }
}


export default compose(
  connect(mapStateToProps),
)(UserLoginsContainer)
