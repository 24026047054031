import NeatPermissions from '../helpers/NeatPermissions'
import HandleTimeout from '../helpers/SessionHelper'

const querystring = require('querystring')

export const REQUEST_ALL_TICKETS = 'REQUEST_ALL_TICKETS'
function requestTickets(ticketFilters) {
  return {
    type: REQUEST_ALL_TICKETS,
    requiredPermission: NeatPermissions.tickets.index,
    ticketFilters,
  }
}

export const RECEIVE_ALL_TICKETS = 'RECEIVE_ALL_TICKETS'
function receiveTickets(tickets) {
  return {
    type: RECEIVE_ALL_TICKETS,
    tickets,
  }
}

export const FAILURE_ALL_TICKETS = 'FAILURE_ALL_TICKETS'
function failureTickets(error) {
  return {
    type: FAILURE_ALL_TICKETS,
    error,
  }
}

export const REQUEST_ALL_TICKETS_CSV = 'REQUEST_ALL_TICKETS_CSV'
function requestTicketsCSV(ticketFilters) {
  return {
    type: REQUEST_ALL_TICKETS_CSV,
    requiredPermission: NeatPermissions.tickets.index,
    ticketFilters,
  }
}

export const RECEIVE_ALL_TICKETS_CSV = 'RECEIVE_ALL_TICKETS_CSV'
function receiveTicketsCSV(ticketsCsv) {
  return {
    type: RECEIVE_ALL_TICKETS_CSV,
    ticketsCsv,
  }
}

export const FAILURE_ALL_TICKETS_CSV = 'FAILURE_ALL_TICKETS_CSV'
function failureTicketsCSV(error) {
  return {
    type: FAILURE_ALL_TICKETS_CSV,
    error,
  }
}

export const REQUEST_TICKET = 'REQUEST_TICKET'
function requestTicket(ticketId) {
  return {
    type: REQUEST_TICKET,
    requiredPermission: NeatPermissions.tickets.show,
    ticketId,
  }
}

export const RECEIVE_TICKET = 'RECEIVE_TICKET'
function receiveTicket(json) {
  return {
    type: RECEIVE_TICKET,
    ticket: json,
  }
}

export const FAILURE_TICKET = 'FAILURE_TICKET'
function failureTicket(error) {
  return {
    type: FAILURE_TICKET,
    error,
  }
}

export function fetchTickets(filters = {}) {
  return (dispatch) => {
    if (dispatch(requestTickets(filters))) {
      const params = {
        ticket_id: filters.ticket_id,
        start_date: filters.startDate,
        end_date: filters.endDate,
        location: filters.location,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 0) + 1,
        sort_by: filters.sort_by,
        order: filters.order,
        created_at: filters.created_at,
      }
      return fetch(`/api/tickets/?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveTickets(json)))
        .catch(error => dispatch(failureTickets(error)))
    }
    return false
  }
}

export function fetchTicketsCSV(filters = {}, callback) {
  return (dispatch) => {
    if (dispatch(requestTicketsCSV(filters))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        location: filters.location,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 0) + 1,
        sort_by: filters.sort_by,
        order: filters.order,
        all: true,
      }
      return fetch(`/api/tickets/?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then((json) => {
          dispatch(receiveTicketsCSV(json))
          callback()
        })
        .catch(error => dispatch(failureTicketsCSV(error)))
    }
    return false
  }
}

export function fetchTicket(ticketId) {
  return (dispatch) => {
    if (dispatch(requestTicket(ticketId))) {
      return fetch(`/api/tickets/${ticketId}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveTicket(json)))
        .catch(error => dispatch(failureTicket(error)))
    }
    return false
  }
}
