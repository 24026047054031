export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE'
function toggleDark() {
  return {
    type: TOGGLE_DARK_MODE,
  }
}

export function fetchToggleDarkMode() {
  return (dispatch) => {
    dispatch(toggleDark())
  }
}
