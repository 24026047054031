/*
  This modal component is controlled and technically stateless.
  Its open state and onClose handler must come from a parent component.
  The parent component must also specify the title and content of the modal.
  The component expects that the content will be another component.
  You can specify the max width of the modal with the maxWidth prop
  but it defaults to 'lg'.
  The modal currently employs a 'slide up' transition effect. This could be easily
  changed to be dynamic and passed in as a prop. But for now, this works just fine.
*/
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import SlideUpTransition from './SlideUpTransition'

const SlideUpModal = memo((props) => {
  const {
    open, onClose, childComponent, maxWidth, title,
  } = props

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideUpTransition}
      onClose={() => onClose(false)}
      maxWidth={maxWidth}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {childComponent}
      </DialogContent>
    </Dialog>
  )
})

// maxWidth prop must be one of 'sm', 'md', 'lg', 'xl', 'xs', or false
// Set to false to disable maxWidth.
SlideUpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  childComponent: PropTypes.instanceOf(Object),
  maxWidth: PropTypes.string,
  title: PropTypes.string.isRequired,
}

SlideUpModal.defaultProps = {
  childComponent: {},
  maxWidth: 'lg',
}

export default SlideUpModal
