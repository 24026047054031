import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import CircularProgressSpinner from './CircularProgressSpinner'
import NeatGraph from './NeatGraph'
import { fetchGatewaysThroughput } from '../actions/GatewaysActions'
import DateFilters from './DateFilters'
import {
  formatData,
  graphHeight,
  graphMargins,
  xAxisFormat,
  xAxisLabel,
} from '../helpers/GatewayHelper'


export default class ThroughputGraph extends React.Component {
  // fetch data for the default timeframe when the component first renders
  componentDidMount() {
    this.fetchThroughputData()
  }

  // fetches new throughput data for the site and given timeframe
  fetchThroughputData = (startDate, endDate) => {
    const { dispatch, siteId } = this.props
    dispatch(fetchGatewaysThroughput(siteId, { startDate, endDate }))
  }

  render() {
    const { data, expanded, toggleExpanded } = this.props
    return (
      <React.Fragment>
        <Grid container>
          { expanded && <DateFilters onFilter={this.fetchThroughputData} xs={2} /> }
          <Grid item xs={expanded ? 10 : 12}>
            { data.length >= 2 ? (
              <NeatGraph
                title="Device Throughput"
                expanded={expanded}
                toggleExpanded={() => toggleExpanded()}
                color={[data[0].color, data[1].color]}
                label={[data[0].legend, data[1].legend]}
                type={['line', 'line']}
                data={formatData(data, 'throughput')}
                height={graphHeight(expanded)}
                xAxisLabel={xAxisLabel(expanded)}
                xFormat={val => (xAxisFormat(val, expanded))}
                yFormat={val => `${val.toFixed(1)} MB`}
                yDomainMargin={[0, 0.5]}
                margin={graphMargins(expanded)}
                showExpandButton
                tickLabelAngle={-45}
              />
            ) : (
              <React.Fragment>
                <CircularProgressSpinner />
                <Typography variant="body1">Loading...</Typography>
              </React.Fragment>
            )}

          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

ThroughputGraph.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  dispatch: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
}
