import HandleTimeout from '../helpers/SessionHelper'
import NeatPermissions from '../helpers/NeatPermissions'
import { SET_ERROR } from './ErrorActions'

const querystring = require('querystring')

export const REQUEST_SITES = 'REQUEST_SITES'
function requestSites() {
  return {
    type: REQUEST_SITES,
    requiredPermission: NeatPermissions.sites.index,
  }
}

export const RECEIVE_SITES = 'RECEIVE_SITES'
function receiveSites(sites) {
  return {
    type: RECEIVE_SITES,
    sites,
  }
}

function failureSites(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_NINETY_DAY_ACTIVATIONS = 'REQUEST_NINETY_DAY_ACTIVATIONS'
function requestNinetyDayActivations() {
  return {
    type: REQUEST_NINETY_DAY_ACTIVATIONS,
    requiredPermission: NeatPermissions.sites.ninety_day_activations,
  }
}

export const RECEIVE_NINETY_DAY_ACTIVATIONS = 'RECEIVE_NINETY_DAY_ACTIVATIONS'
function receiveNinetyDayActivations(ninetyDaySites) {
  return {
    type: RECEIVE_NINETY_DAY_ACTIVATIONS,
    ninetyDaySites,
  }
}

export const FAILURE_NINETY_DAY_ACTIVATIONS = 'FAILURE_NINETY_DAY_ACTIVATIONS'
function failureNinetyDayActivations(error) {
  return {
    type: FAILURE_NINETY_DAY_ACTIVATIONS,
    error,
  }
}

export const REQUEST_SITE = 'REQUEST_SITE'
function requestSite(siteId) {
  return {
    type: REQUEST_SITE,
    requiredPermission: NeatPermissions.sites.show,
    siteId,
  }
}

export const RECEIVE_SITE = 'RECEIVE_SITE'
function receiveSite(site) {
  return {
    type: RECEIVE_SITE,
    site,
  }
}

function failureSite(error) {
  return {
    type: SET_ERROR,
    error,
  }
}


export const REQUEST_GATEWAY_DETAILS = 'REQUEST_GATEWAY_DETAILS'
function requestGatewayDetails(siteId) {
  return {
    type: REQUEST_GATEWAY_DETAILS,
    requiredPermission: NeatPermissions.sites.gateways,
    siteId,
  }
}

export const RECEIVE_GATEWAY_DETAILS = 'RECEIVE_GATEWAY_DETAILS'
function receiveGatewayDetails(gatewayDetails) {
  return {
    type: RECEIVE_GATEWAY_DETAILS,
    gatewayDetails,
  }
}

function failureGatewayDetails(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_TICKETS = 'REQUEST_TICKETS'
function requestTickets(siteId, ticketFilters) {
  return {
    type: REQUEST_TICKETS,
    requiredPermission: NeatPermissions.sites.tickets,
    siteId,
    ticketFilters,
  }
}

export const RECEIVE_TICKETS = 'RECEIVE_TICKETS'
function receiveTickets(tickets) {
  return {
    type: RECEIVE_TICKETS,
    tickets,
  }
}

function failureTickets(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_TICKETS_CSV = 'REQUEST_TICKETS_CSV'
function requestTicketsCsv(siteId, ticketFilters) {
  return {
    type: REQUEST_TICKETS_CSV,
    requiredPermission: NeatPermissions.sites.tickets,
    siteId,
    ticketFilters,
  }
}

export const RECEIVE_TICKETS_CSV = 'RECEIVE_TICKETS_CSV'
function receiveTicketsCsv(ticketsCsv) {
  return {
    type: RECEIVE_TICKETS_CSV,
    ticketsCsv,
  }
}

export const FAILURE_TICKETS_CSV = 'FAILURE_TICKETS_CSV'
function failureTicketsCsv(error) {
  return {
    type: FAILURE_TICKETS_CSV,
    error,
  }
}

export const REQUEST_NINETY_DAY_TICKETS = 'REQUEST_NINETY_DAY_TICKETS'
function requestNinetyDayTickets(siteId, ticketFilters) {
  return {
    type: REQUEST_NINETY_DAY_TICKETS,
    requiredPermission: NeatPermissions.sites.tickets,
    siteId,
    ticketFilters,
  }
}

export const RECEIVE_NINETY_DAY_TICKETS = 'RECEIVE_NINETY_DAY_TICKETS'
function receiveNinetyDayTickets(ninetyDayTickets) {
  return {
    type: RECEIVE_NINETY_DAY_TICKETS,
    ninetyDayTickets,
  }
}

export const FAILURE_NINETY_DAY_TICKETS = 'FAILURE_NINETY_DAY_TICKETS'
function failureNinetyDayTickets(error) {
  return {
    type: FAILURE_NINETY_DAY_TICKETS,
    error,
  }
}

export const REQUEST_NINETY_DAY_TICKETS_CSV = 'REQUEST_NINETY_DAY_TICKETS_CSV'
function requestNinetyDayTicketsCsv(siteId, ticketFilters) {
  return {
    type: REQUEST_NINETY_DAY_TICKETS_CSV,
    requiredPermission: NeatPermissions.sites.tickets,
    siteId,
    ticketFilters,
  }
}

export const RECEIVE_NINETY_DAY_TICKETS_CSV = 'RECEIVE_NINETY_DAY_TICKETS_CSV'
function receiveNinetyDayTicketsCsv(ninetyDayTicketsCsv) {
  return {
    type: RECEIVE_NINETY_DAY_TICKETS_CSV,
    ninetyDayTicketsCsv,
  }
}

export const FAILURE_NINETY_DAY_TICKETS_CSV = 'FAILURE_NINETY_DAY_TICKETS_CSV'
function failureNinetyDayTicketsCsv(error) {
  return {
    type: FAILURE_NINETY_DAY_TICKETS_CSV,
    error,
  }
}

export const REQUEST_DEVICE_DETAILS = 'REQUEST_DEVICE_DETAILS'
function requestDeviceDetails(siteId) {
  return {
    type: REQUEST_DEVICE_DETAILS,
    requiredPermission: NeatPermissions.sites.devices,
    siteId,
  }
}

export const RECEIVE_DEVICE_DETAILS = 'RECEIVE_DEVICE_DETAILS'
function receiveDeviceDetails(json) {
  return {
    type: RECEIVE_DEVICE_DETAILS,
    devicesDetails: {
      ...json,
      alertStateMapping: json.alertStateMapping,
    },
  }
}

function failureDeviceDetails(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_DEVICE_PING = 'REQUEST_DEVICE_PING'
function requestDevicePing(deviceId) {
  return {
    type: REQUEST_DEVICE_PING,
    requiredPermissions: NeatPermissions.sites.devices,
    deviceId,
  }
}

export const RECEIVE_DEVICE_PING = 'RECEIVE_DEVICE_PING'
function receiveDevicePing(pingDetails) {
  return {
    type: RECEIVE_DEVICE_PING,
    pingDetails,
  }
}

export const FAILURE_DEVICE_PING = 'FAILURE_DEVICE_PING'
function failureDevicePing(error) {
  return {
    type: FAILURE_DEVICE_PING,
    error,
  }
}

export const REQUEST_DEVICE_PDSH_PING = 'REQUEST_DEVICE_PDSH_PING'
function requestDevicePdshPing(deviceId) {
  return {
    type: REQUEST_DEVICE_PDSH_PING,
    requiredPermissions: NeatPermissions.sites.devices,
    deviceId,
  }
}

export const RECEIVE_DEVICE_PDSH_PING = 'RECEIVE_DEVICE_PDSH_PING'
function receiveDevicePdshPing(pingDetails) {
  return {
    type: RECEIVE_DEVICE_PDSH_PING,
    pingDetails,
  }
}

export const FAILURE_DEVICE_PDSH_PING = 'FAILURE_DEVICE_PDSH_PING'
function failureDevicePdshPing(error) {
  return {
    type: FAILURE_DEVICE_PDSH_PING,
    error,
  }
}

export const REQUEST_DOCUMENTS = 'REQUEST_DOCUMENTS'
function requestDocuments(siteId) {
  return {
    type: REQUEST_DOCUMENTS,
    requiredPermission: NeatPermissions.sites.documents,
    siteId,
  }
}

export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS'
function receiveDocuments(documents) {
  return {
    type: RECEIVE_DOCUMENTS,
    documents,
  }
}

function failureDocuments(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_CLIENT_USAGE_METRICS = 'REQUEST_CLIENT_USAGE_METRICS'
function requestClientUsageMetrics(siteId, dateFilter) {
  return {
    type: REQUEST_CLIENT_USAGE_METRICS,
    requiredPermission: NeatPermissions.sites.client_reporting,
    siteId,
    dateFilter,
  }
}

export const RECEIVE_CLIENT_USAGE_METRICS = 'RECEIVE_CLIENT_USAGE_METRICS'
function receiveClientUsageMetrics(json) {
  return {
    type: RECEIVE_CLIENT_USAGE_METRICS,
    clientUsageMetrics: {
      ...json,
    },
  }
}

export const FAILURE_CLIENT_USAGE_METRICS = 'FAILURE_CLIENT_USAGE_METRICS'
function failureClientUsageMetrics(error) {
  return {
    type: FAILURE_CLIENT_USAGE_METRICS,
    error,
  }
}

export const REQUEST_RETURNING_CLIENT_METRICS = 'REQUEST_RETURNING_CLIENT_METRICS'
function requestReturningClientMetrics(siteId, dateFilter) {
  return {
    type: REQUEST_RETURNING_CLIENT_METRICS,
    requiredPermission: NeatPermissions.sites.client_reporting,
    siteId,
    dateFilter,
  }
}

export const RECEIVE_RETURNING_CLIENT_METRICS = 'RECEIVE_RETURNING_CLIENT_METRICS'
function receiveReturningClientMetrics(json) {
  return {
    type: RECEIVE_RETURNING_CLIENT_METRICS,
    returningClientMetrics: {
      ...json,
    },
  }
}

export const FAILURE_RETURNING_CLIENT_METRICS = 'FAILURE_RETURNING_CLIENT_METRICS'
function failureReturningClientMetrics(error) {
  return {
    type: FAILURE_RETURNING_CLIENT_METRICS,
    error,
  }
}


export const REQUEST_CLIENT_DEVICE_TYPE_PERCENTAGES = 'REQUEST_CLIENT_DEVICE_TYPE_PERCENTAGES'
function requestClientDeviceTypePercentages(siteId, dateFilter) {
  return {
    type: REQUEST_CLIENT_DEVICE_TYPE_PERCENTAGES,
    requiredPermission: NeatPermissions.sites.client_reporting,
    siteId,
    dateFilter,
  }
}

export const RECEIVE_CLIENT_DEVICE_TYPE_PERCENTAGES = 'RECEIVE_CLIENT_DEVICE_TYPE_PERCENTAGES'
function receiveClientDeviceTypePercentages(json) {
  return {
    type: RECEIVE_CLIENT_DEVICE_TYPE_PERCENTAGES,
    clientDeviceTypePercentages: {
      ...json,
    },
  }
}

export const FAILURE_CLIENT_DEVICE_TYPE_PERCENTAGES = 'FAILURE_CLIENT_DEVICE_TYPE_PERCENTAGES'
function failureClientDeviceTypePercentages(error) {
  return {
    type: FAILURE_CLIENT_DEVICE_TYPE_PERCENTAGES,
    error,
  }
}

export const REQUEST_CLIENT_SESSION_DETAILS = 'REQUEST_CLIENT_SESSION_DETAILS'
function requestClientSessionDetails(siteId, dateFilter) {
  return {
    type: REQUEST_CLIENT_SESSION_DETAILS,
    requiredPermission: NeatPermissions.sites.client_reporting,
    siteId,
    dateFilter,
  }
}

export const RECEIVE_CLIENT_SESSION_DETAILS = 'RECEIVE_CLIENT_SESSION_DETAILS'
function receiveClientSessionDetails(json) {
  return {
    type: RECEIVE_CLIENT_SESSION_DETAILS,
    clientSessionDetails: {
      ...json,
    },
  }
}

export const FAILURE_CLIENT_SESSION_DETAILS = 'FAILURE_CLIENT_SESSION_DETAILS'
function failureClientSessionDetails(error) {
  return {
    type: FAILURE_CLIENT_SESSION_DETAILS,
    error,
  }
}

export const REQUEST_OPEN_CLOSED_TICKET_COUNTS = 'REQUEST_OPEN_CLOSED_TICKET_COUNTS'
function requestOpenClosedTicketCounts(siteId) {
  return {
    type: REQUEST_OPEN_CLOSED_TICKET_COUNTS,
    requiredPermission: NeatPermissions.tickets.open_closed_tickets_graph,
    siteId,
  }
}

export const RECEIVE_OPEN_CLOSED_TICKET_COUNTS = 'RECEIVE_OPEN_CLOSED_TICKET_COUNTS'
function receiveOpenClosedTicketCounts(json) {
  return {
    type: RECEIVE_OPEN_CLOSED_TICKET_COUNTS,
    openClosedTicketCounts: json,
  }
}

function failureOpenClosedTicketCounts(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export function fetchSites() {
  return (dispatch) => {
    if (dispatch(requestSites())) {
      return fetch('/api/sites')
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveSites(json)))
        .catch(error => dispatch(failureSites(error)))
    }
    return false
  }
}

export function fetchNinetyDayActivations() {
  return (dispatch) => {
    if (dispatch(requestNinetyDayActivations())) {
      const params = {
        ninety_day_activations: true,
      }
      return fetch(`/api/sites?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveNinetyDayActivations(json)))
        .catch(error => dispatch(failureNinetyDayActivations(error)))
    }
    return false
  }
}

export function fetchSite(siteId) {
  return (dispatch) => {
    if (dispatch(requestSite(siteId))) {
      return fetch(`/api/sites/${siteId}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveSite(json))
          } else {
            dispatch(failureSite(json))
          }
        })
    }
    return false
  }
}

export function fetchGatewayDetails(siteId) {
  return (dispatch) => {
    if (dispatch(requestGatewayDetails(siteId))) {
      return fetch(`/api/sites/${siteId}/gateways`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveGatewayDetails(json))
          } else {
            dispatch(failureGatewayDetails(json))
          }
        })
    }
    return false
  }
}

export function fetchTickets(siteId, filters = {}) {
  return (dispatch) => {
    if (dispatch(requestTickets(siteId, filters))) {
      const params = {
        ticket_id: filters.ticket_id,
        start_date: filters.startDate,
        end_date: filters.endDate,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 1),
        sort_by: filters.sort_by,
        order: filters.order,
        building_apartment_or_room_number: filters.building_apartment_or_room_number,
        grouped: true,
      }
      return fetch(`/api/sites/${siteId}/tickets?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveTickets(json))
          } else {
            dispatch(failureTickets(json))
          }
        })
    }
    return false
  }
}

export function fetchTicketsCsv(siteId, filters = {}, callback) {
  return (dispatch) => {
    if (dispatch(requestTicketsCsv(siteId, filters))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 0) + 1,
        sort_by: filters.sort_by,
        order: filters.order,
        building_apartment_or_room_number: filters.building_apartment_or_room_number,
        grouped: true,
        all: true,
      }
      return fetch(`/api/sites/${siteId}/tickets?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then((json) => {
          dispatch(receiveTicketsCsv(json))
          callback()
        })
        .catch(error => dispatch(failureTicketsCsv(error)))
    }
    return false
  }
}

export function fetchNinetyDayTickets(siteId, filters = {}) {
  return (dispatch) => {
    if (dispatch(requestNinetyDayTickets(siteId, filters))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 0) + 1,
        sort_by: filters.sort_by,
        order: filters.order,
        grouped: false,
      }
      return fetch(`/api/sites/${siteId}/tickets?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveNinetyDayTickets(json)))
        .catch(error => dispatch(failureNinetyDayTickets(error)))
    }
    return false
  }
}

export function fetchNinetyDayTicketsCsv(siteId, filters = {}, callback) {
  return (dispatch) => {
    if (dispatch(requestNinetyDayTicketsCsv(siteId, filters))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        priority: filters.priority,
        status: filters.status,
        page: (filters.page || 0) + 1,
        sort_by: filters.sort_by,
        order: filters.order,
        grouped: false,
        all: true,
      }
      return fetch(`/api/sites/${siteId}/tickets?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then((json) => {
          dispatch(receiveNinetyDayTicketsCsv(json))
          callback()
        })
        .catch(error => dispatch(failureNinetyDayTicketsCsv(error)))
    }
    return false
  }
}

export function fetchDevicesDetails(siteId) {
  return (dispatch) => {
    if (dispatch(requestDeviceDetails(siteId))) {
      return fetch(`/api/sites/${siteId}/devices`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveDeviceDetails(json))
          } else {
            dispatch(failureDeviceDetails(json))
          }
        })
    }
    return false
  }
}

export function fetchDevicePing(siteId, deviceId) {
  return (dispatch) => {
    if (dispatch(requestDevicePing(siteId, deviceId))) {
      const params = {
        device_id: deviceId,
      }
      return fetch(`/api/sites/${siteId}/ping?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(json => dispatch(receiveDevicePing(json[0])))
        .catch(error => dispatch(failureDevicePing(error)))
    }
    return false
  }
}

export function fetchDevicePdshPing(siteId, deviceId) {
  return (dispatch) => {
    if (dispatch(requestDevicePdshPing(siteId, deviceId))) {
      const params = {
        device_id: deviceId,
      }
      return fetch(`/api/sites/${siteId}/pdsh_ping_device?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveDevicePdshPing(json[0])))
        .catch(error => dispatch(failureDevicePdshPing(error)))
    }
    return false
  }
}

export function fetchDocuments(siteId) {
  return (dispatch) => {
    if (dispatch(requestDocuments(siteId))) {
      return fetch(`/api/sites/${siteId}/documents`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveDocuments(json))
          } else {
            dispatch(failureDocuments(json))
          }
        })
    }
    return false
  }
}

export function fetchClientUsageMetrics(siteId, dateFilter = {}) {
  return (dispatch) => {
    if (dispatch(requestClientUsageMetrics(siteId, dateFilter))) {
      const params = {
        start_date: dateFilter.start_date.toLocaleDateString(),
        end_date: dateFilter.end_date.toLocaleDateString(),
      }
      return fetch(`/api/sites/${siteId}/client_usage_metrics?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveClientUsageMetrics(json)))
        .catch(error => dispatch(failureClientUsageMetrics(error)))
    }
    return false
  }
}

export function fetchReturningClientMetrics(siteId, dateFilter = {}) {
  return (dispatch) => {
    if (dispatch(requestReturningClientMetrics(siteId, dateFilter))) {
      const params = {
        start_date: dateFilter.start_date.toLocaleDateString(),
        end_date: dateFilter.end_date.toLocaleDateString(),
      }
      return fetch(`/api/sites/${siteId}/returning_client_metrics?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveReturningClientMetrics(json)))
        .catch(error => dispatch(failureReturningClientMetrics(error)))
    }
    return false
  }
}

export function fetchClientDeviceTypePercentages(siteId, dateFilter = {}) {
  return (dispatch) => {
    if (dispatch(requestClientDeviceTypePercentages(siteId, dateFilter))) {
      const params = {
        start_date: dateFilter.start_date.toLocaleDateString(),
        end_date: dateFilter.end_date.toLocaleDateString(),
      }
      return fetch(`/api/sites/${siteId}/client_device_type_percentages?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveClientDeviceTypePercentages(json)))
        .catch(error => dispatch(failureClientDeviceTypePercentages(error)))
    }
    return false
  }
}

export function fetchClientSessionDetails(siteId, dateFilter = {}) {
  return (dispatch) => {
    if (dispatch(requestClientSessionDetails(siteId, dateFilter))) {
      const params = {
        start_date: dateFilter.start_date.toLocaleDateString(),
        end_date: dateFilter.end_date.toLocaleDateString(),
      }
      return fetch(`/api/sites/${siteId}/client_session_details?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json())
        .then(json => dispatch(receiveClientSessionDetails(json)))
        .catch(error => dispatch(failureClientSessionDetails(error)))
    }
    return false
  }
}

export function fetchOpenClosedTicketCounts(siteId) {
  return (dispatch) => {
    if (dispatch(requestOpenClosedTicketCounts(siteId))) {
      return fetch(`/api/sites/${siteId}/open_closed_ticket_counts?`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            const headers = json.shift()
            // this here is required in order to get the tooltip for the graph
            // to not format in a ridiculous way. Please dont remove this unless
            // you find a better way to make the tooltip format
            json.map((array) => {
              const date = array[0]
              const [year, month, day] = date.split('-').map(datePart => parseInt(datePart, 10))
              return array.splice(0, 1, new Date(year, month - 1, day))
            })
            json.unshift(headers)
            dispatch(receiveOpenClosedTicketCounts(json))
          } else {
            dispatch(failureOpenClosedTicketCounts(json))
          }
        })
    }
    return false
  }
}
