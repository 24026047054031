/*
  This modal component is controlled and technically stateless.
  Its open state and onClose handler must come from a parent component.
  The parent component must also specify the title and content of the modal.
  The component expects that the content will be another component.
  You CAN NOT specify the max width of this modal as it is full screen only.
  If you want a non full screen modal, check out SlideUpModal.js
  The modal currently employs a 'slide up' transition effect. This could be easily
  changed to be dynamic and passed in as a prop. But for now, this works just fine.
*/
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SlideUpTransition from './SlideUpTransition'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  devicesContainer: {
    marginTop: '4rem',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const FullScreenModal = memo((props) => {
  const {
    open, onClose, childComponent, title,
  } = props
  const classes = useStyles()
  return (
    <Dialog
      fullScreen
      TransitionComponent={SlideUpTransition}
      open={open}
      onClose={() => onClose(false)}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onClose(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>{title}</Typography>
        </Toolbar>
      </AppBar>
      {childComponent}
    </Dialog>
  )
})

FullScreenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  childComponent: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
}

export default FullScreenModal
