import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
}))

const timezoneOptions = () => moment.tz.names().map(tz => tz)

export default function TimezoneInput({ disabled, timezone, onChange }) {
  const classes = useStyles()
  return (
    <FormControl disabled={disabled} className={classes.formControl}>
      <InputLabel>Timezone</InputLabel>
      <Select
        name="Timezone"
        value={timezone || ''}
        onChange={onChange}
      >
        { timezoneOptions().map(tzString => (
          <MenuItem key={tzString} value={tzString}>{tzString}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

TimezoneInput.propTypes = {
  disabled: PropTypes.bool,
  timezone: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

TimezoneInput.defaultProps = {
  disabled: false,
  timezone: '',
}
