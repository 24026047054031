import {
  REQUEST_GRAPH_DATA,
  RECEIVE_GRAPH_DATA,
} from '../actions/MonitoredDeviceActions'

export default (
  state = {
    graphdata: {},
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_GRAPH_DATA:
      return Object.assign({}, state, {
        graphData: action.graphData,
      })
    case REQUEST_GRAPH_DATA:
    default:
      return state
  }
}
