import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '2.5rem',
    background: theme.palette.primary.main,
    borderRadius: 10,
  },

  font: {
    fontFamily: 'sans-serif',
    color: theme.palette.secondary.main,
  },

  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
}))

const formatSessionMinutes = (minutes) => {
  if (minutes !== undefined) {
    const hoursDecimal = String(minutes / 60).split('.')
    let mins = `.${hoursDecimal[1]}`
    mins = Math.ceil((mins * 10) * 6)
    return `${hoursDecimal[0]} Hours ${mins} Minutes`
  }
  return 'N/A'
}

export default function AverageSessionTime(props) {
  const classes = useStyles()
  const { avgSessionTime } = props
  return (
    <div>
      <h4 className={classes.header}>Average Session Time</h4>
      <Paper className={classes.root}>
        <h4 className={classes.font}>{formatSessionMinutes(avgSessionTime)}</h4>
      </Paper>
    </div>
  )
}

AverageSessionTime.propTypes = {
  avgSessionTime: PropTypes.string,
}

AverageSessionTime.defaultProps = {
  avgSessionTime: undefined,
}
