import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import {
  Grid, Paper, Checkbox,
  FormLabel, FormControl, FormGroup,
  FormControlLabel, Select, MenuItem,
  Button,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

// I dont like having this big constant in this file but I can't think
// of anyting better. I could export it from a different file but this
// is only used in this file so there's not really any point in doing that.
const predefinedTimeframeOptions = () => {
  const endDate = moment().format()
  return [
    {
      id: 'last_hour',
      label: 'Last Hour',
      startDate: moment().subtract(1, 'hour').format(),
      endDate,
    },
    {
      id: 'last_2_hours',
      label: 'Last 2 Hours',
      startDate: moment().subtract(2, 'hours').format(),
      endDate,
    },
    {
      id: 'last_5_hours',
      label: 'Last 5 Hours',
      startDate: moment().subtract(5, 'hours').format(),
      endDate,
    },
    {
      id: 'last_8_hours',
      label: 'Last 8 Hours',
      startDate: moment().subtract(8, 'hours').format(),
      endDate,
    },
    {
      id: 'last_12_hours',
      label: 'Last 12 Hours',
      startDate: moment().subtract(12, 'hours').format(),
      endDate,
    },
    {
      id: 'last_24_hours',
      label: 'Last 24 Hours',
      startDate: moment().subtract(1, 'day').format(),
      endDate,
    },
    {
      id: 'last_2_days',
      label: 'Last 2 Days',
      startDate: moment().subtract(2, 'days').format(),
      endDate,
    },
    {
      id: 'last_7_days',
      label: 'Last 7 Days',
      startDate: moment().subtract(7, 'days').format(),
      endDate,
    },
    {
      id: 'last_30_days',
      label: 'Last 30 Days',
      startDate: moment().subtract(30, 'days').format(),
      endDate,
    },
    {
      id: 'last_3_months',
      label: 'Last 3 Months',
      startDate: moment().subtract(3, 'months').format(),
      endDate,
    },
    {
      id: 'last_year',
      label: 'Last Year',
      startDate: moment().subtract(1, 'year').format(),
      endDate,
    },
  ]
}

const useStyles = makeStyles(theme => ({
  filterButton: {
    width: 'fit-content',
    marginTop: theme.spacing(2),
  },

  fieldSet: {
    margin: theme.spacing(3),
    width: '80%',
  },
  datePickerContainer: {
    marginLeft: theme.spacing(6),
  },
}))

/*
  onFilter is a callback/handler prop that is used by the filter button.
  This component keeps its own state for the start/end dates as well as
  which filter method (default, predefined, custom) is being used.
  The default range is 24 hours.
  There are several predefined time ranges defined by predefinedTimeframeOptions.
  There is also a custom range filter option.
  Only one of the filtering methods can be active at a given time.
  The predefined select box controls the start/end custom filters
  by changing the component's state.
*/
export default function DateFilters({ onFilter, xs }) {
  const classes = useStyles()
  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'days').format())
  const [endDate, setEndDate] = React.useState(moment().format())
  const [filterMethod, setFilterMethod] = React.useState('default')
  const [selectedPredefinedFilter, setSelectedPredefinedFilter] = React.useState('')

  /*
    this function handles when the user changes the
    filtering method between default, predefined, and custom
  */
  const handleFilterMethodChange = (event) => {
    // event target is one of the checkboxes
    setFilterMethod(event.target.name)
  }

  /*
    When the user checks the default checkbox, we need to
    reset the filters back to the default 24 hour window
    in case they previously selected one of the other methods.
    This is because the start/end date state will still remain
    whatever they had last selected.
  */
  const handleDefaultChecked = (event) => {
    setStartDate(moment().subtract(1, 'days').format())
    setEndDate(moment().format())
    setFilterMethod(event.target.name)
  }

  const handlePredefinedFilterChanged = (event) => {
    // when they change the predefined filter, find the corresponding object
    // in the predefinedTimeframeOptions in order to get at the start and
    // end dates that were defined for the given time range

    // predefinedTimeframeOptions returns an array of objects
    const predefinedOptions = predefinedTimeframeOptions()
    const selectedOption = predefinedOptions.find(timeframe => timeframe.id === event.target.value)
    setStartDate(selectedOption.startDate)
    setEndDate(selectedOption.endDate)
    setSelectedPredefinedFilter(event.target.value)
  }

  /*
    material ui keyboard date picker input only provides
    a date and not an event as an argument so we need
    two different handlers for the start / end date custom inputs.
  */
  const handleStartDateChanged = (date) => {
    setStartDate(moment(date).format())
  }

  const handleEndDateChanged = (date) => {
    setEndDate(moment(date).format())
  }

  const renderPredefinedFilter = () => (
    <Select
      className={classes.select}
      value={selectedPredefinedFilter}
      onChange={handlePredefinedFilterChanged}
      displayEmpty
      disabled={filterMethod !== 'predefined'}
    >
      <MenuItem value="" disabled>
        Select A Timeframe
      </MenuItem>
      { predefinedTimeframeOptions().map(timeframe => (
        <MenuItem key={timeframe.id} value={timeframe.id}>
          { timeframe.label }
        </MenuItem>
      ))}
    </Select>
  )

  const renderCustomFilters = () => (
    <Grid className={classes.datePickerContainer} container justify="flex-start" style={{ marginTop: '1rem' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          disabled={filterMethod !== 'custom'}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          label="Start Date"
          name="startDate"
          value={startDate}
          onChange={date => handleStartDateChanged(date)}
          KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
        />
        <KeyboardDatePicker
          autoOk
          disabled={filterMethod !== 'custom'}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          label="End Date"
          name="endDate"
          value={endDate}
          onChange={date => handleEndDateChanged(date)}
          KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )

  return (
    <Grid item xs={xs}>
      <Paper>
        <FormControl component="fieldset" className={classes.fieldSet}>
          <FormLabel component="legend">Filter By Date</FormLabel>
          <FormGroup>
            <Button className={classes.filterButton} variant="outlined" color="primary" onClick={() => onFilter(startDate, endDate)}>Filter</Button>
            <FormControlLabel
              control={<Checkbox checked={filterMethod === 'default'} onChange={handleDefaultChecked} name="default" />}
              label="Default Time Range (24 Hours)"
            />
            <Grid alignContent="flex-start" container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={filterMethod === 'predefined'} onChange={handleFilterMethodChange} name="predefined" />}
                  label="Predefined Time Range:"
                />
                { renderPredefinedFilter() }
              </Grid>

            </Grid>

            <FormControlLabel
              control={<Checkbox checked={filterMethod === 'custom'} onChange={handleFilterMethodChange} name="custom" />}
              label="Custom Date Range:"
            />
            { renderCustomFilters() }
          </FormGroup>
        </FormControl>
      </Paper>

    </Grid>
  )
}

DateFilters.propTypes = {
  onFilter: PropTypes.func.isRequired,
  xs: PropTypes.number,
}

DateFilters.defaultProps = {
  xs: 2,
}
