import {
  TOGGLE_DARK_MODE,
} from '../actions/DarkModeActions'

export default (
  state = {
    useDarkMode: false,
  },
  action,
) => {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return Object.assign({}, { useDarkMode: !state.useDarkMode })
    default:
      return state
  }
}
