import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import DateFilters from './DateFilters'
import NeatGraph from './NeatGraph'
import { Titleize } from '../helpers/StringHelper'
import { fetchGraphData } from '../actions/MonitoredDeviceActions'


class MonitoredDeviceGraph extends React.Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData = (startDate, endDate) => {
    // deviceId here is the CPK compatible ID, that's why it's an array in the PropTypes
    const { dispatch, deviceId } = this.props
    dispatch(fetchGraphData(deviceId, { startDate, endDate }))
  }

  determineSeriesType = line => (
    // Why does LM say it's a 'stack' graph when it's a line graph?
    line.type === 'Stack' ? 'area' : line.type.toLowerCase()
  )

  render() {
    const { graphData } = this.props
    let { lines } = graphData

    if (lines && lines.length) {
      lines = lines.filter(line => !['lastSeen', 'rx', 'tx', 'txRx', 'capacity', 'capacity50G'].includes(line.legend))
    }

    return (
      <Grid container>
        <DateFilters onFilter={this.fetchData} xs={4} />
        { Object.keys(graphData).length > 0 ? (
          <Grid item xs={8}>
            <NeatGraph
              title={Titleize(graphData.title)}
              color={lines.map(line => line.color)}
              label={lines.map(line => line.legend)}
              type={lines.map(line => this.determineSeriesType(line))}
              data={
                lines.map(line => (
                  line.data.map(dataHash => ({
                    x: moment(dataHash.x, 'yyyy-MM-DD HH:mm:ss Z').valueOf(),
                    y: dataHash.y,
                  }))
                ))
              }
              height={500}
              width={1000}
              xAxisLabel="Date"
              xFormat={val => moment(val).format('MM/DD hh:mm a')}


              margin={{ left: 60, bottom: 80 }}
              tickLabelAngle={-45}
            />
          </Grid>
        ) : (
          <Grid style={{ marginLeft: 'auto', marginRight: 'auto' }} item xs={6}>
            <Typography variant="h4">
              No Graph Data Available.
            </Typography>
          </Grid>
        )}
      </Grid>

    )
  }
}

MonitoredDeviceGraph.propTypes = {
  deviceId: PropTypes.instanceOf(Array).isRequired,
  dispatch: PropTypes.func.isRequired,
  graphData: PropTypes.instanceOf(Object),
}

MonitoredDeviceGraph.defaultProps = {
  graphData: {},
}

function mapStateToProps(state) {
  const { monitoredDevicesReducer } = state
  const { graphData } = monitoredDevicesReducer
  return { graphData }
}

export default compose(
  connect(mapStateToProps),
)(MonitoredDeviceGraph)
