import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import {
  Grid, Chip, Box, Typography, Paper,
} from '@material-ui/core'

import ChipStyles from '../styles/ChipStyles'

import SiteDetailsProptype from '../proptypes/SiteDetailsProptype'
import NeatPermissions from '../helpers/NeatPermissions'

const styles = () => ({
  title: {
    color: 'grey',
  },
  detailKey: {
    float: 'right',
    fontSize: 'smaller',
  },
  detailValue: {
    marginLeft: '.5rem',
    fontSize: 'smaller',
  },
})

/*
  LocationDetails
  Component which renders the 3 different location details widgets
 */
class LocationDetails extends React.PureComponent {
  technicalInformationObject = () => {
    const { details } = this.props
    return [
      { label: 'Provider', info: details.service_provider, permission: NeatPermissions.sites.show },
      { label: 'Download (kbps)', info: details.download_kbps, permission: NeatPermissions.sites.show },
      { label: 'Wireless Speed', info: details.end_user_speed, permission: NeatPermissions.sites.show },
      { label: 'Wired Speed', info: details.wired_port_end_user_speed, permission: NeatPermissions.sites.show },
      { label: 'Segment', info: details.segment, permission: NeatPermissions.sites.show },
      { label: 'Property Mgmt Portal', info: details.property_authentication_portal, permission: NeatPermissions.sites.property_authentication_portal },
      { label: 'Device Manager Portal', info: details.resident_portal, permission: NeatPermissions.sites.resident_portal },
      { label: 'End User Auth Method', info: details.end_user_authentication_method, permission: NeatPermissions.sites.end_user_authentication_method },
      { label: 'Admin Con. Network', info: details.admin_connectivity_network, permission: NeatPermissions.sites.admin_connectivity_network },
      { label: 'MGMT Market Area', info: details.market_area, permission: NeatPermissions.sites.mgmt_market_area },
      { label: 'Region', info: details.customer_region, permission: NeatPermissions.sites.region },
      // eslint-disable-next-line no-underscore-dangle
      { label: 'Sparing Acct (WBS #)', info: details.sparing_account_wbs_dd, permission: NeatPermissions.sites.sparing_account },
    ]
  }

  renderLocationDetails() {
    const {
      classes, details,
    } = this.props
    const chips = [
      ['HDTV', details.hdtv],
      ['VOIP', details.voip],
      ['PAN', details.pan_private_area_network],
      ['WIFI', details.wifi],
      ['ETHERNET', details.ethernet],
      ['SITE MANAGER', details.site_manager],
    ]
    const chipViews = []
    chips.forEach((i) => {
      chipViews.push(
        <Chip
          label={i[0]}
          size="small"
          style={i[1] ? ChipStyles.active : ChipStyles.non_active}
          key={i[0]}
        />,
      )
    })

    return (
      <Box display="flex" flexDirection="column" style={{ overflowX: 'auto' }}>
        <Box height="100%" p={1} display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography className={classes.title} variant="subtitle1">
              Location Details
            </Typography>
          </Box>
          {/* eslint-disable-next-line react/no-array-index-key */}
          <Typography className={classes.detailValue} component="span" variant="body1">{details.location && details.location.split('\n').map((i, key) => <div key={key}>{i}</div>)}</Typography>
          <Typography className={classes.detailValue} variant="body1">{details.phone_number}</Typography>
          <Box height="100%" display="flex" flexDirection="column" justifyContent="flex-end">
            <Box mb={1}>
              <Typography className={classes.title} variant="subtitle1">Location Services</Typography>
            </Box>
            <Box>
              {chipViews}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderLocationContact() {
    const { details } = this.props
    const information = [
      { label: 'Guest Sup', info: details.guest_support, permission: NeatPermissions.sites.show },
      { label: 'Staff Sup', info: details.staff_support, permission: NeatPermissions.sites.show },
    ]

    return this.renderDetails('Location Contact', information)
  }

  renderDetails(header, details) {
    const { classes, user } = this.props

    const informationViews = []
    details.forEach((detail) => {
      if (user.hasPermission(detail.permission)) {
        informationViews.push(
          <Grid item xs={4} key={`label_${detail.label}`}>
            <Typography className={classes.detailKey} variant="body1">{`${detail.label}:`}</Typography>
          </Grid>,
          <Grid item xs={8} key={`value_${detail.label}_${detail.info}`}>
            <Typography className={classes.detailValue} variant="body1">{detail.info}</Typography>
          </Grid>,
        )
      }
    })

    return (
      <React.Fragment>
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle1">{header}</Typography>
            </Grid>
            <Grid container spacing={0} item xs={12}>
              {informationViews}
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Box component={Paper} height="100%" overflow="auto">
        { this.renderLocationDetails() }
        { this.renderLocationContact() }
        { this.renderDetails('Technical Information', this.technicalInformationObject()) }
      </Box>
    )
  }
}

LocationDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  details: SiteDetailsProptype.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

export default withStyles(styles)(LocationDetails)
