import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Box, Paper,
} from '@material-ui/core'

import { fetchTicket } from '../actions/TicketsActions'
import TicketProptype from '../proptypes/TicketProptype'
import TicketComments from '../components/TicketComments'
import TicketDetails from '../components/TicketDetails'

class TicketCommentsContainer extends React.PureComponent {
  componentDidMount() {
    const { dispatch, ticketId } = this.props
    dispatch(fetchTicket(ticketId))
  }

  render() {
    const { ticket } = this.props
    return (
      <Box p={1}>
        <Box component={Paper} p={1} mb={1}>
          <TicketDetails ticket={ticket} />
        </Box>
        <Box component={Paper} p={1}>
          <TicketComments comments={ticket.comments} />
        </Box>
      </Box>
    )
  }
}

TicketCommentsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticketId: PropTypes.string.isRequired,
  ticket: TicketProptype.isRequired,
}

function mapStateToProps(state, ownProps) {
  const { ticketsReducer } = state
  const {
    ticket,
  } = ticketsReducer

  const returnObj = {
    ticket,
  }

  // in the old use case, this was dependent on URL params for the ticketId
  // i'm changing this to allow passing in a ticketId as props as well.
  if (ownProps.match !== undefined) {
    const { ticketId } = ownProps.match.params
    returnObj.ticketId = ticketId
  }

  return returnObj
}

export default connect(mapStateToProps)(TicketCommentsContainer)
