import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import RouterLocationPrototype from '../proptypes/RouterLocationPrototype'

const renderFunction = (Component, render, location, user, requiredPermission) => (props) => {
  const renderComponent = render ? render(props) : (
    <Component {...props} />
  )

  return (
    user.hasPermission(requiredPermission) ? renderComponent : (
      <Redirect
        to={{
          pathname: '/no-access',
          state: { from: location },
        }}
      />
    )
  )
}

const PrivateRoute = ({
  location, user, requiredPermission, component: Component, render, ...rest
}) => (
  <Route
    {...rest}
    render={renderFunction(Component, render, location, user, requiredPermission)}
  />
)

PrivateRoute.propTypes = {
  location: RouterLocationPrototype,
  requiredPermission: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  component: PropTypes.instanceOf(Object),
  render: PropTypes.func,
}

PrivateRoute.defaultProps = {
  location: null,
  component: null,
  render: null,
}

const mapStateToProps = state => ({
  user: state.global.user,
})

export default connect(mapStateToProps)(PrivateRoute)
