import HandleTimeout from '../helpers/SessionHelper'

const querystring = require('querystring')

export const REQUEST_GRAPH_DATA = 'REQUEST_GRAPH_DATA'

function requestGraphData() {
  return {
    type: REQUEST_GRAPH_DATA,
  }
}

export const RECEIVE_GRAPH_DATA = 'RECEIVE_GRAPH_DATA'
function receiveGraphData(graphData) {
  return {
    type: RECEIVE_GRAPH_DATA,
    graphData,
  }
}

export const FAILURE_GRAPH_DATA = 'FAILURE_GRAPH_DATA'
function failureGraphData(error) {
  return {
    type: FAILURE_GRAPH_DATA,
    error,
  }
}

export function fetchGraphData(deviceId, filters = {}) {
  return (dispatch) => {
    if (dispatch(requestGraphData())) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      }
      return fetch(`/api/monitored_devices/${deviceId}/graph_data?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveGraphData(json))
          } else {
            dispatch(failureGraphData(json))
          }
        })
    }
    return false
  }
}
