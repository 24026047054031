import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, InputLabel, Input, InputAdornment, IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
}))
export default function PasswordInput({
  show, value, onChange, showClicked,
}) {
  const classes = useStyles()
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="password">Password</InputLabel>
      <Input
        id="password"
        type={show ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={showClicked}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
    </FormControl>
  )
}

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showClicked: PropTypes.func.isRequired,
  value: PropTypes.string,
}

PasswordInput.defaultProps = {
  value: '',
}
