import NeatRoutes from './NeatRoutes'

const getRouteFromObject = (object, path) => {
  let route = null
  Object.keys(object).forEach((key) => {
    if (object[key].path && path.includes(object[key].path)) {
      route = object[key]
    } else if (typeof object[key] === 'object') {
      const temp = getRouteFromObject(object[key], path)
      if (temp) {
        route = temp
      }
    }
  })
  return route
}

// eslint-disable-next-line import/prefer-default-export
export const getRoute = path => getRouteFromObject(NeatRoutes, path)
