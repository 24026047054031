// this form handles new/edit for FeatureRequestForm model
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Paper, TextField, Typography,
  Button, Container, Grid, Select,
  MenuItem, FormControl, InputLabel,
  FormHelperText, Divider, Link,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { CamelToSnake, Titleize } from '../../../helpers/StringHelper'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    width: '100%',
  },
  container: {
    overflow: 'hidden',
    paddingBottom: theme.spacing(3),
  },
  divider: {
    height: 3,
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
  },
  screenshotsContainer: {
    marginTop: theme.spacing(1),
  },
  image: {
    width: '95%',
    maxWidth: '95%',
    height: 'auto',
  },
}))

const developmentTypes = [
  'new development',
  'feature enhancement',
  'bug',
]

const priorities = [
  'low',
  'normal',
  'high',
  'urgent',
]

// this one is a hash because titleizing wont work for the labels
const applications = [
  {
    id: 'neat',
    label: 'NEAT',
  },
  {
    id: 'ticketing',
    label: 'Ticketing (Zendesk)',
  },
  {
    id: 'monitoring',
    label: 'Monitoring (LogicMonitor)',
  },
]

const requiredFields = [
  'application',
  'developmentType',
  'priority',
  'reasonDescription',
  'requestDescription',
  'title',
  ]

export default function FeatureRequestForm(props) {
  const classes = useStyles()
  const { form } = props
  // either a real form gets passed in as props, or the default props are used
  const [state, setState] = React.useState({
    id: form.id,
    developmentType: form.development_type,
    priority: form.priority,
    application: form.application,
    title: form.title,
    requestedCompleteBy: form.requested_complete_by,
    requestDescription: form.request_description,
    reasonDescription: form.reason_description,
    attachedFiles: form.attached_files || [],
  })

  // array that holds the field names (string) that are 'erroring' (empty)
  const [errorFields, setErrorFields] = React.useState([])

  const handleInputErrors = (fieldName, value) => {
    // if the field was previously erroring, but it has a value now
    if (errorFields.includes(fieldName) && value.trim()) {
      // using a copy here because splice returns the spliced
      // element in a new array but mutates the original array
      const errorFieldsDup = errorFields

      // remove the previously erroring field from the errorFields state
      errorFieldsDup.splice(errorFieldsDup.indexOf(fieldName), 1)
      setErrorFields(errorFieldsDup)
    }

    // if the field wasn't in error previously, but now it is empty
    if (value.trim() === '') {
      // add the field to the errorFields, keeping previous ones in tact
      setErrorFields([...errorFields, fieldName])
    }
  }

  const handleInputChanged = (event) => {
    const fieldName = event.target.name
    const { target: { value } } = event // destructure deeeeper
    handleInputErrors(fieldName, value)
    setState({ ...state, [fieldName]: value })
  }

  const handleDateChanged = (date) => {
    setState({ ...state, requestedCompleteBy: date })
  }

  const onFileChange = (event) => {
    // make sure to get all the files for multi input
    setState({ ...state, attachedFiles: [...event.target.files] })
  }

  const handleFormSubmit = () => {
    const { onSubmit } = props
    const { attachedFiles, id } = state
    const missingFields = requiredFields.filter(reqField => !state[reqField])

    // if there are missing fields here we gotta set errors and not submit
    if (missingFields.length) {
      setErrorFields(missingFields)
    } else {
      // otherwise, the form is filled out and good to submit
      // so iterate over the state and append to the form data
      const formData = new FormData()
      Object.keys(state)
        // handle appending the files and ID separately
        .filter(field => !['attachedFiles', 'id'].includes(field))
        .forEach((field) => {
          formData.append(CamelToSnake(field), state[field]) // snakelize it for ruby happiness
        })

      // it'll have an id if the form is for updating
      // but don't append a blank value, or else bad things will happen
      if (id) formData.append('id', id)
      if (attachedFiles.length) {
        attachedFiles.forEach((file) => {
          formData.append('screenshots[]', file, file.name)
        })
      }
      onSubmit(formData)
    }
  }


  const hasError = field => errorFields.includes(field)

  // destructure the snake key into a camel var with a default of empty array
  const { screenshot_preview_links: screenshotPreviewLinks = [] } = form

  const {
    developmentType, application, priority,
    title, requestDescription, reasonDescription,
   } = state

   let { requestedCompleteBy } = state
   requestedCompleteBy = requestedCompleteBy ? moment(requestedCompleteBy).format('MM/DD/YYYY') : requestedCompleteBy

  return (
    <Container component={Paper} className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl required variant="filled" className={classes.formControl} error={hasError('developmentType')}>
            <InputLabel id="development-type-select-label">Development Type</InputLabel>
            <Select
              id="development-type-select"
              label="Development Type"
              labelId="development-type-select-label"
              name="developmentType"
              onChange={handleInputChanged}
              value={developmentType.toLowerCase()}
              renderValue={val => Titleize(val)}
            >
              <MenuItem value="">Development Type</MenuItem>
              { developmentTypes.map(dt => (
                <MenuItem key={dt} value={dt}>{Titleize(dt)}</MenuItem>
              )) }
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl required variant="filled" className={classes.formControl} error={hasError('application')}>
            <InputLabel id="application-select-label">Application</InputLabel>
            <Select
              id="application-select"
              label="Application"
              labelId="application-select-label"
              name="application"
              onChange={handleInputChanged}
              value={application.toLowerCase()}
              renderValue={val => Titleize(val)}
            >
              <MenuItem value="">Application</MenuItem>
              { applications.map(app => (
                <MenuItem key={app.id} value={app.id}>{app.label}</MenuItem>
              )) }
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl required variant="filled" className={classes.formControl} error={hasError('priority')}>
            <InputLabel id="priority-select-label">Priority</InputLabel>
            <Select
              id="priority-select"
              label="Priority"
              labelId="priority-select-label"
              name="priority"
              onChange={handleInputChanged}
              value={priority.toLowerCase()}
              renderValue={val => Titleize(val)}
            >
              <MenuItem value="">Priority</MenuItem>
              { priorities.map(p => (
                <MenuItem key={p} value={p}>{Titleize(p)}</MenuItem>
              )) }
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <TextField
            required
            className={classes.formControl}
            error={hasError('title')}
            helperText="Required"
            label="Development/Feature Title"
            name="title"
            onChange={handleInputChanged}
            value={title}
            variant="filled"
          />
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              className={classes.formControl}
              disableToolbar
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              helperText="Must be at least 2 weeks from now"
              label="Tentative Completed By"
              name="requestedCompleteBy"
              onChange={handleDateChanged}
              variant="inline"
              value={requestedCompleteBy}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            helperText="Required"
            className={classes.formControl}
            error={hasError('requestDescription')}
            label="Development / Feature Background (What will this change / add to the application?)"
            multiline
            name="requestDescription"
            onChange={handleInputChanged}
            rows={4}
            rowsMax={8}
            value={requestDescription}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            helperText="Required"
            className={classes.formControl}
            error={hasError('reasonDescription')}
            label="How will this request make your job easier? (E.g. By doing the following steps, I'll be able to save X amount of time.)"
            multiline
            name="reasonDescription"
            onChange={handleInputChanged}
            rows={4}
            rowsMax={8}
            value={reasonDescription}
            variant="filled"
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={3} className={classes.screenshotsContainer}>
        <Grid item xs={6}>
          <Typography variant="h5">Add Screenshots</Typography>
          <input type="file" multiple onChange={onFileChange} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Existing Screenshots</Typography>
          { screenshotPreviewLinks.length > 0 && screenshotPreviewLinks.map(link => (
            <Typography key={link.path} variant="body1">
              <Link href={link.path} target="_blank">{link.filename}</Link>
            </Typography>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.actionsContainer}>
        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={handleFormSubmit}>Submit</Button>
        </Grid>
        { errorFields.length > 0 && (
          <Grid item xs={11}>
            <Alert variant="outlined" severity="error">Please fill in all required fields.</Alert>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

FeatureRequestForm.propTypes = {
  form: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
}

FeatureRequestForm.defaultProps = {
  form: {
    id: null,
    development_type: '',
    priority: '',
    application: '',
    title: '',
    requested_complete_by: null,
    request_description: '',
    reason_description: '',
    attached_files: [],
  },
}
