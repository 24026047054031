import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_PERMISSIONS = 'REQUEST_PERMISSIONS'
function requestPermissions() {
  return {
    type: REQUEST_PERMISSIONS,
  }
}

export const RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS'
function receivePermissions(permissions) {
  return {
    type: RECEIVE_PERMISSIONS,
    permissions,
  }
}

export const FAILURE_PERMISSIONS = 'FAILURE_PERMISSIONS'
function failurePermissions(error) {
  return {
    type: FAILURE_PERMISSIONS,
    error,
  }
}

export function fetchPermissions() {
  return (dispatch) => {
    dispatch(requestPermissions())
    return fetch('/api/permissions')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receivePermissions(json)))
      .catch(error => dispatch(failurePermissions(error)))
  }
}
