import React from 'react'
import PropTypes from 'prop-types'

// import redux helpers
import { compose } from 'redux'
import { connect } from 'react-redux'

// import MUI components
import {
  Modal,
  Box,
  Button,
  withStyles,
  Snackbar,
  SnackbarContent,
  Typography,
  IconButton,
} from '@material-ui/core'

// import icons
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

// import custom admin page styles
import AdminStyles from '../styles/AdminStyles'

// import table helpers for welcome texts
import { welcomeTextHeaders, welcomeTextRows } from '../table_helpers/WelcomeTextTableHelper'

import WelcomeTextManager from '../components/WelcomeTextManager'

// import fetching actions
import {
  fetchWelcomeTexts,
  fetchCreateWelcomeText,
  fetchUpdateWelcomeText,
  fetchDeleteWelcomeText,
  fetchToggleWelcomeTextActive,
} from '../fetchers/WelcomeTextFetchers'

import NeatTable from '../components/NeatTable'

class WelcomeTextsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
    }
  }

  // fetch welcome texts when the component mounts
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchWelcomeTexts())
  }

  // set errors in component state when something goes wrong w/ the api
  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  // handles setting the edit/create/delete/toggle active modal state
  // (it's all the same modal technically)
  setModalOpen = (open, type, object = {}) => () => {
    if (object.customer) {
      this.onChooseCustomer(object.customer)
    }
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  // handles updating or creating a welcome text based upon what modal is currently open
  updateOrCreateWelcomeText = (text) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateWelcomeText
    } else if (modalType === 'delete') {
      request = fetchDeleteWelcomeText
    } else {
      request = fetchUpdateWelcomeText
    }

    dispatch(request(text))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  // toggles the welcome text state
  toggleWelcomeTextActive = (welcomeText) => {
    const { dispatch } = this.props
    dispatch(fetchToggleWelcomeTextActive(welcomeText))
      .then(() => this.setModalOpen(false))
      .catch(error => this.setErrors(error))
  }

  // handles the rendering of the modal. can be create, edit, delete, or toggle active
  renderWelcomeTextModal = (modalOpen, modalType, modalObject) => {
    return (
      <Modal open={modalOpen} onClose={this.setModalOpen(false)}>
        <React.Fragment>
          <WelcomeTextManager
            type={modalType}
            welcomeText={modalObject}
            onSubmit={this.updateOrCreateWelcomeText}
            onClose={this.setModalOpen(false)}
          />
        </React.Fragment>
      </Modal>
    )
  }

  // resets the component error state
  clearErrors = () => {
    this.setState({ errors: [] })
  }

  // renders errors under the modal component
  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  render() {
    const rowProps = {
      setModalOpen: this.setModalOpen,
      toggleActive: this.toggleWelcomeTextActive,
      ...this.props,
    }
    const {
      modalOpen,
      modalType,
      modalObject,
    } = this.state

    return (
      <Box width="100%" p={1}>
        <Box display="flex" justifyContent="flex-end" m={1}>
          <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
        </Box>

        <NeatTable
          headers={welcomeTextHeaders()}
          rows={welcomeTextRows(rowProps)}
        />

        { this.renderWelcomeTextModal(modalOpen, modalType, modalObject)}
        { this.renderErrors() }
      </Box>
    )
  }
}

WelcomeTextsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { welcomeTextsReducer } = state
  const { welcomeTexts } = welcomeTextsReducer
  return { welcomeTexts }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(WelcomeTextsContainer)
