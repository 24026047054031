import {
  REQUEST_WELCOME_TEXTS,
  RECEIVE_WELCOME_TEXTS,
} from '../actions/WelcomeTextsActions'

export default (
  state = {
    welcomeTexts: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_WELCOME_TEXTS:
      return Object.assign({}, state, {
        welcomeTexts: action.welcomeTexts,
      })
    case REQUEST_WELCOME_TEXTS:
    default:
      return state
  }
}
