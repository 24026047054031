import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
} from '@material-ui/core'

import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

import {
  fetchCreateTheme,
  fetchDeleteTheme,
  fetchEditTheme,
  fetchThemes,
} from '../actions/ThemesActions'

import { fetchPermissions } from '../actions/PermissionsActions'

import AdminStyles from '../styles/AdminStyles'

import NeatTable from '../components/NeatTable'
import ThemeManager from '../components/ThemeManager'

class ThemesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchThemes())
    dispatch(fetchPermissions())
  }

  setModalOpen = (open, type, object = {}) => () => {
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  clearErrors = () => this.setState({ errors: [] })

  headers = () => ([
    {
      id: 'name', label: 'Name',
    },
    {
      id: 'icon', label: 'Icon', format: val => <Avatar src={val} style={{ margin: '5px' }} />,
    },
    {
      id: 'primaryColor', label: 'Primary Color', format: val => <Avatar style={{ margin: '5px', backgroundColor: val }} />,
    },
    {
      id: 'secondaryColor', label: 'Secondary Color', format: val => <Avatar style={{ margin: '5px', backgroundColor: val }} />,
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ])

  rows = () => {
    const { themes } = this.props
    const rows = []
    themes.forEach((theme) => {
      rows.push(this.createRow(theme))
    })
    return rows
  }

  createRow = (theme) => {
    const { classes } = this.props
    return (
      {
        name: theme.name,
        icon: theme.icon_url || '',
        primaryColor: theme.primary_color || '',
        secondaryColor: theme.secondary_color || '',
        edit: <Button color="primary" onClick={this.setModalOpen(true, 'edit', theme)}>Edit</Button>,
        delete: (
          <Button
            className={classes.deleteButton}
            disabled={theme.in_use}
            onClick={this.setModalOpen(true, 'delete', theme)}
          >
            Delete
          </Button>
        ),
      }
    )
  }

  updateOrCreateTheme = (t) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateTheme
    } else if (modalType === 'delete') {
      request = fetchDeleteTheme
    } else {
      request = fetchEditTheme
    }
    dispatch(request(t))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  renderModal = (open, type, object) => {
    const { dispatch, permissions } = this.props

    return (
      <Modal open={open} onClose={this.setModalOpen(false)}>
        <React.Fragment>
          <ThemeManager
            type={type}
            dispatch={dispatch}
            availablePermissions={permissions}
            theme={object}
            onSubmit={this.updateOrCreateTheme}
            onClose={this.setModalOpen(false)}
          />
        </React.Fragment>
      </Modal>
    )
  }

  render() {
    const { modalOpen, modalType, modalObject } = this.state

    return (
      <Box width="100%" p={1}>
        <Box display="flex" justifyContent="flex-end" m={1}>
          <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
        </Box>

        <NeatTable
          headers={this.headers()}
          rows={this.rows()}
        />

        { this.renderModal(modalOpen, modalType, modalObject) }
        { this.renderErrors() }
      </Box>
    )
  }
}

ThemesContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  themes: PropTypes.arrayOf(Object).isRequired,
  permissions: PropTypes.arrayOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { permissionsReducer, themesReducer } = state
  const { permissions } = permissionsReducer
  const { themes } = themesReducer
  return { themes, permissions }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(ThemesContainer)
