import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import Grid from '@material-ui/core/Grid'
import Moment from 'moment'
import { fetchTickets, fetchTicketsCSV } from '../actions/TicketsActions'

import NeatTable from '../components/NeatTable'
import CounterWidget from '../components/CounterWidget'
import { Titleize } from '../helpers/StringHelper'
import TicketCommentsContainer from './TicketCommentsContainer'
import SlideUpModal from '../components/SlideUpModal'
import ticketStatusLegend from '../constants/TicketStatusLegend'
import { getStatusIcon, statusFormat } from '../helpers/TicketStatusHelper'
import { priorityFormat } from '../helpers/TicketPriorityHelper'

class AllCustomerTicketsContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      filters: {},
      modalOpen: false,
      ticketId: '',
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchTickets())
  }

  ticketCommentsContainer = () => {
    const { ticketId } = this.state
    return (
      <TicketCommentsContainer ticketId={ticketId} />
    )
  }

  headers = () => ([
    {
      id: 'ticket_id',
      label: 'Ticket ID',
      filterable: true,
      filterType: 'text',
    },
    {
      id: 'location',
      filterable: true,
      filterType: 'text',
      label: 'Location',
    },
    {
      id: 'priority',
      filterable: true,
      mapping: {
        low: 'low',
        normal: 'normal',
        high: 'high',
        urgent: 'urgent',
      },
      format: priorityFormat,
      label: 'Priority',
    },
    {
      id: 'subject',
      label: 'Subject',
    },
    {
      id: 'status',
      filterable: true,
      mapping: {
        open: 'open',
        hold: 'hold',
        pending: 'pending',
        new: 'new',
        closed: 'closed',
        solved: 'solved',
        resolved: 'resolved',
        unresolved: 'unresolved',
      },
      format: statusFormat,
      label: 'Status',
    },
    {
      id: 'ticket_state',
      label: 'Stage',
    },
    {
      id: 'category',
      label: 'Category',
    },
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      filterable: true,
      format: val => Moment(val).format('LLL'),
    },
    {
      id: 'room_count',
      label: 'Room Count',
    },
    {
      id: 'source_prefix',
      label: 'Source Prefix',
      filterable: false,
      hidden: true
    }
  ])

  rows = () => {
    const { tickets } = this.props
    return this.createRows(tickets.tickets)
  }

  createRows = (tickets) => {
    const rows = []
    tickets.forEach((ticket) => {
      rows.push(this.createRow(ticket))
    })
    return rows
  }

  createRow = ticket => (
    {
      ticket_id: ticket.ticket_id.replace(/cwzd|cw|zd/, ''),
      location: ticket.location,
      priority: ticket.priority,
      subject: ticket.subject,
      status: ticket.status,
      ticket_state: ticket.ticket_state,
      category: ticket.category,
      created_at: ticket.created_at,
      room_count: ticket.room_count,
      source_prefix: ticket.ticket_id.replace(/\d+$/, ''),
    }
  )

  toggleModal = (ticket = {}) => {
    const { modalOpen } = this.state
    const stateObj = { modalOpen: !modalOpen }
    if (Object.keys(ticket).length) {
      const ticketId = ticket.ticket_id
      const sourcePrefix = ticket.source_prefix
      stateObj.ticketId = `${sourcePrefix}${ticketId}`
    }

    this.setState(stateObj)
  }

  handleRequest = (filters) => {
    const { dispatch } = this.props
    dispatch(fetchTickets(filters))
    this.setState({ filters })
  }

  handleTileClick = (status) => {
    const { filters } = this.state
    filters.status = status
    this.handleRequest(filters)
  }

  initializeStatusCounters = () => {
    const { tickets } = this.props
    const { counts } = tickets
    const statuses = ['new', 'open', 'pending', 'hold', 'solved', 'closed', 'resolved', 'unresolved']
    return statuses.map(status => (
      {
        label: Titleize(status),
        count: counts[status],
        onClick: () => this.handleTileClick(status),
      }
    ))
  }

  renderStatusCounters = () => {
    const { tickets } = this.props
    const { counts } = tickets
    const statusCounters = this.initializeStatusCounters()

    return (
      <Box mb={1}>
        <Grid container spacing={1} justify="center">
          {
            statusCounters.map(statusCounter => (
              <Grid key={statusCounter.label} item xs={1}>
                <CounterWidget clickable label={statusCounter.label} value={`${statusCounter.count}`} handleClick={statusCounter.onClick} />
              </Grid>
            ))
          }
          <Grid item xs={1}>
            <CounterWidget label="Closed Last 24hr" value={`${counts.closed_last_24_hours}`} />
          </Grid>
          <Grid item xs={1}>
            <CounterWidget
              label="MTTR Last 24hr"
              value={`${Moment.utc(counts.mttr_last_24_hours * 1000)
                .format('HH:mm:ss')}`}
            />
          </Grid>
          <Grid item xs={1}>
            <CounterWidget
              label="MTTC Last 24hr"
              value={`${Moment.utc(counts.mttc_last_24_hours * 1000)
                .format('HH:mm:ss')}`}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }

  render() {
    const { dispatch, tickets, ticketsCsv } = this.props
    const { counts } = tickets
    const { filters, modalOpen } = this.state
    return (
      <Box p={1}>
        {this.renderStatusCounters()}
        <NeatTable
          serverSide
          totalCount={counts.total}
          pageSize={tickets.page_size}
          headers={this.headers()}
          rows={this.rows()}
          rowsForCsv={this.createRows(ticketsCsv)}
          csvRequest={callback => dispatch(fetchTicketsCSV(filters, callback))}
          setFilterValues={filters}
          onRowClicked={this.toggleModal}
          onHandleRequest={this.handleRequest}
          legendMapping={ticketStatusLegend}
          getIcon={getStatusIcon}
        />
        <SlideUpModal
          open={modalOpen}
          onClose={() => this.toggleModal()}
          childComponent={this.ticketCommentsContainer()}
          title="Ticket Details"
        />
      </Box>
    )
  }
}

AllCustomerTicketsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tickets: PropTypes.instanceOf(Object).isRequired,
  ticketsCsv: PropTypes.instanceOf(Object),
}

AllCustomerTicketsContainer.defaultProps = {
  ticketsCsv: { tickets: [] },
}

function mapStateToProps(state) {
  const { ticketsReducer } = state
  const {
    tickets,
    ticketsCsv,
  } = ticketsReducer
  return {
    tickets,
    ticketsCsv,
  }
}

export default connect(mapStateToProps)(AllCustomerTicketsContainer)
