/* eslint-disable max-len */
import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Paper, MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as moment from 'moment'
import configureStore, { history } from './Store'
import { loadState } from './localStorage'
import Header from './components/Header'
import NeatRoutes from './helpers/NeatRoutes'
import PrivateRoute from './wrappers/PrivateRoute'
import { trackPageView } from './helpers/GAHelper'
import Welcome from './components/Welcome'
import LocationsDashboardContainer from './containers/LocationsDashboardContainer'
import MonitoredDevicesContainer from './containers/MonitoredDevicesContainer'
import TicketsDashboardContainer from './containers/TicketsDashboardContainer'
import TicketCommentsContainer from './containers/TicketCommentsContainer'
import CustomerOpsTicketsContainer from './containers/AllCustomerTicketsContainer'
import AdminContainer from './containers/AdminContainer'
import CentralizedOutageDashboardContainer from './containers/CentralizedOutageDashboardContainer'
import NinetyDayActivationsContainer from './containers/NinetyDayActivationsContainer'
import NomadixUtilizationDashboardContainer from './containers/NomadixUtilizationDashboardContainer'
import ClientReportingContainer from './containers/ClientReportingContainer'
import DistributedOutageDashboardContainer from './containers/DistributedOutageDashboardContainer'
import OutageAlertsContainer from './components/OutageAlerts/OutageAlertsContainer'
import ErrorNotification from './components/ErrorNotification'
import ErrorBoundary from './components/ErrorBoundary'
import CustomerSettingsContainer from './containers/CustomerSettingsContainer'
import OutageDashboardContainer from './containers/OutageDashboardContainer'
import FeatureRequestForms from './components/forms/feature_request_forms/Index'

/*
  Starting point in application that contains the Router.
  Each Router renders the appropriate component for that page.
  Provides redux store to app.
  Props:
  user - Currently logged in user for app
 */
class App extends React.Component {
  persistedState = loadState()

  constructor(props) {
    super(props)
    const { user, gaTrackingCode } = props
    if (gaTrackingCode) {
      ReactGA.initialize(gaTrackingCode, {
        gaOptions: {
          userId: user.id,
          username: user.username,
        },
      })
    }
    trackPageView(window.location.pathname)
    let useDarkMode
    // if there is persisted state (not null or undefined)
    if (this.persistedState && this.persistedState.darkModeReducer) {
      ({ darkModeReducer: { useDarkMode } } = this.persistedState)
    } else {
      useDarkMode = false
    }
    moment.tz.setDefault(user.time_zone)
    this.state = {
      useDarkMode,
    }
  }

  render() {
    const { user } = this.props
    const { theme } = user
    const userHasTheme = theme && Object.keys(theme).length > 0
    const { useDarkMode } = this.state

    const initialState = {
      global: {
        user,
      },
      ...this.persistedState,

    }

    const store = configureStore(initialState)

    const customTheme = createMuiTheme({
      palette: {
        type: useDarkMode ? 'dark' : 'light',
        primary: {
          main: userHasTheme ? theme.primary_color : '#0F4780',
        },
        secondary: {
          main: userHasTheme ? theme.secondary_color : '#03DAC5',
        },
      },
    })

    return (
      <ErrorBoundary>
        <MuiThemeProvider theme={customTheme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Paper square style={{ height: '100vh' }}>
                <Header useDarkMode={useDarkMode} />
                <ErrorNotification />
                <ErrorBoundary>
                  { /* NeatRoutes.dashboards.ticket_metrics routes go below this */ }
                  <PrivateRoute
                    path={NeatRoutes.dashboards.ticket_metrics.all_customer_tickets.path}
                    requiredPermission={NeatRoutes.dashboards.ticket_metrics.all_customer_tickets.permission}
                    component={CustomerOpsTicketsContainer}
                  />
                  <PrivateRoute
                    path={NeatRoutes.dashboards.ticket_metrics.ninety_day_activations.path}
                    requiredPermission={NeatRoutes.dashboards.ticket_metrics.ninety_day_activations.permission}
                    component={NinetyDayActivationsContainer}
                  />

                  { /* NeatRoutes.dashboards.other_metrics routes go below this comment */ }
                  <PrivateRoute
                    path={NeatRoutes.dashboards.other_metrics.centralized_outages.path}
                    requiredPermission={NeatRoutes.dashboards.other_metrics.centralized_outages.permission}
                    component={CentralizedOutageDashboardContainer}
                  />
                  <PrivateRoute
                    path={NeatRoutes.dashboards.other_metrics.distributed_outages.path}
                    requiredPermission={NeatRoutes.dashboards.other_metrics.distributed_outages.permission}
                    component={DistributedOutageDashboardContainer}
                  />
                  <PrivateRoute
                    path={NeatRoutes.dashboards.other_metrics.outage_dashboard.path}
                    requiredPermission={NeatRoutes.dashboards.other_metrics.outage_dashboard.permission}
                    component={OutageDashboardContainer}
                  />
                  <PrivateRoute
                    path={NeatRoutes.dashboards.other_metrics.nomadix_utilization.path}
                    requiredPermission={NeatRoutes.dashboards.other_metrics.nomadix_utilization.permission}
                    component={NomadixUtilizationDashboardContainer}
                  />

                  { /* non dashboard related routes go below this comment */ }
                  <Route
                    exact
                    path={`${NeatRoutes.welcome.path}`}
                    requiredPermission={NeatRoutes.welcome.permission}
                    render={props => <Welcome {...props} user={user} />}
                  />
                  <PrivateRoute
                    path={`${NeatRoutes.locations.path}/:siteId?`}
                    requiredPermission={NeatRoutes.locations.permission}
                    component={LocationsDashboardContainer}
                  />
                  <PrivateRoute
                    path={NeatRoutes.monitoring.path}
                    requiredPermission={NeatRoutes.monitoring.permission}
                    component={MonitoredDevicesContainer}
                  />
                  <PrivateRoute
                    path={`${NeatRoutes.ticketing.path}/:siteId?`}
                    requiredPermission={NeatRoutes.ticketing.permission}
                    component={TicketsDashboardContainer}
                  />
                  <PrivateRoute
                    exact
                    path={`${NeatRoutes.tickets.path}/:ticketId?`}
                    requiredPermission={NeatRoutes.tickets.permission}
                    render={props => <TicketCommentsContainer {...props} />}
                  />
                  <PrivateRoute
                    path={`${NeatRoutes.client_reporting.path}/:siteId?`}
                    requiredPermission={NeatRoutes.client_reporting.permission}
                    render={props => <ClientReportingContainer {...props} />}
                  />

                  <PrivateRoute
                    path={NeatRoutes.admin.path}
                    requiredPermission={NeatRoutes.admin.permission}
                    component={AdminContainer}
                  />

                  <PrivateRoute
                    path={`${NeatRoutes.outage_alerts.path}/:siteId?`}
                    requiredPermission={NeatRoutes.outage_alerts.permission}
                    component={OutageAlertsContainer}
                  />

                  <PrivateRoute
                    path={NeatRoutes.customer_settings.path}
                    requiredPermission={NeatRoutes.customer_settings.permission}
                    render={props => <CustomerSettingsContainer {...props} />}
                  />

                  <PrivateRoute
                    path={NeatRoutes.forms.feature_request_form.path}
                    requiredPermission={NeatRoutes.forms.feature_request_form.permission}
                    render={props => <FeatureRequestForms {...props} user={user} />}
                  />
                </ErrorBoundary>
              </Paper>
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </ErrorBoundary>
    )
  }
}

App.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  gaTrackingCode: PropTypes.string,
}

App.defaultProps = {
  gaTrackingCode: '',
}

export default hot(App)
