import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  ButtonGroup,
  Menu,
  Typography,
} from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

// This component relies on a list of options (array of objects) that includes:
// a button label, a button icon, an onClick handler function, and a boolean
// for whether or not the button is disabled. The options should be provided
// as a prop, as well as a label for the dropdown portion that will house the buttons.

export default function DropDownButtonGroup(props) {
  const { options, label } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  // this is the handler for when the menu is opened
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // this is the handler for when an individual menu item is clicked. In most
  // cases, this should use the onClick property of an object being provided
  // within the options[] prop
  const handleMenuItemClick = (event, option) => {
    option.onClick(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // handles iterating over the provided options and rendering a button for each option
  const renderButtonOptions = () => (
    options.map(option => (
      <Button
        color="default"
        key={option.label}
        disabled={option.disabled}
        onClick={event => handleMenuItemClick(event, option)}
      >
        <FontAwesomeIcon style={{ marginRight: '1.5rem' }} icon={option.icon} />
        <Typography style={{ fontSize: 'small', marginLeft: 'auto' }} noWrap variant="body1">{option.label}</Typography>
      </Button>
    ))
  )

  // handles rendering of the inner button group that holds the individual buttons
  const renderButtonGroup = () => (
    <ButtonGroup
      orientation="vertical"
      color="primary"
      variant="text"
      aria-label="vertical button group"
    >
      { renderButtonOptions() }
    </ButtonGroup>
  )

  // handles rendering of the outer dropdown menu
  const renderDropdownMenu = () => (
    <Menu
      id="button-menu"
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{
        border: '1px solid black',
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      { renderButtonGroup() }
    </Menu>
  )

  // handles rendering the top level button that expands the main menu
  const renderDropdownTrigger = () => (
    <Button fullWidth color="primary" variant="contained" aria-controls="button-menu" aria-haspopup="true" onClick={handleClick}>
      {label}
      <ArrowDropDownIcon style={{ marginLeft: 'auto' }} />
    </Button>
  )

  return (
    <React.Fragment>
      { renderDropdownTrigger() }
      { renderDropdownMenu() }
    </React.Fragment>
  )
}

DropDownButtonGroup.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  label: PropTypes.string,
}

DropDownButtonGroup.defaultProps = {
  label: '',
}
