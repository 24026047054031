import NeatPermissions from './NeatPermissions'

// Make sure you provide params in the route, otherwise you will get the following error:
// Uncaught TypeError: Cannot read property 'length' of undefined at getParams

const NeatRoutes = {
  welcome: {
    path: '/',
    title: 'Welcome',
    permission: NeatPermissions.any,
    params: [],
  },
  locations: {
    path: '/locations',
    title: 'Locations',
    permission: NeatPermissions.sites.index,
    params: ['siteId'],
  },
  forms: {
    feature_request_form: {
      path: '/feature_request_forms',
      title: 'Feature Request Forms',
      permission: NeatPermissions.forms.feature_request_form,
      params: [],
    },
  },
  dashboards: {
    // all dashboards regarding tickets should be placed within the ticket_metrics section
    ticket_metrics: {
      all_customer_tickets: {
        path: '/reporting/all-customer-tickets',
        title: 'All Customer Tickets',
        permission: NeatPermissions.tickets.index,
        params: [],
      },
      ninety_day_activations: {
        path: '/reporting/90-day-activations',
        title: '90 Day Activations',
        permission: NeatPermissions.sites.ninety_day_activations,
        params: [],
      },
    },
    // all other dashboards that don't relate to ticketing
    // should be placed in the other_metrics section
    other_metrics: {
      centralized_outages: {
        path: '/reporting/centralized-outages',
        title: 'Centralized Outages',
        permission: NeatPermissions.outages.centralized,
        params: [],
      },
      distributed_outages: {
        path: '/reporting/distributed-outages',
        title: 'Distributed Outages',
        permission: NeatPermissions.outages.distributed,
        params: [],
      },
      outage_dashboard: {
        path: '/reporting/outage-dashboard',
        title: 'Outage Dashboard',
        permission: NeatPermissions.outages.dashboard,
        params: [],
      },
      nomadix_utilization: {
        path: '/reporting/nomadix-utilization',
        title: 'Nomadix Utilization',
        permission: NeatPermissions.sites.nomadix_utilization,
        params: [],
      },
    },
  },
  client_reporting: {
    path: '/reporting/client-reporting',
    title: 'Client Reporting',
    permission: NeatPermissions.sites.client_reporting,
    params: ['siteId'],
  },
  monitoring: {
    path: '/monitoring',
    title: 'Monitoring',
    permission: NeatPermissions.sites.devices,
    params: [],
  },
  ticketing: {
    path: '/ticketing',
    title: 'Ticketing',
    permission: NeatPermissions.sites.tickets,
    params: ['siteId'],
  },
  tickets: {
    path: '/tickets',
    title: 'Ticket Comments',
    permission: NeatPermissions.tickets.show,
    params: [],
  },
  admin: {
    path: '/admin',
    title: 'Admin',
    permission: NeatPermissions.support_admin, // allow support_admin users to go to admin
    params: [],
    // uhhhh shouldn't there be more in here?
    users: {
      path: '/admin/users',
      title: 'Users',
      permission: NeatPermissions.support_admin,
      params: [],
    },
    roles: {
      path: '/admin/roles',
      title: 'Roles',
      permission: NeatPermissions.admin,
      params: [],
    },
    customer_settings: {
      path: '/admin/customer_settings',
      title: 'Customer Settings',
      permission: NeatPermissions.customer_admin,
      params: [],
    },
  },
  customer_settings: {
    path: '/customer_settings',
    title: 'Customer Settings',
    permission: NeatPermissions.customer_admin,
    params: [],
  },
  outage_alerts: {
    path: '/outage-alerts',
    title: 'Outage Alerts',
    permission: NeatPermissions.outage_alerts.index,
    params: ['siteId'],
  },
}

export default NeatRoutes
