import {
  REQUEST_USERS,
  RECEIVE_USERS,
  REQUEST_USERS_CSV,
  RECEIVE_USERS_CSV,
  REQUEST_USER_PERMISSIONS,
  RECEIVE_USER_PERMISSIONS,
  REQUEST_USER_LOGINS,
  RECEIVE_USER_LOGINS,
  REQUEST_PASSWORD_RESET_EMAIL,
  RECEIVE_PASSWORD_RESET_EMAIL,
  FAILURE_PASSWORD_RESET_EMAIL,
  FAILURE_PROFILE_UPDATE,
} from '../actions/UsersActions'

export default (
  state = {
    users: [],
    usersCsv: [],
    totalCount: 0,
    userLogins: [],
    permissions: [],
    passwordResetSuccessMessage: '',
    passwordResetFailMessage: '',
    errors: {},
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_USERS:
      return Object.assign({}, state, {
        users: action.response.users,
        totalCount: action.response.totalCount,
      })
    case RECEIVE_USERS_CSV:
      return Object.assign({}, state, {
        usersCsv: action.usersCsv.users,
      })
    case RECEIVE_USER_PERMISSIONS:
      return Object.assign({}, state, {
        permissions: action.permissions,
      })
    case RECEIVE_PASSWORD_RESET_EMAIL:
      return Object.assign({}, state, {
        passwordResetSuccessMessage: action.json.mailer_success,
        passwordResetFailMessage: '',
      })
    case RECEIVE_USER_LOGINS:
      return Object.assign({}, state, {
        userLogins: action.userLogins,
      })
    case FAILURE_PASSWORD_RESET_EMAIL:
      return Object.assign({}, state, {
        passwordResetFailMessage: action.error.mailer_error,
        passwordResetSuccessMessage: '',
      })
    case FAILURE_PROFILE_UPDATE:
      return Object.assign({}, state, {
        errors: action.errors,
      })
    case REQUEST_USERS:
    case REQUEST_USERS_CSV:
    case REQUEST_USER_PERMISSIONS:
    case REQUEST_USER_LOGINS:
    case REQUEST_PASSWORD_RESET_EMAIL:
    default:
      return state
  }
}
