import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Map, TileLayer, Marker, Popup, FeatureGroup, Polyline, WMSTileLayer,
} from 'react-leaflet'
import { connect } from 'react-redux'
import {
  Box, Typography, Button, withStyles, Checkbox, FormControlLabel, FormGroup, Paper,
} from '@material-ui/core'
import L from 'leaflet'
import 'leaflet-control-geocoder'
import 'leaflet.awesome-markers'
import LeafletControl from 'react-leaflet-control'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressArrowsAlt, faExpandArrowsAlt, faBars } from '@fortawesome/free-solid-svg-icons'
import SiteDetailsProptype from '../proptypes/SiteDetailsProptype'
import NeatPermissions from '../helpers/NeatPermissions'

const styles = () => ({
  button: {
    background: 'white',
  },
  label: {
    fontSize: 10,
  },
})

class MappingDetails extends Component {
  static defaultProps = {
    expanded: false,
    height: '470px',
    width: '100%',
    zIndex: 1,
  }

  constructor(props) {
    super(props)
    // TODO - replace with paid geocoder service, for now select free geocoder for open street maps
    this.geocoder = new L.Control.Geocoder.Nominatim()
    this.state = {
      lat: props.site.gps_lat || null,
      lng: props.site.gps_lng || null,
      zoom: 14,
      siteName: props.site.name || '',
      warehouses: props.site.nearest_warehouses,
      weatherTileEnabled: true,
    }
    if (!props.site.gps_lat && !props.site.gps_lng && props.site.address) {
      this.geocode(props.site.address)
    }
  }

  // TODO move componentWillReceiveProps logic to deriveStateFromProps and componentDidUpdate
  componentWillReceiveProps(nextProps) {
    const { site: nextSite } = nextProps
    if (nextSite && nextSite.gps_lat && nextSite.gps_lng) {
      this.setState({
        lat: nextSite.gps_lat,
        lng: nextSite.gps_lng,
        siteName: nextSite.name,
        warehouses: nextSite.nearest_warehouses,
      })
    } else if (nextSite && nextSite.address) {
      // if the site doesn't have a lat & lng value then geocode the address
      this.setState({ warehouses: nextSite.nearest_warehouses, lat: null, lng: null })
      this.geocode(nextSite.address)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lat, lng } = this.state
    // eslint-disable-next-line react/destructuring-assignment
    const { address } = this.props.site
    if (!lat && !lng && address) {
      this.geocode(address)
    } else if (prevState.lat !== lat && prevState.lng !== lng && this.feature) {
      this.resetBoundsAfterUpdate(this.feature.leafletElement.getBounds())
    }
  }

  onFeatureGroupAdd = (e) => {
    const warehouseBounds = e.target.getBounds()
    this.resetBoundsAfterUpdate(warehouseBounds)
  }

  // use point symmetry to center the site and fitbounds to zoom the map to include all warehouses
  resetBoundsAfterUpdate(warehouseBounds) {
    const { lat, lng } = this.state
    const ne = warehouseBounds.getNorthEast()
    const sw = warehouseBounds.getSouthWest()
    if (ne && sw && this.leafletMap) {
      const neSymetric = [ne.lat + (lat - ne.lat) * 2, ne.lng + (lng - ne.lng) * 2]
      const swSymetric = [sw.lat + (lat - sw.lat) * 2, sw.lng + (lng - sw.lng) * 2]
      warehouseBounds.extend(L.latLngBounds(swSymetric, neSymetric))
      this.leafletMap.leafletElement.fitBounds(warehouseBounds)
    }
  }

  geocode(location) {
    // trim extra white space and lf/cr before sending the location for geocoding
    const tiddyLocation = location.replace(/\s{2,10}/g, ' ')
    this.geocoder.geocode(tiddyLocation, (results) => {
      const r = results[0]
      if (r) {
        this.setState({ siteName: location, lat: r.center.lat, lng: r.center.lng })
        if (this.marker) {
          this.marker.position = r.center
        }
      }
    })
  }

  renderContactInfo = contact => (
    <React.Fragment key={`contact-${contact.contact}`}>
      <Typography variant="body2">
        Contact:
        <br />
        {contact.contact.split('\n').map(contactSegment => (
          // would be cool to have mailing and call links for the contact info
          // but the data in this columns is very inconsistent right now
          <React.Fragment key={contactSegment}>
            { contactSegment }
            <br />
          </React.Fragment>
        ))}
        <br />
        Type:
        <br />
        {contact.type}
      </Typography>
      <hr />
    </React.Fragment>
  )

  render() {
    const {
      zoom, lat, lng, siteName, warehouses, weatherTileEnabled,
    } = this.state
    const {
      width, height, zIndex, classes, toggleMapExpanded, expanded, user, dashboardLocked,
    } = this.props
    const position = [lat, lng]
    // eslint-disable-next-line max-len
    const warehouseLocations = warehouses ? warehouses.map(wh => L.marker([wh.latitude, wh.longitude])) : []

    return (
      <Box height="100%" width="100%" boxShadow={1} borderRadius="borderRadius" bgcolor="background.paper">
        {siteName && lat !== null && lng !== null && (
          <Map
            center={position}
            ref={(m) => { this.leafletMap = m }}
            zoom={zoom}
            style={{ width, height, zIndex }}
          >
            <TileLayer
              url="https://api.mapbox.com/styles/v1/ccisystems/ck7km5keh0kow1imjvso5t886/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2Npc3lzdGVtcyIsImEiOiJjazc1MDVyZzgwNmtzM2luNzdwemZxaHQwIn0.rKct28YFrtokPzcu9nFDNw"

            />
            <WMSTileLayer
              attribution="Weather data &copy; 2015 IEM Nexrad"
              url="https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi"
              transparent
              opacity={weatherTileEnabled ? 0.3 : 0}
              format="image/png"
              layers="nexrad-n0r"
            />
            )
            <Marker ref={(m) => { this.marker = m }} position={position} on>
              <Popup>
                {siteName}
              </Popup>
            </Marker>
            <FeatureGroup ref={(f) => { this.feature = f }} onAdd={this.onFeatureGroupAdd}>
              {user.hasPermission(NeatPermissions.sites.warehouses)
              && warehouseLocations
              && warehouseLocations.map((wh, index) => {
                const pos = wh.getLatLng()
                // TODO add geocoding support for warehouses that don't have lat/lng set
                if (!pos) return null
                const ll = L.latLng(pos.lat, pos.lng)
                const whPosition = [pos.lat, pos.lng]
                const distance = (ll.distanceTo(L.latLng(lat, lng)) / 1609.344).toFixed(1)
                const redMarker = L.AwesomeMarkers.icon({
                  icon: 'warehouse',
                  iconColor: 'white',
                  prefix: 'fa',
                  markerColor: 'red',
                })
                return (
                  <React.Fragment key={`wh-${pos.lat}${pos.lng}`}>
                    <Marker
                      onMouseOver={(ev) => {
                        if (!expanded) {
                          ev.target.openPopup()
                        }
                      }}
                      onFocus={(ev) => {
                        if (!expanded) {
                          ev.target.openPopup()
                        }
                      }}
                      position={whPosition}
                      icon={redMarker}
                    >
                      <Popup className={expanded ? classes.mapPopup : ''}>
                        <Typography variant="h6">
                          Warehouse Information:
                        </Typography>
                        <Typography variant="body2">
                          Name:&nbsp;
                          {warehouses[index].id.replace('ch', '')}
                        </Typography>
                        <Typography variant="body2">
                          Distance:&nbsp;
                          {distance}
                          mi.
                        </Typography>
                        <Typography variant="body2">
                          Warehouse address:
                          <br />
                          {warehouses[index].address}
                        </Typography>
                        { expanded && (
                          <React.Fragment>
                            <hr />
                            <Typography variant="h6">
                              Contact Information:
                            </Typography>
                          </React.Fragment>
                        )}
                        {expanded && warehouses[index].contacts.map(contact => (
                          this.renderContactInfo(contact)
                        ))}
                      </Popup>
                    </Marker>
                    <Polyline positions={[position, whPosition]} color="blue" />
                  </React.Fragment>
                )
              })}
            </FeatureGroup>
            <LeafletControl position="topright">
              <Button variant="contained" className={classes.button} onClick={() => { toggleMapExpanded() }}>
                { expanded
                  ? <FontAwesomeIcon icon={faCompressArrowsAlt} />
                  : <FontAwesomeIcon icon={faExpandArrowsAlt} /> }
              </Button>
            </LeafletControl>
            <LeafletControl position="topright">
              <FormGroup>
                <FormControlLabel
                  label="Weather"
                  className={classes.label}
                  control={
                    (
                      <Checkbox
                        color="primary"
                        checked={weatherTileEnabled}
                        onChange={() => this.setState({ weatherTileEnabled: !weatherTileEnabled })}
                      />
                    )
                  }
                />
              </FormGroup>
            </LeafletControl>
          </Map>
        )}
        {/* dont even render the drag icon if the dashboard is locked */}
        {!expanded && !dashboardLocked && (
          <Paper
            className="rgl-drag-handle"
            style={{
              cursor: 'grab', zIndex: 100, position: 'absolute', bottom: '10px', left: '10px', padding: '5px',
            }}
          >
            <FontAwesomeIcon icon={faBars} size="2x" />
          </Paper>
        )}
      </Box>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    global,
  } = state
  const { user } = global

  return { user }
}

MappingDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dashboardLocked: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  height: PropTypes.string,
  site: SiteDetailsProptype.isRequired,
  toggleMapExpanded: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string,
  zIndex: PropTypes.number,
}

export default connect(mapStateToProps)(withStyles(styles)(MappingDetails))
