import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './reducers'
import { saveState } from './localStorage'
import privateRequest from './middleware/PrivateRequest'
import { trackPageView } from './helpers/GAHelper'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const history = createBrowserHistory()
history.listen((location) => {
  trackPageView(location.pathname)
})

const appReducer = rootReducer(history)

export default function configureStore(initialState = {}) {
  const store = createStore(
    appReducer,
    initialState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        privateRequest,
      ),
    ),
  )

  store.subscribe(() => {
    saveState({
      dashboard: store.getState().dashboard,
      darkModeReducer: store.getState().darkModeReducer,
    })
  })

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => store.replaceReducer(appReducer))
  }

  return store
}
