import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import {
  Box,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core'
import RouterLocationPrototype from '../proptypes/RouterLocationPrototype'
import ExternalLinksContainer from './ExternalLinksContainer'
import WelcomeTextsContainer from './WelcomeTextsContainer'
import ThemesContainer from './ThemesContainer'
import RolesContainer from './RolesContainer'
import UsersContainer from './UsersContainer'
import CustomerSettingsContainer from './CustomerSettingsContainer'
import NeatPermissions from '../helpers/NeatPermissions'

const styles = theme => ({
  tabs: {
    height: '100vh',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
})

class AdminContainer extends React.PureComponent {
  // Do not remove this _event argument even though it is unused.
  // If you pass a reference to a function as on onClick or onChange
  // prop, react will provide the dom event as the first argument to that function
  // by default. This needs to remain here so we can specify the second
  // argument of the route that is changing. Removing this _event arg will cause a bug.
  handleChangeRoute = (_event, route) => {
    const { dispatch } = this.props
    dispatch(push(route))
  }

  renderUsers() {
    const { dispatch } = this.props
    return (
      <UsersContainer dispatch={dispatch} />
    )
  }

  renderRoles() {
    const { dispatch } = this.props
    return (
      <RolesContainer dispatch={dispatch} />
    )
  }

  renderThemes() {
    const { dispatch } = this.props
    return (
      <ThemesContainer dispatch={dispatch} />
    )
  }

  renderExternalLinks() {
    const { dispatch } = this.props
    return (
      <ExternalLinksContainer dispatch={dispatch} />
    )
  }

  renderWelcomeTexts() {
    const { dispatch } = this.props
    return (
      <WelcomeTextsContainer dispatch={dispatch} />
    )
  }

  renderCustomerSettings() {
    const { dispatch } = this.props
    return (
      <CustomerSettingsContainer dispatch={dispatch} />
    )
  }

  render() {
    const {
      location, match, classes, user,
    } = this.props
    return (
      <Box display="flex" flexDirection="row">
        <Tabs
          orientation="vertical"
          value={location.pathname}
          onChange={this.handleChangeRoute}
          className={classes.tabs}
        >
          <Tab label="Users" value={`${match.url}/users`} />
          {user.hasPermission(NeatPermissions.admin)
            && <Tab label="Roles" value={`${match.url}/roles`} />
          }
          {user.hasPermission(NeatPermissions.admin)
            && <Tab label="Themes" value={`${match.url}/themes`} />
          }
          {user.hasPermission(NeatPermissions.admin)
            && <Tab label="External Links" value={`${match.url}/external_links`} />
          }
          {user.hasPermission(NeatPermissions.admin)
            && <Tab label="Welcome Texts" value={`${match.url}/welcome_texts`} />
          }
          {user.hasPermission(NeatPermissions.admin)
            && <Tab label="Customer Settings" value={`${match.url}/customer_settings`} />
          }
        </Tabs>
        <Route exact path={`${match.url}/users`} render={() => this.renderUsers()} />
        {user.hasPermission(NeatPermissions.admin) && (
          <React.Fragment>
            <Route exact path={`${match.url}/roles`} render={() => this.renderRoles()} />
            <Route exact path={`${match.url}/themes`} render={() => this.renderThemes()} />
            <Route exact path={`${match.url}/external_links`} render={() => this.renderExternalLinks()} />
            <Route exact path={`${match.url}/welcome_texts`} render={() => this.renderWelcomeTexts()} />
            <Route exact path={`${match.url}/customer_Settings`} render={() => this.renderCustomerSettings()} />
          </React.Fragment>
        )}
      </Box>
    )
  }
}

AdminContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  location: RouterLocationPrototype.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
}

// i'm not sure why, but dispatch wasn't coming through without this here...?
function mapStateToProps(state) {
  const {
    global,
  } = state
  const { user } = global

  return { user }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(AdminContainer)
