import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { fetchOutageDashboard } from '../actions/OutagesActions'
import OutageDashboardTable from '../components/OutageDashboardTable'
import CircularProgressSpinner from '../components/CircularProgressSpinner'

// this component is meant to handle all of the setup for the outage dashboard
// defining the headers/rows and data fetchers ect... look in OutageDashboardTable for all
// of the logic related to the table and filtering
class OutageDashboardContainer extends Component {
  componentDidMount() {
    this.handleFetchData()
  }

  headers = () => ([
    { id: 'ticketId', label: 'NEAT Ticket ID' },
    { id: 'status', label: 'Outage Status' },
    { id: 'assignee', label: 'Tier 2 POC' },
    { id: 'location', label: 'Property' },
    { id: 'poc', label: 'POC' },
    { id: 'region', label: 'Region' },
    { id: 'topology', label: 'Centralized/Distributed' },
    { id: 'category', label: 'Outage Type' },
    { id: 'totalDeviceCount', label: 'Total Devices' },
    { id: 'totalDownDeviceCount', label: 'Total Devices Down' },
    { id: 'downPercent', label: 'Percent Down' },
    { id: 'sparingAccountWbs', label: 'Sparing Ticket #' },
    { id: 'jobNumber', label: 'Biller WO#' },
    // { id: 'TBD', label: 'Schedule Date' },
    { id: 'ticketState', label: 'What is our next step(s)' },
    {
      id: 'source_prefix',
      label: 'Source Prefix',
      filterable: false,
      hidden: true,
    },
  ])

  rows = () => {
    const { outageDashboardTickets } = this.props
    const { tickets } = outageDashboardTickets
    return tickets.map(ticket => this.createRow(ticket))
  }

  createRow = ticket => (
    {
      ticketId: ticket.ticket_id.replace(/cwzd|cw|zd/, ''),
      status: ticket.status,
      assignee: ticket.assignee_name,
      location: ticket.location,
      poc: ticket.point_of_contact,
      region: ticket.region_name,
      topology: ticket.topology,
      category: ticket.category,
      totalDeviceCount: ticket.total_device_count,
      totalDownDeviceCount: ticket.total_down_device_count,
      downPercent: ticket.down_percent,
      sparingAccountWbs: ticket.sparing_account_wbs,
      jobNumber: ticket.job_number,
      ticketState: ticket.ticket_state,
      rowColor: ticket.row_color,
      siteId: ticket.site_id,
      source_prefix: ticket.ticket_id.replace(/\d+$/, ''),
    }
  )

  handleFetchData = (filters = {}) => {
    const { dispatch } = this.props
    dispatch(fetchOutageDashboard(filters))
  }

  render() {
    const { outageDashboardTickets, dispatch, user } = this.props
    const { availableTicketGroups } = outageDashboardTickets
    return Object.keys(outageDashboardTickets).length > 0 ? (
      <OutageDashboardTable
        availableTicketGroups={availableTicketGroups}
        dispatch={dispatch}
        headers={this.headers()}
        requestOutages={this.handleFetchData}
        rows={this.rows()}
        user={user}
      />
    ) : (
      <Box width="fit-content" ml="auto" mr="auto" display="flex" flexDirection="row" p={2}>
        <CircularProgressSpinner />
        <Typography style={{ marginLeft: '1rem' }} variant="h5">Loading Outage Dashboard...</Typography>
      </Box>
    )
  }
}

OutageDashboardContainer.propTypes = {
  availableTicketGroups: PropTypes.arrayOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  outageDashboardTickets: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
}
OutageDashboardContainer.defaultProps = {
  outageDashboardTickets: {},
  user: {},
}


function mapStateToProps(state) {
  const {
    outagesReducer,
  } = state
  const {
    outageDashboardTickets,
  } = outagesReducer
  return {
    outageDashboardTickets,
  }
}

export default compose(
  connect(mapStateToProps),
)(OutageDashboardContainer)
