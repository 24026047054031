import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS'
function requestCustomers() {
  return {
    type: REQUEST_CUSTOMERS,
  }
}

export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS'
function receiveCustomers(customers) {
  return {
    type: RECEIVE_CUSTOMERS,
    customers,
  }
}

export const FAILURE_CUSTOMERS = 'FAILURE_CUSTOMERS'
function failureCustomers(error) {
  return {
    type: FAILURE_CUSTOMERS,
    error,
  }
}

export const REQUEST_CUSTOMER_REGIONS = 'REQUEST_CUSTOMER_REGIONS'
function requestCustomerRegions(customerId) {
  return {
    type: REQUEST_CUSTOMER_REGIONS,
    customerId,
  }
}

export const RECEIVE_CUSTOMER_REGIONS = 'RECEIVE_CUSTOMER_REGIONS'
function receiveCustomerRegions(regions) {
  return {
    type: RECEIVE_CUSTOMER_REGIONS,
    regions,
  }
}

export const FAILURE_CUSTOMER_REGION = 'FAILURE_CUSTOMER_REGION'
function failureCustomerRegions(error) {
  return {
    type: FAILURE_CUSTOMER_REGION,
    error,
  }
}

export const REQUEST_CUSTOMER_SITES = 'REQUEST_CUSTOMER_SITES'
function requestCustomerSites(customerId) {
  return {
    type: REQUEST_CUSTOMER_SITES,
    customerId,
  }
}

export const RECEIVE_CUSTOMER_SITES = 'RECEIVE_CUSTOMER_SITES'
function receiveCustomerSites(sites) {
  return {
    type: RECEIVE_CUSTOMER_SITES,
    sites,
  }
}

export const FAILURE_CUSTOMER_SITES = 'FAILURE_CUSTOMER_SITES'
function failureCustomerSites(error) {
  return {
    type: FAILURE_CUSTOMER_SITES,
    error,
  }
}

export const REQUEST_CUSTOMER_TAGS = 'REQUEST_CUSTOMER_TAGS'
function requestCustomerTags(customerId) {
  return {
    type: REQUEST_CUSTOMER_TAGS,
    customerId,
  }
}

export const RECEIVE_CUSTOMER_TAGS = 'RECEIVE_CUSTOMER_TAGS'
function receiveCustomerTags(tags) {
  return {
    type: RECEIVE_CUSTOMER_TAGS,
    tags,
  }
}

export const FAILURE_CUSTOMER_TAGS = 'FAILURE_CUSTOMER_TAGS'
function failureCustomerTags(error) {
  return {
    type: FAILURE_CUSTOMER_TAGS,
    error,
  }
}

export function fetchCustomers() {
  return (dispatch) => {
    dispatch(requestCustomers())
    return fetch('/api/customers')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveCustomers(json)))
      .catch(error => dispatch(failureCustomers(error)))
  }
}

export function fetchCustomerRegions(customerId) {
  return (dispatch) => {
    dispatch(requestCustomerRegions(customerId))
    return fetch(`/api/customers/${customerId}/regions`)
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveCustomerRegions(json)))
      .catch(error => dispatch(failureCustomerRegions(error)))
  }
}

export function fetchCustomerSites(customerId) {
  return (dispatch) => {
    dispatch(requestCustomerSites(customerId))
    return fetch(`/api/customers/${customerId}/sites`)
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveCustomerSites(json)))
      .catch(error => dispatch(failureCustomerSites(error)))
  }
}

export function fetchCustomerTags(customerId) {
  return (dispatch) => {
    dispatch(requestCustomerTags(customerId))
    return fetch(`/api/customers/${customerId}/customer_tags`)
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveCustomerTags(json)))
      .catch(error => dispatch(failureCustomerTags(error)))
  }
}
