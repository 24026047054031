const ticketStatusLegend = {
  new: {
    description: 'Indicates that no action has been taken on the ticket and requires attention.',
    color: '#f5ca03',
  },
  open: {
    description: 'Indicates a ticket is in progress and being worked on.',
    color: '#e8292a',
  },
  pending: {
    description: 'Indicates a ticket requires additional information from a requester',
    color: '#59bbe0',
  },
  hold: {
    description: 'Indicates a ticket requires additional information from a 3rd party (E.g. Field Technician, Dispatch, IT)',
    color: '#000000',
  },
  solved: {
    description: 'Indicates a ticket has been resolved. The ticket can be re-opened if the issue is not resolved. Tickets remain in a solved state for approximately 1 hour.',
    color: '#828282',
  },
  closed: {
    description: 'Indicates a ticket has been locked and can no longer be edited. A follow-up ticket can be created.',
    color: '#d8dcde',
  },
  unknown: {
    description: 'Indicates a ticket does not have a recognized status. (I.e. a blank status)',
    color: '#828282',
  }
}

export default ticketStatusLegend
