import ReactGA from 'react-ga'
import { getRoute } from './RouteHelper'

const getParams = (path, route) => {
  const rest = path.replace(route.path, '').split('/').filter(m => m)
  const params = rest
    .slice(0, route.params.length)
    .map((v, i) => ({ [route.params[i]]: v }))
    .reduce((a, b) => ({ ...a, ...b }), {})

  params.page = route.path
  return params
}

export const trackEvent = ({ category, action, ...rest }) => {
  ReactGA.event({
    category,
    action,
    ...rest,
  })
}

export const trackPageView = (path) => {
  const route = getRoute(path)
  if (route) {
    ReactGA.pageview(route.path, null, route.title)
    trackEvent({
      category: 'Pageview', action: 'Visited Page', label: route.title, ...getParams(path, route),
    })
  }
}
