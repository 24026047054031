export default (
  {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabs: {
      height: '100vh',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundColor: 'white',
    },
    error: {
      backgroundColor: '#d32f2f',
    },
    deleteButton: {
      color: 'red',
    },
    deactivateButton: {
      color: 'red',
    },
    activateButton: {
      color: '#0F4780',
    },
  }
)
