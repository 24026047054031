import PropTypes from 'prop-types'

export default (
  PropTypes.shape({
    ticket_id: PropTypes.string,
    submitter_person_id: PropTypes.string,
    assignee_person_id: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
    priority: PropTypes.string,
  })
)
