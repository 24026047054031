import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_ROLES = 'REQUEST_ROLES'
function requestRoles() {
  return {
    type: REQUEST_ROLES,
  }
}

export const RECEIVE_ROLES = 'RECEIVE_ROLES'
function receiveRoles(roles) {
  return {
    type: RECEIVE_ROLES,
    roles,
  }
}

export const FAILURE_ROLES = 'FAILURE_ROLES'
function failureRoles(error) {
  return {
    type: FAILURE_ROLES,
    error,
  }
}

export const REQUEST_CREATE_ROLE = 'REQUEST_CREATE_ROLE'
function requestCreateRole(role) {
  return {
    type: REQUEST_CREATE_ROLE,
    role,
  }
}

export const RECEIVE_CREATE_ROLE = 'RECEIVE_CREATE_ROLE'
function receiveCreateRole(role) {
  return {
    type: RECEIVE_CREATE_ROLE,
    role,
  }
}

export const FAILURE_CREATE_ROLE = 'FAILURE_CREATE_ROLE'
function failureCreateRole(error) {
  return {
    type: FAILURE_CREATE_ROLE,
    error,
  }
}

export const REQUEST_EDIT_ROLE = 'REQUEST_EDIT_ROLE'
function requestEditRole(role) {
  return {
    type: REQUEST_EDIT_ROLE,
    role,
  }
}

export const RECEIVE_EDIT_ROLE = 'RECEIVE_EDIT_ROLE'
function receiveEditRole(role) {
  return {
    type: RECEIVE_EDIT_ROLE,
    role,
  }
}

export const FAILURE_EDIT_ROLE = 'FAILURE_EDIT_ROLE'
function failureEditRole(error) {
  return {
    type: FAILURE_EDIT_ROLE,
    error,
  }
}

export const REQUEST_DELETE_ROLE = 'REQUEST_DELETE_ROLE'
function requestDeleteRole(role) {
  return {
    type: REQUEST_DELETE_ROLE,
    role,
  }
}

export const RECEIVE_DELETE_ROLE = 'RECEIVE_DELETE_ROLE'
function receiveDeleteRole(role) {
  return {
    type: RECEIVE_DELETE_ROLE,
    role,
  }
}

export const FAILURE_DELETE_ROLE = 'FAILURE_DELETE_ROLE'
function failureDeleteRole(error) {
  return {
    type: FAILURE_DELETE_ROLE,
    error,
  }
}

export function fetchRoles() {
  return (dispatch) => {
    dispatch(requestRoles())
    return fetch('/api/roles')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveRoles(json)))
      .catch(error => dispatch(failureRoles(error)))
  }
}

export function fetchCreateRole(role) {
  return (dispatch) => {
    dispatch(requestCreateRole(role))
    return fetch('/api/roles/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchRoles())
          dispatch(receiveCreateRole(role))
          return json
        }
        dispatch(failureCreateRole(json))
        throw json
      })
  }
}

export function fetchEditRole(role) {
  return (dispatch) => {
    dispatch(requestEditRole(role))
    return fetch('/api/roles/edit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchRoles())
          dispatch(receiveEditRole(role))
          return json
        }
        dispatch(failureEditRole(json))
        throw json
      })
  }
}

export function fetchDeleteRole(role) {
  return (dispatch) => {
    dispatch(requestDeleteRole(role))
    return fetch('/api/roles/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ role }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchRoles())
          dispatch(receiveDeleteRole(role))
          return json
        }
        dispatch(failureDeleteRole(json))
        throw json
      })
  }
}
