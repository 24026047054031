export default (state = {}, action) => {
  const { type } = action
  const matches = /((?<reqstate>REQUEST|RECEIVE|FAILURE)_(?<reqname>.*)|SET_ERROR)/.exec(type)

  // not a REQUEST / RECEIVE / FAILURE action, so we ignore them
  if (!matches) return state

  const requestState = matches.groups.reqstate
  const requestName = matches.groups.reqname

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    [requestName]: requestState === 'REQUEST',
  }
}

// returns true only when all actions are not loading
export const isFetchingRequests = state => Object.values(state.loadingReducer)
  .filter(action => action).length !== 0
