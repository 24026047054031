import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  descriptionText: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}))

export default function TableLegend(props) {
  const { legendMapping, getIcon } = props
  const [visible, setVisible] = React.useState(false)
  const classes = useStyles()

  const handleSetVisible = () => {
    setVisible(!visible)
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel onChange={handleSetVisible}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="legend-content"
          id="legend-header"
        >
          <Typography className={classes.heading}>{visible ? 'Hide Legend' : 'Show Legend'}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box className={classes.flex}>
            <TableLegendDetails legendMapping={legendMapping} getIcon={getIcon} />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

function TableLegendDetails(props) {
  const { legendMapping, getIcon } = props
  const classes = useStyles()
  return (
    Object.keys(legendMapping).map(key => (
      <Box key={key} style={{ textAlign: 'center' }}>
        { getIcon(key) }
        <br />
        <Typography className={classes.descriptionText} variant="body2">{legendMapping[key].description}</Typography>
      </Box>
    ))
  )
}

TableLegend.propTypes = {
  legendMapping: PropTypes.instanceOf(Object).isRequired,
  getIcon: PropTypes.func.isRequired,
}
