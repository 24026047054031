/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography, Grid, withStyles, TextField, Button, Box, Paper,
} from '@material-ui/core'

import ManagerModalStyles from '../styles/ManagerModalStyles'
import DeleteConfirmation from './DeleteConfirmation'

class ExternalLinkManager extends React.Component {
  constructor(props) {
    super(props)
    const { link } = props
    const {
      display_name, url, resource, tooltip_message,
    } = link
    this.state = {
      display_name: display_name || '',
      url: url || '',
      resource: resource || '',
      tooltip_message: tooltip_message || '',
    }
  }

  handleChange = field => (event) => {
    this.setState({ [field]: event.target.value })
  }

  saveExternalLink() {
    const {
      display_name, url, resource, tooltip_message,
    } = this.state
    const { onSubmit, link } = this.props
    const { id } = link

    if (display_name && url && resource && tooltip_message) {
      const linkData = new FormData()
      if (id) {
        linkData.append('id', id)
      }
      linkData.append('display_name', display_name)
      linkData.append('url', url)
      linkData.append('resource', resource)
      linkData.append('tooltip_message', tooltip_message)
      onSubmit(linkData)
    } else this.setState({ error: true })
  }

  renderFormFields() {
    const { classes } = this.props
    const {
      display_name, url, resource, tooltip_message,
    } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <TextField className={classes.formControl} required label="Name" name="display_name" value={display_name} onChange={event => this.setState({ display_name: event.target.value })} />
        </Grid>
        <Grid item>
          <TextField className={classes.formControl} required label="URL" name="url" value={url} onChange={event => this.setState({ url: event.target.value })} />
        </Grid>

        <Grid item>
          <TextField className={classes.formControl} required label="Resource" name="resource" value={resource} onChange={event => this.setState({ resource: event.target.value })} />
        </Grid>

        <Grid item>
          <TextField className={classes.formControl} required label="Tooltip Message" name="tooltip_message" value={tooltip_message} onChange={event => this.setState({ tooltip_message: event.target.value })} />
        </Grid>
      </React.Fragment>
    )
  }

  renderForm() {
    const { type } = this.props
    const {
      error,
    } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {type === 'create' ? 'Create' : 'Edit'} External Link
          </Typography>
        </Grid>
        { this.renderFormFields() }
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => this.saveExternalLink()}>
            <Typography>Save External Link</Typography>
          </Button>
          {error && <Typography color="error">All fields are required</Typography>}
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {
      classes, type, onClose, onSubmit, link,
    } = this.props

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
        <Grid container direction="column" spacing={2} className={classes.container}>
          {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={link}
                title="External Link"
              />
            )
          }
          {(type === 'create' || type === 'edit') && this.renderForm()}
        </Grid>
      </Box>
    )
  }
}

ExternalLinkManager.propTypes = {
  display_name: PropTypes.string,
  url: PropTypes.string,
  resource: PropTypes.string,
  tooltip_message: PropTypes.string,
  link: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

ExternalLinkManager.defaultProps = {
  display_name: '',
  url: '',
  resource: '',
  tooltip_message: '',
  link: {},
  type: '',
}


export default withStyles(ManagerModalStyles)(ExternalLinkManager)
