export default function HandleTimeout(response) {
  if (response.status === 401) {
    // eslint-disable-next-line no-alert
    alert('Your session has timed out. Please press OK to log in again.')
    // stop all other requests that may be pending
    window.stop()
    // relaod the window and let devise handle redirecting to the login page
    window.location.reload()
    return false
  }
  return response
}
