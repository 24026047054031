import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'
import Moment from 'moment'

import {
  Box, ListItemText, MenuItem, MenuList, withStyles,
} from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import {
  fetchNinetyDayActivations,
  fetchNinetyDayTickets, fetchNinetyDayTicketsCsv,
} from '../actions/SitesActions'
import NeatTable from '../components/NeatTable'
import TicketsDetailsProptype from '../proptypes/TicketsDetailsProptype'
import CounterWidget from '../components/CounterWidget'
import { Titleize } from '../helpers/StringHelper'
import SlideUpModal from '../components/SlideUpModal'
import TicketCommentsContainer from './TicketCommentsContainer'
import ticketStatusLegend from '../constants/TicketStatusLegend'
import { getStatusIcon, statusFormat } from '../helpers/TicketStatusHelper'

const styles = theme => ({
  menu: {
    height: '100%',
    width: '25%',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  table: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
})

class NinetyDayActivationsContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    const { dispatch, ninetyDaySites } = props
    let site = {}
    if (ninetyDaySites.length === 0) {
      dispatch(fetchNinetyDayActivations())
    } else {
      [site] = ninetyDaySites
      dispatch(fetchNinetyDayTickets(site.id))
    }
    this.state = {
      filters: {},
      modalOpen: false,
      site,
      ticketId: '',
      ticketSourcePrefix: '',
    }
  }

  componentDidUpdate(prevProps) {
    const { ninetyDaySites } = this.props
    if (prevProps.ninetyDaySites.length === 0 && ninetyDaySites.length > 0) {
      this.handleMenuClick(ninetyDaySites[0])
    }
  }

  ticketCommentsContainer = () => {
    const { ticketId, ticketSourcePrefix } = this.state
    // JTP: Nolan, I don't know how to do the needful here...
    // (the thing that I hacked in TicketDashboardContainer)
    return (
      <TicketCommentsContainer ticketId={`${ticketSourcePrefix}${ticketId}`} />
    )
  }

  toggleModal = (ticket = {}) => {
    const { modalOpen } = this.state
    const stateObj = { modalOpen: !modalOpen }
    if (Object.keys(ticket).length) {
      const ticketId = ticket.ticket_id
      const ticketSourcePrefix = ticket.source_prefix
      stateObj.ticketId = ticketId
      stateObj.ticketSourcePrefix = ticketSourcePrefix
    }

    this.setState(stateObj)
  }

  headers = () => ([
    {
      id: 'ticket_id', label: 'Ticket ID',
    },
    {
      id: 'caller_type', label: 'Caller Type',
    },
    {
      id: 'category', label: 'Category',
    },
    {
      id: 'created_at', format: val => Moment(val).format('LLL'), label: 'Date',
    },
    {
      id: 'ticket_people.name', label: 'Assignee',
    },
    {
      id: 'ticket_groups.name', label: 'Group',
    },
    {
      id: 'ticket_state', label: 'State',
    },
    {
      id: 'status',
      label: 'Status',
      filterable: true,
      mapping: {
        open: 'open', hold: 'hold', pending: 'pending', new: 'new', closed: 'closed', solved: 'solved', resolved: 'resolved', unresolved: 'unresolved',
      },
      format: statusFormat,
    },
    {
      id: 'resolution_category', label: 'Resolution Category',
    },
    {
      id: 'subject', label: 'Subject',
    },
    {
      id: 'building_apartment_or_room_number', label: 'Apt/Room',
    },
    {
      id: 'total_time_spent_sec', label: 'Total Time',
    },
    {
      id: 'source_prefix',
      label: 'Source Prefix',
      filterable: false,
      hidden: true
    }
  ])

  createRows = (tickets) => {
    const rows = []
    tickets.forEach((ticket) => {
      rows.push(this.createRow(ticket))
    })
    return rows
  }

  rows = () => {
    const { ninetyDayTickets } = this.props
    return this.createRows(ninetyDayTickets.tickets)
  }

  createRow = ticket => (
    {
      ticket_id: ticket.ticket_id.replace(/cwzd|cw|zd/, ''),
      caller_type: ticket.caller_type,
      category: ticket.category,
      created_at: ticket.created_at,
      'ticket_people.name': ticket.assignee ? ticket.assignee.name : '',
      'ticket_groups.name': ticket.group ? ticket.group.name : '',
      ticket_state: ticket.ticket_state,
      status: ticket.status,
      resolution_category: ticket.resolution_category,
      subject: ticket.subject,
      building_apartment_or_room_number: ticket.building_apartment_or_room_number,
      total_time_spent_sec: Moment.utc(ticket.total_time_spent_sec * 1000).format('HH:mm:ss'),
      source_prefix: ticket.ticket_id.replace(/\d+/, ''),
    }
  )

  handleMenuClick = (site) => {
    const { dispatch } = this.props
    const { filters } = this.state
    dispatch(fetchNinetyDayTickets(site.id, filters))
    this.setState({ site })
  }

  handleRequest = (filters) => {
    const { dispatch } = this.props
    const { site } = this.state
    dispatch(fetchNinetyDayTickets(site.id, filters))
    this.setState({ filters })
  }

  handleTileClick = (status) => {
    const { filters } = this.state
    filters.status = status
    this.handleRequest(filters)
  }

  initializeStatusCounters = () => {
    const { ninetyDayTickets } = this.props
    const { counts } = ninetyDayTickets
    const statuses = ['new', 'open', 'pending', 'hold', 'solved', 'closed']
    return statuses.map(status => (
      {
        label: Titleize(status),
        count: counts[status] || 0,
        onClick: () => this.handleTileClick(status),
      }
    ))
  }

  renderStatusCounters = () => {
    const statusCounters = this.initializeStatusCounters()

    return (
      <Box mb={1} width="100%">
        <Grid container spacing={1} justify="center">
          {
            statusCounters.map(statusCounter => (
              <Grid key={statusCounter.label} item xs>
                <CounterWidget clickable label={statusCounter.label} value={`${statusCounter.count}`} handleClick={statusCounter.onClick} />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    )
  }

  render() {
    const {
      dispatch, classes, ninetyDaySites, ninetyDayTickets, ninetyDayTicketsCsv,
    } = this.props
    const { counts } = ninetyDayTickets
    const { filters, site, modalOpen } = this.state

    return (
      <Box height="100%" display="flex" flexDirection="row">
        <MenuList className={classes.menu}>
          {ninetyDaySites.map((s, index) => (
            <MenuItem
              key={s.name}
              selected={ninetyDaySites[index] === site}
              onClick={() => this.handleMenuClick(s)}
            >
              <ListItemText
                primary={s.name}
                secondary={(
                  <React.Fragment>
                    {`Activation: ${Moment(s.activation_date).format('LL')}`}
                    <br />
                    {`Tickets: ${s.ticket_count}`}
                  </React.Fragment>
                )}
              />
            </MenuItem>
          ))}
        </MenuList>
        <Box m={1} width="75%" maxHeight="100%">
          { this.renderStatusCounters() }
          <NeatTable
            className={classes.table}
            serverSide
            small
            totalCount={counts.total}
            pageSize={ninetyDayTickets.page_size}
            headers={this.headers()}
            rows={this.rows()}
            rowsForCsv={this.createRows(ninetyDayTicketsCsv)}
            csvRequest={callback => dispatch(fetchNinetyDayTicketsCsv(site.id, filters, callback))}
            setFilterValues={filters}
            onRowClicked={this.toggleModal}
            onHandleRequest={this.handleRequest}
            legendMapping={ticketStatusLegend}
            getIcon={getStatusIcon}
          />
        </Box>
        <SlideUpModal
          open={modalOpen}
          onClose={() => this.toggleModal()}
          childComponent={this.ticketCommentsContainer()}
          title="Ticket Details"
        />
      </Box>
    )
  }
}

NinetyDayActivationsContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  ninetyDaySites: PropTypes.arrayOf(Object).isRequired,
  ninetyDayTickets: TicketsDetailsProptype.isRequired,
  ninetyDayTicketsCsv: PropTypes.arrayOf(Object).isRequired,
}

function mapStateToProps(state) {
  const {
    sitesReducer,
  } = state
  const {
    ninetyDaySites,
    ninetyDayTickets,
    ninetyDayTicketsCsv,
  } = sitesReducer
  return {
    ninetyDaySites,
    ninetyDayTickets,
    ninetyDayTicketsCsv,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(NinetyDayActivationsContainer)
