/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography, Grid, withStyles, TextField, Button, Box, Paper, Checkbox, FormControlLabel,
} from '@material-ui/core'

import ManagerModalStyles from '../../styles/ManagerModalStyles'
import DeleteConfirmation from '../DeleteConfirmation'

class OutageAlertManager extends React.Component {
  constructor(props) {
    super(props)
    const { outageAlert, type } = props
    const {
      message, service_alert, active,
    } = outageAlert
    this.state = {
      message: message || '',
      serviceAlert: service_alert || false,
      active: type === 'create' || active,
    }
  }

  saveOutageAlert() {
    const {
      message, serviceAlert, active,
    } = this.state
    const { onSubmit, outageAlert, siteId } = this.props
    const { id } = outageAlert

    if (message) {
      const outageAlertData = new FormData()
      if (id) {
        outageAlertData.append('id', id)
      }
      outageAlertData.append('message', message)
      outageAlertData.append('service_alert', serviceAlert)
      outageAlertData.append('active', active)
      outageAlertData.append('site_id', siteId)
      onSubmit(outageAlertData)
    } else this.setState({ error: true })
  }

  renderFormFields() {
    const { classes } = this.props
    const {
      message, serviceAlert, active,
    } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <TextField className={classes.formControl} required label="Message" name="message" value={message} onChange={event => this.setState({ message: event.target.value })} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={serviceAlert} onChange={event => this.setState({ serviceAlert: event.target.checked })} name="serviceAlert" />}
            label="Service Alert?"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={active} onChange={event => this.setState({ active: event.target.checked })} name="active" />}
            label="Active?"
          />
        </Grid>
      </React.Fragment>
    )
  }

  renderForm() {
    const { type } = this.props
    const {
      error,
    } = this.state
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {type === 'create' ? 'Create' : 'Edit'} Outage Alert
          </Typography>
        </Grid>
        { this.renderFormFields() }
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => this.saveOutageAlert()}>
            <Typography>Save Outage Alert</Typography>
          </Button>
          {error && <Typography color="error">All fields are required</Typography>}
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const {
      classes, type, onClose, onSubmit, outageAlert,
    } = this.props

    return (
      <Box tabIndex={-1} component={Paper} p={2} className={classes.root}>
        <Grid container direction="column" spacing={2} className={classes.container}>
          {
            type === 'delete'
            && (
              <DeleteConfirmation
                classes={classes}
                onClose={onClose}
                onDelete={onSubmit}
                record={outageAlert}
                title="Outage Alert"
              />
            )
          }
          {(type === 'create' || type === 'edit') && this.renderForm()}
        </Grid>
      </Box>
    )
  }
}

OutageAlertManager.propTypes = {
  outageAlert: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
}

OutageAlertManager.defaultProps = {
  outageAlert: {},
  type: '',
}


export default withStyles(ManagerModalStyles)(OutageAlertManager)
