import HandleTimeout from '../helpers/SessionHelper'

export const REQUEST_REGIONS = 'REQUEST_REGIONS'
function requestRegions() {
  return {
    type: REQUEST_REGIONS,
  }
}

export const RECEIVE_REGIONS = 'RECEIVE_REGIONS'
function receiveRegions(regions) {
  return {
    type: RECEIVE_REGIONS,
    regions,
  }
}

export const FAILURE_REGIONS = 'FAILURE_REGIONS'
function failureRegions(regions) {
  return {
    type: FAILURE_REGIONS,
    regions,
  }
}

export function fetchRegions() {
  return (dispatch) => {
    dispatch(requestRegions())
    return fetch('/api/regions')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveRegions(json)))
      .catch(error => dispatch(failureRegions(error)))
  }
}
