import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = () => ({
  root: {
    marginTop: '-2rem',
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 450,
    overflow: 'auto',
  },
  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
})

class ClientReportingSessionInfo extends React.Component {
  constructor(props) {
    super(props)

    this.keyTitles = {
      date: 'Session Date',
      total_active_minutes: 'Active Time',
      total_down_pretty: 'Downloaded',
      total_up_pretty: 'Uploaded',
      total_pretty: 'Total Usage',
      device_type: 'Device Type',
      platform: 'Platform',
    }
  }

  columns = () => {
    const cols = this.displayColumns()
    return cols.map(col => ({
      id: col,
      name: this.keyTitles[col],
      minWidth: 130,
    }))
  }

  displayColumns = () => [
    'date',
    'total_active_minutes',
    'total_down_pretty',
    'total_up_pretty',
    'total_pretty',
    'device_type',
    'platform',
  ]

  renderHeaders = () => {
    const columns = this.columns()
    return columns.map(column => (
      <TableCell
        key={column.id}
        align={column.align}
        style={{ minWidth: column.minWidth }}
      >
        {column.name}
      </TableCell>
    ))
  }

  renderTableBody = (clientSessionDetails) => {
    const displayColumns = this.displayColumns()
    return (
      Object.keys(clientSessionDetails).length > 0 ? (
        Object.values(clientSessionDetails).map(row => (
          <TableRow hover tabIndex={-1} key={row.total_down_raw}>
            {Object.keys(row).map((key) => {
              if (displayColumns.includes(key)) {
                return (
                  <TableCell key={key} align="left">
                    {row[key]}
                  </TableCell>
                )
              }
              return false
            })}
          </TableRow>
        ))
      ) : (
        <TableRow hover tabIndex={-1} key="no-data-row">
          <TableCell key="no-data-cell" align="center" colSpan={12}>No Data To Display...</TableCell>
        </TableRow>
      )
    )
  }

  render() {
    const { classes, clientSessionDetails } = this.props
    return (
      <div className={classes.root}>
        <h4 className={classes.header}>Client Session Details</h4>
        <Paper>
          <div className={classes.tableWrapper}>
            <Table stickyHeader aria-label="Session Information" size={Object.keys(clientSessionDetails).length > 8 ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  { this.renderHeaders() }
                </TableRow>
              </TableHead>
              <TableBody>
                { this.renderTableBody(clientSessionDetails) }
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

ClientReportingSessionInfo.propTypes = {
  clientSessionDetails: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
}

function mapStateToProps(state) {
  const {
    sitesReducer,
  } = state

  const {
    clientSessionDetails,
    classes,
  } = sitesReducer
  return {
    clientSessionDetails,
    classes,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ClientReportingSessionInfo)
