import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  withStyles, Box,
} from '@material-ui/core'
// MenuList, MenuItem, ListItemText
import IframeRenderer from '../components/IframeRenderer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menu: {
    height: '100%',
    width: 'auto',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))
class NomadixUtilizationDashboardContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.links = {
      'Throughput Percent Of Circuit Per Site': 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3AjxRma38aBc%3ANzU4NjE3NTc1MDBlYTExMzUyMzM4YzhkOTE1NWYzNGM5NTZiNTQxMGJiYjE1ZjVhMWM3NjBlOWRjMjRkYzIxMA%3D%3D#widget=4126',
      'Nomadix 5x00 Client License Utilization Per Site': 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3Aa8evG8GfQz%3AOGI3OTA5MGUwYTQ3MGJiMmFjZTBiNGZjZmViMWVlNGE2MWFkYjVlMTg2NDYzM2UxMjY5ODAyZTZhY2I4MDJjNQ%3D%3D#widget=4124',
      'Nomadix 2x00 Client License Utilization Per Site': 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3Adi2Q6d6q8v%3AMGVmMmNhOWY2NDA4MTYzMzg5ODY3MDFmZWVlOGJiMGYyNTJlYTE4NmFiNjUyN2RmNGIzZGZhODM0NDQ3MWI0Mg%3D%3D#widget=4128',
      'Throughput Threshold Violations': 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3A9B5MJjS46n%3ANmU4M2U2NzY3ODljMjliY2I0ZGI2YWNhMzVhZTk0YTcyOTM1MTc0N2RhNzViMjU2Zjk0OTc0ODM5MTMzZmQ3Mw%3D%3D#widget=4121',
      'Client License Usage Alerts': 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3A9xB84FiDC3%3ANDY5NDNmNzEzYzk5ZWQ1NjEwYWI5MDMxNjg4MTFlYjliMGNhNTkyM2I2ZGYxNzNlOGIwOTBjOGU3NjYyMGY1YQ%3D%3D#widget=4122',
    }
    // this.state = { selectedGraph: 'https://ccisystems.logicmonitor.com/santaba/sharedwidget.jsp?auth=LMSTv1%3A9B5MJjS46n%3ANmU4M2U2NzY3ODljMjliY2I0ZGI2YWNhMzVhZTk0YTcyOTM1MTc0N2RhNzViMjU2Zjk0OTc0ODM5MTMzZmQ3Mw%3D%3D#widget=4121' }
  }

  // handleMenuClick = (graph) => {
  //   this.setState({
  //     selectedGraph: graph,
  //   })
  // }

  render() {
    const { classes } = this.props
    return (
      <Box style={{ overflow: 'overlay' }} height="100%" display="flex" flexDirection="column">
        {Object.keys(this.links).map(link => (
          <Box className={classes.margin} width="100%" height="20%">
            <IframeRenderer iframe={this.links[link]} />
          </Box>
        ))}
        {/* I'm just gonna leave this commented because I have a feeling they may want it put back */}
        {/* <MenuList className={classes.menu}>
          {Object.keys(this.links).map((key, index) => (
            <MenuItem
              key={key}
              selected={Object.values(this.links)[index] === selectedGraph}
              onClick={() => this.handleMenuClick(this.links[key])}
            >
              <ListItemText
                primary={key}
              />
            </MenuItem>
          ))}
        </MenuList>
        <Box width="100%" height="100%">
          <IframeRenderer iframe={selectedGraph} />
        </Box> */}
      </Box>
    )
  }
}

function mapStateToProps(state) {
  const {
    sitesReducer,
  } = state
  const {
    graphs,
  } = sitesReducer
  return {
    graphs,
  }
}
NomadixUtilizationDashboardContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
}
export default compose(
  connect(mapStateToProps),
  withStyles(useStyles),
)(NomadixUtilizationDashboardContainer)
