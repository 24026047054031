import HandleTimeout from '../helpers/SessionHelper'
import NeatPermissions from '../helpers/NeatPermissions'
import { SET_ERROR } from './ErrorActions'

const querystring = require('querystring')

export const REQUEST_GATEWAYS_THROUGHPUT = 'REQUEST_GATEWAYS_THROUGHPUT'
function requestGatewaysThroughput(siteId) {
  return {
    type: REQUEST_GATEWAYS_THROUGHPUT,
    requiredPermission: NeatPermissions.gateways.throughput,
    siteId,
  }
}

export const RECEIVE_GATEWAYS_THROUGHPUT = 'RECEIVE_GATEWAYS_THROUGHPUT'
function receiveGatewaysThroughput(throughputData) {
  return {
    type: RECEIVE_GATEWAYS_THROUGHPUT,
    throughputData,
  }
}

function failureGatewaysThroughput(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export const REQUEST_GATEWAYS_CLIENT_COUNT = 'REQUEST_GATEWAYS_CLIENT_COUNT'
function requestGatewaysClientCount(siteId) {
  return {
    type: REQUEST_GATEWAYS_CLIENT_COUNT,
    requiredPermission: NeatPermissions.gateways.client_count,
    siteId,
  }
}

export const RECEIVE_GATEWAYS_CLIENT_COUNT = 'RECEIVE_GATEWAYS_CLIENT_COUNT'
function receiveGatewaysClientCount(clientCountData) {
  return {
    type: RECEIVE_GATEWAYS_CLIENT_COUNT,
    clientCountData,
  }
}

function failureGatewaysClientCount(error) {
  return {
    type: SET_ERROR,
    error,
  }
}

export function fetchGatewaysThroughput(siteId, filters = {}) {
  return (dispatch) => {
    if (dispatch(requestGatewaysThroughput(siteId))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      }
      return fetch(`/api/gateways/${siteId}/throughput?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveGatewaysThroughput(json))
          } else {
            dispatch(failureGatewaysThroughput(json))
          }
        })
    }
    return false
  }
}


export function fetchGatewaysClientCount(siteId, filters = {}) {
  return (dispatch) => {
    if (dispatch(requestGatewaysClientCount(siteId))) {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      }
      return fetch(`/api/gateways/${siteId}/client-count?${querystring.encode(params)}`)
        .then(response => HandleTimeout(response))
        .then(response => response.json().then(json => [json, response.ok]))
        .then(([json, ok]) => {
          if (ok) {
            dispatch(receiveGatewaysClientCount(json))
          } else {
            dispatch(failureGatewaysClientCount(json))
          }
        })
    }
    return false
  }
}
