export const REORDER_DASHBOARD_LAYOUT = 'REORDER_DASHBOARD_LAYOUT'
export const reorderDashboardLayout = layout => ({
  type: REORDER_DASHBOARD_LAYOUT,
  layout,
})

export const RESET_DASHBOARD_LAYOUT = 'RESET_DASHBOARD_LAYOUT'
export const resetDashboardLayout = () => ({
  type: RESET_DASHBOARD_LAYOUT,
})

export const TOGGLE_DASHBOARD_LOCK = 'TOGGLE_DASHBOARD_LOCK'
export const toggleDashboardLock = () => ({
  type: TOGGLE_DASHBOARD_LOCK,
})
