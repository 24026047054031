import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'moment'

import {
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
} from '@material-ui/core'

import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

import AdminStyles from '../styles/AdminStyles'

import {
  fetchCreateRole,
  fetchDeleteRole,
  fetchEditRole,
  fetchRoles,
} from '../actions/RolesActions'
import { fetchPermissions } from '../actions/PermissionsActions'


import NeatTable from '../components/NeatTable'
import UpdateRole from '../components/admin/UpdateRole'

class RolesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalType: '',
      modalObject: {},
      errors: [],
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchRoles())
    dispatch(fetchPermissions())
  }

  setModalOpen = (open, type, object = {}) => () => {
    if (object.customer) {
      this.onChooseCustomer(object.customer)
    }
    this.setState({ modalOpen: open, modalType: type, modalObject: object })
  }

  setErrors = (error) => {
    const errors = []
    Object.keys(error).forEach((key) => {
      error[key].forEach(value => errors.push(`${key} ${value}`))
    })
    this.setState({ errors })
  }

  clearErrors = () => this.setState({ errors: [] })

  headers = () => ([
    {
      id: 'id', label: 'Role ID',
    },
    {
      id: 'name', label: 'Name',
    },
    {
      id: 'permissions', label: 'Permissions',
    },
    {
      id: 'created_at', format: val => Moment(val).format('LLL'), label: 'Created',
    },
    {
      id: 'edit', label: '',
    },
    {
      id: 'delete', label: '',
    },
  ])

  rows = () => {
    const { roles } = this.props
    const rows = []
    roles.forEach((role) => {
      rows.push(this.createRow(role))
    })
    return rows
  }

  createRow = (role) => {
    const { classes, permissions } = this.props
    const resources = role.role_permissions.map(permission => permission.resource)
    return {
      id: role.id,
      name: role.name,
      permissions: permissions.filter(p => resources.includes(p.resource)).map(p => p.title).join(', '),
      created_at: role.created_at,
      edit: (
        <Button
          color="primary"
          onClick={this.setModalOpen(true, 'edit', role)}
        >
          Edit
        </Button>
      ),
      delete: (
        <Button
          className={classes.deleteButton}
          onClick={this.setModalOpen(true, 'delete', role)}
          disabled={role.in_use}
        >
          Delete
        </Button>
      ),
    }
  }

  updateOrCreateRole = (r) => {
    const { dispatch } = this.props
    const { modalType } = this.state
    let request
    if (modalType === 'create') {
      request = fetchCreateRole
    } else if (modalType === 'delete') {
      request = fetchDeleteRole
    } else {
      request = fetchEditRole
    }
    dispatch(request(r))
      .then(() => this.setState({ modalOpen: false }))
      .catch(error => this.setErrors(error))
  }

  clearErrors = () => {
    this.setState({ errors: [] })
  }

  renderErrors() {
    const { classes } = this.props
    const { errors } = this.state

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors.length > 0}
        autoHideDuration={6000}
        onClose={this.clearErrors}
      >
        <SnackbarContent
          className={classes.error}
          message={(
            <Box display="flex" flexDirection="row">
              <ErrorIcon />
              <Box mr={1} />
              <Typography>
                { errors.map(error => (
                  <React.Fragment key={error}>
                    {error}
                    <br />
                  </React.Fragment>
                )) }
              </Typography>
            </Box>
          )}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.clearErrors}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }

  renderModal = (open, type, object) => {
    const { dispatch, permissions, classes } = this.props
    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={this.setModalOpen(false)}
      >
        <React.Fragment>
          <UpdateRole
            type={type}
            dispatch={dispatch}
            availablePermissions={permissions}
            role={object}
            onSubmit={this.updateOrCreateRole}
            onClose={this.setModalOpen(false)}
          />
        </React.Fragment>
      </Modal>
    )
  }

  render() {
    const { modalOpen, modalType, modalObject } = this.state

    return (
      <Box width="100%" p={1}>
        <Box display="flex" justifyContent="flex-end" m={1}>
          <Button color="primary" variant="contained" onClick={this.setModalOpen(true, 'create')}>Create</Button>
        </Box>

        <NeatTable
          headers={this.headers()}
          rows={this.rows()}
        />

        { this.renderModal(modalOpen, modalType, modalObject)}
        { this.renderErrors() }
      </Box>
    )
  }
}

RolesContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(Object).isRequired,
  permissions: PropTypes.arrayOf(Object).isRequired,
}


function mapStateToProps(state) {
  const {
    rolesReducer,
    permissionsReducer,
  } = state
  const { roles } = rolesReducer
  const { permissions } = permissionsReducer
  return {
    permissions,
    roles,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(AdminStyles),
)(RolesContainer)
