/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMoon, faSun,
} from '@fortawesome/free-solid-svg-icons'
import {
  AppBar, Avatar, Grid, Tabs, Tab, Typography, IconButton,
  Menu, MenuItem, Popover, LinearProgress, Modal, Tooltip,
} from '@material-ui/core'
import { AccountCircle, ArrowDropDown } from '@material-ui/icons'
import { Camelize, Titleize } from '../helpers/StringHelper'

import RouterLocationPrototype from '../proptypes/RouterLocationPrototype'

import {
  fetchUserLogout,
} from '../actions/UsersActions'
import { fetchToggleDarkMode } from '../actions/DarkModeActions'
import { getRoute } from '../helpers/RouteHelper'
import { isFetchingRequests } from '../reducers/LoadingReducer'

import NeatRoutes from '../helpers/NeatRoutes'
import UserProfile from './UserProfile'
import NeatPermissions from '../helpers/NeatPermissions'
import NestedMenu from './NestedMenu'

/*
  General application site header that allows users to navigate the different routes in App.
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    display: 'inline-flex',
    flexGrow: 1,
  },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  accountSection: {
    display: 'inline-flex',
    flexGrow: 1,
  },
  queryBar: {
    height: 5,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: '5px',
    marginRight: '15px',
    marginLeft: '-5px',
  },
  userMenu: {
    marginTop: theme.spacing(1),
    right: 0,
  },
  darkModeToggleIcon: {
    marginLeft: 'auto',
  },
}))


function NestedDropdown({
  // eslint-disable-next-line react/prop-types
  location, anchorEl, links, onClose, handleChangeRoute,
}) {
  const open = Boolean(anchorEl)
  const id = open ? 'dropdown-popover' : undefined

  function getPath() {
    let match = false
    const path = location.pathname.split('/')
    links.forEach((link) => {
      if (link && link.path && link.path === `/${path[1]}/${path[2]}`) {
        // eslint-disable-next-line prefer-destructuring
        match = link[1]
      }
    })
    return match
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Tabs
        orientation="vertical"
        onChange={handleChangeRoute}
        value={getPath()}
        TabIndicatorProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {/* going forward, there shouldn't be any items in this without sub-menus */}
        {/* anything unrelated to ticketing should go in the 'other' dropdown' */}
        {/* so we don't need to worry about rendering the non-module group tab */}
        { links.map(link => (
          <NestedMenu
            key={link.key}
            id={link.key}
            label={link.key}
            anchorel={link.anchorEl}
            routes={link.routes}
            handleChangeRoute={handleChangeRoute}
          />
        ))}

      </Tabs>
    </Popover>
  )
}

NestedDropdown.propTypes = {
  location: RouterLocationPrototype.isRequired,
  links: PropTypes.arrayOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  handleChangeRoute: PropTypes.func.isRequired,
}

function Header({
  dispatch, isFetching, location, user, useDarkMode,
}) {
  const [anchorEl, setAnchorEl] = React.useState({
    menu: null,
    reporting: null,
    forms: null,
  })

  const [isUserProfileOpen, setUserProfileOpen] = React.useState(false)
  const classes = useStyles()

  const handleAnchor = item => (event) => {
    setAnchorEl({ ...anchorEl, [item]: event.currentTarget })
  }

  const closeAnchor = item => () => {
    setAnchorEl({ ...anchorEl, [item]: null })
  }

  function closeAnchors() {
    setAnchorEl({ menu: null, reporting: null, forms: null })
  }

  function logout() {
    closeAnchors()
    dispatch(fetchUserLogout())
  }

  function handleToggleDarkMode() {
    dispatch(fetchToggleDarkMode()) // saves the dark mode state to local storage
    window.location.reload()
  }

  // Change router location based on Tab value.
  function handleChangeRoute(event, route) {
    if (['/reporting', '/forms'].includes(route)) {
      return
    }
    closeAnchors()
    dispatch(push(route))
  }

  function handleShowUserProfile() {
    closeAnchors()
    setUserProfileOpen(true)
  }

  function getPath() {
    const ignoredPaths = ['/no-access', '/admin', '/customer_settings', '/monitoring', '/ticketing', '/tickets', '/outage-alerts', '/feature_request_forms']
    const { path } = getRoute(location.pathname)
    if (new RegExp(ignoredPaths.join('|')).test(path)) return false
    return `/${path.split('/')[1]}`
  }

  // this function's purpose is to create a array of
  // objects that can be used to render the reporting menu.
  // this will filter the results based on user permission so
  // that they can't see links they don't have access to

  function formattedRoutes(routes) {
    const returnRoutes = Object.keys(routes).map((key) => {
      const currentRoutes = Object.keys(routes[key])
      let currentPermission
      const anyPermission = currentRoutes.some((route) => {
        // set currentPermission here so we can access it later
        currentPermission = NeatRoutes.dashboards[key][route].permission
        return user.hasPermission(currentPermission)
      })

      // only add the main key if the user has permission for one of the subroutes
      if (anyPermission) {
        const permittedRoutes = currentRoutes.filter((route) => {
          // only return the sub-route if the user has permission for it
          return user.hasPermission(NeatRoutes.dashboards[key][route].permission)
        })
        return {
          key: Titleize(key),
          anchorEl: Camelize(key),
          routes: permittedRoutes.map((route) => {
            const currentRoute = routes[key][route]
            // return a hash with the current sub-route's label and path
            return {
              label: currentRoute.title,
              path: currentRoute.path,
            }
          }),
        }
      }
      // return undefined if they dont have any permissions
      return undefined
    }).filter(el => el !== undefined) // then filter out the undefineds
    return returnRoutes
  }


  const renderMenu = (
    <Menu
      className={classes.userMenu}
      getContentAnchorEl={null}
      id="account-menu"
      anchorEl={anchorEl.menu}
      open={Boolean(anchorEl.menu)}
      onClose={closeAnchor('menu')}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {(user.hasPermission(NeatPermissions.admin)
        || user.hasPermission(NeatPermissions.support_admin))
        && <MenuItem onClick={event => handleChangeRoute(event, '/admin/users')}>Admin Panel</MenuItem>
      }
      {/* if the user isn't an admin, but a customer admin, show them a link
      to the customer_settings page (not the admin page) */}
      {(
        !user.hasPermission(NeatPermissions.admin)
        && user.hasPermission(NeatPermissions.customer_admin)
      ) && <MenuItem onClick={event => handleChangeRoute(event, '/customer_settings')}>Customer Settings</MenuItem>}
      <MenuItem onClick={handleShowUserProfile}>Profile</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  )

  const renderUserProfile = (
    <Modal
      className={classes.modal}
      open={isUserProfileOpen}
      onClose={() => setUserProfileOpen(false)}
    >
      <React.Fragment>
        <UserProfile
          user={user}
          onClose={() => setUserProfileOpen(false)}
        />
      </React.Fragment>
    </Modal>
  )

  // this could probably be refactored to be similar to the NestedDropdown component
  // but it could get too complex, since this needs to render actual
  // anchor tags with the external links
  const renderExternalLinksDropdown = (
    <Menu
      getContentAnchorEl={null}
      id="external-links-menu"
      anchorEl={anchorEl.externalLinks}
      open={Boolean(anchorEl.externalLinks)}
      onClose={closeAnchor('externalLinks')}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      { user.external_links.map(link => (
        <MenuItem key={link.resource} onClick={closeAnchor('externalLinks')}>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href={link.url} rel="noopener noreferrer" target="_blank">{link.display_name}</a>
        </MenuItem>
      ))}
    </Menu>
  )

  const renderFormsDropdown = (
    <Menu
      getContentAnchorEl={null}
      id="forms-menu"
      anchorEl={anchorEl.forms}
      open={Boolean(anchorEl.forms)}
      onClose={closeAnchor('forms')}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      { Object.values(NeatRoutes.forms).map(link => (
        user.hasPermission(link.permission) && (
          <MenuItem key={link.path} onClick={e => handleChangeRoute(e, link.path)}>
            {link.title}
          </MenuItem>
        )
      ))}
    </Menu>
  )

  const renderReportingDropdown = (
    <NestedDropdown
      location={location}
      anchorEl={anchorEl.reporting}
      links={formattedRoutes(NeatRoutes.dashboards)}
      onClose={closeAnchor('reporting')}
      handleChangeRoute={handleChangeRoute}
      user={user}
    />
  )

  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar position="static">
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Tabs value={getPath()} onChange={handleChangeRoute} className={classes.tabs}>
                <Tab
                  label={
                    (
                      <Typography variant="inherit" color="inherit" className={classes.dropdown}>
                        <Avatar className={classes.icon} src={user.theme_icon} />
                        Welcome
                      </Typography>
                    )
                  }
                  value="/"
                />
                {
                  user.hasPermission(NeatRoutes.locations.permission) && (
                    <Tab
                      value={NeatRoutes.locations.path}
                      label={NeatRoutes.locations.title}
                    />
                  )
                }
                { /* since the function filters based on permissions, this wont show up
                if the user doesn't have at least 1 reporting permission */ }
                { formattedRoutes(NeatRoutes.dashboards).length && (
                  <Tab
                    onClick={handleAnchor('reporting')}
                    value="/reporting"
                    label={(
                      <Typography variant="inherit" color="inherit" className={classes.dropdown}>
                        Reporting
                        <ArrowDropDown fontSize="small" />
                      </Typography>
                    )}
                  />
                )}
                { user && user.external_links && user.external_links.length && (
                  <Tab
                    onClick={handleAnchor('externalLinks')}
                    label={(
                      <Typography variant="inherit" color="inherit" className={classes.dropdown}>
                        External links
                        <ArrowDropDown fontSize="small" />
                      </Typography>
                    )}
                  />
                )}

                <Tab
                  onClick={handleAnchor('forms')}
                  value="/forms"
                  label={(
                    <Typography variant="inherit" color="inherit" className={classes.dropdown}>
                      Forms
                      <ArrowDropDown fontSize="small" />
                    </Typography>
                  )}
                />
              </Tabs>
            </Grid>
            <Grid item className={classes.darkModeToggleIcon}>
              <Tooltip title={`Turn dark mode ${useDarkMode ? 'off' : 'on'}`} placement="bottom-start">
                <IconButton
                  aria-label="toggle-dark-mode"
                  aria-haspopup="false"
                  color="inherit"
                  onClick={() => handleToggleDarkMode()}
                >
                  <FontAwesomeIcon icon={useDarkMode ? faSun : faMoon} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleAnchor('menu')}
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>
        </AppBar>
        {renderMenu}
        {renderUserProfile}
        {renderReportingDropdown}
        {renderExternalLinksDropdown}
        {renderFormsDropdown}
        { isFetching && <LinearProgress className={classes.queryBar} variant="query" color="primary" /> }
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: RouterLocationPrototype.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  useDarkMode: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const isFetching = isFetchingRequests(state)
  const { location } = state.router
  const { user } = state.global

  return {
    isFetching,
    location,
    user,
  }
}

export default connect(
  mapStateToProps,
)(Header)
