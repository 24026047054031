import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box, IconButton, Typography,
  List, ListItem, ListItemText,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { hideError } from '../actions/ErrorActions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    backgroundColor: '#ffcccb',
    border: '1px solid red',
    borderRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  closeIcon: {
    // display: 'block',
    // marginLeft: 'auto',
  },
  headerContainer: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    margin: theme.spacing(1),
  },
  bodyContainer: {
    display: 'flex',
  },
}))

function ErrorNotification({ dispatch, errors, isOpen }) {
  const classes = useStyles()

  const handleClose = () => {
    dispatch(hideError())
  }

  return (
    isOpen && errors.length > 0 && (
      <Box className={classes.root}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.header} variant="h6">Server Errors Encountered:</Typography>
          <IconButton className={classes.closeIcon} onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.bodyContainer}>
          <List>
            { errors.map(error => (
              <ListItem key={error.error}>
                <ListItemText primary={error.error} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    )
  )
}

ErrorNotification.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Array),
  isOpen: PropTypes.bool,
}

ErrorNotification.defaultProps = {
  error: '',
  isOpen: false,
}

function mapStateToProps(state) {
  const { errors, isOpen } = state.errorsReducer
  return {
    errors,
    isOpen,
  }
}

export default connect(
  mapStateToProps,
)(ErrorNotification)
