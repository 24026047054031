import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    borderRadius: 10,
    color: 'white',
    padding: 10,
  },
  dt: {
    color: theme.palette.secondary.main,
  },
  dl: {
    color: theme.palette.secondary.main,
    display: 'flex',
    fontFamily: 'sans-serif',
  },
  dd: {
    color: theme.palette.secondary.main,
    marginLeft: 'auto',
  },
  font: {
    fontSize: 18,
  },
  header: {
    color: 'black',
    fontFamily: 'sans-serif',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
}))

export default function AverageDataUsage(props) {
  const classes = useStyles()
  const { avgSessionUp, avgSessionDown, avgSessionTotal } = props
  return (
    <div>
      <h4 className={classes.header}>Average Data Usage Per Guest</h4>
      <Paper className={classes.root}>
        <dl className={classes.dl}>
          <dt className={classes.dt}>Average Uploaded:</dt>
          <dd className={classes.dd}>{avgSessionUp || 'N/A'}</dd>
        </dl>

        <dl className={classes.dl}>
          <dt className={classes.dt}>Average Downloaded:</dt>
          <dd className={classes.dd}>{avgSessionDown || 'N/A'}</dd>
        </dl>

        <dl className={classes.dl}>
          <dt className={classes.dt}>Average Usage:</dt>
          <dd className={classes.dd}>{avgSessionTotal || 'N/A'}</dd>
        </dl>
      </Paper>
    </div>
  )
}

AverageDataUsage.propTypes = {
  avgSessionUp: PropTypes.string,
  avgSessionDown: PropTypes.string,
  avgSessionTotal: PropTypes.string,
}

AverageDataUsage.defaultProps = {
  avgSessionUp: 'N/A',
  avgSessionDown: 'N/A',
  avgSessionTotal: 'N/A',
}
