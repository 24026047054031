// This file is intended to hold the actual fetching functionality
// the redux actions go in their own file in the actions directory
import HandleTimeout from '../helpers/SessionHelper'
import {
  requestWelcomeTexts,
  receiveWelcomeTexts,
  failureWelcomeTexts,
  requestCreateWelcomeText,
  receiveCreateWelcomeText,
  failureCreateWelcomeText,
  requestUpdateWelcomeText,
  receiveUpdateWelcomeText,
  failureUpdateWelcomeText,
  requestDeleteWelcomeText,
  receiveDeleteWelcomeText,
  failureDeleteWelcomeText,
  requestToggleWelcomeTextActive,
  receiveToggleWelcomeTextActive,
  failureToggleWelcomeTextActive,
} from '../actions/WelcomeTextsActions'

export function fetchWelcomeTexts() {
  return (dispatch) => {
    dispatch(requestWelcomeTexts())
    return fetch('/api/welcome_texts')
      .then(response => HandleTimeout(response))
      .then(response => response.json())
      .then(json => dispatch(receiveWelcomeTexts(json)))
      .catch(error => dispatch(failureWelcomeTexts(error)))
  }
}

export function fetchCreateWelcomeText(welcomeText) {
  return (dispatch) => {
    dispatch(requestCreateWelcomeText(welcomeText))
    return fetch('/api/welcome_texts/create', {
      method: 'POST',
      body: welcomeText,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchWelcomeTexts())
          dispatch(receiveCreateWelcomeText(welcomeText))
          return json
        }
        dispatch(failureCreateWelcomeText(json))
        throw json
      })
  }
}

export function fetchUpdateWelcomeText(welcomeText) {
  return (dispatch) => {
    dispatch(requestUpdateWelcomeText(welcomeText))
    return fetch('/api/welcome_texts/update', {
      method: 'POST',
      body: welcomeText,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchWelcomeTexts())
          dispatch(receiveUpdateWelcomeText(welcomeText))
          return json
        }
        dispatch(failureUpdateWelcomeText(json))
        throw json
      })
  }
}

export function fetchDeleteWelcomeText(welcomeText) {
  return (dispatch) => {
    dispatch(requestDeleteWelcomeText(welcomeText))
    return fetch('/api/welcome_texts/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ welcomeText }),
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchWelcomeTexts())
          dispatch(receiveDeleteWelcomeText(welcomeText))
          return json
        }
        dispatch(failureDeleteWelcomeText(json))
        throw json
      })
  }
}

export function fetchToggleWelcomeTextActive(welcomeText) {
  return (dispatch) => {
    dispatch(requestToggleWelcomeTextActive())
    return fetch(`/api/welcome_texts/${welcomeText.id}/${welcomeText.active ? 'deactivate' : 'activate'}`, {
      method: 'PUT',
      body: welcomeText,
    })
      .then(response => HandleTimeout(response))
      .then(response => response.json().then(json => [json, response.ok]))
      .then(([json, ok]) => {
        if (ok) {
          dispatch(fetchWelcomeTexts())
          dispatch(receiveToggleWelcomeTextActive(welcomeText))
          return json
        }
        dispatch(failureToggleWelcomeTextActive(json))
        throw json
      })
  }
}
