import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { Grid, Button, LinearProgress } from '@material-ui/core'
import { isFetchingRequests } from '../reducers/LoadingReducer'
import {
  fetchClientUsageMetrics,
  fetchReturningClientMetrics,
  fetchClientDeviceTypePercentages,
  fetchClientSessionDetails,
} from '../actions/SitesActions'

const styles = theme => ({
  root: {
    marginLeft: '1.5rem',
    width: 'fit-content',
  },
  font: {
    fontFamily: theme.typography.fontFamily,
  },
  endDate: {
    marginLeft: '1rem',
  },
  button: {
    background: theme.palette.primary.main,
    color: '#b2c2e1',
    marginLeft: '1.5rem',
  },
  queryBar: {
    height: 5,
  },
})

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    const startDate = new Date()
    const pastDate = startDate.getDate() - 7

    this.state = {
      startDate: new Date(startDate.setDate(pastDate)),
      endDate: new Date(),
    }
  }

  handleStartDateChange(e) {
    this.setState({
      startDate: e,
    })
  }

  handleEndDateChange(e) {
    this.setState({
      endDate: e,
    })
  }

  refresh() {
    const { dispatch, siteid } = this.props
    const { startDate, endDate } = this.state
    const dateFilter = { start_date: startDate, end_date: endDate }
    if (siteid !== undefined) {
      dispatch(fetchClientUsageMetrics(siteid, dateFilter))
      dispatch(fetchReturningClientMetrics(siteid, dateFilter))
      dispatch(fetchClientDeviceTypePercentages(siteid, dateFilter))
      dispatch(fetchClientSessionDetails(siteid, dateFilter))
    }
  }


  render() {
    const { classes, isFetching } = this.props
    const { startDate, endDate } = this.state

    return (
      <Grid className={classes.root} container justify="flex-start" style={{ marginTop: '1rem' }}>
        { isFetching && <LinearProgress className={classes.queryBar} variant="query" color="primary" /> }
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="start-date"
            label="Start Date"
            value={startDate}
            onChange={(e => this.handleStartDateChange(e))}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

          <KeyboardDatePicker
            className={classes.endDate}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="end-date"
            label="End Date"
            value={endDate}
            onChange={(e => this.handleEndDateChange(e))}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={this.refresh}
        >
          Refresh
        </Button>
      </Grid>
    )
  }
}

DateRangePicker.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  siteid: PropTypes.string.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const isFetching = isFetchingRequests(state)
  const {
    sitesReducer, selectedStartDate,
  } = state
  const {
    classes,
  } = sitesReducer
  return {
    selectedStartDate,
    isFetching,
    classes,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(DateRangePicker)
