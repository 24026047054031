// This file contains helper methods for use with Objects

// This method will return the key in an object that corresponds to a provided value
export default function GetKeyFromValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

// didn't end up using this but i'm gonna keep this here
export function ObjectsEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  // eslint-disable-next-line consistent-return
  keys1.forEach((key) => {
    if (object1[key] !== object2[key]) {
      return false
    }
  })

  return true
}
