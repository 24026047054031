import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import NeatTable from '../components/NeatTable'
import { fetchDistributedOutages } from '../actions/OutagesActions'
import {
  OutagePopover, OutageCharts, showOpenTickets, showLocationsDashboard, formatStatistics,
} from '../helpers/OutageDashboardHelper'

class DistributedOutageDashboardContainer extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    const { dispatch } = props
    dispatch(fetchDistributedOutages())
    this.state = {
      selectedIndex: -1,
      selectedData: null,
      open: false,
      x: 0,
      y: 0,
    }
  }

  onClose = () => {
    this.setState({ open: false })
  }

  onRowClicked = (_row, index, e) => {
    this.setState({
      selectedIndex: index, open: true, x: e.clientX, y: e.clientY,
    })
  }

  showStatistics = () => {
    const { distributedOutages } = this.props
    const { selectedIndex } = this.state
    const outage = distributedOutages[selectedIndex]
    const selectedData = formatStatistics(outage, 'distributed')
    this.setState({ selectedData, open: false })
  }

  headers = () => ([
    {
      id: 'location',
      label: 'Location',
      filterable: true,
      filterType: 'text',
    },
    {
      id: 'mtk',
      label: 'MTK',
    },
    {
      id: 'ap',
      label: 'AP',
    },
    {
      id: 'pdu',
      label: 'PDU',
    },
    {
      id: 'total',
      label: 'Total',
    },
    {
      id: 'percent_down',
      label: '% Down',
      filterable: true,
      filterLabel: 'Percent Down',
      // eslint-disable-next-line no-restricted-globals
      format: val => `${isNaN(val) ? 0 : parseFloat(val).toFixed(0)}%`,
      type: 'range',
      mapping: {
        '0 - 5%': '0-5',
        '5 - 25%': '5-25',
        '25 - 50%': '25-50',
        '50 - 75%': '50-75',
        '75 - 100%': '75-100',
      },
    },
    {
      id: 'open_tickets',
      label: 'Open Tickets',
      filterable: true,
      filterLabel: 'Open Tickets',
      type: 'range',
      mapping: {
        'No Open Tickets': '0-0',
        // this is a range, so i'm using 100,000,000 as the max for now.
        'At Least 1 Open Ticket': '1-100000000',
      },
    },
  ])

  createRow = outage => ({
    location: outage.location,
    mtk: `${outage.mtk_total - outage.mtk_down}/${outage.mtk_total}`,
    ap: `${outage.ap_total - outage.ap_down}/${outage.ap_total}`,
    pdu: `${outage.pdu_total - outage.pdu_down}/${outage.pdu_total}`,
    total: `${outage.all_total - outage.all_down}/${outage.all_total}`,
    percent_down: outage.all_down / outage.all_total * 100,
    open_tickets: outage.open_ticket_count,
  })

  rows = () => {
    const { distributedOutages } = this.props
    const rows = []
    distributedOutages.forEach((co) => {
      rows.push(this.createRow(co))
    })
    return rows
  }

  renderPopover() {
    const {
      open, x, y, selectedIndex,
    } = this.state
    const { dispatch, distributedOutages } = this.props

    return (
      <OutagePopover
        open={open}
        onClose={this.onClose}
        x={x}
        y={y}
        showStats={this.showStatistics}
        showOpenTickets={() => showOpenTickets(dispatch, distributedOutages, selectedIndex)}
        showLocationDb={() => showLocationsDashboard(dispatch, distributedOutages, selectedIndex)}
      />
    )
  }

  render() {
    const { selectedData } = this.state

    return (
      <Box p={1}>
        <NeatTable
          small
          headers={this.headers()}
          rows={this.rows()}
          pageSize={15}
          onRowClicked={this.onRowClicked}
        />
        <Box mt={1} height={250}>
          { selectedData && (
            <OutageCharts
              data={selectedData}
              chartWidth={4}
              type="distributed"
            />
          )}
        </Box>
        { this.renderPopover() }
      </Box>
    )
  }
}

DistributedOutageDashboardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  distributedOutages: PropTypes.arrayOf(Object).isRequired,
}

function mapStateToProps(state) {
  const { outagesReducer } = state
  const {
    distributedOutages,
  } = outagesReducer
  return {
    distributedOutages,
  }
}

export default connect(mapStateToProps)(DistributedOutageDashboardContainer)
