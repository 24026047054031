import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import {
  Box, Paper, Grid, Typography, withStyles,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle, faExclamationTriangle, faSkull, faWrench,
} from '@fortawesome/free-solid-svg-icons'
import GatewayDetailsProptype from '../proptypes/GatewayDetailsProptype'
import NeatPermissions from '../helpers/NeatPermissions'

const styles = () => ({
  title: {
    color: 'grey',
  },
  detailKey: {
    float: 'right',
  },
  detailValue: {
    marginLeft: '.5rem',
  },
})

// this component will render multiple gateway devices as pages
class GatewayDetails extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
    }
  }

  handlePageChanged = (value) => {
    let { page } = this.state
    const { details } = this.props
    page += value
    if (page >= 0 && page < details.length) {
      this.setState({ page })
    }
  }

  formUrl = (host) => {
    const reg = new RegExp(/^https?:\/\//i)
    // if it's already an https:// or http:// url, just return that
    if (reg.test(host)) {
      return host
    }
    // otherwise, we need to add the basic http:// to the front of the bare IP address
    return `http://${host}`
  }

  // oof this desperately needs a refactor
  render() {
    const { page } = this.state
    const { classes, user, details } = this.props
    const selectedDetails = details[page] || {}
    // eslint-disable-next-line max-len
    const alertStatus = selectedDetails.gateway_device_statuses.sort((a, b) => b.alert_state_rank - a.alert_state_rank)[0]
    let statusIcon
    if (alertStatus.alert_state_rank === 0) statusIcon = <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
    else if (alertStatus.alert_state_rank === 1) statusIcon = <FontAwesomeIcon style={{ color: 'blue' }} icon={faExclamationTriangle} />
    else if (alertStatus.alert_state_rank === 2) statusIcon = <FontAwesomeIcon style={{ color: 'orange' }} icon={faExclamationTriangle} />
    else if (alertStatus.alert_state_rank === 3) statusIcon = <FontAwesomeIcon style={{ color: 'red' }} icon={faExclamationTriangle} />
    else if (alertStatus.alert_state_rank === 4) statusIcon = <FontAwesomeIcon style={{ color: 'red' }} icon={faExclamationTriangle} />
    else if (alertStatus.alert_state_rank === 4 && alertStatus.in_sdt) statusIcon = <FontAwesomeIcon style={{ color: 'yellow' }} icon={faWrench} />
    const information = [
      ['Name', selectedDetails.name],
      ['Host', selectedDetails.link || selectedDetails.host], // default back to the host if the device doesn't have a 'link' property
      ['Alert Status', statusIcon],
      ['Host Status', selectedDetails.status === 'normal'
        ? <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
        : <FontAwesomeIcon style={{ color: 'red' }} icon={faSkull} />],
      ['Uptime', selectedDetails.formatted_uptime],
    ]
    const informationViews = []
    information.forEach((i) => {
      informationViews.push(
        <Grid item xs={4} key={`label_${i[0]}`}>
          <Typography className={classes.detailKey} variant="body1">{`${i[0]}:`}</Typography>
        </Grid>,
        <Grid item xs={8} key={`value_${i[0]}_${i[1]}`}>
          {
            i[0] === 'Host' && user.hasPermission(NeatPermissions.devices.redirect) ? (
              <a href={this.formUrl(i[1])} rel="noopener noreferrer" target="_blank">
                <Typography className={classes.detailValue} variant="body1">{i[1]}</Typography>
              </a>
            )
              : (<Typography className={classes.detailValue} variant="body1">{i[1]}</Typography>)
          }
        </Grid>,
      )
    })

    return (
      <Box component={Paper} height="100%" display="flex" flexDirection="column">
        <Box height="100%" p={1} display="flex" flexDirection="column">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="subtitle1">Gateway Details</Typography>
            </Grid>
            <Grid container spacing={0} item xs={12}>
              {informationViews}
            </Grid>
          </Grid>
          <Box height="100%" display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <ChevronLeft
                color={page === 0 ? 'disabled' : 'inherit'}
                onClick={() => this.handlePageChanged(-1)}
              />
              <ChevronRight
                color={page >= details.length - 1 ? 'disabled' : 'inherit'}
                onClick={() => this.handlePageChanged(1)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

GatewayDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  details: PropTypes.arrayOf(GatewayDetailsProptype).isRequired,
}

function mapStateToProps(state) {
  const { user } = state.global
  return {
    user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(GatewayDetails)
