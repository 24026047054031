import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import {
  Box,
  Paper,
  TextareaAutosize,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  paper: {
    flex: 1,
    width: '50%',
    padding: theme.spacing(2),
    overflowY: 'scroll',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
  },
}))

// this accepts an object as an argument
// and destructures the necessary variables
const renderCheckbox = ({
  checked, onChange, name, label,
}) => (
  <FormControlLabel
    key={name}
    control={<Checkbox checked={checked} onChange={e => onChange(e.target.checked)} name={name} />}
    label={label}
  />
)

// this takes any number of objects as arguments
// the objects are created by createCheckboxArgs and describe
// checked, onChange, name, and label for each checkbox
const renderCheckboxes = (...checkboxArgs) => {
  const classes = useStyles()
  return (
    <Box>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          { checkboxArgs.map(checkboxArg => renderCheckbox(checkboxArg)) }
        </FormGroup>
      </FormControl>
    </Box>
  )
}

// this is shorthand function syntax to return an object with
// keys that are the same name as the function arguments
const createCheckboxArgs = (checked, onChange, name, label) => ({
  checked, onChange, name, label,
})

renderCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const renderMarkdownPreview = (markdown) => {
  const classes = useStyles()
  return (
    <ScrollSyncPane>
      <Paper className={classes.paper}>
        <ReactMarkdown className={classes.text} source={markdown} />
      </Paper>
    </ScrollSyncPane>
  )
}

const renderMarkdownEditor = (markdown, onChange) => {
  const classes = useStyles()
  return (
    <ScrollSyncPane>
      <Paper className={classes.paper}>
        <TextareaAutosize
          style={{ maxWidth: '100%', width: '100%' }}
          aria-label="markdown-input"
          rowsMin={20}
          value={markdown}
          onChange={e => onChange(e.target.value)}
          name="markdownText"
        />
      </Paper>
    </ScrollSyncPane>
  )
}

export default function WelcomeTextEditor({
  active, handleActiveChanged, markdownText, handleMarkdownTextChanged,
}) {
  const classes = useStyles()
  const activeCheckboxArgs = createCheckboxArgs(active, handleActiveChanged, 'active', 'Active?')

  return (
    <ScrollSync>
      <div className={classes.box}>
        { renderCheckboxes(activeCheckboxArgs) }
        { renderMarkdownEditor(markdownText, handleMarkdownTextChanged) }
        { renderMarkdownPreview(markdownText) }
      </div>
    </ScrollSync>
  )
}

WelcomeTextEditor.propTypes = {
  active: PropTypes.bool.isRequired,
  markdownText: PropTypes.string.isRequired,
  handleActiveChanged: PropTypes.func.isRequired,
  handleMarkdownTextChanged: PropTypes.func.isRequired,
}
