import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
} from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
    width: '100%',
    maxHeight: theme.typography.subtitle1.lineHeight * 16 * 2,
    overflow: 'auto',
  },
}))
const OutageAlert = ({ outageAlert }) => {
  const classes = useStyles()
  const { active, message } = outageAlert
  return (
    <Box key="outage-alert" display="flex" p={1} bgcolor="#f5ad42" border={1} borderColor="#eb8f07" borderRadius="borderRadius">
      { active && (
        <Typography
          className={classes.title}
          variant="subtitle1"
        >
          <Box width="fit-content" margin="auto">{message}</Box>
        </Typography>
      )}
    </Box>
  )
}

OutageAlert.propTypes = {
  outageAlert: PropTypes.instanceOf(Object).isRequired,
}

export default OutageAlert
