import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

import {
  Typography, Box, ButtonBase, withStyles,
} from '@material-ui/core'

import TicketsDetailsProptype from '../proptypes/TicketsDetailsProptype'
import TicketDashboardContainer from '../containers/TicketsDashboardContainer'
import FullScreenModal from './FullScreenModal'

const styles = theme => ({
  open: {
    backgroundColor: theme.palette.background.paper,
  },
  closed: {
    backgroundColor: theme.palette.grey['400'],
  },
})

class TicketWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      status: '',
    }
  }

  toggleModal = (status) => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen, status })
  }

  ticketDashboardContainer = () => {
    const { siteId } = this.props
    const { status } = this.state
    return (
      <TicketDashboardContainer siteId={siteId} status={status} />
    )
  }

  renderOpenTickets() {
    const { classes, details } = this.props
    const { counts } = details
    return (
      <Box
        component={ButtonBase}
        className={classes.open}
        width="100%"
        height="100%"
        onClick={() => this.toggleModal('unresolved')}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={5}
        mb={2}
        boxShadow={1}
        border={1}
        borderColor="red"
        borderRadius="borderRadius"
      >
        <Box>
          <Typography variant="h2" style={{ color: 'red' }}>
            {/* if there are no open counts returned from the concern
              then this will be undefined and won't show up, so show 0
            */}
            {counts.unresolved || 0}
          </Typography>
          <Box mt={2}>
            <Typography style={{ color: 'red' }}>
              Open Tickets
            </Typography>
            <Typography variant="subtitle2" style={{ color: 'red' }}>
              {counts.open_last_12_hours}
              {' Last 12 Hours'}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  renderClosedTickets() {
    const { classes, details } = this.props
    const { counts } = details

    return (
      <Box
        component={ButtonBase}
        className={classes.closed}
        width="100%"
        height="100%"
        onClick={() => this.toggleModal('resolved')}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={5}
        boxShadow={1}
        borderRadius="borderRadius"
      >
        <Typography variant="h2" style={{ color: '#FFF' }}>
          {counts.resolved || 0}
        </Typography>
        <Box mt={2}>
          <Typography style={{ color: '#FFF' }}>
            Closed Tickets
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    const { modalOpen } = this.state
    return (
      <React.Fragment>
        <Box display="flex" flexDirection="row" width="100%" height="100%">
          { this.renderOpenTickets() }
          <Box m={1} />
          { this.renderClosedTickets() }
        </Box>
        <FullScreenModal
          open={modalOpen}
          onClose={() => this.toggleModal()}
          childComponent={this.ticketDashboardContainer()}
          title="Ticket Dashboard"
        />
      </React.Fragment>
    )
  }
}

TicketWidget.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  details: TicketsDetailsProptype.isRequired,
  siteId: PropTypes.string.isRequired,
}


export default compose(
  withStyles(styles),
  connect(),
)(TicketWidget)
