import React from 'react'
import PropTypes from 'prop-types'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Titleize } from '../../helpers/StringHelper'
import TreeNode from '../tree/TreeNode'

class PermissionTree extends React.Component {
  getChildren = (parent, permissions) => {
    const children = []
    permissions.filter(p => p.parent && p.parent === parent.resource).forEach((p) => {
      p.children.push(...this.getChildren(p, permissions))
      children.push(p)
    })
    return children
  }

  getPermissionsTree = () => {
    const { availablePermissions } = this.props
    const permissions = []
    availablePermissions.forEach((p) => {
      const temp = { ...p, children: [] }
      permissions.push(temp)
    })
    const tree = []
    permissions.filter(p => !p.parent).forEach((p) => {
      p.children.push(...this.getChildren(p, permissions))
      tree.push(p)
    })
    return tree
  }

  getPermissionParents = (permission) => {
    const { availablePermissions } = this.props
    const parents = []
    if (!permission.parent) {
      return parents
    }
    availablePermissions.forEach((p) => {
      if (p.resource === permission.parent) {
        parents.push(...this.getPermissionParents(p))
        parents.push(p)
      }
    })
    return parents
  }

  getPermissionChildren = (permission) => {
    const { availablePermissions } = this.props
    const children = []
    availablePermissions.forEach((p) => {
      if (permission.resource === p.parent) {
        children.push(...this.getPermissionChildren(p))
        children.push(p)
      }
    })
    return children
  }

  cleanArray = (array, elements = []) => {
    const temp = array
    elements.forEach((e) => {
      const index = temp.indexOf(e)
      if (index > -1) {
        temp.splice(index, 1)
      }
    })
    return temp
  }

  handlePermissionSelect = (event, flag) => {
    const { availablePermissions, handleSelectPermissions } = this.props
    const { selectedPermissions } = this.props
    const id = event.target.value
    const permission = availablePermissions.filter(p => p.resource === id)[0]
    if (flag) {
      const parents = this.getPermissionParents(permission)
      parents.forEach((parent) => {
        if (selectedPermissions.filter(p => p.id === parent.resource).length === 0) {
          selectedPermissions.push({
            id: parent.resource,
            value: parent.resource,
            label: parent.title,
          })
        }
      })
      selectedPermissions.push({
        id: permission.resource,
        value: permission.resource,
        label: permission.title,
      })
    } else {
      // eslint-disable-next-line max-len
      let index = selectedPermissions.indexOf(selectedPermissions.filter(p => p.id === permission.resource)[0])
      selectedPermissions.splice(index, 1)
      const children = this.getPermissionChildren(permission)
      children.forEach((child) => {
        // eslint-disable-next-line max-len
        index = selectedPermissions.indexOf(selectedPermissions.filter(p => p.id === child.resource)[0])
        if (index >= 0) {
          selectedPermissions.splice(index, 1)
        }
      })
    }
    handleSelectPermissions(selectedPermissions)
  }

  renderTreeNode = (branch) => {
    const { selectedPermissions } = this.props
    return branch.map(p => (
      <TreeNode
        key={p.resource}
        label={p.title}
        description={p.description}
        id={p.resource}
        handleChange={this.handlePermissionSelect}
        checked={selectedPermissions.filter(sp => sp.id === p.resource).length > 0}
      >
        {p.children && p.children.length > 0 && this.renderTreeNode(p.children)}
      </TreeNode>
    ))
  }

  // there's too many subtle differences here to refactor the existing
  // renderTreeNode function. But if you can think of a better way...
  renderDisabledTreeNode = (branch) => {
    const { selectedPermissions } = this.props
    const checkedPermissions = selectedPermissions.map(resource => resource[0])
    return branch.map((p) => {
      const roles = selectedPermissions.filter(arr => arr[0] === p.resource).flat()[1] || []
      const checked = checkedPermissions.includes(p.resource)

      return (
        <TreeNode
          key={p.resource}
          disableCheckboxes
          label={p.title}
          description={`${roles.length > 0 ? 'Granted By: ' : ''}${roles.join(', ')}`}
          id={p.resource}
          checked={checked}
        >
          {p.children && p.children.length > 0 && this.renderDisabledTreeNode(p.children)}
        </TreeNode>
      )
    })
  }

  getPermissionsFor = (identifier, permissions = this.getPermissionsTree()) => {
    const matcher = `^${identifier}`
    return permissions.filter(permission => permission.resource.match(matcher) !== null)
  }

  buildPermissionsObject = () => ({
    admin: this.getPermissionsFor('admin'),
    customerAdmin: this.getPermissionsFor('customer_admin'),
    supportAdmin: this.getPermissionsFor('support_admin'),
    sites: this.getPermissionsFor('sites'),
    tickets: this.getPermissionsFor('tickets'),
    devices: this.getPermissionsFor('devices'),
    gateways: this.getPermissionsFor('gateways'),
    outageAlerts: this.getPermissionsFor('outage_alerts'),
    outages: this.getPermissionsFor('outages'),
    externalLinks: this.getPermissionsFor('external_links'),
    forms: this.getPermissionsFor('forms'),
  })

  render() {
    const { disableCheckboxes } = this.props
    const permissions = this.buildPermissionsObject()

    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        {Object.keys(permissions).map((label, index) => (
          <TreeItem key={label} nodeId={String(index)} label={Titleize(label)}>
            { disableCheckboxes ? (
              this.renderDisabledTreeNode(permissions[label])
            ) : (
              this.renderTreeNode(permissions[label])
            )}

          </TreeItem>
        ))}

      </TreeView>
    )
  }
}

PermissionTree.propTypes = {
  availablePermissions: PropTypes.arrayOf(Object).isRequired,
  disableCheckboxes: PropTypes.bool,
  selectedPermissions: PropTypes.arrayOf(Object),
  handleSelectPermissions: PropTypes.func,
}

PermissionTree.defaultProps = {
  disableCheckboxes: false,
  selectedPermissions: null,
  handleSelectPermissions: null,
}

export default PermissionTree
