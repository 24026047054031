import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@material-ui/core'

export default function DeleteConfirmation(props) {
  const {
    classes, onClose, onDelete, record, title,
  } = props

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4">{`Delete ${title}?`}</Typography>
      <Box className={classes.formControl} display="flex" justifyContent="flex-start" mt={1}>
        <Typography>
          {`Are you sure you want to delete the ${record.name ? record.name : ''} ${title}? This action cannot be undone.`}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button onClick={() => onDelete({ id: record.id })} variant="text" className={classes.deleteButton}>
          Delete
        </Button>
      </Box>
    </Box>
  )
}

DeleteConfirmation.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  record: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
}
